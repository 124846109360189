import React from 'react';
import { StandardText, FontSizes, FontWeights } from '@brandfolder/react';
import { IconAlertFilled, IconSize } from '@integration-frontends/common/ui';
import './error-banner.scss';

interface ErrorBannerProps {
  credentialError: string;
  header: string;
}

export const ErrorBanner = ({ credentialError, header }: ErrorBannerProps) => {
  const errorMessage = credentialError.replace(/^\:\s+/, '');
  return (
    <div className="create-credential-error-banner">
      <div className="icon">
        <IconAlertFilled className="icon-alert-filled" iconSize={IconSize.Large} />
      </div>
      <div className="text" data-testid="credential-modal-error-text">
        <StandardText size={FontSizes.Small} weight={FontWeights.Bold}>
          {header}
        </StandardText>
        {errorMessage}
      </div>
    </div>
  );
};
