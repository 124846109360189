import { all, fork, call } from 'typed-redux-saga';
import { authWorkflow } from './auth-workflow';
import { initWorkflow } from './init-workflow';
import { createPersistRouteEffect } from '@integration-frontends/apps/common/routing/route-persistence';
import { getObservabilityService } from '@integration-frontends/core';

export function* rootWorkflow() {
  try {
    yield initWorkflow();
    yield all([
      fork(authWorkflow),
      fork(createPersistRouteEffect([/\/clients/])),
      fork(createPersistRouteEffect([/\/workflows/])),
      fork(createPersistRouteEffect([/\/create-workflow\/app-info/])),
    ]);
  } catch (e) {
    yield call(getObservabilityService().addError, e);
  }
}
