import { StandardTagger } from '@brandfolder/react';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import {
  formOptionList,
  generateWorkfrontProjectForms,
  handleFormOptionUpdateByForm,
} from '../common/utils';

export const ProjectFormsTagger = () => {
  const { watch } = useFormContext();
  const workfrontPortfolioCustomFields = watch('portfolio_custom_fields');
  const workfrontProgramCustomFields = watch('program_custom_fields');
  const onTagged = handleFormOptionUpdateByForm();

  return (
    <StandardTagger
      id="workfront-project-forms-tagger"
      data-testid="workfront-project-forms-tagger"
      labels={{
        iconButtonLabel: 'Toggle list of Workfront project forms',
        label: 'Project forms',
        listboxLabel: 'List of Workfront project forms',
        removeTagLabel: 'Remove Workfront project form',
      }}
      onTagged={onTagged}
      allowCustomValue={false}
      allowDuplicates={false}
      options={formOptionList('workfront_project_forms')}
      className="project-forms-tagger-container"
      initialTags={generateWorkfrontProjectForms(
        workfrontPortfolioCustomFields,
        workfrontProgramCustomFields,
      )?.map((item) => {
        const key = Object.keys(item)[0];
        const value = item[key];
        return {
          value: value as string,
          children: <>{value}</>,
          disabled: false,
          key: key,
          search: value as string,
        };
      })}
    />
  );
};
