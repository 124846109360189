import { BFLabel, BFSelect, BFSelectOption } from '@integration-frontends/common/ui';
import React, { ReactElement } from 'react';
import { FieldArrayWithId, useFormContext, useWatch } from 'react-hook-form';
import { Mappings } from '../dest-config';
import { FieldArrayType } from '../salsify-property/select-salsify-property';
import './select-custom-field-value.scss';
import { useSelector } from 'react-redux';
import { selectCustomFieldValues } from '@integration-frontends/workflow-manager/core/application';

export interface SelectCustomFieldValueProps {
  fields: FieldArrayWithId<Mappings, 'dest_config', 'id'>[];
  index: number;
}

export const SelectCustomFieldValue = ({
  fields,
  index,
}: SelectCustomFieldValueProps): ReactElement => {
  const {
    control,
    formState: { errors },
    register,
    setValue,
    watch,
  } = useFormContext();
  const mappings = useWatch({
    name: 'dest_config',
    control,
  }) as unknown as FieldArrayType;
  const customFieldValues = useSelector(selectCustomFieldValues);
  const customFieldKey = watch(`dest_config.${index}.key`);
  const watchFieldArray = watch(`dest_config.${index}`);
  const controlledFields = fields.map((field, index) => {
    return {
      ...field,
      ...watchFieldArray[index],
    };
  });
  const selectedCustomFieldValues = controlledFields
    ?.filter((mapping, i) => customFieldKey === mapping.key && i !== index)
    .map((mapping) => mapping.value);
  const customFieldValueOptions = customFieldValues?.[customFieldKey]?.filter(
    (value) => !selectedCustomFieldValues?.includes(value),
  );
  const customFieldValueSources: BFSelectOption[] = customFieldValueOptions?.map((value) => {
    return { label: value, value: value };
  });

  return (
    <div className="optional-mapping-row-item">
      <BFLabel className="form-label" htmlFor="optional-custom-field-value">
        Brandfolder custom field value
      </BFLabel>
      <BFSelect
        data-testid={`select-custom-field-value-dest-config-${index}`}
        disabled={!customFieldKey}
        id="custom-field-value"
        required={true}
        options={customFieldValueSources || []}
        {...(customFieldKey && register(`dest_config.${index}.value`))}
        onOptionChange={(listOption): void => {
          setValue(`dest_config.${index}.value`, listOption?.value || ('' as string), {
            shouldValidate: true,
            shouldDirty: true,
          });
        }}
        value={customFieldValueSources?.find((cf) => cf.value === mappings[index]?.value)?.value}
        placeholder={'Choose Value'}
      />
    </div>
  );
};
