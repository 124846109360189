import { put, all, select, takeEvery } from 'typed-redux-saga';
import { createNotification, NotificationType } from '@integration-frontends/common/notifications';
import {
  callWithTokenRefresh,
  seismicEditFetchInit,
  seismicEditFetchLoaded,
  seismicRehydratedCollectionMaps,
  seismicRehydratedSectionMaps,
  seismicRehydrateSyncMaps,
  selectSelectedClientId,
} from '@integration-frontends/workflow-manager/core/application';
import {
  FORM_INFO_REPO_TOKEN,
  IFormInfoRepo,
} from '@integration-frontends/workflow-manager/core/model';
import { DI_CONTAINER } from '@integration-frontends/core';
import { pageLoadError } from '@integration-frontends/common/app';
import { SeismicSyncMap } from '@integration-frontends/common/temporal-api';

function* handler(action: ReturnType<typeof seismicRehydrateSyncMaps>) {
  yield put(seismicEditFetchInit());
  try {
    const { bf_info, credential_id, sync_map_object, teamsite_id, sync_map_type } = action.payload;
    const formInfoRepo: IFormInfoRepo = DI_CONTAINER.get(FORM_INFO_REPO_TOKEN);
    const clientId = yield select(selectSelectedClientId);
    const FOLDERS_PER_PAGE = 20;

    let formattedSyncMaps: SeismicSyncMap[] = [];

    if ( bf_info && sync_map_type ) {
      Object.keys(sync_map_object).map((srcId, index) => {
        const bfSubset = bf_info?.find((data) => {
          return data?.value === srcId;
        });
        formattedSyncMaps[index] = {
          bfSrcContainer: { name: bfSubset?.label, id: bfSubset?.value },
          seismicDestinationContainer: {
            name: null,
            id: null,
          },
        };
      });
    } else {
      formattedSyncMaps = [
        {
          bfSrcContainer: { name: null, id: null },
          seismicDestinationContainer: {
            name: null,
            id: null,
          },
        },
      ];
    }

    const destinations = yield all(
      Object.values(sync_map_object).map((destId) =>
        callWithTokenRefresh(formInfoRepo.getSeismicFormInfo, clientId, {
          credential_id,
          folder_id: destId,
          teamsite_id,
          per: FOLDERS_PER_PAGE,
          page: 1,
        }),
      ),
    );
    if (destinations) {
      destinations.forEach((destination, index) => {
        formattedSyncMaps[index] = {
          bfSrcContainer: {
            name: formattedSyncMaps[index]?.bfSrcContainer?.name,
            id: formattedSyncMaps[index]?.bfSrcContainer?.id,
          },
          seismicDestinationContainer: {
            name: destination?.current_folder?.name,
            id: destination?.current_folder?.id,
          },
        };
      });
    } else {
      yield put(
        createNotification({
          message: 'Failed to recover Seismic folders',
          location: 'topLevel',
          type: NotificationType.Error,
        }),
      );
    }

    switch (sync_map_type) {
      case 'section_to_folder':
        yield put(seismicRehydratedSectionMaps({ maps: formattedSyncMaps }));
        yield put(seismicEditFetchLoaded());
        break;
      case 'collection_to_folder':
        yield put(seismicRehydratedCollectionMaps({ maps: formattedSyncMaps }));
        yield put(seismicEditFetchLoaded());
        break;
    }
  } catch (e) {
    yield put(pageLoadError({ error: e }));
    yield put(seismicEditFetchLoaded);
  }
}

export function* seismicRehydrateSyncMapsEffects() {
  yield takeEvery(seismicRehydrateSyncMaps, handler);
}
