import {
  OauthBody,
  TemporalApi,
  getResponseDataOrDefault,
} from '../../../../../common/temporal-api/src';

import { IOauthService } from '../../../../core/model/src/index';

import { injectable } from 'inversify';
import { ServiceBase } from './service-base';
import { environment } from '@integration-frontends/apps/workflows-admin-ui/src/environments/environment';

@injectable()
export class OauthService extends ServiceBase implements IOauthService {
  signInOauth = async (clientId: string, oauthbody: OauthBody): Promise<any[]> => {
    return this.temporalApi
      .oauthSignIn(await this.getApiKey(), oauthbody, clientId)
      .then(getResponseDataOrDefault)
      .then((res) => {
        return res || [];
      });
  };
  getHostUrl(): string {
    return environment.hostUrl;
  }
  refreshBrandfolderOauthToken = async (refreshToken: string): Promise<any> => {
    return this.temporalApi
      .refreshBrandfolderOauthToken(refreshToken) 
  };
}
