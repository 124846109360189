import { put, select, takeEvery } from 'typed-redux-saga';
import { createNotification, NotificationType } from '@integration-frontends/common/notifications';
import {
  callWithTokenRefresh,
  highspotFetchSpots,
  highspotReceiveSpots,
} from '@integration-frontends/workflow-manager/core/application';
import {
  FORM_INFO_REPO_TOKEN,
  IFormInfoRepo,
} from '@integration-frontends/workflow-manager/core/model';
import { DI_CONTAINER } from '@integration-frontends/core';
import { pageLoadError } from '@integration-frontends/common/app';
import { selectSelectedClientId } from '../../../../../../../application/src/lib/create-workflow';

function* handler(action: ReturnType<typeof highspotFetchSpots>) {
  try {
    const { credential } = action.payload;
    const formInfoRepo: IFormInfoRepo = DI_CONTAINER.get(FORM_INFO_REPO_TOKEN);
    const clientId = yield select(selectSelectedClientId);

    const spots = yield callWithTokenRefresh(formInfoRepo.getHighspotFormInfo, clientId, {
      credential_id: credential.id,
      api_host: credential.host,
    });

    if (spots) {
      yield put(highspotReceiveSpots({ spots: spots.collection }));
    } else {
      yield put(
        createNotification({
          message: 'Failed to fetch Spots',
          location: 'topLevel',
          type: NotificationType.Error,
        }),
      );
    }
  } catch (e) {
    yield put(pageLoadError({ error: e }));
  }
}

export function* hightspotFetchSpotsEffects() {
  yield takeEvery(highspotFetchSpots, handler);
}
