import {
  Colors,
  FontIcon,
  FontIconColors,
  FontIcons,
  FontSizes,
  FontWeights,
  Placements,
  StandardCombobox,
  StandardLabel,
  StandardText,
  StandardTextfield,
  StandardTooltip,
  TooltipTypes,
  Widths,
} from '@brandfolder/react';
import { BFMultiSelect, BFMultiSelectOption, Loader } from '@integration-frontends/common/ui';
import { BFErrorLabel } from '@integration-frontends/common/ui/forms/form-controls/form-controls';
import {
  BFSelect,
  BFSelectOption,
} from '@integration-frontends/common/ui/forms/form-controls/select';
import {
  selectBrandfolderFormInfo,
  selectSelectedCredentialId,
  selectAsanaState,
  asanaSetCredentialId,
  asanaFetchFormInfo,
  selectSelectedClientId,
  selectAsanaWorkspaces,
  selectAsanaProjects,
  selectAsanaSections,
  asanaClearFormInfo,
  asanaClearFormInfoSections,
  getCustomFieldFormInfo,
  selectallCustomFieldKeys,
  selectAsanaInitialTags,
  selectWorkflowMode,
  workflowModes,
  selectAsanaProjectsLoading,
  selectAsanaSectionsLoading,
  selectAsanaWorkspacesLoading,
} from '@integration-frontends/workflow-manager/core/application';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { SelectBrandfolder } from '../../common/components/select-brandfolder/select-brandfolder';
import { WorkflowInfo } from '../../common/components/workflow-info/workflow-info';
import { WorkflowName } from '../../common/components/workflow-name.tsx/workflow-name';

import './workflow-form-asana.scss';
import '../../create-workflow-form.scss';
import { alphabetizBfOptions } from '../../../common/functions/alphabetize';
import { StandardTagger } from '@brandfolder/react';
import { ListBoxOption } from '../workflow-form-workfront/common/utils';

export const CreateAsanaWorkflowFormContainer = ({ formComplete }) => {
  const {
    register,
    formState: { errors },
    setValue,
    reset,
    watch,
    setError,
  } = useFormContext();

  const dispatch = useDispatch();
  const defaultValues = useSelector(selectAsanaState);
  const workflowMode = useSelector(selectWorkflowMode);
  const editing = workflowMode === workflowModes.edit;
  const credentialId = useSelector(selectSelectedCredentialId);
  const clientId = useSelector(selectSelectedClientId);
  const asanaWorkspaces = useSelector(selectAsanaWorkspaces);
  const asanaWorkspacesLoading = useSelector(selectAsanaWorkspacesLoading);
  const [resetAsanaWorkspaceSelector, setResetAsanaWorkspaceSelector] = useState(false);
  const asanaProjects = useSelector(selectAsanaProjects);
  const asanaProjectsLoading = useSelector(selectAsanaProjectsLoading);
  const [resetAsanaProjectSelector, setResetAsanaProjectSelector] = useState(false);
  const asanaSections = useSelector(selectAsanaSections);
  const asanaSectionsLoading = useSelector(selectAsanaSectionsLoading);
  const [resetAsanaSectionSelector, setResetAsanaSectionSelector] = useState(false);
  const allCfKeys = useSelector(selectallCustomFieldKeys);
  const initialTags = useSelector(selectAsanaInitialTags)?.map((value) => ({
    value: value,
    children: <>{value}</>,
    disabled: false,
    key: value,
    search: value,
  }));

  // Form fields
  const workflowName = watch('workflow_name');

  // Asana fields
  const asanaWorkspaceId = watch('workspace_id');
  const asanaProjectId = watch('project_id');
  const asanaSectionId = watch('section_id');
  const assetNameTemplate = watch('asset_name_template');
  const attachmentNameTemplate = watch('attachment_name_template');

  // BF Fields Destination
  const bfDestinationKey = watch('bf_destination_key');
  const bfDestinationSection = watch('bf_destination_section');
  const customFieldMap = watch('custom_field_map');

  useEffect(() => {
    reset(defaultValues);
  }, []);

  useEffect(() => {
    if (resetAsanaWorkspaceSelector) {
      setValue('workspace_id', null);
      setError('workspace_id', { type: 'required' });
      setResetAsanaWorkspaceSelector(false);
    }
    if (resetAsanaProjectSelector) {
      setValue('project_id', null);
      setError('project_id', { type: 'required' });
      setResetAsanaProjectSelector(false);
    }
    if (resetAsanaSectionSelector) {
      setValue('section_id', null);
      setResetAsanaSectionSelector(false);
    }
  }, [resetAsanaWorkspaceSelector, resetAsanaProjectSelector, resetAsanaSectionSelector]);

  useEffect(() => {
    formComplete(
      !!(
        workflowName &&
        bfDestinationKey &&
        bfDestinationSection &&
        asanaWorkspaceId &&
        asanaProjectId
      ),
    );
  }, [workflowName, bfDestinationKey, bfDestinationSection, asanaWorkspaceId, asanaProjectId]);

  useEffect(() => {
    if (bfDestinationKey) {
      dispatch(getCustomFieldFormInfo({ brandfolderId: bfDestinationKey }));
    }
  }, [bfDestinationKey]);

  useEffect(() => {
    dispatch(asanaSetCredentialId({ credential_id: credentialId }));
    if (credentialId) {
      dispatch(
        asanaFetchFormInfo({
          credential_id: credentialId,
          clientId,
          workspace_id: asanaWorkspaceId,
          project_id: asanaProjectId,
          section_id: asanaSectionId,
        }),
      );
    }
  }, [credentialId, asanaWorkspaceId, asanaProjectId]);

  const brandfolderFormInfo = useSelector(selectBrandfolderFormInfo);
  const bfSources = brandfolderFormInfo?.map((bf) => {
    return { label: bf.name, value: bf.id, sections: bf.sections, collections: bf.collections };
  });

  const bfSections = bfSources
    ?.find((bf) => bf.value === bfDestinationKey)
    ?.sections?.data.map((section) => {
      return { label: section.name, value: section.id };
    });

  const bfCollections = bfSources
    ?.find((bf) => bf.value === bfDestinationKey)
    ?.collections?.data.map((collection) => {
      return { label: collection.name, value: collection.id };
    });

  const handleChangeSection = (values) => {
    setValue('bf_destination_section', values || null);
  };

  const handleSelectCollection = (value) => {
    setValue('bf_destination_collection', value || null);
  };

  const onTagged = (tags, action, tag) => {
    setValue(
      'tag_templates',
      tags.map((tag) => {
        // replace all spaces with underscores in tag.value and trim trailing white space
        return tag.value.trim().replace(/\s+/g, '_');
      }),
      { shouldDirty: true },
    );
  };

  const getSelectedCustomFieldValues = () => {
    const arr: string[] = [];
    if (customFieldMap) {
      for (const [keyName, keyId] of Object.entries(customFieldMap as Record<string, string>)) {
        arr.push(keyId);
      }
    }
    return arr;
  };

  const handleSelectionChanged = (selectedKeys) => {
    const newMap = {};
    for (const keyId of selectedKeys) {
      const keyName = allCfKeys.find((cfKey) => cfKey.id === keyId)?.name;
      newMap[keyName] = keyId;
    }
    setValue('custom_field_map', newMap, { shouldDirty: true });
  };

  return (
    <>
      {credentialId && (
        <section className="forms-content-container" data-testid="asana-workflow-form-container">
          <WorkflowInfo
            linkToDocumentation={
              'https://smar-wiki.atlassian.net/wiki/spaces/PROD/pages/71135461389/Copy+of+Guide+IWM+-+Asana'
            }
            linkTestId="asana-documentation-link"
            textTestId="asana-documentation-text"
            extraText='Workflow will run when a task is marked as "complete."'
          />
          <WorkflowName />
          <StandardText className="asana-section-headings" weight={FontWeights.Bold}>
            Source
          </StandardText>
          {editing && (
            <StandardText className="asana-description-text" data-testid="asana-description-text">
              The Asana source workspace, project, and section can’t be edited after the workflow is
              published. To edit the source, deactivate this workflow and then create a new one.
            </StandardText>
          )}
          <div className="asana-page-selector-container mt-xxl">
            <StandardLabel className="mb-xxs" htmlFor={'select-workspace'}>
              Asana workspace
            </StandardLabel>
            <StandardCombobox
              id="asana-select-workspace"
              disabled={editing}
              labels={{
                iconButtonLabel: 'Choose workspace button',
                label: 'Choose workspace',
                listboxLabel: 'Choose workspace option',
                loaderLabel: 'Loading workspaces',
              }}
              showLabel={false}
              options={
                asanaWorkspaces
                  ? ([
                      ...asanaWorkspaces.map((workspace) => ({
                        children: <>{workspace.name}</>,
                        key: workspace.gid,
                        value: workspace.name,
                        search: workspace.name,
                      })),
                    ] as ListBoxOption[])
                  : []
              }
              onSelection={(listOption): void => {
                setValue('workspace_id', listOption?.key || null, { shouldValidate: true });
                // reset child values every time this parent values changes
                setValue('project_id', null);
                setValue('section_id', null);
                setResetAsanaProjectSelector(true);
                setResetAsanaSectionSelector(true);
                dispatch(asanaClearFormInfo());
              }}
              loading={asanaWorkspacesLoading}
              placeholder="Choose workspace"
              initialSelectedIndex={
                asanaWorkspaces?.findIndex((ws) => asanaWorkspaceId === ws?.gid) || 0
              }
              data-testid={`asana-workflow-form-select-workspace`}
              required={true}
              editable={true}
              search={true}
              reset={resetAsanaWorkspaceSelector}
              {...register('workspace_id', { required: true })}
            />
            {errors.workspace_id?.type === 'required' && (
              <BFErrorLabel role="alert">Workspace is required</BFErrorLabel>
            )}
          </div>

          <div className="asana-page-selector-container mt-xxl">
            <StandardLabel className="mb-xxs" htmlFor={'select-project'}>
              Asana project
            </StandardLabel>
            <StandardCombobox
              id="asana-select-project"
              disabled={!asanaWorkspaceId || !asanaProjects?.length || editing}
              labels={{
                iconButtonLabel: 'Choose project button',
                label: 'Choose project',
                listboxLabel: 'Choose project option',
                loaderLabel: 'Loading projects',
              }}
              showLabel={false}
              onSelection={(listOption): void => {
                setValue('project_id', listOption?.key || null, { shouldValidate: true });
                // reset child values every time this parent values changes
                setValue('section_id', null);
                setResetAsanaSectionSelector(true);
                dispatch(asanaClearFormInfoSections());
              }}
              loading={asanaProjectsLoading}
              placeholder="Choose project"
              options={
                asanaProjects
                  ? ([
                      ...asanaProjects.map((project) => ({
                        children: <>{project.name}</>,
                        key: project.gid,
                        value: project.name,
                        search: project.name,
                      })),
                    ] as ListBoxOption[])
                  : []
              }
              initialSelectedIndex={
                asanaProjects?.findIndex((proj) => asanaProjectId === proj?.gid) || 0
              }
              data-testid={`asana-workflow-form-select-project`}
              required={true}
              editable={true}
              search={true}
              reset={resetAsanaProjectSelector}
              {...register('project_id', { required: true })}
            />
            {errors.project_id?.type === 'required' && (
              <BFErrorLabel role="alert">Project is required</BFErrorLabel>
            )}
          </div>

          <div className="asana-page-selector-container mt-xxl">
            <StandardLabel className="mb-xxs" htmlFor={'select-section'}>
              Asana section (optional)
            </StandardLabel>
            <StandardCombobox
              disabled={!asanaWorkspaceId || !asanaProjectId || !asanaSections?.length || editing}
              id="asana-select-section"
              placeholder="Choose section"
              labels={{
                iconButtonLabel: 'Choose section button',
                label: 'Choose section',
                listboxLabel: 'Choose section option',
                loaderLabel: 'Loading sections',
              }}
              showLabel={false}
              loading={asanaSectionsLoading}
              options={
                asanaSections
                  ? ([
                      ...asanaSections.map((section) => ({
                        children: <>{section.name}</>,
                        key: section.gid,
                        value: section.name,
                        search: section.name,
                      })),
                    ] as ListBoxOption[])
                  : []
              }
              onSelection={(listOption): void =>
                setValue('section_id', listOption?.key || null, { shouldValidate: true })
              }
              initialSelectedIndex={
                asanaSections?.findIndex((sec) => asanaSectionId === sec?.gid) || 0
              }
              editable={true}
              search={true}
              reset={resetAsanaSectionSelector}
              data-testid={`asana-workflow-form-select-asana-section`}
            />
            <StandardText className="asana-text-sub">
              If nothing is selected, we'll sync all assets in Asana to Brandfolder.
            </StandardText>
          </div>

          <div className="asana-page-selector-container mt-xxl">
            <div className="flex">
              <StandardLabel className="mb-xxs" htmlFor={'select-section'}>
                Asset name template (optional)
              </StandardLabel>
              <StandardTooltip
                color={Colors.Primary}
                defaultOpen={false}
                id="asana-asset-template-tooltip"
                className="asana-tooltip"
                placement={Placements.TopCenter}
                tooltip="By default, we use the asset name in Asana as the asset name in Brandfolder."
                triggerOffset={8}
                type={TooltipTypes.Description}
                width={Widths.Medium}
              >
                <FontIcon icon={FontIcons.ToolTip} color={FontIconColors.Primary} />
              </StandardTooltip>
            </div>
            <StandardTextfield
              className="text-input"
              type="text"
              id="asana-asset-template"
              label=""
              placeholder="Enter name template"
              value={assetNameTemplate}
              data-testid={`asana-workflow-form-asset-template`}
              onChange={(e): void =>
                setValue('asset_name_template', e.target.value, { shouldDirty: true })
              }
            />
            <StandardText className="asana-text-sub">
              {`Example: Client ABC {{Client_Name}} {{Sales_Client}}  {{Ad_Format}} {{Platform}} {{task_name}}`}
            </StandardText>
          </div>

          <div className="asana-page-selector-container mt-xxl">
            <div className="flex">
              <StandardLabel className="mb-xxs" htmlFor={'select-section'}>
                Attachment name template (optional)
              </StandardLabel>
              <StandardTooltip
                color={Colors.Primary}
                defaultOpen={false}
                id="asana-attachment-template-tooltip"
                className="asana-tooltip"
                placement={Placements.TopCenter}
                tooltip="By default, we use the attachment name in Asana as the attachment name in Brandfolder."
                triggerOffset={8}
                type={TooltipTypes.Description}
                width={Widths.Medium}
              >
                <FontIcon icon={FontIcons.ToolTip} color={FontIconColors.Primary} />
              </StandardTooltip>
            </div>
            <StandardTextfield
              className="text-input"
              type="text"
              id="asana-attachment-template"
              label=""
              placeholder="Enter name template"
              value={attachmentNameTemplate}
              data-testid={`asana-workflow-form-attachment-template`}
              onChange={(e): void =>
                setValue('attachment_name_template', e.target.value, { shouldDirty: true })
              }
            />
            <StandardText className="asana-text-sub">
              {`Example: Client ABC {{Sales_Client}} {{Ad_Format}} {{Platform}} {{Initiative}} {{Request_Title_Campaign_Name}}{{extension}}`}
            </StandardText>
          </div>

          <div className="asana-page-selector-container mt-xxl">
            <div className="flex">
              <StandardLabel className="mb-xxs" htmlFor={'select-tags'}>
                Custom fields to tags (optional)
              </StandardLabel>
              <StandardTooltip
                color={Colors.Primary}
                defaultOpen={false}
                id="asana-custom-field-tooltip"
                className="asana-tooltip"
                placement={Placements.TopCenter}
                tooltip="The Asana custom fields that will be synced as tags in Brandfolder for all assets."
                triggerOffset={8}
                type={TooltipTypes.Description}
                width={Widths.Medium}
              >
                <FontIcon icon={FontIcons.ToolTip} color={FontIconColors.Primary} />
              </StandardTooltip>
            </div>

            <StandardTagger
              id="asana-select-tags"
              labels={{
                iconButtonLabel: 'Toggle list of Asana tags',
                label: 'Asana tags',
                listboxLabel: 'List of Asana tags',
                removeTagLabel: 'Remove Asana tag',
              }}
              showLabel={false}
              onTagged={onTagged}
              allowDuplicates={false}
              options={[
                {
                  children: null,
                  disabled: false,
                  key: 'enter-fields-placeholder',
                  placeholder: 'Enter custom fields',
                  search: '',
                  value: '',
                },
              ]}
              initialTags={initialTags}
            />
            <StandardText className="asana-text-sub">
              Enter fields separated by commas.
            </StandardText>
          </div>
          <section className="mb-xl">
            <StandardText
              className="asana-section-headings"
              size={FontSizes.XxLarge}
              weight={FontWeights.Bold}
            >
              Destination
            </StandardText>

            <div className="asana-page-selector-container mt-xxl">
              <SelectBrandfolder
                valueName="bf_destination_key"
                placeHolder="Choose Brandfolder"
                bfSource={bfDestinationKey}
                bfSources={bfSources}
                {...register('bf_destination_key', { required: true })}
                onChange={() => {
                  handleChangeSection(null);
                  handleSelectCollection(null);
                }}
              />
              {errors.bf_destination_key?.type === 'required' && (
                <BFErrorLabel role="alert">Brandfolder is required</BFErrorLabel>
              )}
            </div>

            <div className="asana-page-selector-container mt-xxl">
              <StandardLabel className="mb-xxs" htmlFor={'select-section'}>
                Brandfolder section
              </StandardLabel>
              <BFSelect
                id="asana-select-section"
                placeholder={'Choose section'}
                options={(alphabetizBfOptions(bfSections) as BFSelectOption[]) || []}
                disabled={!bfDestinationKey}
                value={watch('bf_destination_section')}
                {...register('bf_destination_section', { required: true })}
                onOptionChange={(listOption): void => {
                  setValue('bf_destination_section', listOption?.value || null, {
                    shouldValidate: true,
                    shouldDirty: true,
                  });
                }}
                required={true}
                data-testid={`asana-workflow-form-select-bf-section`}
              />
              {errors.bf_destination_section?.type === 'required' && (
                <BFErrorLabel role="alert">Section is required</BFErrorLabel>
              )}
            </div>

            <div className="asana-page-selector-container mt-xxl">
              <StandardLabel className="mb-xxs" htmlFor={'select-collection'}>
                Brandfolder collection (optional)
              </StandardLabel>
              <div className="wrike-page-selector-container">
                <BFSelect
                  id="asana-select-collection"
                  placeholder={'Choose collection'}
                  options={(alphabetizBfOptions(bfCollections) as BFSelectOption[]) || []}
                  disabled={!bfDestinationKey}
                  value={watch('bf_destination_collection')}
                  onOptionChange={(listOption): void => {
                    setValue('bf_destination_collection', listOption?.value || null, {
                      shouldValidate: true,
                      shouldDirty: true,
                    });
                    setValue('resource', listOption);
                  }}
                  required={true}
                  data-testid={`asana-workflow-form-select-collection`}
                />
              </div>
            </div>

            <div className="asana-page-selector-container mt-xxl">
              <div className="flex">
                <StandardLabel className="mb-xxs" htmlFor={'select-section'}>
                  Brandfolder custom fields (optional)
                </StandardLabel>
                <StandardTooltip
                  color={Colors.Primary}
                  defaultOpen={false}
                  id="asana-custom-field-tooltip"
                  className="asana-tooltip"
                  placement={Placements.TopCenter}
                  tooltip="The Brandfolder custom fields you want to populate with custom field values from Asana. The field names must be an exact match in Asana for data to be synced."
                  triggerOffset={8}
                  type={TooltipTypes.Description}
                  width={Widths.Medium}
                >
                  <FontIcon icon={FontIcons.ToolTip} color={FontIconColors.Primary} />
                </StandardTooltip>
              </div>
              <BFMultiSelect
                id="select-custom-fields"
                placeholder={'Choose custom fields'}
                options={
                  allCfKeys?.map((cfKey) => {
                    return {
                      label: cfKey.name,
                      value: cfKey.id,
                    } as BFMultiSelectOption;
                  }) || []
                }
                disabled={!bfDestinationKey}
                selected={allCfKeys?.reduce((acc: BFMultiSelectOption[], cfKey) => {
                  if (customFieldMap) {
                    for (const [keyName, keyId] of Object.entries(customFieldMap)) {
                      if (keyId === cfKey.id) {
                        acc.push({ value: cfKey.id, label: cfKey.name });
                      }
                    }
                  }
                  return acc;
                }, [])}
                selectedValues={getSelectedCustomFieldValues()}
                onChange={handleSelectionChanged}
                data-testid={`asana-select-custom-fields`}
                panelStyleOptions={{ width: '400px' }}
                buttonStyleOptions={{ width: '400px' }}
                getOptionKey={(o) => o.value}
                zIndex={2}
              />
            </div>
          </section>
        </section>
      )}
      {!credentialId && <Loader />}
    </>
  );
};
