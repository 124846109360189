import { BFErrorLabel, BFLabel, BFSelect, BFSelectOption } from '@integration-frontends/common/ui';
import { alphabetizBfOptions } from 'libs/workflow-manager/ui/src/common/functions/alphabetize';
import * as React from 'react';
import { useFormContext } from 'react-hook-form';
import './select-brandfolder.scss';

export interface SelectBrandfolderProps {
  valueName?: string;
  placeHolder?: string;
  bfSource: string;
  bfSources: BFSelectOption[];
  onChange?: () => void;
}

export const SelectBrandfolder = ({
  valueName,
  placeHolder,
  bfSource,
  bfSources,
  onChange,
}: SelectBrandfolderProps): React.ReactElement => {
  const {
    register,
    setValue,
    formState: { errors },
  } = useFormContext();

  return (
    <>
      <BFLabel className="form-label mb-xs" htmlFor="select-brandfolder">
        Brandfolder
      </BFLabel>
      <BFSelect
        data-testid="select-brandfolder"
        id="select-brandfolder"
        required={true}
        className={'mt-xxs'}
        options={(alphabetizBfOptions(bfSources) as BFSelectOption[]) || []}
        {...register(valueName || 'bf_source_key', { required: true })}
        onOptionChange={(listOption): void => {
          setValue(valueName || 'bf_source_key', listOption?.value || ('' as string), {
            shouldValidate: true,
            shouldDirty: true,
          });
          onChange && onChange();
        }}
        value={bfSources?.find((bf) => bf.value === bfSource)?.value}
        placeholder={placeHolder || 'Select Brandfolder'}
      />

      {(valueName
        ? errors.valueName?.type === 'required'
        : errors.bf_source_key?.type === 'required') && (
        <BFErrorLabel role="alert">Brandfolder is required</BFErrorLabel>
      )}
    </>
  );
};
