import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IconCloseThin, IconSize } from '@integration-frontends/common/ui';
import { push } from 'redux-first-history';
import './exit-modal.scss';
import {
  ButtonLooks,
  FontIcon,
  FontIcons,
  FontWeights,
  StandardButton,
  StandardText,
} from '@brandfolder/react';
import {
  selectWorkflowMode,
  workflowModes,
} from '@integration-frontends/workflow-manager/core/application';

export const ExitModal = ({ setOpen }) => {
  const workflowMode = useSelector(selectWorkflowMode);
  const editing = workflowMode === workflowModes.edit;
  const reviewing = workflowMode === workflowModes.review;

  interface modalDynamicContent {
    discardType: string;
    description: string;
  }

  const editContent: modalDynamicContent = {
    discardType: 'changes',
    description: 'If you continue, you’ll lose any changes you’ve made.',
  };

  const newWorkflowContent: modalDynamicContent = {
    discardType: 'workflow',
    description: 'If you continue, you’ll lose any workflow info you’ve entered.',
  };

  const modalVerbiage = (): modalDynamicContent => {
    if (editing || reviewing) {
      return editContent;
    } else return newWorkflowContent;
  };

  const dispatch = useDispatch();
  return (
    <div className="exit-worfklow-modal-container" onClick={() => setOpen(false)}>
      <div className="exit-workflow-modal">
        <div className="exit-workflow-modal-header">
          <div className="exit-workflow-modal-header-left">
            <FontIcon iconSize={15} icon={FontIcons.Trash} />
            <StandardText
              className="ml-sm exit-workflow-modal-header-left-text"
              weight={FontWeights.Bold}
            >
              {`Discard ${modalVerbiage().discardType}`}
            </StandardText>
          </div>
          <IconCloseThin
            className="exit-workflow-modal-header-close-icon"
            onClick={() => setOpen(false)}
            iconSize={IconSize.Medium}
          />
        </div>
        <div className="exit-workflow-modal-body">
          <StandardText>{modalVerbiage().description}</StandardText>
          <div className="exit-workflow-modal-actions">
            <StandardButton
              look={ButtonLooks.Tertiary}
              onClick={() => setOpen(false)}
              className="mr-md"
            >
              Cancel
            </StandardButton>
            <StandardButton onClick={() => dispatch(push('/workflows'))}>
              {`Discard ${modalVerbiage().discardType}`}
            </StandardButton>
          </div>
        </div>
      </div>
    </div>
  );
};
