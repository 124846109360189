import React from 'react';
import { sections } from '../../../create-workflow-form';
import { IconCheckFilled, IconSize } from '@integration-frontends/common/ui';
import { StandardText } from '@brandfolder/react';
import './section-tracker.scss';

interface SectionTrackerProps {
  activeSection: string;
}

export const SectionTracker = ({ activeSection }: SectionTrackerProps) => {
  const sectionElements = sections.map((el) => (
    <SectionItem
      key={el[0]}
      sectionName={el[0]}
      sectionTitle={el[1]}
      activeSection={activeSection}
    />
  ));

  return (
    <section className="tracker-container">
      <ul className="tracker-list-container flex flex-col">{sectionElements}</ul>
    </section>
  );
};

const SectionItem = ({ sectionName, sectionTitle, activeSection }) => {
  const isComplete = (section: string) => {
    return (
      sections.findIndex((el) => el[0] === activeSection) >
      sections.findIndex((el) => el[0] === section)
    );
  };
  const isActive = (section: string) => {
    return activeSection === section;
  };
  return (
    <li className="track-item">
      {isActive(sectionName) && <div className="active-icon tracker-icon"></div>}
      {isComplete(sectionName) && (
        <span className="success-icon tracker-icon">
          <IconCheckFilled iconSize={IconSize.ExtraSmall} />
        </span>
      )}
      {!isActive(sectionName) && !isComplete(sectionName) && (
        <div className="inactive-icon tracker-icon"></div>
      )}
      <StandardText className="tracker-item-title">{sectionTitle}</StandardText>
    </li>
  );
};
