import { StateSelector } from '@integration-frontends/common/utils/redux';
import { workflowAdapter } from '../entities';
import {
  WorkflowListItem,
  WorkflowsActiveOption,
  WorkflowsSortOption,
} from '@integration-frontends/workflow-manager/core/model';
import { createSelector, EntityState } from '@reduxjs/toolkit';

export function createWorkflowSelectors(
  stateSelector: StateSelector<EntityState<WorkflowListItem>>,
) {
  const baseSelectors = workflowAdapter.getSelectors(stateSelector);

  const filterWorkflows = (
    workflowString: string,
    sortOption: WorkflowsSortOption,
    filterOption: WorkflowsActiveOption,
  ) =>
    createSelector(baseSelectors.selectAll, (workflows) => {
      return (
        workflows
          // look into using localCompare instead of toUpperCase().includes(string.toUpperCase())
          .filter((workflow) =>
            workflow.workflow_name.toUpperCase().includes(workflowString.toUpperCase()),
          )
          .filter((workflow) => {
            if (filterOption === 'active') {
              return workflow.active === true;
            } else if (filterOption === 'inactive') {
              return workflow.active === false;
            } else return workflow;
          })
          .sort((a, b) => {
            if (sortOption === 'az') {
              return a.workflow_name.toUpperCase() < b.workflow_name.toUpperCase() ? -1 : 1;
            } else if (sortOption === 'za') {
              return a.workflow_name.toUpperCase() >= b.workflow_name.toUpperCase() ? -1 : 1;
            } else if (sortOption === 'createdOldest') {
              return a.created_at < b.created_at ? -1 : 1;
            } else if (sortOption === 'createdNewest') {
              return a.created_at >= b.created_at ? -1 : 1;
            } else if (sortOption === 'updatedOldest') {
              return a.updated_at < b.updated_at ? -1 : 1;
            } else if (sortOption === 'updatedNewest') {
              return a.updated_at >= b.updated_at ? -1 : 1;
            } else if (sortOption === 'integrationAz') {
              return a.integration_type.toUpperCase() < b.integration_type.toUpperCase() ? -1 : 1;
            } else if (sortOption === 'integrationZa') {
              return a.integration_type.toUpperCase() >= b.integration_type.toUpperCase() ? -1 : 1;
            }
            return 0;
          })
      );
    });

  return {
    ...baseSelectors,
    filterWorkflows,
  };
}
