import { ReviewSection, ReviewSectionLine } from '../../../common';
import { push } from 'redux-first-history';
import {
  selectSelectedClientId,
  clientEntitySelectors,
  selectSeismicWorkflowName,
  selectSeismicBrandfolder,
  selectSeismicTeamsiteId,
  selectSeismicSectionToFolderMap,
  selectSeismicCollectionToFolderMap,
  brandfolderEntitySelectors,
  selectCreateWorkflowSuccess,
  selectSelectedCredentialId,
  credentialEntitySelectors,
  selectSeismicRehydratedSectionMaps,
  selectSeismicRehydratedCollectionMaps,
  selectWorkflowMode,
  workflowModes,
  initEditWorkflow,
  selectSelectedWorkflowId,
  selectSeismicCurrentFolderName,
  selectSeismicFolderId,
} from '@integration-frontends/workflow-manager/core/application';

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { workflowServiceType } from '@integration-frontends/workflow-manager/core/model';
import { ShimmerLoader } from '@brandfolder/react';

export const SeismicReview = ({ setComplete }) => {
  const selectedClientId = useSelector(selectSelectedClientId);
  const selectedClient = useSelector((state) =>
    clientEntitySelectors.selectById(state, selectedClientId),
  );
  const selectedCredentialId = useSelector(selectSelectedCredentialId);
  const selectedCredential = useSelector(
    credentialEntitySelectors.credentialById(selectedCredentialId),
  );
  const selectedBrandfolderId = useSelector(selectSeismicBrandfolder);
  const selectedBrandfolderName = useSelector((state) =>
    brandfolderEntitySelectors.selectById(state, selectedBrandfolderId),
  )?.name;
  const workflowId = useSelector(selectSelectedWorkflowId);
  const workflowName = useSelector(selectSeismicWorkflowName);
  const teamsiteId = useSelector(selectSeismicTeamsiteId);
  const currentFolderId = useSelector(selectSeismicFolderId);
  const currentFolderName = useSelector(selectSeismicCurrentFolderName);
  const sectionToFolderMap = useSelector(selectSeismicSectionToFolderMap);
  const rehydratedSectionToFolderMap = useSelector(selectSeismicRehydratedSectionMaps);
  const collectionToFolderMap = useSelector(selectSeismicCollectionToFolderMap);
  const rehydratedCollectionToFolderMap = useSelector(selectSeismicRehydratedCollectionMaps);
  const workflowMode = useSelector(selectWorkflowMode);
  const isReviewOnly = workflowMode === workflowModes.review;
  const sectionMapsText = isReviewOnly ? rehydratedSectionToFolderMap : sectionToFolderMap;
  const collectionMapsText = isReviewOnly ? rehydratedCollectionToFolderMap : collectionToFolderMap;
  const entireBfMapsText = `${selectedBrandfolderName} to ${currentFolderName}`;
  const entireBfSelected =
    (!sectionMapsText && !collectionMapsText) ||
    (!Object.keys(sectionMapsText || {}).length && !Object.keys(collectionMapsText || {}).length);
  const dispatch = useDispatch();

  const createWorkflowSuccess = useSelector(selectCreateWorkflowSuccess);

  const onEdit = () => {
    dispatch(initEditWorkflow({ integrationType: workflowServiceType.seismic, workflowId }));
  };

  useEffect(() => {
    if (createWorkflowSuccess) {
      dispatch(push('/workflows'));
    }
  }, [createWorkflowSuccess]);

  useEffect(() => {
    setComplete(
      !!(
        workflowName &&
        selectedClient &&
        selectedBrandfolderName &&
        teamsiteId &&
        ((sectionToFolderMap && sectionToFolderMap[0]?.seismicDestinationContainer?.id) ||
          (collectionToFolderMap && collectionToFolderMap[0]?.seismicDestinationContainer?.id) ||
          currentFolderId)
      ),
    );
  }, [workflowName, sectionToFolderMap, collectionToFolderMap, currentFolderId]);

  return (
    <>
      <ReviewSection title="Organization info" dataTestId="seismic-organization-review-section">
        <ReviewSectionLine
          title="Client"
          value={
            <div data-testid="seismic-selected-client-name">{selectedClient?.client_name}</div>
          }
        />
        <ReviewSectionLine
          title="Brandfolder API key"
          value={<div data-testid="seismic-brandfolder-api-key">{selectedCredential?.api_key}</div>}
        />
      </ReviewSection>

      <ReviewSection title="Seismic info" dataTestId="seismic-info-review-section">
        <ReviewSectionLine
          title="Seismic user ID"
          value={<div data-testid="seismic-user-id">{selectedCredential?.external_user_id}</div>}
        />
        <ReviewSectionLine
          title="Seismic client key"
          value={<div data-testid="seismic-client-key">{selectedCredential?.client_key}</div>}
        />
        <ReviewSectionLine
          title="Seismic secret key"
          value={<div data-testid="seismic-secret-key">{selectedCredential?.client_secret}</div>}
        />
        <ReviewSectionLine
          title="Tenant name"
          value={<div data-testid="seismic-tenant-name">{selectedCredential?.host}</div>}
        />
      </ReviewSection>

      <ReviewSection
        title="Workflow info"
        dataTestId="seismic-workflow-review-section"
        editAction={onEdit}
      >
        <ReviewSectionLine
          title="Workflow name"
          value={<div data-testid="seismic-workflow-name">{workflowName}</div>}
        />
        {workflowId && (
          <ReviewSectionLine
            title="Workflow ID"
            value={<div data-testid="seismic-workflow-id">{workflowId || ''}</div>}
          />
        )}
        <ReviewSectionLine
          title="Brandfolder"
          value={
            <div data-testid="seismic-brandfolder-name">
              {selectedBrandfolderName || <ShimmerLoader />}
            </div>
          }
        />
        <ReviewSectionLine
          title="Seismic teamsite ID"
          value={<div data-testid="seismic-teamsite-id">{teamsiteId}</div>}
        />
        {entireBfSelected && entireBfMapsText && (
          <ReviewSectionLine
            title="Sync entire Brandfolder"
            value={
              <div data-testid="seismic-entire-bf-sync">{`${selectedBrandfolderName} to ${currentFolderName}`}</div>
            }
          />
        )}
        {sectionMapsText && sectionMapsText[0]?.seismicDestinationContainer?.id && (
          <ReviewSectionLine
            title="Sync Brandfolder sections(s) to Seismic folders"
            value={
              <ul>
                {sectionMapsText.map((mapping) => (
                  <li key={mapping?.bfSrcContainer?.id}>
                    <div data-testid="seismic-section-sync-maps">{`${mapping?.bfSrcContainer?.name} to ${mapping?.seismicDestinationContainer?.name}`}</div>
                  </li>
                ))}
              </ul>
            }
          />
        )}
        {collectionMapsText && collectionMapsText[0]?.seismicDestinationContainer?.id && (
          <ReviewSectionLine
            title="Sync Brandfolder collection(s) to Seismic folders"
            value={
              <ul>
                {collectionMapsText.map((mapping) => (
                  <li key={mapping?.bfSrcContainer?.id}>
                    <div data-testid="seismic-collection-sync-maps">{`${mapping?.bfSrcContainer?.name} to ${mapping?.seismicDestinationContainer?.name}`}</div>
                  </li>
                ))}
              </ul>
            }
          />
        )}
      </ReviewSection>
    </>
  );
};
