import { createSelector } from 'reselect';
import {
  CreateWorkflowState,
  CreateWorkflowRootState,
  HighspotWorkflowFormState,
  GettyWorkflowFormState,
  HubspotWorkflowFormState,
  WrikeWorkflowFormState,
  SeismicWorkflowFormState,
  SalsifyWorkflowFormState,
  AsanaWorkflowFormState,
  WorkfrontWorkflowFormState,
} from './reducer';
import { StateSelector } from '@integration-frontends/common/utils/redux';

const selectCreateWorkflowState: StateSelector<CreateWorkflowState> = (
  state: CreateWorkflowRootState,
) => state.createWorkflowReducer;
export const selectworkflowPageInit = createSelector(
  selectCreateWorkflowState,
  (state) => state.loading,
);

export const selectCreateWorkflowSuccess = createSelector(
  selectCreateWorkflowState,
  (state) => state.createWorkflowSuccess,
);
export const selectWorkflowMode = createSelector(selectCreateWorkflowState, (state) => state.mode);

export const selectSelectedWorkflowId = createSelector(
  selectCreateWorkflowState,
  (state) => state.selectedWorkflowId,
);

export const selectWorkflowPageLoadError = createSelector(
  selectCreateWorkflowState,
  (state) => state.pageLoadError,
);

export const selectSelectedClientId = createSelector(
  selectCreateWorkflowState,
  (state) => state.selectedClientId,
);

export const selectSelectedCredentialId = createSelector(
  selectCreateWorkflowState,
  (state) => state.selectedCredentialId,
);

export const selectActiveSection = createSelector(
  selectCreateWorkflowState,
  (state) => state.activeSection,
);

export const selectBrandfolderFormInfo = createSelector(
  selectCreateWorkflowState,
  (state) => state.brandfolderFormInfo,
);

export const selectSelectedIntegrationType = createSelector(
  selectCreateWorkflowState,
  (state) => state.selectedIntegrationType,
);

export const selectCustomFieldRestrictedKeys = createSelector(
  selectCreateWorkflowState,
  (state) => state.customFieldRestrictedKeys,
);

export const selectallCustomFieldKeys = createSelector(
  selectCreateWorkflowState,
  (state) => state.allCustomFieldKeys,
);

export const selectCustomFieldValues = createSelector(
  selectCreateWorkflowState,
  (state) => state.customFieldValueOptions,
);

export const selectEditWorkflowInProgress = createSelector(
  selectCreateWorkflowState,
  (state) => state.editWorkflowInProgress,
);

// High Spot
export const selectHighspotState: StateSelector<HighspotWorkflowFormState> = (
  state: CreateWorkflowRootState,
) => state.createWorkflowReducer.highspot;
export const selectHighspotBrandfolder = createSelector(
  selectHighspotState,
  (state) => state.bf_source_key,
);

export const selectHighspotWorkflowName = createSelector(
  selectHighspotState,
  (state) => state.workflow_name,
);

export const selectHighspotCredentialID = createSelector(
  selectHighspotState,
  (state) => state.credential_id,
);

export const selectHighspotSpots = createSelector(selectHighspotState, (state) => state.spots);
export const selectHighspotSpotId = createSelector(selectHighspotState, (state) => state.spot_id);
export const selectHighspotSpot = createSelector(selectHighspotState, (state) =>
  state.spots.find((spot) => spot.id === state.spot_id),
);

export const selectHighspotSelectedSections = createSelector(
  selectHighspotState,
  (state) => state.selectedSections,
);
export const selectHighspotSelectedSectionsToDelete = createSelector(
  selectHighspotState,
  (state) => state.section_keys_to_delete,
);
export const selectHighspotInitialSectionSelection = createSelector(
  selectHighspotState,
  (state) => state.initial_section_selection,
);
export const selectHighspotSelectedCollections = createSelector(
  selectHighspotState,
  (state) => state.selectedCollections,
);
export const selectHighspotInitialCollectionSelection = createSelector(
  selectHighspotState,
  (state) => state.initial_collection_selection,
);
export const selectHighspotSelectedCollectionsToDelete = createSelector(
  selectHighspotState,
  (state) => state.collection_keys_to_delete,
);

export const selectHighspotSelectedSectionNames = createSelector(
  selectCreateWorkflowState,
  (state) => {
    const sectionIds = state.highspot.selectedSections;
    const brandfolder = state.brandfolderFormInfo.find(
      (brandfolder) => brandfolder.id === state.highspot.bf_source_key,
    );
    return sectionIds.map(
      (id) => brandfolder.sections.data.find((section) => section.id === id).name,
    );
  },
);
export const selectHighspotSelectedCollectionNames = createSelector(
  selectCreateWorkflowState,
  (state) => {
    const collectionIds = state.highspot.selectedCollections;
    const brandfolder = state.brandfolderFormInfo.find(
      (brandfolder) => brandfolder.id === state.highspot.bf_source_key,
    );
    return collectionIds.map(
      (id) => brandfolder?.collections?.data.find((collection) => collection.id === id).name,
    );
  },
);

// Getty
export const selectGettyState: StateSelector<GettyWorkflowFormState> = (
  state: CreateWorkflowRootState,
) => state.createWorkflowReducer.getty;

export const selectGettyWorkflowName = createSelector(
  selectGettyState,
  (state) => state.workflow_name,
);

export const selectGettyStartDate = createSelector(
  selectGettyState,
  (state) => state.sync_start_date,
);
export const selectGettyEndDate = createSelector(selectGettyState, (state) => state.sync_end_date);
export const selectGettySyncInterval = createSelector(
  selectGettyState,
  (state) => state.cron_schedule,
);

export const selectGettyAllowExpiredLicense = createSelector(
  selectGettyState,
  (state) => state.allow_expired_license,
);

export const selectGettyResetLastSyncedDate = createSelector(
  selectGettyState,
  (state) => state.reset_last_synced_date,
);

export const selectGettyLastSyncedDate = createSelector(
  selectGettyState,
  (state) => state.last_synced_date,
);

export const selectGettyBrandfolder = createSelector(
  selectGettyState,
  (state) => state.bf_destination_key,
);
export const selectGettySection = createSelector(
  selectGettyState,
  (state) => state.bf_destination_section,
);
export const selectGettyCollection = createSelector(
  selectGettyState,
  (state) => state.bf_destination_collection,
);
export const selectGettyCredentialId = createSelector(
  selectGettyState,
  (state) => state.credential_id,
);

// HubSpot

export const selectHubspotState: StateSelector<HubspotWorkflowFormState> = (
  state: CreateWorkflowRootState,
) => state.createWorkflowReducer.hubspot;

export const selectHubspotWorkflowName = createSelector(
  selectHubspotState,
  (state) => state.workflow_name,
);

export const selectHubspotBrandfolder = createSelector(
  selectHubspotState,
  (state) => state.bf_source_key,
);

export const selectHubspotFolders = createSelector(selectHubspotState, (state) => state.folders);
export const selectHubspotFolder = createSelector(selectHubspotState, (state) => state.sync_folder);
export const selectHubspotFolderId = createSelector(
  selectHubspotState,
  (state) => state.sync_folder_id,
);
export const selectHubspotFolderName = createSelector(
  selectHubspotState,
  (state) => state.sync_folder?.name,
);

export const selectHubspotSelectedSections = createSelector(
  selectHubspotState,
  (state) => state.section_keys,
);
export const selectHubspotExistingSectionSelection = createSelector(
  selectCreateWorkflowState,
  (state) => state.hubspot.hubspot_existing_section_selection,
);
export const selectHubspotSelectedCollection = createSelector(
  selectHubspotState,
  (state) => state.collection_key,
);
export const selectHubspotCredentialID = createSelector(
  selectHubspotState,
  (state) => state.credential_id,
);

export const selectHubspotSelectedSectionNames = createSelector(
  selectCreateWorkflowState,
  (state) => {
    const sectionIds = state.hubspot.section_keys;
    const brandfolder = state.brandfolderFormInfo.find(
      (brandfolder) => brandfolder.id === state.hubspot.bf_source_key,
    );
    if (sectionIds?.length > 0) {
      return sectionIds.map(
        (id) => brandfolder.sections.data.find((section) => section.id === id)?.name,
      );
    }
  },
);
export const selectHubspotSelectedCollectionName = createSelector(
  selectCreateWorkflowState,
  (state) => {
    const collectionId = state.hubspot.collection_key;
    const brandfolder = state.brandfolderFormInfo.find(
      (brandfolder) => brandfolder.id === state.hubspot.bf_source_key,
    );
    if (collectionId !== null && collectionId !== undefined) {
      return brandfolder?.collections?.data.find((collection) => collection?.id === collectionId)
        ?.name;
    }
  },
);
export const selectHubspotContainersLoading = createSelector(
  selectHubspotState,
  (state) => state.loading,
);

// Wrike

export const selectWrikeState: StateSelector<WrikeWorkflowFormState> = (
  state: CreateWorkflowRootState,
) => state.createWorkflowReducer.wrike;

export const selectWrikeSpaces = createSelector(selectWrikeState, (state) => state.spaces);

export const selectWrikeWorkflowName = createSelector(
  selectWrikeState,
  (state) => state.workflow_name,
);
export const selectWrikeCredentialId = createSelector(
  selectWrikeState,
  (state) => state.credential_id,
);

export const selectWrikeBrandfolder = createSelector(
  selectWrikeState,
  (state) => state.bf_destination_key,
);

export const selectWrikeFolderName = createSelector(selectWrikeState, (state) => state.resource);

export const selectWrikeSelectedSection = createSelector(
  selectWrikeState,
  (state) => state.section_key,
);
export const selectWrikeSelectedCollection = createSelector(
  selectWrikeState,
  (state) => state.collection_key,
);
export const selectWrikeSelectedResourceId = createSelector(
  selectWrikeState,
  (state) => state.resource_id,
);
export const selectWrikeSelectedResource = createSelector(
  selectWrikeState,
  (state) => state.resource,
);
export const selectSelectedWrikeSyncingPreferences = createSelector(
  selectWrikeState,
  (state) => state.recursive,
);
export const selectWrikeSelectedSectionName = createSelector(selectCreateWorkflowState, (state) => {
  const sectionId = state.wrike.section_key;
  const brandfolder = state.brandfolderFormInfo.find(
    (brandfolder) => brandfolder.id === state.wrike.bf_destination_key,
  );
  if (sectionId) {
    return brandfolder.sections.data.find((section) => section.id === sectionId).name;
  }
});
export const selectWrikeSelectedCollectionName = createSelector(
  selectCreateWorkflowState,
  (state) => {
    const collectionId = state.wrike.collection_key;
    const brandfolder = state.brandfolderFormInfo.find(
      (brandfolder) => brandfolder.id === state.wrike.bf_destination_key,
    );
    if (collectionId) {
      return brandfolder?.collections?.data.find((collection) => collection.id === collectionId)
        .name;
    }
  },
);

// Asana

export const selectAsanaState: StateSelector<AsanaWorkflowFormState> = (
  state: CreateWorkflowRootState,
) => state.createWorkflowReducer.asana;

export const selectAsanaWorkflowName = createSelector(
  selectAsanaState,
  (state) => state.workflow_name,
);

export const selectAsanaSelectedWorkspaceName = createSelector(selectAsanaState, (state) => {
  const workspaceId = state?.workspace_id;
  if (workspaceId) {
    const workspace = state.workspaces?.find((workspace) => workspace?.gid === workspaceId);
    return workspace?.name;
  }
});

export const selectAsanaSelectedProjectName = createSelector(selectAsanaState, (state) => {
  const projectId = state?.project_id;
  if (projectId) {
    const project = state.projects?.find((project) => project?.gid === projectId);
    return project?.name;
  }
});

export const selectAsanaSelectedAsanaSectionName = createSelector(selectAsanaState, (state) => {
  const sectionId = state?.section_id;
  if (sectionId) {
    const section = state.sections?.find((section) => section?.gid === sectionId);
    return section?.name;
  }
});

export const selectAsanaWorkspaces = createSelector(selectAsanaState, (state) => state.workspaces);

export const selectAsanaWorkspacesLoading = createSelector(
  selectAsanaState,
  (state) => state.workspacesLoading,
);

export const selectAsanaProjects = createSelector(selectAsanaState, (state) => state.projects);

export const selectAsanaProjectsLoading = createSelector(
  selectAsanaState,
  (state) => state.projectsLoading,
);

export const selectAsanaSections = createSelector(selectAsanaState, (state) => state.sections);

export const selectAsanaSectionsLoading = createSelector(
  selectAsanaState,
  (state) => state.sectionsLoading,
);

export const selectAsanaCredentialId = createSelector(
  selectAsanaState,
  (state) => state.credential_id,
);

export const selectAsanaBrandfolder = createSelector(
  selectAsanaState,
  (state) => state.bf_destination_key,
);

export const selectAsanaBrandfolderCollection = createSelector(
  selectAsanaState,
  (state) => state.bf_destination_collection,
);

export const selectAsanaBrandfolderSection = createSelector(
  selectAsanaState,
  (state) => state.bf_destination_section,
);

export const selectAsanaSelectedCustomFieldNames = createSelector(selectAsanaState, (state) => {
  if (state?.custom_field_map) {
    return Object.keys(state.custom_field_map);
  }
});

export const selectAsanaSelectedBfSectionName = createSelector(
  selectCreateWorkflowState,
  (state) => {
    const sectionId = state.asana.bf_destination_section;
    const brandfolder = state.brandfolderFormInfo.find(
      (brandfolder) => brandfolder.id === state.asana.bf_destination_key,
    );
    if (sectionId) {
      return brandfolder?.sections?.data?.find((section) => section.id === sectionId).name;
    }
  },
);

export const selectAsanaSelectedCollectionName = createSelector(
  selectCreateWorkflowState,
  (state) => {
    const collectionId = state.asana.bf_destination_collection;
    const brandfolder = state.brandfolderFormInfo.find(
      (brandfolder) => brandfolder.id === state.asana.bf_destination_key,
    );
    if (collectionId) {
      return brandfolder?.collections?.data?.find((collection) => collection.id === collectionId)
        .name;
    }
  },
);

export const selectAsanaAssetNameTemplate = createSelector(
  selectAsanaState,
  (state) => state.asset_name_template,
);

export const selectAsanaAttachmentNameTemplate = createSelector(
  selectAsanaState,
  (state) => state.attachment_name_template,
);

export const selectAsanaTagTemplates = createSelector(
  selectAsanaState,
  (state) => state.tag_templates,
);

export const selectAsanaCustomFieldMap = createSelector(
  selectAsanaState,
  (state) => state.custom_field_map,
);

export const selectAsanaInitialTags = createSelector(
  selectAsanaState,
  (state) => state.initial_tags,
);

//salsify
export const selectSalsifyState: StateSelector<SalsifyWorkflowFormState> = (
  state: CreateWorkflowRootState,
) => state.createWorkflowReducer.salsify;

export const selectSalsifyCredentialId = createSelector(
  selectCreateWorkflowState,
  (state) => state.salsify.credential_id,
);
export const selectSalsifyOrganizations = createSelector(
  selectCreateWorkflowState,
  (state) => state.salsify.organizations,
);
export const selectSalsifyProductId = createSelector(
  selectCreateWorkflowState,
  (state) => state.salsify.salsify_product_id,
);
export const selectSalsifyProductIdPropName = createSelector(
  selectCreateWorkflowState,
  (state) => state.salsify.productIdPropName,
);
export const selectSalsifyDigitalAssetProps = createSelector(
  selectCreateWorkflowState,
  (state) => state.salsify.digitalAssetProps,
);
export const selectSalsifyStandardProps = createSelector(
  selectCreateWorkflowState,
  (state) => state.salsify.standardProps,
);
export const selectSalsifyWorkflowName = createSelector(
  selectCreateWorkflowState,
  (state) => state.salsify.workflow_name,
);
export const selectSalsifyOrganization = createSelector(
  selectCreateWorkflowState,
  (state) => state.salsify.org_id,
);
export const selectSalsifyBrandfolder = createSelector(
  selectCreateWorkflowState,
  (state) => state.salsify.bf_source_key,
);
export const selectBrandfolderIdentifierCustomField = createSelector(
  selectCreateWorkflowState,
  (state) => state.salsify.brandfolder_identifier_custom_field,
);
export const selectSalsifyDefaultProp = createSelector(
  selectCreateWorkflowState,
  (state) => state.salsify.default_property,
);
export const selectSalsifyExistingDestConfig = createSelector(
  selectCreateWorkflowState,
  (state) => state.salsify.existingDestConfig,
);
export const selectSalsifyDestConfig = createSelector(
  selectCreateWorkflowState,
  (state) => state.salsify.dest_config,
);
export const selectSalsifyExistingCustomFieldMap = createSelector(
  selectCreateWorkflowState,
  (state) => state.salsify.existingCustomFieldMap,
);
export const selectSalsifyCustomFieldMappings = createSelector(
  selectCreateWorkflowState,
  (state) => state.salsify.custom_field_map,
);
export const selectSalsifyLoadingProperties = createSelector(
  selectSalsifyState,
  (state) => state.loadingProperties,
);

// Seismic
export const selectSeismicState: StateSelector<SeismicWorkflowFormState> = (
  state: CreateWorkflowRootState,
) => state.createWorkflowReducer.seismic;
export const selectSeismicCredentialId = createSelector(
  selectCreateWorkflowState,
  (state) => state.seismic.credential_id,
);
export const selectSeismicWorkflowName = createSelector(
  selectCreateWorkflowState,
  (state) => state.seismic.workflow_name,
);
export const selectSeismicBrandfolder = createSelector(
  selectCreateWorkflowState,
  (state) => state.seismic.bf_source_key,
);
export const selectSeismicTeamsiteId = createSelector(
  selectCreateWorkflowState,
  (state) => state.seismic.teamsite_id,
);
export const selectSeismicFolderId = createSelector(
  selectCreateWorkflowState,
  (state) => state.seismic.folder_id,
);
export const selectSeismicCurrentFolderName = createSelector(
  selectCreateWorkflowState,
  (state) => state.seismic.currentFolderName,
);
export const selectSeismicExistingSectionMaps = createSelector(
  selectCreateWorkflowState,
  (state) => state.seismic.seismic_existing_section_maps,
);
export const selectSeismicSectionToFolderMap = createSelector(
  selectCreateWorkflowState,
  (state) => state.seismic.section_to_folder_map,
);
export const selectSeismicRehydratedSectionMaps = createSelector(
  selectCreateWorkflowState,
  (state) => state.seismic.rehydrated_section_maps,
);
export const selectSeismicCollectionToFolderMap = createSelector(
  selectCreateWorkflowState,
  (state) => state.seismic.collection_to_folder_map,
);
export const selectSeismicRehydratedCollectionMaps = createSelector(
  selectCreateWorkflowState,
  (state) => state.seismic.rehydrated_collection_maps,
);
export const selectSeismicContainersLoading = createSelector(
  selectSeismicState,
  (state) => state.loading,
);
export const selectSeismicContainersEditLoading = createSelector(
  selectSeismicState,
  (state) => state.loading_edit,
);
export const selectSeismicContainers = createSelector(
  selectSeismicState,
  (state) => state.seismic_folders,
);
export const selectSeismicExistingCollectionMaps = createSelector(
  selectCreateWorkflowState,
  (state) => state.seismic.seismic_existing_collection_maps,
);
export const selectSeismicSyncEntireBrandfolderOptionSelected = createSelector(
  selectCreateWorkflowState,
  (state) => state.seismic.syncEntireBrandfolderOptionSelected,
);
export const selectSeismicSyncOptionalResourceOptionSelected = createSelector(
  selectCreateWorkflowState,
  (state) => state.seismic.syncOptionalResourceOptionSelected,
);

// Workfront
export const selectWorkfrontState: StateSelector<WorkfrontWorkflowFormState> = (
  state: CreateWorkflowRootState,
) => state.createWorkflowReducer.workfront;
export const selectWorkfrontWorkflowName = createSelector(
  selectCreateWorkflowState,
  (state) => state.workfront.workflow_name,
);
export const selectWorkfrontDocumentCustomFields = createSelector(
  selectCreateWorkflowState,
  (state) => state.workfront.document_custom_fields,
);
export const selectWorkfrontUserCustomFields = createSelector(
  selectCreateWorkflowState,
  (state) => state.workfront.user_custom_fields,
);
export const selectWorkfrontProjectCustomFields = createSelector(
  selectCreateWorkflowState,
  (state) => state.workfront.project_custom_fields,
);
export const selectWorkfrontPortfolioCustomFields = createSelector(
  selectCreateWorkflowState,
  (state) => state.workfront.portfolio_custom_fields,
);
export const selectWorkfrontProgramCustomFields = createSelector(
  selectCreateWorkflowState,
  (state) => state.workfront.program_custom_fields,
);
export const selectWorkfrontTags = createSelector(
  selectCreateWorkflowState,
  (state) => state.workfront.tags,
);
export const selectWorkfrontAddAssetLink = createSelector(
  selectCreateWorkflowState,
  (state) => state.workfront.description_link,
);
export const selectWorkfrontHost = createSelector(
  selectCreateWorkflowState,
  (state) => state.workfront.host,
);
export const selectWorkfrontClientId = createSelector(
  selectCreateWorkflowState,
  (state) => state.workfront.client_id,
);
export const selectWorkfrontClientSecret = createSelector(
  selectCreateWorkflowState,
  (state) => state.workfront.client_secret,
);
export const selectWorkfrontUserId = createSelector(
  selectCreateWorkflowState,
  (state) => state.workfront.user_id,
);
export const selectWorkfrontCustomerId = createSelector(
  selectCreateWorkflowState,
  (state) => state.workfront.customer_id,
);
