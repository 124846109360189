export interface ContainerNode {
  id: string;
  name: string;
  parentId: ContainerNode['id'] | null;
  children?: ContainerNode[];
}

export interface ContainerMetadata {
  total_count: number;
  total_pages: number;
}

export interface ContainerFormInfo {
  currentContainer: ContainerNode;
  siblings: ContainerNode[];
  children: ContainerNode[];
  metadata: ContainerMetadata;
}

export enum ContainerSelectorType {
  Asynchronous = 'async',
  Synchronous = 'synchronous',
}
