import { useDispatch, useSelector } from 'react-redux';
import React, { useState, useEffect } from 'react';

import { CreateHighspotWorkflowFormContainer } from '../workflow-forms/workflow-form-highspot';
import { CreateGettyWorkflowFormContainer } from '../workflow-forms/workflow-form-getty';
import { CreateHubspotWorkflowFormContainer } from '../workflow-forms/workflow-form-hubspot';
import { CreateWrikeWorkflowFormContainer } from '../workflow-forms/workflow-form-wrike';
import { CreateSeismicWorkflowFormContainer } from '../workflow-forms/workflow-form-seismic';
import { CreateWorkflowNav } from '../common/components/workflow-nav/create-workflow-nav';
import { SectionTracker } from '../common/components/section-tracker/section-tracker';
import { useForm, FormProvider, useFormState } from 'react-hook-form';
import { Loader } from '@integration-frontends/common/ui';

import {
  selectSelectedIntegrationType,
  selectActiveSection,
  highspotSetFormData,
  gettySetFormData,
  hubspotSetFormData,
  selectworkflowPageInit,
  createWorkflowInfoFormEntered,
  selectWorkflowMode,
  wrikeSetFormData,
  salsifySetFormData,
  seismicSetFormData,
  workflowModes,
  workfrontSetFormData,
  selectSelectedCredentialId,
  setSelectedCredentialId,
  asanaSetFormData,
  selectEditWorkflowInProgress,
  setEditWorkflowInProgress,
} from '@integration-frontends/workflow-manager/core/application';
import { CreateSalsifyWorkflowFormContainer } from '../workflow-forms/workflow-form-salsify/workflow-form-salsify';
import { workflowServiceType } from '@integration-frontends/workflow-manager/core/model';
import { CreateWorkfrontWorkflowFormContainer } from '../workflow-forms/workflow-form-workfront';
import { CreateAsanaWorkflowFormContainer } from '../workflow-forms/workflow-form-asana';

interface WorkflowInfoPageProps {
  onBack: () => void;
  onNext: () => void;
}

export const WorkflowInfoPage = ({ onBack, onNext }: WorkflowInfoPageProps) => {
  const methods = useForm();
  const dispatch = useDispatch();
  const loading = useSelector(selectworkflowPageInit);
  const mode = useSelector(selectWorkflowMode);
  const editing = mode === workflowModes.edit;
  const editInProgress = useSelector(selectEditWorkflowInProgress) && editing;
  const { isDirty, dirtyFields } = useFormState({ control: methods.control });
  useEffect(() => {
    if (mode === workflowModes.create) {
      dispatch(createWorkflowInfoFormEntered());
    }
  }, []);

  useEffect(() => {
    /**
     * Watch all fields for changes while in edit mode:
     * 
     * The react-hook-form library provides a an interface to watch for changes to the form.
     * The field is called `isDirty`.
     *
     * Throughout the app, certain form fields that call `setValue` will intentionally trigger the `isDirty`
     * flag to be set to true so that this useEffect hook will be called, indicating that the user has made
     * a change to edit form.
     *
     * Users may use the `edit` view the review the workflow configuration. The intention of the behavior
     * described here is to prevent the user from advancing to the next step in editting & publishing
     * the workflow unless they have explicitly made a change to the form.
     */

    if (isDirty && editing) {
      dispatch(setEditWorkflowInProgress({ editWorkflowInProgress: true }));
    }
  }, [isDirty]);

  const integrationType = useSelector(selectSelectedIntegrationType);
  const activeSection = useSelector(selectActiveSection);
  const selectedCredentialId = useSelector(selectSelectedCredentialId);
  const [complete, setComplete] = useState(false);
  const integrationMapping = {
    highspot: highspotSetFormData,
    getty: gettySetFormData,
    hubspot: hubspotSetFormData,
    wrike: wrikeSetFormData,
    seismic: seismicSetFormData,
    salsify: salsifySetFormData,
    workfront: workfrontSetFormData,
    asana: asanaSetFormData,
  };

  const onSubmit = (formData) => {
    const setFormDataAction = integrationMapping[integrationType];
    if (setFormDataAction) {
      dispatch(setFormDataAction(formData));
    }
    onNext();
  };

  useEffect(() => {
    if (!selectedCredentialId) {
      const searchParams = new URLSearchParams(document.location.search);
      const credentialId = searchParams.get('credential_id');
      if (credentialId) {
        dispatch(setSelectedCredentialId({ credentialId }));
      }
    }
  }, []);

  const getOnNext = () => {
    // disable next button during edits until user has made changes to the form
    if (editing && !editInProgress) {
      return null;
    }

    // disable next button during edits until form is complete
    if (editing && editInProgress && !complete) {
      return null;
    }

    // disable next button during create mode until form is complete
    if (!editing && !complete) {
      return null;
    }

    return methods.handleSubmit(onSubmit);
  };

  return (
    <FormProvider {...methods}>
      <CreateWorkflowNav
        onBack={onBack}
        onNext={getOnNext()}
        title="Workflow info"
        editing={editing}
      />
      <section className="workflow-wizard-section">
        <SectionTracker activeSection={activeSection} />
        {loading ? (
          <Loader />
        ) : (
          <section className="forms-container">
            {integrationType === workflowServiceType.highspot && (
              <CreateHighspotWorkflowFormContainer formComplete={(c: boolean) => setComplete(c)} />
            )}
            {integrationType === workflowServiceType.getty && (
              <CreateGettyWorkflowFormContainer formComplete={(c: boolean) => setComplete(c)} />
            )}
            {integrationType === workflowServiceType.hubspot && (
              <CreateHubspotWorkflowFormContainer formComplete={(c: boolean) => setComplete(c)} />
            )}
            {integrationType === workflowServiceType.asana && (
              <CreateAsanaWorkflowFormContainer formComplete={(c: boolean) => setComplete(c)} />
            )}
            {integrationType === workflowServiceType.wrike && (
              <CreateWrikeWorkflowFormContainer formComplete={(c: boolean) => setComplete(c)} />
            )}
            {integrationType === workflowServiceType.salsify && (
              <CreateSalsifyWorkflowFormContainer formComplete={(c: boolean) => setComplete(c)} />
            )}
            {integrationType === workflowServiceType.seismic && (
              <CreateSeismicWorkflowFormContainer formComplete={(c: boolean) => setComplete(c)} />
            )}
            {integrationType === workflowServiceType.workfront && (
              <CreateWorkfrontWorkflowFormContainer formComplete={(c: boolean) => setComplete(c)} />
            )}
          </section>
        )}
      </section>
    </FormProvider>
  );
};
