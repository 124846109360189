import React, { useEffect } from 'react';
import { push } from 'redux-first-history';
import { useDispatch, useSelector } from 'react-redux';
import { ReviewSection, ReviewSectionLine } from '../../../common';
import {
  credentialEntitySelectors,
  initEditWorkflow,
  selectAsanaCredentialId,
  selectAsanaSelectedCollectionName,
  selectAsanaSelectedWorkspaceName,
  selectAsanaTagTemplates,
  selectAsanaWorkflowName,
  selectCreateWorkflowSuccess,
  selectSelectedWorkflowId,
} from '@integration-frontends/workflow-manager/core/application';

import {
  selectSelectedIntegrationType,
  selectSelectedClientId,
  selectAsanaBrandfolder,
  clientEntitySelectors,
  brandfolderEntitySelectors,
  selectAsanaSelectedBfSectionName,
  selectAsanaSelectedProjectName,
  selectAsanaSelectedAsanaSectionName,
  selectAsanaAssetNameTemplate,
  selectAsanaAttachmentNameTemplate,
  selectAsanaSelectedCustomFieldNames,
} from '@integration-frontends/workflow-manager/core/application';
import { workflowTitle } from '@integration-frontends/workflow-manager/core/model';
import { workflowServiceType } from '@integration-frontends/workflow-manager/core/model';
import { ShimmerLoader } from '@brandfolder/react';

export const AsanaReview = ({ setComplete }) => {
  const dispatch = useDispatch();

  const createWorkflowSuccess = useSelector(selectCreateWorkflowSuccess);

  useEffect(() => {
    if (createWorkflowSuccess) {
      dispatch(push('/workflows'));
    }
  }, [createWorkflowSuccess]);

  const workflowId = useSelector(selectSelectedWorkflowId);
  const selectedClientId = useSelector(selectSelectedClientId);
  const selectedClient = useSelector((state) =>
    clientEntitySelectors.selectById(state, selectedClientId),
  );
  const integrationType = useSelector(selectSelectedIntegrationType);

  const workflowName = useSelector(selectAsanaWorkflowName);

  const selectedBrandfolderId = useSelector(selectAsanaBrandfolder);
  const selectedBrandfolder = useSelector((state) =>
    brandfolderEntitySelectors.selectById(state, selectedBrandfolderId),
  );
  const bfDestSection = useSelector(selectAsanaSelectedBfSectionName);
  const bfDestCollection = useSelector(selectAsanaSelectedCollectionName);
  const bfSelectedCustomFields = useSelector(selectAsanaSelectedCustomFieldNames);

  const asanaWorkspaceName = useSelector(selectAsanaSelectedWorkspaceName);
  const asanaProjectName = useSelector(selectAsanaSelectedProjectName);
  const asanaSectionName = useSelector(selectAsanaSelectedAsanaSectionName);
  const asanaAssetNameTemplate = useSelector(selectAsanaAssetNameTemplate);
  const asanaAttachmentNameTemplate = useSelector(selectAsanaAttachmentNameTemplate);
  const asanaTagTemplates = useSelector(selectAsanaTagTemplates);

  const selectedCredentialId = useSelector(selectAsanaCredentialId);
  const credential = useSelector(credentialEntitySelectors.credentialById(selectedCredentialId));

  const onEdit = () => {
    dispatch(initEditWorkflow({ integrationType: workflowServiceType.asana, workflowId }));
  };

  useEffect(() => {
    setComplete(
      !!(
        workflowName &&
        selectedBrandfolderId &&
        bfDestSection &&
        asanaWorkspaceName &&
        asanaProjectName
      ),
    );
  }, [workflowName, selectedBrandfolderId, bfDestSection, asanaWorkspaceName, asanaProjectName]);

  return (
    <>
      <ReviewSection title="Organization info" dataTestId="asana-organization-review-section">
        <ReviewSectionLine
          title="Client"
          value={<div data-testid="asana-selected-client-name">{selectedClient?.client_name}</div>}
        />
        <ReviewSectionLine
          title="Brandfolder API key"
          value={<div data-testid="asana-brandfolder-api-key">****</div>}
        />
      </ReviewSection>

      <ReviewSection title="Integration info" dataTestId="asana-integration-review-section">
        <ReviewSectionLine
          title="Integration"
          value={<div data-testid="asana-integration-type">{workflowTitle[integrationType]}</div>}
        />
        <ReviewSectionLine
          title="Credential name"
          value={<div data-testid="asana-credential-name">{credential?.credential_name}</div>}
        />
      </ReviewSection>

      <ReviewSection
        title="Workflow info"
        dataTestId="asana-workflow-review-section"
        editAction={onEdit}
      >
        <ReviewSectionLine
          title="Workflow name"
          value={<div data-testid="asana-workflow-name">{workflowName || ''}</div>}
        />

        {workflowId && (
          <ReviewSectionLine
            title="Workflow ID"
            value={<div data-testid="asana-workflow-id">{workflowId || ''}</div>}
          />
        )}

        <ReviewSectionLine
          title="Asana workspace"
          value={<div data-testid="asana-selected-workspace-name">{asanaWorkspaceName || ''}</div>}
        />

        <ReviewSectionLine
          title="Asana project"
          value={<div data-testid="asana-selected-project-name">{asanaProjectName || ''}</div>}
        />

        {asanaSectionName && (
          <ReviewSectionLine
            title="Asana section (optional)"
            value={
              <div data-testid="asana-selected-asana-section-name">{asanaSectionName || ''}</div>
            }
          />
        )}

        {asanaAssetNameTemplate && (
          <ReviewSectionLine
            title="Asset name template (optional)"
            value={
              <div data-testid="asana-selected-asset-name-template">
                {asanaAssetNameTemplate || ''}
              </div>
            }
          />
        )}

        {asanaAttachmentNameTemplate && (
          <ReviewSectionLine
            title="Attachment name template (optional)"
            value={
              <div data-testid="asana-selected-attachment-name-template">
                {asanaAttachmentNameTemplate || ''}
              </div>
            }
          />
        )}

        {asanaTagTemplates.length > 0 && (
          <ReviewSectionLine
            title="Custom fields to tags (optional)"
            value={
              <div data-testid="asana-selected-tag-templates">{asanaTagTemplates.join(', ')}</div>
            }
          />
        )}

        <ReviewSectionLine
          title="Brandfolder"
          value={
            <div data-testid="asana-selected-brandfolder-name">{selectedBrandfolder?.name || <ShimmerLoader/>}</div>
          }
        />

        <ReviewSectionLine
          title="Brandfolder section"
          value={<div data-testid="asana-selected-bf-section-name">{bfDestSection || ''}</div>}
        />

        {bfDestCollection && (
          <ReviewSectionLine
            title="Brandfolder collection (optional)"
            value={<div data-testid="asana-selected-collection-name">{bfDestCollection || ''}</div>}
          />
        )}

        {bfSelectedCustomFields.length > 0 && (
          <ReviewSectionLine
            title="Brandfolder custom fields (optional)"
            value={
              <div data-testid="asana-selected-custom-field-names">
                {bfSelectedCustomFields.join(', ')}
              </div>
            }
          />
        )}
      </ReviewSection>
    </>
  );
};
