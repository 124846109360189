import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  selectActiveSection,
  setActiveSection,
  createWorkflow,
  selectSelectedIntegrationType,
  enteredCredentialPage,
  selectWorkflowMode,
  workflowModes,
} from '@integration-frontends/workflow-manager/core/application';
import { activeSection as activeSectionEnum } from '@integration-frontends/workflow-manager/core/model';

import { CreateClientFormSection } from './client-info-page/client-info';
import { CredentialForm } from './app-info-page/credential-form';
import { ReviewPage } from './review-page/review-page';
import { WorkflowInfoPage } from './workflow-info-page/workflow-info-page';

import './create-workflow-form.scss';
import { IntegrationForm } from './integration-info-page/integration-form';
import { push } from 'redux-first-history';

export const sections = [
  ['client-info', 'Client info'],
  ['integration-info', 'Integration info'],
  ['app-info', 'App info'],
  ['workflow-info', 'Workflow info'],
  ['review', 'Review'],
];

export const CreateWorkflowFormContainer = () => {
  const dispatch = useDispatch();

  const activeSection = useSelector(selectActiveSection);
  const integrationType = useSelector(selectSelectedIntegrationType);
  const workflowMode = useSelector(selectWorkflowMode);
  const editing = workflowMode === workflowModes.edit;

  const nextSection = () => {
    const curr = sections.findIndex((el) => el[0] === activeSection);
    if (curr === sections.length - 1) {
      return;
    }
    dispatch(setActiveSection({ activeSection: sections[curr + 1][0] as activeSectionEnum }));
  };

  const previousSection = () => {
    const curr = sections.findIndex((el) => el[0] === activeSection);
    if (curr === 0) {
      return;
    }

    // refetch creds when going back to app-info
    if (curr === 3) {
      dispatch(enteredCredentialPage());
    }

    dispatch(setActiveSection({ activeSection: sections[curr - 1][0] as activeSectionEnum }));
  };

  const integrationFormNextSection =
    integrationType && integrationType !== 'null' ? nextSection : null;

  const credentialFormOnNext = () =>
    dispatch(setActiveSection({ activeSection: activeSectionEnum.workflowInfo }));

  const getWorkflowInfoOnBack = () => {
    if (editing) {
      return () => {
        dispatch(push('/workflows'));
      };
    }

    return previousSection;
  };

  return (
    <section id="create-workflow-form-container" className="create-workflow-form-container">
      <section className="integration-forms-container">
        {activeSection === activeSectionEnum.clientInfo && <CreateClientFormSection onNext={nextSection} />}
        {activeSection === activeSectionEnum.integrationInfo && (
          <IntegrationForm onBack={previousSection} onNext={integrationFormNextSection} />
        )}
        {activeSection === activeSectionEnum.appInfo && (
          <CredentialForm
            onBack={previousSection}
            onNext={integrationType !== 'null' && credentialFormOnNext}
          />
        )}
        {activeSection === activeSectionEnum.workflowInfo && (
          <WorkflowInfoPage onBack={getWorkflowInfoOnBack()} onNext={nextSection} />
        )}
        {activeSection === activeSectionEnum.review && (
          <ReviewPage
            onBack={previousSection}
            onNext={() => {
              dispatch(createWorkflow());
            }}
          />
        )}
      </section>
    </section>
  );
};
