// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { history, store } from './store';
import './app.scss';
import { ToastContainer } from 'react-toastify';
import { dynamicActivate } from '../../../../i18n';

import { Route, Routes, unstable_HistoryRouter as Router } from 'react-router-dom';
import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import {
  CreateWorkflowFormContainer,
  HeaderTitle,
  ClientsPage,
  OauthPage,
  WorkflowsPage,
} from '@integration-frontends/workflow-manager/ui';
import { init } from '@integration-frontends/common/app';
import { AuthProvider, AuthProviderProps } from 'react-oidc-context';
import { User } from 'oidc-client-ts';
import { environment } from '../environments/environment';
import { BrandfolderGlobalStyles } from '@brandfolder/react';
import { I18nProvider } from '@lingui/react';
import { i18n } from '@lingui/core';

function ConnectedApp() {
  useEffect(() => {
    store.dispatch(init({ name: `integration-workflow-manager` }));
  }, []);

  return (
    <Router history={history}>
      <BrandfolderGlobalStyles />
      <div />
      <HeaderTitle />
      <section className="admin-ui-container">
        <Routes>
          <Route
            path="/getting-started"
            element={
              <div id="oauth-container">
                <OauthPage />
              </div>
            }
          />
          <Route path="/workflows" element={<WorkflowsPage />} />
          <Route path="/clients" element={<ClientsPage />} />

          <Route path="/clients/:id" element={<ClientsPage />} />
          <Route path="/create-workflow/:entry" element={<CreateWorkflowFormContainer />} />
        </Routes>
      </section>
    </Router>
  );
}

export function App() {
  const onSigninCallback = (_user: User | void): void => {
    window.history.replaceState({}, document.title, window.location.pathname);
  };

  const oidcConfig: AuthProviderProps = {
    authority: environment.oauthBaseUrl,
    client_id: environment.oauthClientId,
    redirect_uri: environment.oauthRedirectUri,
    onSigninCallback: onSigninCallback,
    // set to false so we can handle renew manually
    automaticSilentRenew: false,
    scope: 'openid offline_access',
  };

  useEffect(() => {
    dynamicActivate('en');
  }, []);

  return (
    <>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        enableMultiContainer
        containerId="topLevel"
        className="top-level-toast-container"
      />
      <I18nProvider i18n={i18n}>
        <AuthProvider {...oidcConfig}>
          <Provider store={store}>
            <ConnectedApp />
          </Provider>
        </AuthProvider>
      </I18nProvider>
    </>
  );
}

export default App;
