import { put, takeEvery, select } from 'typed-redux-saga';
import { push } from 'redux-first-history';
import {
  initEditWorkflow,
  selectSelectedClientId,
  workflowFormFailure,
  workflowFormSuccess,
  setBrandfolderFormInfo,
  highspotSetFormData,
  gettySetFormData,
  setSelectedCredentialId,
  hubspotSetFormData,
  hubspotSetExistingSectionSelection,
  wrikeSetFormData,
  salsifySetFormData,
  salsifySetExistingDestConfig,
  seismicSetFormData,
  seismicSetExistingSectionMaps,
  seismicSetExistingCollectionMaps,
  salsifySetExistingCustomFieldMap,
  workflowPageLoaded,
  workfrontSetFormData,
  asanaSetFormData,
  asanaSetInitialTags,
  seismicSetSyncEntireBrandfolderOptionSelected,
  seismicSetSyncOptionalResourceOptionSelected,
  seismicRehydrateSingleDestination,
  setActiveSection,
} from '../../index';
import {
  WORKFLOW_REPO_TOKEN,
  IWorkflowRepo,
  FORM_INFO_REPO_TOKEN,
  IFormInfoRepo,
  workflowServiceType,
  SalsifyCreateWorkflowBody,
  activeSection,
} from '@integration-frontends/workflow-manager/core/model';
import { DI_CONTAINER } from '@integration-frontends/core';
import { brandfolderEntityActions, callWithTokenRefresh } from '../../../common/entities-state';

function* handler(action: ReturnType<typeof initEditWorkflow>) {
  const client_id = yield select(selectSelectedClientId);
  const { integrationType, workflowId } = action.payload;
  yield put(push('/create-workflow/app-info'));
  yield put(brandfolderEntityActions.brandfoldersRemoved());
  const formInfoRepo: IFormInfoRepo = DI_CONTAINER.get(FORM_INFO_REPO_TOKEN);
  const workflowRepo: IWorkflowRepo = DI_CONTAINER.get(WORKFLOW_REPO_TOKEN);
  const workflow = yield callWithTokenRefresh(
    workflowRepo.getWorkflow,
    client_id,
    workflowId,
    integrationType,
  );
  if (workflow) {
    yield setDataForIntegrationType(workflow, integrationType as workflowServiceType);
    if (workflow?.credential_id) {
      yield put(setSelectedCredentialId({ credentialId: workflow.credential_id }));
      const formInfo = yield callWithTokenRefresh(formInfoRepo.getBrandfolderFormInfo, client_id, {
        credential_id: workflow.credential_id,
      });
      if (formInfo) {
        yield put(setBrandfolderFormInfo(formInfo));
        yield put(brandfolderEntityActions.brandfoldersReceived(formInfo));
        yield put(workflowFormSuccess());
      } else {
        yield put(workflowFormFailure());
      }
    }

    if (
      integrationType === workflowServiceType.workfront &&
      (!workflow.client_id || !workflow.user_id || !workflow.customer_id)
    ) {
      /**
       * The absence of these values indicates that the workflow has not been updated to include
       * the new credential fields required as of July, 2024.
       *
       * When that is true, allow the IWM user to update the credential fields.
       * Workfront is unique in that the credentials are stored on the workflow object
       * rather than a dedicated workfront credential object. That makes this pattern
       * (allowing a user to update credential fields without a new credential object)
       * permissible.
       *
       * This would not be the case for other integrations, which would require a new credential object. ~TC
       */
      yield put(setActiveSection({ activeSection: activeSection.appInfo }));
    }
  } else {
    yield put(workflowFormFailure());
  }
  yield put(workflowPageLoaded());
}

export function* initEditWorkflowEffects() {
  yield takeEvery(initEditWorkflow, handler);
}

function* setDataForIntegrationType(workflow, integrationType: workflowServiceType) {
  switch (integrationType) {
    case workflowServiceType.highspot:
      yield put(
        highspotSetFormData({
          ...workflow,
          selectedSections: Object.keys(workflow.section_to_folder_map),
          selectedCollections: Object.keys(workflow.collection_to_folder_map),
          section_to_folder_map: {},
          collection_to_folder_map: {},
        }),
      );
      break;
    case workflowServiceType.getty:
      yield put(gettySetFormData(workflow));
      break;
    case workflowServiceType.hubspot:
      yield put(
        hubspotSetFormData({
          ...workflow,
          section_keys: Object?.keys(workflow.section_to_folder_map),
          section_to_folder_map: {},
        }),
      );
      yield put(
        hubspotSetExistingSectionSelection({
          hubspot_existing_section_selection: workflow?.section_keys,
        }),
      );
      break;
    case workflowServiceType.wrike:
      yield put(wrikeSetFormData(workflow));
      break;
    case workflowServiceType.salsify:
      {
        const {
          bf_source_key,
          workflow_name,
          custom_field_map: custom_field_map_data,
          org_id: org_id,
          identifier_map,
          dest_config: dest_config_data,
        } = workflow as SalsifyCreateWorkflowBody;
        const brandfolder_identifier_custom_field = Object.values(identifier_map)?.[0];
        const keys = Object.keys(dest_config_data?.custom_fields_to_prop_id);
        const values = keys.map((key) => {
          const valuesForKey = Object.keys(dest_config_data?.custom_fields_to_prop_id[key]);
          return valuesForKey;
        });
        const salsifyProps = keys.map((key) => {
          const salsifyPropsForKey = Object.values(dest_config_data?.custom_fields_to_prop_id[key]);
          return salsifyPropsForKey;
        });

        const dest_config = keys.reduce((mappings, key, index) => {
          const valuesForKey = values[index];
          const salsifyPropsForKey = salsifyProps[index];
          valuesForKey.map((value, index) => {
            const mappingForKey = {
              key,
              value: valuesForKey[index],
              salsifyProp: salsifyPropsForKey[index],
            };

            mappings.push(mappingForKey);
          });
          return mappings;
        }, []);

        const default_property = dest_config_data['default'];
        const salsify_product_id = { id: Object.keys(identifier_map)[0] };
        const customFieldMapKeys = Object.keys(custom_field_map_data);
        const custom_field_map = customFieldMapKeys.map((key) => {
          return { salsifyProp: key, key: custom_field_map_data[key] };
        });
        yield put(salsifySetExistingDestConfig({ destConfig: dest_config }));
        yield put(salsifySetExistingCustomFieldMap({ customFieldMap: custom_field_map }));
        yield put(
          salsifySetFormData({
            bf_source_key,
            brandfolder_identifier_custom_field,
            custom_field_map,
            dest_config,
            default_property,
            org_id,
            salsify_product_id,
            workflow_name,
          }),
        );
      }
      break;
    case workflowServiceType.seismic:
      yield put(seismicSetFormData(workflow));
      yield put(
        seismicSetExistingSectionMaps({
          seismic_existing_section_maps: workflow?.section_to_folder_map,
        }),
      );
      yield put(
        seismicSetExistingCollectionMaps({
          seismic_existing_collection_maps: workflow?.collection_to_folder_map,
        }),
      );

      // if collection_to_folder_map and section_to_folder_map are both empty,
      // then the entire brandfolder is selected to sync
      if (
        Object.keys(workflow?.collection_to_folder_map).length === 0 &&
        Object.keys(workflow?.section_to_folder_map).length === 0
      ) {
        yield put(seismicSetSyncEntireBrandfolderOptionSelected({ value: true }));
        yield put(seismicSetSyncOptionalResourceOptionSelected({ value: false }));
        yield put(
          seismicRehydrateSingleDestination({
            credential_id: workflow?.credential_id,
            teamsite_id: workflow?.teamsite_id,
            folder_id: workflow?.folder_id,
          }),
        );
      } else {
        yield put(seismicSetSyncEntireBrandfolderOptionSelected({ value: false }));
        yield put(seismicSetSyncOptionalResourceOptionSelected({ value: true }));
      }

      break;
    case workflowServiceType.workfront:
      yield put(workfrontSetFormData(workflow));
      break;
    case workflowServiceType.asana:
      yield put(asanaSetFormData(workflow));
      yield put(asanaSetInitialTags({ initial_tags: workflow?.tag_templates || [] }));
      break;
    default:
      break;
  }
}
