import { BFErrorLabel, BFLabel, BFSelect, BFSelectOption } from '@integration-frontends/common/ui';
import { alphabetizBfOptions } from 'libs/workflow-manager/ui/src/common/functions/alphabetize';
import * as React from 'react';
import { useFormContext } from 'react-hook-form';
import './select-custom-field.scss';

export interface SelectCustomFieldProps {
  customFieldSource: string;
  customFieldSources: BFSelectOption[];
  disabled: boolean;
}

export const SelectCustomField = ({
  customFieldSource,
  customFieldSources,
  disabled,
}: SelectCustomFieldProps): React.ReactElement => {
  const {
    register,
    setValue,
    formState: { errors },
  } = useFormContext();
  return (
    <div className="salsify-form-row-item">
      <BFLabel className="form-label" htmlFor="select-brandfolder-custom-field">
        Brandfolder custom field
      </BFLabel>
      <BFSelect
        data-testid="select-brandfolder-custom-field"
        id="select-brandfolder-custom-field"
        disabled={disabled}
        required={true}
        options={(alphabetizBfOptions(customFieldSources) as BFSelectOption[]) || []}
        {...register('brandfolder_identifier_custom_field', { required: true })}
        onOptionChange={(listOption): void => {
          setValue('brandfolder_identifier_custom_field', listOption?.value || ('' as string), {
            shouldValidate: true,
            shouldDirty: true,
          });
        }}
        value={customFieldSources?.find((cf) => cf.value === customFieldSource)?.value}
        placeholder="Choose custom field"
      />
      {errors.custom_field?.type === 'required' && (
        <BFErrorLabel role="alert">Brandfolder custom field is required</BFErrorLabel>
      )}
    </div>
  );
};
