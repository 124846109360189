import { put, takeEvery, select } from 'typed-redux-saga';
import { push } from 'redux-first-history';
import {
  initReviewWorkflow,
  selectSelectedClientId,
  workflowFormFailure,
  workflowFormSuccess,
  setBrandfolderFormInfo,
  highspotSetFormData,
  gettySetFormData,
  setSelectedCredentialId,
  hubspotSetFormData,
  hubspotSetExistingSectionSelection,
  wrikeSetFormData,
  salsifySetFormData,
  salsifySetExistingDestConfig,
  seismicSetFormData,
  seismicSetExistingSectionMaps,
  seismicSetExistingCollectionMaps,
  salsifySetExistingCustomFieldMap,
  selectSelectedCredentialId,
  salsifySelectOrganization,
  salsifyFetchInfo,
  getCustomFieldFormInfo,
  highspotFetchSpots,
  highspotSetCredentialId,
  hubspotSetCredentialId,
  hubspotGetFolderById,
  wrikeSetCredentialId,
  wrikeFetchSpaces,
  seismicSetCredentialId,
  seismicRehydrateSyncMaps,
  selectBrandfolderFormInfo,
  workfrontSetFormData,
  asanaSetCredentialId,
  asanaSetFormData,
  asanaRehydrateInfo,
  gettySetCredentialId,
  seismicRehydrateSingleDestination,
} from '../../index';
import {
  WORKFLOW_REPO_TOKEN,
  IWorkflowRepo,
  FORM_INFO_REPO_TOKEN,
  IFormInfoRepo,
  workflowServiceType,
  SalsifyCreateWorkflowBody,
  Workflow,
  SalsifyWorkflow,
  HubspotWorkflow,
  SeismicWorkflow,
  HighspotWorkflow,
  nonCredentialIntegrations,
  AsanaWorkflow,
} from '@integration-frontends/workflow-manager/core/model';
import { DI_CONTAINER } from '@integration-frontends/core';
import { brandfolderEntityActions, callWithTokenRefresh } from '../../../common/entities-state';
import { credentialEntitySelectors } from '../../../selectors';

function* handler(action: ReturnType<typeof initReviewWorkflow>) {
  const client_id = yield select(selectSelectedClientId);
  const { integrationType, workflowId } = action.payload;
  yield put(push('/create-workflow/app-info'));
  yield put(brandfolderEntityActions.brandfoldersRemoved());
  const formInfoRepo: IFormInfoRepo = DI_CONTAINER.get(FORM_INFO_REPO_TOKEN);
  const workflowRepo: IWorkflowRepo = DI_CONTAINER.get(WORKFLOW_REPO_TOKEN);
  const workflow = yield callWithTokenRefresh(
    workflowRepo.getWorkflow,
    client_id,
    workflowId,
    integrationType,
  );
  if (workflow) {
    yield put(setSelectedCredentialId({ credentialId: workflow?.credential_id }));
    yield setDataForIntegrationType(workflow, integrationType as workflowServiceType);

    if (!nonCredentialIntegrations.includes(integrationType as workflowServiceType)) {
      const formInfo = yield callWithTokenRefresh(formInfoRepo.getBrandfolderFormInfo, client_id, {
        credential_id: workflow?.credential_id,
      });
      if (formInfo) {
        yield put(setBrandfolderFormInfo(formInfo));
        yield getDataForIntegrationType(workflow, integrationType as workflowServiceType);
        yield put(brandfolderEntityActions.brandfoldersReceived(formInfo));
        yield put(workflowFormSuccess());
      } else {
        yield put(workflowFormFailure());
      }
    } else {
      try {
        yield getDataForIntegrationType(workflow, integrationType as workflowServiceType);
        yield put(workflowFormSuccess());
      } catch {
        yield put(workflowFormFailure());
      }
    }
  }
}

export function* initReviewWorkflowEffects() {
  yield takeEvery(initReviewWorkflow, handler);
}

function* setDataForIntegrationType(workflow, integrationType: workflowServiceType) {
  switch (integrationType) {
    case workflowServiceType.highspot:
      yield put(
        highspotSetFormData({
          ...workflow,
          selectedSections: Object.keys(workflow.section_to_folder_map),
          selectedCollections: Object.keys(workflow.collection_to_folder_map),
          section_to_folder_map: {},
          collection_to_folder_map: {},
        }),
      );
      break;
    case workflowServiceType.getty:
      yield put(gettySetFormData(workflow));
      break;
    case workflowServiceType.hubspot:
      yield put(
        hubspotSetFormData({
          ...workflow,
          section_keys: Object?.keys(workflow.section_to_folder_map),
          section_to_folder_map: {},
        }),
      );
      yield put(
        hubspotSetExistingSectionSelection({
          hubspot_existing_section_selection: workflow?.section_keys,
        }),
      );
      break;
    case workflowServiceType.wrike:
      yield put(wrikeSetFormData(workflow));
      break;
    case workflowServiceType.salsify:
      {
        const {
          bf_source_key,
          workflow_name,
          custom_field_map: custom_field_map_data,
          org_id: org_id,
          identifier_map,
          dest_config: dest_config_data,
        } = workflow as SalsifyCreateWorkflowBody;
        const brandfolder_identifier_custom_field = Object.values(identifier_map)?.[0];
        const keys = Object.keys(dest_config_data?.custom_fields_to_prop_id);
        const values = keys.map((key) => {
          const valuesForKey = Object.keys(dest_config_data?.custom_fields_to_prop_id[key]);
          return valuesForKey;
        });
        const salsifyProps = keys.map((key) => {
          const salsifyPropsForKey = Object.values(dest_config_data?.custom_fields_to_prop_id[key]);
          return salsifyPropsForKey;
        });

        const dest_config = keys.reduce((mappings, key, index) => {
          const valuesForKey = values[index];
          const salsifyPropsForKey = salsifyProps[index];
          valuesForKey.map((value, index) => {
            const mappingForKey = {
              key,
              value: valuesForKey[index],
              salsifyProp: salsifyPropsForKey[index],
            };

            mappings.push(mappingForKey);
          });
          return mappings;
        }, []);

        const default_property = dest_config_data['default'];
        const salsify_product_id = { id: Object.keys(identifier_map)[0] };
        const customFieldMapKeys = Object.keys(custom_field_map_data);
        const custom_field_map = customFieldMapKeys.map((key) => {
          return { salsifyProp: key, key: custom_field_map_data[key] };
        });
        yield put(salsifySetExistingDestConfig({ destConfig: dest_config }));
        yield put(salsifySetExistingCustomFieldMap({ customFieldMap: custom_field_map }));
        yield put(
          salsifySetFormData({
            bf_source_key,
            brandfolder_identifier_custom_field,
            custom_field_map,
            dest_config,
            default_property,
            org_id,
            salsify_product_id,
            workflow_name,
          }),
        );
      }
      break;
    case workflowServiceType.seismic:
      yield put(seismicSetFormData(workflow));
      yield put(
        seismicSetExistingSectionMaps({
          seismic_existing_section_maps: workflow?.section_to_folder_map,
        }),
      );
      yield put(
        seismicSetExistingCollectionMaps({
          seismic_existing_collection_maps: workflow?.collection_to_folder_map,
        }),
      );
      break;
    case workflowServiceType.workfront:
      yield put(workfrontSetFormData(workflow));
      break;
    case workflowServiceType.asana:
      yield put(asanaSetFormData(workflow));
      break;
    default:
      break;
  }
}

function* getDataForIntegrationType(workflow: Workflow, integrationType: workflowServiceType) {
  const credential_id = yield select(selectSelectedCredentialId);
  const clientId = yield select(selectSelectedClientId);
  switch (integrationType) {
    case workflowServiceType.getty: {
      yield put(gettySetCredentialId({ credential_id }));
      break;
    }
    case workflowServiceType.hubspot: {
      const hubspotWorkflow = workflow as HubspotWorkflow;
      yield put(hubspotSetCredentialId({ credential_id }));
      yield put(
        hubspotGetFolderById({ credential_id, sync_folder_id: hubspotWorkflow.sync_folder_id }),
      );
      break;
    }
    case workflowServiceType.highspot: {
      const highspotWorkflow = workflow as HighspotWorkflow;
      const selectedCredential = yield select(
        credentialEntitySelectors.credentialById(highspotWorkflow.credential_id),
      );
      yield put(highspotSetCredentialId({ credentialId: credential_id }));
      yield put(highspotFetchSpots({ credential: selectedCredential }));
      break;
    }
    case workflowServiceType.wrike: {
      yield put(wrikeSetCredentialId({ credential_id }));
      yield put(wrikeFetchSpaces({ credential_id }));
      break;
    }
    case workflowServiceType.salsify: {
      const salsifyWorkflow = workflow as SalsifyWorkflow;
      yield put(salsifyFetchInfo({ clientId, credential_id }));
      yield put(
        salsifyFetchInfo({ clientId, credential_id, salsify_org_id: salsifyWorkflow.org_id }),
      );
      yield put(
        salsifySelectOrganization({
          clientId,
          credential_id,
          org_id: salsifyWorkflow.org_id,
        }),
      );
      yield put(getCustomFieldFormInfo({ brandfolderId: salsifyWorkflow.bf_source_key }));
      break;
    }
    case workflowServiceType.seismic: {
      const seismicWorkflow = workflow as SeismicWorkflow;
      const brandfolderFormInfo = yield select(selectBrandfolderFormInfo);
      const bfSource = seismicWorkflow.bf_source_key;
      const bfSources = brandfolderFormInfo?.map((bf) => {
        return { label: bf.name, value: bf.id, sections: bf.sections, collections: bf.collections };
      });

      const bfSections = bfSources
        ?.find((bf) => bf.value === bfSource)
        ?.sections?.data.map((section) => {
          return { label: section.name, value: section.id };
        });

      const bfCollections = bfSources
        ?.find((bf) => bf.value === bfSource)
        ?.collections?.data.map((collection) => {
          return { label: collection.name, value: collection.id };
        });

      yield put(seismicSetCredentialId({ credential_id }));
      if (Object.keys(seismicWorkflow.collection_to_folder_map).length) {
        const sync_map_object = seismicWorkflow.collection_to_folder_map;
        yield put(
          seismicRehydrateSyncMaps({
            bf_info: bfCollections,
            credential_id,
            sync_map_object,
            sync_map_type: 'collection_to_folder',
            teamsite_id: seismicWorkflow.teamsite_id,
          }),
        );
      }
      if (Object.keys(seismicWorkflow.section_to_folder_map).length) {
        const sync_map_object = seismicWorkflow.section_to_folder_map;
        yield put(
          seismicRehydrateSyncMaps({
            bf_info: bfSections,
            credential_id,
            sync_map_object,
            sync_map_type: 'section_to_folder',
            teamsite_id: seismicWorkflow.teamsite_id,
          }),
        );
      }

      if (
        Object.keys(seismicWorkflow.section_to_folder_map).length === 0 &&
        Object.keys(seismicWorkflow.collection_to_folder_map).length === 0
      ) {
        yield put(
          seismicRehydrateSingleDestination({
            credential_id,
            teamsite_id: seismicWorkflow.teamsite_id,
            folder_id: seismicWorkflow.folder_id,
          }),
        );
      }
      break;
    }

    case workflowServiceType.asana: {
      const asanaWorkflow = workflow as AsanaWorkflow;

      yield put(
        asanaRehydrateInfo({
          credential_id,
          workspace_id: asanaWorkflow.workspace_id,
          project_id: asanaWorkflow.project_id,
          section_id: asanaWorkflow.section_id,
        }),
      );

      yield put(asanaSetCredentialId({ credential_id }));
      break;
    }
    default:
      break;
  }
}
