import { FontSizes, FontWeights, StandardText } from '@brandfolder/react';
import React from 'react';
import './review-page.scss';

export interface ReviewSectionProps {
  title: string;
  children: any;
  dataTestId?: string;
  editAction?: () => void;
}

export const ReviewSection = ({ children, title, dataTestId, editAction }: ReviewSectionProps) => {
  return (
    <section className="review-section" data-testid={dataTestId}>
      <span className="edit-wrapper">
        <StandardText
          size={FontSizes.XLarge}
          weight={FontWeights.Bold}
          className="review-section-title mb-lg"
        >
          {title}
        </StandardText>
        {editAction && (
          <p className="edit-text" data-testid="review-edit-link" onClick={editAction}>
            Edit
          </p>
        )}
      </span>
      {children}
    </section>
  );
};

export interface ReviewSectionLineProps {
  title: string;
  value: JSX.Element | JSX.Element[] | string | string[];
  tooltip?: JSX.Element;
}

export const ReviewSectionLine = ({ title, value, tooltip }: ReviewSectionLineProps) => {
  return (
    <div className="review-attribute">
      <span className="review-attribute-title">
        {title}
        {tooltip && <div className="review-title-tooltip">{tooltip}</div>}
      </span>
      <div className="review-attribute-value">{value}</div>
    </div>
  );
};
