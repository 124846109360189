import {
  Brandfolder,
  Client,
  HighspotFormInfo,
  Workflow,
  HubspotFormInfo,
  WrikeFormInfo,
  SalsifyFormInfo,
  SeismicFormInfo,
  BrandfolderFormInfo,
  CustomFieldKeyDatum,
  ClientApiResponseData,
  AsanaFormInfo,
  AsanaWorkflow,
  GettyWorkflow,
} from '@integration-frontends/workflow-manager/core/model';
import { mockClients, mockClientsData } from './client-mock-data';

export const clientsDataMockData: ClientApiResponseData = mockClientsData;
export const clientsMockData: Client[] = mockClients;

export const workflowsMockData = [
  {
    id: '123',
    brandfolder_account_id: 123,
    workflow_name: 'highspot test workflow',
    integration_type: 'highspot',
    active: false,
    total_assets_affected: 10,
    brandfolder: 'Fonts',
    created_at: '2022-04-14T16:04:59.533492Z',
    updated_at: '2022-04-20T19:20:37.616116Z',
    last_updated_by: 'Test User',
  },
  {
    id: '234',
    brandfolder_account_id: 123,
    workflow_name: 'getty test workflow',
    integration_type: 'getty',
    active: false,
    total_assets_affected: 7,
    brandfolder: 'Marketing',
    created_at: '2022-03-08T21:03:36.98053Z',
    updated_at: '2022-03-08T21:03:36.98053Z',
    last_updated_by: 'Test User',
  },
  {
    id: '345',
    brandfolder_account_id: 123,
    workflow_name: 'wrike test workflow',
    integration_type: 'wrike',
    active: true,
    total_assets_affected: 91,
    brandfolder: 'Videos',
    created_at: '2022-06-08T21:03:36.98053Z',
    updated_at: '2022-06-08T21:03:36.98053Z',
    last_updated_by: 'Test User',
  },
  {
    id: '456',
    brandfolder_account_id: 123,
    workflow_name: 'hubspot test workflow',
    integration_type: 'hubspot',
    active: false,
    total_assets_affected: 2,
    brandfolder: 'PDFs',
    created_at: '2022-05-08T21:03:36.98053Z',
    updated_at: '2022-05-08T21:03:36.98053Z',
    last_updated_by: 'Test User',
  },
  {
    id: '567',
    brandfolder_account_id: 123,
    workflow_name: 'salsify test workflow',
    integration_type: 'salsify',
    active: false,
    total_assets_affected: 0,
    brandfolder: 'Colors',
    created_at: '2022-11-08T21:03:36.98053Z',
    updated_at: '2022-11-08T21:03:36.98053Z',
    last_updated_by: 'Test User',
  },
  {
    id: '678',
    brandfolder_account_id: 123,
    workflow_name: 'seismic test workflow',
    integration_type: 'seismic',
    active: true,
    total_assets_affected: 38,
    brandfolder: 'Documents',
    created_at: '2022-09-08T21:03:36.98053Z',
    updated_at: '2022-09-08T21:03:36.98053Z',
    last_updated_by: 'Test User',
  },
];

export const credentialsMockData = [
  {
    access_token: 'accesstoken 1',
    api_key: 'apiKey1',
    brandfolder_account_id: 0,
    client_key: 'clientkey1',
    client_secret: 'client secret 1',
    created_at: 'string',
    credential_type: 'highspot',
    email: 'email@email.com',
    expires_at: 'string',
    external_user_id: 'string',
    id: 'abc123',
    refresh_token: 'asdfawef',
    scopes: 'string',
    updated_at: 'string',
  },
  {
    access_token: 'accesstoken 2',
    api_key: 'apiKey2',
    brandfolder_account_id: 0,
    client_key: 'clientkey2',
    client_secret: 'client secret 2',
    created_at: 'string',
    credential_type: 'highspot',
    email: 'email@email.com',
    expires_at: 'string',
    external_user_id: 'string',
    id: 'abc223',
    refresh_token: 'asdfaasdwef',
    scopes: 'string',
    updated_at: 'string',
  },
  {
    access_token: 'accesstoken 2',
    api_key: 'apiKey2',
    brandfolder_account_id: 0,
    client_key: 'clientkey2',
    client_secret: 'client secret 2',
    created_at: 'string',
    credential_type: 'getty',
    email: 'email@email.com',
    expires_at: 'string',
    external_user_id: 'string',
    id: 'abc223',
    refresh_token: 'asdfaasdwef',
    scopes: 'string',
    updated_at: 'string',
  },
];

export const asanaFormInfoMockData: AsanaFormInfo = {
  workspaces: {
    data: [
      {
        gid: '1',
        name: 'Workspace 1',
        resource_type: 'workspace',
      },
    ],
  },
  projects: {
    data: [
      {
        gid: '2',
        name: 'Project 1',
        resource_type: 'project',
      },
    ],
  },
  sections: {
    data: [
      {
        gid: '3',
        name: 'Section 1',
        resource_type: 'section',
      },
    ],
  },
};

export const brandfolderFormInfoMockData: Brandfolder[] = [
  {
    collections: {
      data: [
        {
          id: '1',
          name: 'BF 1 Collection 1',
        },
        {
          id: '2',
          name: 'BF 1 Collection 2',
        },
      ],
    },
    id: '1',
    name: 'Brandfolder 1',
    sections: {
      data: [
        {
          id: '1',
          name: 'BF 1 Section 1',
        },
        {
          id: '2',
          name: 'BF 1 Section 2',
        },
        {
          id: '3',
          name: 'BF 1 Section 3',
        },
      ],
    },
  },
  {
    collections: {
      data: [
        {
          id: '1',
          name: 'BF 2 Collection 1',
        },
        {
          id: '2',
          name: 'BF 2 Collection 2',
        },
      ],
    },
    id: '2',
    name: 'Brandfolder 2',
    sections: {
      data: [
        {
          id: '1',
          name: 'BF 2 Section 1',
        },
      ],
    },
  },
];

export const customFieldKeyResponseMockDatum: BrandfolderFormInfo = {
  brandfolder_info: [
    {
      id: '',
      name: '',
      collections: {
        data: [],
      },
      sections: {
        data: [],
      },
      custom_fields: {
        data: [
          {
            id: '0',
            allowed_values: ['test value 1', 'test value 2'],
            restricted: true,
            name: 'name',
          },
          {
            id: '1',
            allowed_values: ['test value 2', 'test value 3'],
            restricted: false,
            name: 'name2',
          },
          {
            id: '2',
            allowed_values: ['test value 4', 'test value 5'],
            restricted: true,
            name: 'name3',
          },
        ],
      },
    },
  ],
};

export const customFieldKeyResponseMockData: BrandfolderFormInfo = customFieldKeyResponseMockDatum;

export const highspotFormInfoMockData: HighspotFormInfo = {
  collection: [
    {
      id: '1',
      title: 'Highspot Collection',
    },
    {
      id: '2',
      title: 'Highspot Collection 2',
    },
  ],
};

export const hubspotFormInfoMockData: HubspotFormInfo = {
  children: {
    data: [
      {
        id: '0',
        name: 'folder 0',
        parent_folder_id: 'None',
      },
      {
        id: '1',
        name: 'folder 1',
        parent_folder_id: 'None',
      },
      {
        id: '2',
        name: 'folder 2',
        parent_folder_id: 'None',
      },
    ],
  },
  current_folder: {
    id: 'None',
    name: 'root folder',
    parent_folder_id: 'None',
  },
  siblings: {
    data: [],
  },
  metadata: {
    total_count: 0,
    total_pages: 0,
  },
};

export const wrikeFormInfoMockData: WrikeFormInfo = {
  resources: [
    {
      id: '0',
      title: 'Root',
      childIds: ['1', '2', '3'],
      space: false,
    },
    {
      id: '1',
      title: 'Folder 1',
      childIds: [],
      space: false,
    },
    {
      id: '2',
      title: 'Folder 2',
      childIds: [],
      space: false,
    },
    {
      id: '3',
      title: 'Folder 3',
      childIds: [],
      space: false,
    },
  ],
};

export const salsifyPropsMockData = [
  {
    'salsify:system_id': '0',
    'salsify:id': 'id-1',
    'salsify:name': 'Digital Asset Prop 1',
    'salsify:data_type': 'digital_asset',
  },
  {
    'salsify:system_id': '1',
    'salsify:id': 'id-2',
    'salsify:name': 'Digital Asset Prop 2',
    'salsify:data_type': 'digital_asset',
  },
  {
    'salsify:system_id': '2',
    'salsify:id': 'id-3',
    'salsify:name': 'Other prop type 3',
    'salsify:data_type': 'string',
  },
  {
    'salsify:system_id': '3',
    'salsify:id': 'id-4',
    'salsify:name': 'Other prop type 4',
    'salsify:data_type': 'number',
  },
];

export const salsifyFormInfoMockData: SalsifyFormInfo = {
  organizations: [
    {
      id: '1',
      name: 'org name',
      system_id: 'id',
    },
  ],
  paginated_properties: {
    properties: [
      ...salsifyPropsMockData,
      {
        'salsify:data_type': 'data',
        'salsify:id': 'id',
        'salsify:name': 'name',
        'salsify:role': 'role',
        'salsify:system_id': 'systemId',
      },
    ],
    meta: {
      current_page: 0,
      total_pages: 0,
      per_page: 0,
      total_entries: 0,
    },
  },
};

export const seismicFormInfoMockData: SeismicFormInfo = {
  current_folder: {
    id: 'root',
    name: 'Home',
    type: 'folder',
    parentFolderId: 'root',
  },
  siblings: [],
  children: [
    {
      id: '1',
      name: 'Folder 1',
      type: 'folder',
      parentFolderId: 'root',
    },
    {
      id: '2',
      name: 'Folder 2',
      type: 'folder',
      parentFolderId: 'root',
    },
    {
      id: '3',
      name: 'Folder 3',
      type: 'folder',
      parentFolderId: 'root',
    },
  ],
  metadata: {
    total_count: 0,
    total_pages: 0,
  },
};

export const createWorkflowMockData: Workflow = {
  id: '0',
  brandfolder_account_id: 1,
  active: false,
  created_at: '2023-04-17T18:17:37.023862Z',
  updated_at: '2023-04-17T18:17:37.023862Z',
  last_updated_by: 1,
  credential_id: '1',
  bf_source_key: 'id',
  workflow_name: 'test workflow name',
  custom_field_map: {
    '0': '1',
  },
  org_id: 'org-id',
  identifier_map: {
    '0': '1',
  },
  dest_config: {
    custom_fields_to_prop_id: {
      id: {
        value: 'salsify-id',
      },
      id1: {
        'value-1': 'salsify-id',
      },
    },
    default: 'salsify-id',
  },
};

export const gettyGetWorkflowMockData: GettyWorkflow = {
  id: 'a1b2d7e3-289a-4cb5-9531-7bdc39368dd7',
  brandfolder_account_id: 1000000039,
  active: false,
  created_at: '2023-03-15T20:57:02.484944Z',
  updated_at: '2023-03-15T20:57:02.484944Z',
  last_updated_by: 1000000039,
  credential_id: '0',
  bf_destination_key: '1',
  workflow_name: 'Test workflow',
  last_synced_date: '',
  sync_start_date: '2023-03-15',
  sync_end_date: '2100-01-01',
  tag_template: '',
  bf_destination_collection: '1',
  bf_destination_section: '2',
  allow_expired_license: false,
  cron_schedule: '0 0 * * 0',
};

export const hubspotGetWorkflowMockData: Workflow = {
  id: '0',
  brandfolder_account_id: 0,
  active: false,
  created_at: '2023-04-11T21:08:35.731806Z',
  updated_at: '2023-04-24T16:11:12.677276Z',
  last_updated_by: 0,
  credential_id: '0',
  bf_source_key: '1',
  workflow_name: 'hubspot test workflow',
  sync_folder_id: '0',
  section_to_folder_map: {
    1: '0',
    2: '0',
  },
  collection_key: '1',
  section_keys: ['0', '1'],
};

export const wrikeGetWorkflowMockData: Workflow = {
  id: '0',
  brandfolder_account_id: 0,
  active: false,
  created_at: '2023-04-11T21:08:35.731806Z',
  updated_at: '2023-04-24T16:11:12.677276Z',
  last_updated_by: 0,
  credential_id: '0',
  bf_destination_key: '1',
  workflow_name: 'wrike test workflow',
  section_key: '1',
  collection_key: '',
  resource_type: 'folders',
  resource_id: '0',
  webhook_id: '',
  recursive: true,
};

export const asanaGetWorkflowMockData: AsanaWorkflow = {
  id: '0',
  brandfolder_account_id: 0,
  workflow_name: 'asana test workflow',
  active: true,
  bf_destination_key: '1',
  bf_destination_section: '1',
  bf_destination_collection: '1',
  credential_id: '0',
  workspace_id: '1',
  project_id: '2',
  section_id: '4',
  asset_name_template: 'asset template',
  attachment_name_template: 'attachment template',
  tag_templates: ['tag1', 'tag2', 'tag3'],
  sync_on_complete: true,
  custom_field_map: {
    name: '0',
    name2: '1',
  },
};

export const seismicGetWorkflowMockData: Workflow = {
  id: '0',
  brandfolder_account_id: 0,
  active: false,
  created_at: '2023-04-14T16:04:59.533492Z',
  updated_at: '2023-04-20T19:20:37.616116Z',
  last_updated_by: 0,
  credential_id: '0',
  bf_source_key: '1',
  workflow_name: 'seismic test workflow',
  teamsite_id: '1',
  section_to_folder_map: {
    '1': '1',
  },
  custom_fields_map: {},
  collection_to_folder_map: {
    '1': '1',
  },
  folder_id: '',
};

export const highspotGetWorkflowMockData: Workflow = {
  id: '1',
  brandfolder_account_id: 0,
  active: false,
  created_at: '2023-04-28T05:29:48.162044992Z',
  updated_at: '2023-04-28T05:29:48.162044992Z',
  last_updated_by: 0,
  credential_id: '0',
  bf_source_key: '1',
  integration_id: '0',
  section_to_folder_map: {},
  collection_to_folder_map: {},
  workflow_name: 'Test Highspot Workflow',
  spot_id: '1',
};

export const salsifyGetWorkflowMockData: Workflow = {
  id: '0',
  brandfolder_account_id: 1,
  active: false,
  created_at: '2023-04-17T18:17:37.023862Z',
  updated_at: '2023-04-17T18:17:37.023862Z',
  last_updated_by: 1,
  credential_id: '1',
  bf_source_key: '1',
  workflow_name: 'test workflow name',
  custom_field_map: {
    '3': '2', 
  },
  org_id: '1',
  identifier_map: {
    '1': '1',
  },
  dest_config: {
    custom_fields_to_prop_id: {
      '0': {
        'test value 1': '1',
      },
    },
    default: '0',
  },
};

export const salsifyOrgMockData = [
  {
    system_id: '1',
    id: '1',
    name: 'Org',
  },
  {
    system_id: '2',
    id: '2',
    name: 'Org 2',
  },
];

export const customFieldFormInfoMockData: CustomFieldKeyDatum[] = [
  {
    id: '0',
    allowed_values: ['test value 1', 'test value 2'],
    name: 'test 1',
    restricted: false,
  },
  {
    id: '1',
    allowed_values: ['test value 2', 'test value 3'],
    name: 'test 2',
    restricted: false,
  },
];

export const customFieldAllowedValuesOptionsMockData = {
  '0': ['test value 1', 'test value 2'],
  '1': ['test value 2', 'test value 3'],
  '2': ['test value 4', 'test value 5'],
};

export const workfrontGetWorkflowMockData: Workflow = {
  id: '1',
  brandfolder_account_id: 0,
  active: false,
  created_at: '2023-04-28T05:29:48.162044992Z',
  updated_at: '2023-04-28T05:29:48.162044992Z',
  last_updated_by: 0,
  credential_id: '0',
  workflow_name: 'Test workflow',
  document_custom_fields: true,
  user_custom_fields: true,
  project_custom_fields: false,
  portfolio_custom_fields: true,
  program_custom_fields: false,
  tags: ['test-tag-1', 'test-tag-2'],
  description_link: true,
  host: 'smartsheet',
  client_id: 'mock-client-id',
  client_secret: '****',  
  customer_id: 'mock-customer-id',
  user_id: 'mock-user-id',
};
