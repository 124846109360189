import React, { useEffect } from 'react';
import { CreateWorkflowInputField } from '../../common/components/create-workflow-input-field/create-workflow-input-field';
import { useFormContext } from 'react-hook-form';
import { BFErrorLabel } from '@integration-frontends/common/ui';
import { useDispatch } from 'react-redux';
import { setEditWorkflowInProgress } from '@integration-frontends/workflow-manager/core/application';

export interface WorkfrontCredentialFormProps {
  credsComplete?: (c: boolean) => void;
  credFormData?: any;
}

export const WorkfrontCredentialForm = ({
  credsComplete,
  credFormData,
}: WorkfrontCredentialFormProps) => {
  const {
    register,
    formState: { errors },
    setValue,
    watch,
    getValues,
  } = useFormContext();

  const dispatch = useDispatch();

  const host = watch('host');
  const clientId = watch('client_id');
  const userId = watch('user_id');
  const customerId = watch('customer_id');
  const clientSecret = watch('client_secret');

  useEffect(() => {
    dispatch(setEditWorkflowInProgress({ editWorkflowInProgress: true }));

    if (host && clientId && userId && customerId && clientSecret) {
      if (credsComplete && credFormData) {
        credsComplete(true);
        credFormData(getValues());
      } else {
        credsComplete(false);
      }
    }
  }, [host, clientId, userId, customerId, clientSecret]);

  return (
    <>
      <CreateWorkflowInputField
        label={'Workfront client ID'}
        placeholder={'Enter client ID'}
        value={clientId}
        inputId={'set-workfront-client-id'}
        tooltip="The client ID is found in the Workfront UI after completing the OAuth flow. See documentation linked above for details."
        onChange={(e) => {
          setValue('client_id', e.target.value || ('' as string));
        }}
        validation={register('client_id', { required: true })}
      />
      {errors.client_id?.type === 'required' && (
        <BFErrorLabel role="alert">Please verify the client ID.</BFErrorLabel>
      )}

      <CreateWorkflowInputField
        label={'Workfront client secret'}
        placeholder={'Enter client secret'}
        value={clientSecret}
        inputId={'set-workfront-client-secret'}
        tooltip="The client secret is found in the Workfront UI after completing the OAuth flow. See documentation linked above for details."
        onChange={(e) => {
          setValue('client_secret', e.target.value || ('' as string));
        }}
        validation={register('client_secret', { required: true })}
      />
      {errors.client_secret?.type === 'required' && (
        <BFErrorLabel role="alert">Please verify the client secret.</BFErrorLabel>
      )}

      <CreateWorkflowInputField
        label={'Workfront user ID'}
        placeholder={'Enter user ID'}
        value={userId}
        inputId={'set-workfront-user-id'}
        tooltip="The user ID is found in the Workfront UI after completing the OAuth flow. See documentation linked above for details."
        onChange={(e) => {
          setValue('user_id', e.target.value || ('' as string));
        }}
        validation={register('user_id', { required: true })}
      />
      {errors.user_id?.type === 'required' && (
        <BFErrorLabel role="alert">Please verify the user ID.</BFErrorLabel>
      )}

      <CreateWorkflowInputField
        label={'Workfront customer ID'}
        placeholder={'Enter customer ID'}
        value={customerId}
        inputId={'set-workfront-customer-id'}
        tooltip="The customer ID the unique identifier for the Workfront API."
        onChange={(e) => {
          setValue('customer_id', e.target.value || ('' as string));
        }}
        validation={register('customer_id', { required: true })}
      />
      {errors.customer_id?.type === 'required' && (
        <BFErrorLabel role="alert">Please verify the customer ID.</BFErrorLabel>
      )}

      <CreateWorkflowInputField
        label={'Workfront subdomain'}
        placeholder={'Enter subdomain'}
        value={host}
        inputId={'set-workfront-subdomain'}
        tooltip="The subdomain is the text string or word prior to .workfront.com or my.workfront.com"
        onChange={(e) => {
          setValue('host', e.target.value || ('' as string));
        }}
        validation={register('host', { required: true })}
      />
      {errors.host?.type === 'required' && (
        <BFErrorLabel role="alert">Please verify the subdomain.</BFErrorLabel>
      )}
    </>
  );
};
