import React from 'react';
import { IconLogo } from '@integration-frontends/common/ui';
import { logoutThunk } from '@integration-frontends/common/auth/core/application';
import { useDispatch } from 'react-redux';
import { useAuth } from 'react-oidc-context';
import { useLocation } from 'react-router-dom';
import { FontWeights, StandardText } from '@brandfolder/react';

export const HeaderTitle = () => {
  const auth = useAuth();
  const dispatch = useDispatch();

  const handleSignOut = async () => {
    await auth.removeUser();
    dispatch(logoutThunk() as any);
  };

  const location = useLocation();
  const showHeader = location.pathname.split('/')[1] !== 'create-workflow';

  return (
    showHeader && (
      <header className="header">
        <IconLogo />
        <div className="links flex gap-sm">
          <a href='/assets/importer.html'>
            Importer
          </a>
          <StandardText
            className="sign-out"
            data-cy="logout"
            onClick={() => handleSignOut()}
            weight={FontWeights.Medium}
          >
            Sign Out
          </StandardText>
        </div>
      </header>
    )
  );
};
