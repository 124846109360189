import { Environment } from '@integration-frontends/apps/common/utils/environment-types';
const hostUrl = 'https://workflow-manager.brandfolder-svc.com';

export const environment = {
  production: true,
  bfBaseUrl: 'https://brandfolder.com/api',
  bfWebsiteBaseUrl: 'https://brandfolder.com',
  bfStaticAssetsBaseUrl: 'https://static-fast.brandfolder.com',
  hostUrl,
  oauthBaseUrl: 'https://oauth2.brandfolder-apps.com',
  oauthClientId: 'workflow-manager-client-prod',
  temporalBaseUrl: 'https://integration-workflows.brandfolder-svc.com/api',
  oauthRedirectUri: hostUrl,
  environment: Environment.Production,
};
