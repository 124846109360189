export interface WorkflowsTableColumn {
  children: string;
  heading: string;
  rowKey: string;
  tdClassName: string;
}

export const workflowsTableColumns: WorkflowsTableColumn[] = [
  {
    children: 'Integration',
    heading: 'Integration',
    rowKey: 'integration',
    tdClassName: 'integration-column',
  },
  {
    children: 'Workflow Name',
    heading: 'Workflow Name',
    rowKey: 'workflowName',
    tdClassName: 'workflow-name-column',
  },
  {
    children: 'Brandfolder',
    heading: 'Brandfolder',
    rowKey: 'brandfolder',
    tdClassName: 'brandfolder-column',
  },
  {
    children: 'Assets affected',
    heading: 'Assets affected',
    rowKey: 'totalAssetsAffected',
    tdClassName: 'total-assets-affected-column',
  },
  {
    children: 'Status',
    heading: 'Status',
    rowKey: 'status',
    tdClassName: 'status-column',
  },
  {
    children: 'Created',
    heading: 'Created',
    rowKey: 'created',
    tdClassName: 'created-column',
  },
  {
    children: 'Last updated',
    heading: 'Last updated',
    rowKey: 'lastUpdated',
    tdClassName: 'last-updated-column',
  },
  {
    children: 'Updated by',
    heading: 'Updated by',
    rowKey: 'updatedBy',
    tdClassName: 'updated-by-column',
  },
  {
    children: '',
    heading: 'Options',
    rowKey: 'options',
    tdClassName: 'options-column',
  },
];
