import { HubspotFolder } from '@integration-frontends/common/temporal-api';

/**
 * Workflow interface definitions in this file should align directly with the objects returned from the API.
 *
 * ~TC
 */
export interface SalsifyWorkflow {
  active: boolean;
  bf_source_key: string;
  brandfolder_account_id: number;
  created_at: string;
  credential_id: string;
  custom_field_map: Record<string, string>;
  dest_config: {
    custom_fields_to_prop_id: {
      [key: string]: Record<string, string>;
    };
    default: string;
  };
  id: string;
  identifier_map: Record<string, string>;
  last_updated_by: number;
  org_id: string;
  updated_at: string;
  workflow_name: string;
}

export interface HubspotWorkflow {
  active: boolean;
  bf_source_key: string;
  brandfolder_account_id: number;
  collection_key: string;
  created_at: string;
  credential_id: string;
  last_updated_by: number;
  section_keys: string[];
  section_to_folder_map: {
    [key: string]: string;
  };
  sync_folder_id: string;
  updated_at: string;
  workflow_name: string;
}

export interface SeismicWorkflow {
  id: string;
  brandfolder_account_id: number;
  active: boolean;
  created_at: string;
  updated_at: string;
  last_updated_by: number;
  credential_id: string;
  bf_source_key: string;
  workflow_name: string;
  teamsite_id: string;
  section_to_folder_map: {
    [key: string]: string;
  };
  custom_fields_map: {
    [key: string]: string;
  };
  collection_to_folder_map: {
    [key: string]: string;
  };
  folder_id: string;
}
export interface GettyWorkflow {
  id: string;
  active: boolean;
  bf_destination_key: string;
  brandfolder_account_id: number;
  created_at: string;
  credential_id: string;
  last_updated_by: number;
  last_synced_date: string;
  tag_template: string;
  updated_at: string;
  workflow_name: string;
  allow_expired_license: boolean;
  cron_schedule: string;
  bf_destination_collection: string;
  bf_destination_section: string;
  sync_start_date: string;
  sync_end_date: string;
}

export interface HighspotWorkflow {
  id: string;
  brandfolder_account_id: number;
  active: boolean;
  created_at: string;
  updated_at: string;
  last_updated_by: number;
  credential_id: string;
  bf_source_key: string;
  integration_id: string;
  section_to_folder_map: {
    [key: string]: string;
  };
  collection_to_folder_map: {
    [key: string]: string;
  };
  workflow_name: string;
  spot_id: string;
}

export interface WrikeWorkflow {
  id: string;
  brandfolder_account_id: number;
  active: boolean;
  created_at: string;
  updated_at: string;
  last_updated_by: number;
  credential_id: string;
  bf_destination_key: string;
  workflow_name: string;
  section_key: string;
  collection_key: string;
  resource_type: string;
  resource_id: string;
  webhook_id: string;
  recursive: boolean;
}

export interface WorkfrontWorkflow {
  active: boolean;
  workflow_name: string;
  document_custom_fields: boolean;
  user_custom_fields: boolean;
  project_custom_fields: boolean;
  portfolio_custom_fields: boolean;
  program_custom_fields: boolean;
  tags: string[];
  description_link: boolean;
  host: string;
  client_id: string;
  client_secret: string;
  customer_id: string;
  user_id: string;
}

export interface AsanaWorkflow {
  id: string;
  active: boolean;
  brandfolder_account_id: number;
  workflow_name: string;
  credential_id: string;
  workspace_id: string;
  project_id: string;
  section_id?: string;
  asset_name_template?: string;
  attachment_name_template?: string;
  tag_templates?: string[];
  custom_field_map?: {
    [key: string]: string;
  };
  bf_destination_key: string;
  bf_destination_section: string;
  bf_destination_collection?: string;
  sync_on_complete: boolean;
}

export interface WorkflowListItem {
  id: string;
  brandfolder: string;
  brandfolder_account_id: number;
  workflow_name: string;
  integration_type: string;
  active: boolean;
  total_assets_affected: number;
  created_at: string;
  updated_at: string;
  last_updated_by: string;
}

export const WORKFLOW_REPO_TOKEN = 'WORKFLOW_REPO';

export type CreateWorkflowBody =
  | HighspotCreateWorkflowBody
  | GettyCreateWorkflowBody
  | HubspotCreateWorkflowBody
  | WrikeCreateWorkflowBody
  | SeismicCreateWorkflowBody
  | SalsifyCreateWorkflowBody
  | WorkfrontCreateWorkflowBody
  | AsanaCreateWorkflowBody;

export type UpdateWorkflowBody = {
  service: workflowServiceType;
  active?: boolean;
};

export type Workflow =
  | HighspotWorkflow
  | SalsifyWorkflow
  | HubspotWorkflow
  | GettyWorkflow
  | SeismicWorkflow
  | WrikeWorkflow
  | WorkfrontWorkflow
  | AsanaWorkflow;

export enum workflowServiceType {
  highspot = 'highspot',
  getty = 'getty',
  salsify = 'salsify',
  seismic = 'seismic',
  hubspot = 'hubspot',
  wrike = 'wrike',
  workfront = 'workfront',
  asana = 'asana',
}

export enum activeSection {
  clientInfo = 'client-info',
  integrationInfo = 'integration-info',
  appInfo = 'app-info',
  workflowInfo = 'workflow-info',
  review = 'review',
}

export const oauthWorkflows = [workflowServiceType.asana, workflowServiceType.wrike];

export const nonCredentialIntegrations: workflowServiceType[] = [workflowServiceType.workfront];

export const workflowTitle: Record<workflowServiceType, string> = {
  [workflowServiceType.highspot]: 'Highspot',
  [workflowServiceType.getty]: 'Getty',
  [workflowServiceType.salsify]: 'Salsify',
  [workflowServiceType.seismic]: 'Seismic',
  [workflowServiceType.hubspot]: 'HubSpot',
  [workflowServiceType.wrike]: 'Wrike',
  [workflowServiceType.workfront]: 'Workfront',
  [workflowServiceType.asana]: 'Asana',
};

export const docsLink = `https://smar-wiki.atlassian.net/wiki/spaces/PROD/pages/70971228283/CX+Guide+-+IWM+-+Integration+Workflow+Manager`;

interface BaseWorkflowBody {
  active?: boolean;
  credential_id: string;
  service: workflowServiceType;
}

export interface HighspotCreateWorkflowBody extends BaseWorkflowBody {
  api_host: string;
  bf_source_key: string;
  collection_to_folder_map?: Record<string, string>;
  integration_id: string;
  section_to_folder_map?: Record<string, string>;
  spot_id: string;
  workflow_name: string;
}

export interface GettyCreateWorkflowBody extends BaseWorkflowBody {
  allow_expired_license: boolean;
  bf_destination_collection: string;
  bf_destination_key: string;
  bf_destination_section: string;
  cron_schedule: string;
  sync_end_date: Date;
  sync_start_date: Date;
  tag_template: string;
}

export interface HubspotCreateWorkflowBody extends BaseWorkflowBody {
  bf_source_key: string;
  collection_key?: string;
  private_app_key: string;
  section_keys?: string[];
  section_to_folder_map?: Record<string, string>;
  sync_folder_id: string;
  sync_folder: HubspotFolder;
  workflow_name: string;
}

export interface WrikeCreateWorkflowBody extends BaseWorkflowBody {
  bf_destination_key: string;
  collection_key: string;
  recursive: boolean;
  resource_id: string;
  resource_type: string;
  section_key: string;
  workflow_name: string;
}
export interface SalsifyCreateWorkflowBody extends BaseWorkflowBody {
  api_key: string;
  bf_source_key: string;
  org_id: string;
  custom_field_map: Record<string, string>;
  identifier_map: Record<string, string>;
  dest_config: {
    custom_fields_to_prop_id: {
      [key: string]: Record<string, string>;
    };
    default: string;
  };
  workflow_name: string;
}

export interface SeismicCreateWorkflowBody extends BaseWorkflowBody {
  bf_source_key: string;
  collection_keys?: string[];
  collection_to_folder_map?: Record<string, string>;
  folder_id: string;
  section_keys?: string[];
  section_to_folder_map?: Record<string, string>;
  workflow_name: string;
}

export enum WorkfrontFormOption {
  document_custom_fields = 'Document forms',
  project_custom_fields = 'Project forms',
  user_custom_fields = 'User forms',
}

export enum WorkfrontProjectFormOption {
  portfolio_custom_fields = 'Portfolio forms',
  program_custom_fields = 'Program forms',
}

export interface WorkfrontCreateWorkflowBody extends BaseWorkflowBody {
  workflow_name: string;
  workfront_forms: WorkfrontFormOption[];
  workfront_project_forms: WorkfrontProjectFormOption[];
  workfront_form_fields: string[];
  document_custom_fields: boolean;
  user_custom_fields: boolean;
  project_custom_fields: boolean;
  portfolio_custom_fields: boolean;
  program_custom_fields: boolean;
  tags: string[];
  description_link: boolean;
  client_key: string;
  host: string;
  client_id: string;
  client_secret: string;
  customer_id: string;
  user_id: string;
  subdomain: string;
}

export interface AsanaCreateWorkflowBody extends BaseWorkflowBody {
  workflow_name: string;
  workspace_id: string;
  project_id: string;
  section_id?: string;
  asset_name_template?: string;
  attachment_name_template?: string;
  tag_templates?: string[];
  custom_field_map?: {
    [key: string]: string;
  };
  bf_destination_key: string;
  bf_destination_section: string;
  bf_destination_collection?: string;
  sync_on_complete: boolean;
}

export interface IWorkflowRepo {
  listWorkflows: (clientId: string) => Promise<WorkflowListItem[]>;
  createWorkflow: (clientId: string, clientBody: CreateWorkflowBody) => Promise<Workflow>;
  updateWorkflow: (
    clientId: string,
    workflowId: string,
    clientBody: UpdateWorkflowBody,
  ) => Promise<Workflow>;
  getWorkflow: (clientId: string, workflowId: string, service: string) => Promise<Workflow>;
}
