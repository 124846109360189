import { createReducer } from '@reduxjs/toolkit';
import { clientsLoading, clientsLoadingSuccess, clientsLoadingFail } from './actions';

export interface ClientsPageState {
  clientsLoading: boolean;
}

export const clientsPageInitialState: ClientsPageState = {
  clientsLoading: false,
};

export const clientsPageReducer = createReducer(clientsPageInitialState, (builder) =>
  builder
    .addCase(clientsLoading, (state) => {
      state.clientsLoading = true;
    })
    .addCase(clientsLoadingSuccess, (state) => {
      state.clientsLoading = false;
    })
    .addCase(clientsLoadingFail, (state) => {
      state.clientsLoading = false;
    }),
);

export interface ClientsPageRootState {
  clientsPageReducer: ClientsPageState;
}
