import React from 'react';
import { useFormContext } from 'react-hook-form';
import {
  WorkfrontFormOption,
  WorkfrontProjectFormOption,
} from '@integration-frontends/workflow-manager/core/model';

export interface ListBoxOption {
  value: string;
  children: any;
  disabled: boolean;
  key: string;
  search: string;
  placeholder?: string;
}
type FormType = 'workfront_forms' | 'workfront_project_forms' | 'workfront_form_fields';

export const handleFormOptionUpdateByForm = () => {
  const { setValue } = useFormContext();

  const handleFormOptionUpdate = (tags, action, tag) => {
    if (action === 'remove') {
      setValue(tag.key as string, false, { shouldDirty: true });
    } else {
      setValue(tag.key as string, true, { shouldDirty: true });
    }
  };

  return handleFormOptionUpdate;
};

export const formOptionList = (form: FormType) => {
  const formOptionPlaceholder: ListBoxOption = {
    children: null,
    disabled: false,
    key: 'placeholder',
    placeholder: 'Choose forms',
    search: '',
    value: '',
  };

  return [
    formOptionPlaceholder,
    ...Object.entries(
      form === 'workfront_forms' ? WorkfrontFormOption : WorkfrontProjectFormOption,
    ).map(([key, value]) => ({
      value: value as string,
      children: <>{value}</>,
      disabled: false,
      key: key,
      search: value as string,
    })),
  ];
};

export const generateWorkfrontForms = (
  workfrontDocumentCustomFields: boolean,
  workfrontUserCustomFields: boolean,
  workfrontProjectCustomFields: boolean,
) => {
  const initWorkfrontForms = [];

  if (workfrontDocumentCustomFields) {
    initWorkfrontForms.push({
      document_custom_fields: WorkfrontFormOption.document_custom_fields,
    });
  }
  if (workfrontUserCustomFields) {
    initWorkfrontForms.push({ user_custom_fields: WorkfrontFormOption.user_custom_fields });
  }
  if (workfrontProjectCustomFields) {
    initWorkfrontForms.push({ project_custom_fields: WorkfrontFormOption.project_custom_fields });
  }
  return initWorkfrontForms;
};

export const generateWorkfrontProjectForms = (
  workfrontPortfolioCustomFields: boolean,
  workfrontProgramCustomFields: boolean,
) => {
  const initWorkfrontProjectForms = [];

  if (workfrontPortfolioCustomFields) {
    initWorkfrontProjectForms.push({
      portfolio_custom_fields: WorkfrontProjectFormOption.portfolio_custom_fields,
    });
  }
  if (workfrontProgramCustomFields) {
    initWorkfrontProjectForms.push({
      program_custom_fields: WorkfrontProjectFormOption.program_custom_fields,
    });
  }
  return initWorkfrontProjectForms;
};
