import React from 'react';
import { OauthCredentialForm } from '../../common/components/oauth-credential-form/oauth-credential-form';

interface AsanaCredentialFormProps {
  credsComplete?: (c: boolean) => void;
  credFormData?: any;
}

export const AsanaCredentialForm = ({ credsComplete, credFormData }: AsanaCredentialFormProps) => {
  return (
    <OauthCredentialForm
      integrationName="Asana"
      credFormData={credFormData}
      credsComplete={credsComplete}
    />
  );
};
