import { CheckboxSwitch, StandardText } from '@brandfolder/react';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import './asset-link-toggle.scss';

export const AddAssetLinkToggle = () => {
  const { setValue, watch } = useFormContext();
  const workfrontDescriptionLink = watch('description_link');

  return (
    <div className="add-asset-link-container">
      {workfrontDescriptionLink != undefined && (
        <CheckboxSwitch
          label="Add asset link to document description"
          defaultChecked={workfrontDescriptionLink}
          showLabel={false}
          id="add-asset-link-switch"
          className="add-asset-link-switch"
          data-testid="add-asset-link-switch"
          onChange={() => setValue('description_link', !workfrontDescriptionLink, { shouldDirty: true })}
        />
      )}
      <div className="add-asset-link-text-container">
        <StandardText className="add-asset-link-text-main">
          Add asset link to document description
        </StandardText>
        <StandardText className="add-asset-link-text-sub">
          The link to the asset in Brandfolder will appear in the document description field in
          Workfront.
        </StandardText>
      </div>
    </div>
  );
};
