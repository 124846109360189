import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CreateClientForm } from './create-client-form';
import { Banner } from '../common';
import { Loader } from '@integration-frontends/common/ui';
import {
  clientEntitySelectors,
  clientsPageEntered,
  selectClientCreateSuccess,
  selectClient,
  createClient,
  selectClientsLoading,
} from '@integration-frontends/workflow-manager/core/application';

import { ClientSortOption } from '@integration-frontends/workflow-manager/core/model';

import './clients-page.scss';
import { dateFormatter } from '@integration-frontends/common/utils/date-formatter';
import {
  StandardText,
  StandardPagination,
  StandardTable,
  StandardHeading,
  HeadingLevels,
} from '@brandfolder/react';
import { push } from 'redux-first-history';
import { ClientsHeader } from './clients-header/clients-header';

export interface SortOption {
  label: string;
  value: ClientSortOption;
}

const clientSortOptions: SortOption[] = [
  { label: 'Client (A-Z)', value: 'az' },
  { label: 'Client (Z-A)', value: 'za' },
  { label: 'Updated (oldest)', value: 'updatedOldest' },
  { label: 'Updated (newest)', value: 'updatedNewest' },
];

export const ClientsPage = () => {
  const dispatch = useDispatch();
  const [createClientOpen, setCreateClientOpen] = useState(false);
  const [search, setSearch] = useState('');
  const [sort, setSort] = useState('az' as ClientSortOption);
  const clients = useSelector(clientEntitySelectors.filterClients(search, sort));
  const clientsLoading = useSelector(selectClientsLoading);
  const createClientSuccess = useSelector(selectClientCreateSuccess);

  const [currentPage, setCurrentPage] = useState<number>(1);
  const clientsPerPage = 25;
  const numOfPages = Math.ceil(clients?.length / clientsPerPage);

  const startIndex = (currentPage - 1) * clientsPerPage;
  const endIndex = startIndex + clientsPerPage;

  const currentClients = clients.slice(startIndex, endIndex);

  const convertedClientsFormat = () => {
    return currentClients.map((client) => {
      return {
        clientName: <span id={client.id}>{client?.client_name}</span>,
        integrations: <span>{client?.workflows?.length}</span>,
        lastUpdated: <span>{dateFormatter(client?.updated_at, 'MM/DD/YY')}</span>,
      };
    });
  };

  useEffect(() => {
    dispatch(clientsPageEntered());
  }, []);

  useEffect(() => {
    if (createClientSuccess) {
      setCreateClientOpen(false);
    }
  }, [createClientSuccess]);

  const onSubmit = (clientName, bfApiKey) => {
    dispatch(createClient({ clientName, bfApiKey }));
  };

  const handlePageChange = (page): void => {
    setCurrentPage(page);
  };

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
    setCurrentPage(1);
  };

  const onSelectClient = (e) => {
    const row = e?.target?.closest('tr');
    const clientId = row?.querySelector('span')?.querySelector('span')?.getAttribute('id');
    if (clientId) {
      dispatch(selectClient({ clientId: clientId }));
      dispatch(push('/workflows'));
    }
  };

  const handleCreateClientOpen = () => {
    setCreateClientOpen(!createClientOpen);
  };

  const handleSetSort = (selectedOption: ClientSortOption) => {
    setSort(selectedOption);
  };

  return (
    <section className="clients-page" data-testid="clients-page-container">
      <Banner />
      <ClientsHeader
        handleSearchChange={handleSearchChange}
        handleCreateClientOpen={handleCreateClientOpen}
        handleSetSort={handleSetSort}
        sortOptions={clientSortOptions}
      />
      {createClientOpen && (
        <CreateClientForm
          modal={true}
          onClose={() => setCreateClientOpen(false)}
          onSubmit={onSubmit}
        />
      )}
      <StandardTable
        breakpoints={[768]}
        caption={'List of clients'}
        showCaption={false}
        tableProps={{ className: 'client-page-table', id: 'client-page-table' }}
        tbodyProps={{ className: clients?.length > 0 && 'client-page-table-border-bottom' }}
        columns={[
          {
            children: 'Client',
            heading: 'Client',
            rowKey: 'clientName',
            tdClassName: 'client-name-column',
          },
          {
            children: 'Integrations',
            heading: 'Integrations',
            rowKey: 'integrations',
            tdClassName: 'integrations-column',
          },
          {
            children: 'Last Updated',
            heading: 'Last Updated',
            rowKey: 'lastUpdated',
            tdClassName: 'last-updated-column',
          },
        ]}
        id={'clients-page-table'}
        thProps={{ className: 'client-row-header' }}
        trProps={{ className: 'client-row-container', onClick: onSelectClient }}
        rows={convertedClientsFormat()}
        emptyContent={clientsLoading ? <Loader /> : clientPageEmptyContent()}
        footer
        footerContent={
          clients?.length > clientsPerPage &&
          clientPageFooterContent(currentPage, handlePageChange, numOfPages)
        }
      />
    </section>
  );
};

const clientPageEmptyContent = () => {
  return (
    <div
      className="clients-empty-state-container"
      data-testid="clients-empty-state-message"
      role="alert"
    >
      <StandardHeading level={HeadingLevels.h2} className="clients-empty-state-header">
        No results found
      </StandardHeading>
      <StandardText className="clients-empty-state-message">
        We couldn’t find a client with this name. If you’ve spelled their name correctly, they may
        not have any workflows in Workflow Manager yet.
      </StandardText>
    </div>
  );
};

const clientPageFooterContent = (
  currentPage: number,
  handlePageChange: (page: number) => void,
  numOfPages: number,
) => {
  return (
    <div className="clients-pagination-container" data-testid="clients-pagination-container">
      <StandardPagination
        initial={currentPage}
        labels={{
          nextLabel: 'Next',
          previousLabel: 'Previous',
        }}
        onPageChange={handlePageChange}
        total={numOfPages}
      />
    </div>
  );
};
