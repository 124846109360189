import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import { selectWorkfrontState } from '@integration-frontends/workflow-manager/core/application';

import '../../create-workflow-form.scss';

import { WorkflowName } from '../../common/components/workflow-name.tsx/workflow-name';
import { WorkflowInfo } from '../../common/components/workflow-info/workflow-info';
import { WorkfrontOptionalMetadata } from './workfront-optional-metadata/workfront-optional-metadata';

export const CreateWorkfrontWorkflowFormContainer = ({ formComplete }) => {
  const { reset, watch } = useFormContext();

  const workflowName = watch('workflow_name');
  const defaultValues = useSelector(selectWorkfrontState);

  useEffect(() => {
    reset(defaultValues);
  }, []);

  // Checks if the form is complete, disables Next if not
  useEffect(() => {
    if (workflowName) {
      formComplete(true);
    } else {
      formComplete(false);
    }
  }, [workflowName]);

  return (
    <section className="forms-content-container" data-testid="workfront-workflow-form-container">
      <WorkflowInfo
        linkToDocumentation={
          'https://smar-wiki.atlassian.net/wiki/spaces/PROD/pages/71086408541/Guide+IWM+-+Workfront'
        }
        linkTestId="workfront-documentation-link"
      />
      <WorkflowName />
      <WorkfrontOptionalMetadata />
    </section>
  );
};
