import { ShimmerLoader, StandardTextfield } from '@brandfolder/react';
import { SalsifyProdctIdObj } from '@integration-frontends/workflow-manager/core/model';
import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import './product-id.scss';
import { BFLabel } from '@integration-frontends/common/ui';
export interface ProductIdProps {
  salsifyProductId: SalsifyProdctIdObj;
  loadingProperties?: boolean;
}

export const ProductId = ({
  loadingProperties,
  salsifyProductId,
}: ProductIdProps): React.ReactElement => {
  const { setValue } = useFormContext();

  useEffect(() => {
    setValue('salsify_product_id', { id: salsifyProductId?.id, name: salsifyProductId?.name });
  }, [salsifyProductId]);

  return (
    <div className="salsify-form-row-item responsive-row-margin salsify-product-id-container">
      <BFLabel className="form-label" id="product-id-label" htmlFor="salsify-product-id">
        Salsify Product ID
      </BFLabel>
      {loadingProperties ? (
        <ShimmerLoader className="workflow-shimmer-loader" />
      ) : (
        <StandardTextfield
          className="salsify-product-id-item"
          label=""
          data-testid="salsify-product-id"
          disabled={true}
          id="salsify-product-id"
          value={salsifyProductId?.name || ''}
          placeholder={salsifyProductId?.name || ''}
        />
      )}
    </div>
  );
};
