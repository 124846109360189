import { createSelector } from 'reselect';
import {
  CreateCredentialState,
  CreateCredentialRootState,
  HighspotCredential,
  GettyCredential,
  HubspotCredential,
  WrikeCredential,
  SalsifyCredential,
  SeismicCredential,
  AsanaCredential,
} from './reducer';
import { StateSelector } from '@integration-frontends/common/utils/redux';

const selectCreateCredentialState: StateSelector<CreateCredentialState> = (
  state: CreateCredentialRootState,
) => state.createCredentialReducer;

export const selectCreateCredentialLoading = createSelector(
  selectCreateCredentialState,
  (state) => state.credentialLoading,
);
export const selectCreateCredentialError = createSelector(
  selectCreateCredentialState,
  (state) => state.createCredentialError,
);

// High Spot
const selectHighspotState: StateSelector<HighspotCredential> = (state: CreateCredentialRootState) =>
  state.createCredentialReducer.highspot;

export const selectHighspotCredName = createSelector(
  selectHighspotState,
  (state) => state.credential_name,
);
export const selectHighspotClientKey = createSelector(
  selectHighspotState,
  (state) => state.client_key,
);
export const selectHighspotClientSecret = createSelector(
  selectHighspotState,
  (state) => state.client_secret,
);
export const selectHighspotApiHost = createSelector(selectHighspotState, (state) => state.api_host);

// Getty
const selectGettyState: StateSelector<GettyCredential> = (state: CreateCredentialRootState) =>
  state.createCredentialReducer.getty;

export const selectGettyCredName = createSelector(
  selectGettyState,
  (state) => state.credential_name,
);
export const selectGettyClientKey = createSelector(selectGettyState, (state) => state.client_key);
export const selectGettyClientSecret = createSelector(
  selectGettyState,
  (state) => state.client_secret,
);

// HubSpot
const selectHubspotState: StateSelector<HubspotCredential> = (state: CreateCredentialRootState) =>
  state.createCredentialReducer.hubspot;

export const selectHubspotCredName = createSelector(
  selectHubspotState,
  (state) => state.credential_name,
);
export const selectHubspotAccessToken = createSelector(
  selectHubspotState,
  (state) => state.private_app_key,
);

// Wrike
const selectWrikeState: StateSelector<WrikeCredential> = (state: CreateCredentialRootState) =>
  state.createCredentialReducer.wrike;

export const selectWrikeCredName = createSelector(
  selectWrikeState,
  (state) => state.credential_name,
);

// Asana
const selectAsanaState: StateSelector<AsanaCredential> = (state: CreateCredentialRootState) =>
  state.createCredentialReducer.asana;

export const selectAsanaCredName = createSelector(
  selectAsanaState,
  (state) => state.credential_name,
);

//Salsify
const selectSalsifyState: StateSelector<SalsifyCredential> = (state: CreateCredentialRootState) =>
  state.createCredentialReducer.salsify;

export const selectSalsifyCredName = createSelector(
  selectSalsifyState,
  (state) => state.credential_name,
);
export const selectSalsifyApiKey = createSelector(selectSalsifyState, (state) => state.api_key);

// Seismic
const selectSeismicState: StateSelector<SeismicCredential> = (state: CreateCredentialRootState) =>
  state.createCredentialReducer.seismic;

export const selectSeismicCredName = createSelector(
  selectSeismicState,
  (state) => state.credential_name,
);
export const selectSeismicUserId = createSelector(
  selectSeismicState,
  (state) => state.external_user_id,
);
export const selectSeismicClientId = createSelector(selectSeismicState, (state) => state.client_id);
export const selectSeismicSecretId = createSelector(selectSeismicState, (state) => state.secret_id);
export const selectSeismicHost = createSelector(selectSeismicState, (state) => state.host);
