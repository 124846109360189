import {
  ContainerNode,
  ContainerSelectorType,
} from '@integration-frontends/workflow-manager/core/model';
import React, { useEffect, useState } from 'react';
import { ContainerSelectorComponent } from './container-selector-component';

interface ContainerSelectorProps {
  onChange: any;
  displayedName: string;
  containerNamePropertyName: string;
  containers: ContainerNode[];
  currentContainer: ContainerNode;
  disabled?: boolean;
}

export const SynchronousContainerSelector = ({
  onChange,
  displayedName,
  containerNamePropertyName,
  containers,
  disabled,
}: ContainerSelectorProps) => {
  const [containerHistory, setContainerHistory] = useState<ContainerNode[]>([]);
  const [selectedContainer, setSelectedContainer] = useState<ContainerNode>(null);
  const [selectableContainers, setSelectableContainers] = useState<ContainerNode[]>([]);

  useEffect(() => {
    onChange(selectedContainer);
  }, [selectedContainer]);

  useEffect(() => {
    if (containers && containers.length > 0) {
      setSelectableContainers(containers[0]?.children);
    }
  }, [containers]);

  const handleContainerSelected = (selectedContainer: ContainerNode) => {
    setSelectedContainer(selectedContainer);
  };
  const handleGoToContainer = (container: ContainerNode) => {
    setContainerHistory([...containerHistory, container]);
    setSelectableContainers(container?.children);
  };
  const handleGoToParentContainer = () => {
    let previousContainer = containerHistory.length > 1 ? containerHistory.at(-2) : containers[0];
    setSelectableContainers(previousContainer?.children);
    setContainerHistory(containerHistory.slice(0, -1));
  };

  return (
    <ContainerSelectorComponent
      selectorType={ContainerSelectorType.Synchronous}
      displayedName={displayedName}
      children={selectableContainers}
      containerSelected={(container) => handleContainerSelected(container)}
      goToContainer={(container) => handleGoToContainer(container)}
      goToParentContainer={() => handleGoToParentContainer()}
      disableGoToParentContainer={containerHistory.length === 0}
      containerNamePropertyName={containerNamePropertyName}
      disabled={disabled}
    />
  );
};
