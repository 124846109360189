import React, { ReactElement } from 'react';
import { BFLabel, BFSelect, BFSelectOption } from '@integration-frontends/common/ui';
import { FieldArrayWithId, useFormContext, useWatch } from 'react-hook-form';
import './select-custom-field-key.scss';
import { FieldArrayType, SalsifyFieldArrayName } from '../salsify-property/select-salsify-property';
import classNames from 'classnames';
import { CustomFieldMappings } from '../../multi-select-salsify-properties/multi-select-salsify-properties';
import { alphabetizBfOptions } from 'libs/workflow-manager/ui/src/common/functions/alphabetize';

export interface SelectCustomFieldKeyProps {
  customFieldSources: BFSelectOption[];
  fieldArrayName: SalsifyFieldArrayName;
  fields?: FieldArrayWithId<CustomFieldMappings, 'custom_field_map', 'id'>[];
  index: number;
  brandfolderId?: string;
}

export const SelectCustomFieldKey = ({
  customFieldSources,
  fieldArrayName,
  fields,
  index,
}: SelectCustomFieldKeyProps): ReactElement => {
  const {
    control,
    register,
    formState: { errors },
    setValue,
    watch,
  } = useFormContext();
  const selectedBrandfolder = watch('bf_source_key');
  const mappings = useWatch({
    name: fieldArrayName,
    control,
  }) as unknown as FieldArrayType;
  const watchFieldArray = watch(`${fieldArrayName}.${index}`);
  const controlledFields = fields?.map((field, index) => {
    return {
      ...field,
      ...watchFieldArray[index],
    };
  });
  const selectedCustomFieldKeys = controlledFields
    ?.filter((mapping, i) => mapping.key && i !== index)
    .map((mapping) => mapping.key);
  const fiteredCustomFieldKeySources = customFieldSources?.filter(
    (key) => !selectedCustomFieldKeys?.includes(key.value),
  );
  return (
    <div className="optional-mapping-row-item responsive-row-margin">
      <BFLabel className="form-label" htmlFor="optional-custom-field-key">
        Brandfolder custom field key
      </BFLabel>
      <BFSelect
        className={classNames({
          [`select-custom-field-key-dest-config-${index} select-custom-field-key-dest-config`]:
            fieldArrayName === SalsifyFieldArrayName.DestConfig,
          [`select-custom-field-key-custom-field-map-${index} select-custom-field-key-custom-field-map`]:
            fieldArrayName === SalsifyFieldArrayName.CustomFieldMap,
        })}
        data-testid={classNames({
          [`select-custom-field-key-dest-config-${index}`]:
            fieldArrayName === SalsifyFieldArrayName.DestConfig,
          [`select-custom-field-key-custom-field-map-${index}`]:
            fieldArrayName === SalsifyFieldArrayName.CustomFieldMap,
        })}
        disabled={!selectedBrandfolder}
        id="custom-field-key"
        required={true}
        options={
          (alphabetizBfOptions(
            fieldArrayName === SalsifyFieldArrayName.CustomFieldMap
              ? fiteredCustomFieldKeySources || []
              : customFieldSources,
          ) as BFSelectOption[]) || []
        }
        {...register(`${fieldArrayName}.${index}.key`)}
        onOptionChange={(listOption): void => {
          setValue(`${fieldArrayName}.${index}.key`, listOption?.value || ('' as string), {
            shouldValidate: true,
            shouldDirty: true,
          });
        }}
        value={customFieldSources?.find((cf) => cf.value === mappings[index]?.key)?.value}
        placeholder={'Choose custom field'}
      />
    </div>
  );
};
