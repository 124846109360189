import { createSelector } from 'reselect';
import { WorkflowsPageRootState, WorkflowsPageState } from './reducer';
import { StateSelector } from '@integration-frontends/common/utils/redux';

const SelectWorkflowsPageState: StateSelector<WorkflowsPageState> = (
  state: WorkflowsPageRootState,
) => state.workflowsPageReducer;

export const selectWorkflowsLoading = createSelector(
  SelectWorkflowsPageState,
  (state) => state.workflowsLoading,
);
