import {
  BrandfolderDto,
  CollectionDto,
  IncludedBrandfolderDto,
  OrganizationDto,
  Relationship,
  ResourceDto,
  SectionDto,
} from '@integration-frontends/common/brandfolder-api';
import {
  Brandfolder,
  Collection,
  Organization,
  ResourceBase,
  ResourceType,
  Section,
} from '@integration-frontends/integration/core/model';

export const GET_API_KEY_TOKEN = 'GET_API_KEY';
export type IGetAuthToken = () => Promise<string>;

export function mapBrandfolder(brandfolderDto: BrandfolderDto): Brandfolder {
  if (!brandfolderDto) return null;

  return {
    ...mapResourceFields(brandfolderDto),
    type: ResourceType.BRANDFOLDER,
    assetCount: brandfolderDto.attributes.asset_count,
    organizationId: brandfolderDto.relationships?.[Relationship.ORGANIZATION]?.data.id,
    cardImage: brandfolderDto.attributes.card_image,
    name: brandfolderDto.attributes.name,
    hasAccess: true,
  };
}

export function mapIncludedBrandfolder(
  includedBrandfolderDto: IncludedBrandfolderDto,
  organizationId: string,
): Brandfolder {
  return {
    ...mapResourceFields(includedBrandfolderDto),
    type: ResourceType.BRANDFOLDER,
    id: includedBrandfolderDto.id,
    organizationId,
    name: includedBrandfolderDto.attributes.name,
    hasAccess: false,
  };
}

export function mapCollection(
  collectionDto: CollectionDto,
  brandfolderDto: BrandfolderDto,
): Collection {
  if (!collectionDto || !brandfolderDto) return null;

  return {
    ...mapResourceFields(collectionDto),
    name: `${collectionDto.attributes.name}`,
    type: ResourceType.COLLECTION,
    assetCount: collectionDto.attributes.asset_count,
    brandfolderId: collectionDto.relationships?.[Relationship.BRANDFOLDER]?.data.id,
    hasAccess: true,
    slug: `${brandfolderDto.attributes.slug}/${collectionDto.attributes.slug}`,
  };
}

export function mapOrganization(organizationDto: OrganizationDto): Organization {
  return {
    id: organizationDto.id,
    type: ResourceType.ORGANIZATION,
    name: organizationDto.attributes.name,
    position: organizationDto.attributes.position,
  };
}

export const mapSection = (sectionDto: SectionDto): Section => {
  return {
    id: sectionDto.id,
    type: ResourceType.SECTION,
    name: sectionDto.attributes.name,
    position: sectionDto.attributes.position,
    brandfolderId: sectionDto.relationships?.[Relationship.BRANDFOLDER]?.data.id,
    assetType: sectionDto.attributes.default_asset_type,
  };
};

function mapResourceFields(
  resourceDto: ResourceDto,
): Pick<ResourceBase, 'id' | 'name' | 'slug' | 'createdAt' | 'updatedAt' | 'position'> {
  const { name, slug, created_at, updated_at, position } = resourceDto.attributes;

  return {
    id: resourceDto.id,
    name,
    slug,
    createdAt: created_at && new Date(created_at),
    updatedAt: updated_at && new Date(updated_at),
    position,
  };
}

export interface CustomFieldKeyResponseDatum {
  id: string;
  type: string;
  attributes: {
    allowed_values: string[];
    name: string;
    prioritized?: boolean;
    required?: boolean;
    restricted: boolean;
  };
}

export interface CustomFieldKeyResponseData {
  data: CustomFieldKeyResponseDatum[];
  meta?: {
    current_page: number | null;
    next_page: number | null;
    prev_page: number | null;
    total_pageS: number;
    total_count: number;
  };
}
