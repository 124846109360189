import { withPayloadType } from '@integration-frontends/common/utils/redux';
import { createAction } from '@reduxjs/toolkit';

export const createCredential = createAction('CREATE_CREDENTIAL');

export const enteredCredentialPage = createAction('ENTERED_CREDENTIAL_PAGE');
export const setCredentialIdFromQueryParams = createAction('SET_CREDENTIAL_ID_FROM_QUERY_PARAMS');

export const credentialLoading = createAction('CREDENTIAL_LOADING');
export const credentialSuccess = createAction('CREDENTIAL_SUCCESS');
export const credentialFailure = createAction(
  'CREDENTIAL_FAILURE',
  withPayloadType<{ error: string }>(),
);

// HIGH SPOT

export const highspotSetCredName = createAction(
  'HIGHSPOT_SET_CRED_NAME',
  withPayloadType<{
    credential_name: string;
  }>(),
);
export const highspotSetClientKey = createAction(
  'HIGHSPOT_SET_CLIENT_KEY',
  withPayloadType<{
    client_key: string;
  }>(),
);
export const highspotSetClientSecret = createAction(
  'HIGHSPOT_SET_CLIENT_SECRET',
  withPayloadType<{
    client_secret: string;
  }>(),
);
export const highspotSetCredFormData = createAction(
  'HIGHSPOT_SET_CRED_FORM_DATA',
  withPayloadType<{
    credential_name: string;
    client_key: string;
    client_secret: string;
    api_host: string;
  }>(),
);

// GETTY

export const gettySetCredName = createAction(
  'GETTY_SET_CRED_NAME',
  withPayloadType<{
    credential_name: string;
  }>(),
);
export const gettySetClientKey = createAction(
  'GETTY_SET_CLIENT_KEY',
  withPayloadType<{
    client_key: string;
  }>(),
);
export const gettySetClientSecret = createAction(
  'GETTY_SET_CLIENT_SECRET',
  withPayloadType<{
    client_secret: string;
  }>(),
);
export const gettySetCredFormData = createAction(
  'GETTY_SET_CRED_FORM_DATA',
  withPayloadType<{
    credential_name: string;
    client_key: string;
    client_secret: string;
  }>(),
);

// HUBSPOT

export const hubspotSetCredName = createAction(
  'HUBSPOT_SET_CRED_NAME',
  withPayloadType<{
    credential_name: string;
  }>(),
);
export const hubspotSetAccessToken = createAction(
  'HUBSPOT_SET_ACCESS_TOKEN',
  withPayloadType<{
    private_app_key: string;
  }>(),
);
export const hubspotSetCredFormData = createAction(
  'HUBSPOT_SET_CRED_FORM_DATA',
  withPayloadType<{
    credential_name: string;
    private_app_key: string;
  }>(),
);

//SALSIFY

export const salsifySetCredName = createAction(
  'SALSIFY_SET_CRED_NAME',
  withPayloadType<{
    credential_name: string;
  }>(),
);
export const salsifyApiKey = createAction(
  'SALSIFY_API_KEY',
  withPayloadType<{
    api_key: string;
  }>(),
);
export const salsifySetCredFormData = createAction(
  'SALSIFY_SET_CRED_FORM_DATA',
  withPayloadType<{
    credential_name: string;
    api_key: string;
  }>(),
);

// WRIKE

export const wrikeSetCredFormData = createAction(
  'WRIKE_SET_CRED_FORM_DATA',
  withPayloadType<{
    credential_name: string;
  }>(),
);

// ASANA

export const asanaSetCredFormData = createAction(
  'ASANA_SET_CRED_FORM_DATA',
  withPayloadType<{
    credential_name: string;
  }>(),
);

// SEISMIC

export const seismicSetCredFormData = createAction(
  'SEISMIC_SET_CRED_FORM_DATA',
  withPayloadType<{
    credential_name: string;
    external_user_id: string;
    client_id: string;
    secret_id: string;
    host: string;
  }>(),
);
