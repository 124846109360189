import {
  StandardText,
  StandardLabel,
  StandardTooltip,
  Colors,
  Placements,
  TooltipTypes,
  Widths,
  FontIcon,
  FontIcons,
  FontIconColors,
} from '@brandfolder/react';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { AddAssetLinkToggle } from '../asset-link-toggle/asset-link-toggle';
import { generateWorkfrontForms, generateWorkfrontProjectForms } from '../common/utils';
import { FormFieldsTagger } from '../form-fields-tagger/form-fields-tagger';
import { ProjectFormsTagger } from '../project-forms-tagger/project-forms-tagger';
import { WorkfrontFormsTagger } from '../workfront-forms-tagger/workfront-forms-tagger';
import './workfront-optional-metadata.scss';

export const WorkfrontOptionalMetadata = () => {
  const { watch, setValue } = useFormContext();
  const workfrontDocumentCustomFields = watch('document_custom_fields');
  const workfrontUserCustomFields = watch('user_custom_fields');
  const workfrontProjectCustomFields = watch('project_custom_fields');
  const workfrontPortfolioCustomFields = watch('portfolio_custom_fields');
  const workfrontProgramCustomFields = watch('program_custom_fields');
  const workfrontTags = watch('tags');

  const [formsLoaded, setFormsLoaded] = useState(false);
  const [projectFormsLoaded, setProjectFormsLoaded] = useState(false);
  const [formFieldsLoaded, setFormFieldsLoaded] = useState(false);

  const workfrontForms = generateWorkfrontForms(
    workfrontDocumentCustomFields,
    workfrontUserCustomFields,
    workfrontProjectCustomFields,
  );

  const checkForTaggersLoaded = () => {
    setFormsLoaded(
      typeof workfrontDocumentCustomFields !== 'undefined' &&
        typeof workfrontUserCustomFields !== 'undefined' &&
        typeof workfrontProjectCustomFields !== 'undefined',
    );
    setProjectFormsLoaded(
      typeof workfrontPortfolioCustomFields !== 'undefined' &&
        typeof workfrontProgramCustomFields !== 'undefined',
    );
    setFormFieldsLoaded(typeof workfrontTags !== 'undefined');
  };

  const resetTaggers = () => {
    const workfrontFormPresent =
      workfrontDocumentCustomFields || workfrontUserCustomFields || workfrontProjectCustomFields;

    if (formsLoaded && !workfrontFormPresent) {
      setValue('portfolio_custom_fields', false, { shouldDirty: true });
      setValue('program_custom_fields', false, { shouldDirty: true });
    }
    if (workfrontTags?.length === 0) {
      setValue('tags', null, { shouldDirty: true });
    }
  };

  useEffect(() => {
    checkForTaggersLoaded();
    resetTaggers();
  }, [
    workfrontDocumentCustomFields,
    workfrontUserCustomFields,
    workfrontProjectCustomFields,
    workfrontPortfolioCustomFields,
    workfrontProgramCustomFields,
    workfrontTags,
  ]);

  useEffect(() => {
    if (formsLoaded) {
      generateWorkfrontForms(
        workfrontDocumentCustomFields,
        workfrontUserCustomFields,
        workfrontProjectCustomFields,
      );
    }
    if (projectFormsLoaded) {
      generateWorkfrontProjectForms(workfrontPortfolioCustomFields, workfrontProgramCustomFields);
    }
  }, [formsLoaded, projectFormsLoaded]);

  return (
    <section className="workfront-optional-metadata-container">
      <div className="workfront-optional-metadata-content">
        <StandardText className="optional-metadata-label">OPTIONAL METADATA</StandardText>
        <label className="workfront-forms-label-container">
          <StandardLabel htmlFor={'workfront-forms'}>Workfront forms</StandardLabel>
          <StandardTooltip
            color={Colors.Primary}
            defaultOpen={false}
            id="workfront-form-fields-tooltip"
            placement={Placements.TopCenter}
            tooltip="You can enter the Workfront form fields you want to sync as asset tags in Brandfolder. If a tag doesn’t exist, we’ll create it when the customer syncs the document."
            triggerOffset={8}
            type={TooltipTypes.Description}
            width={Widths.Medium}
          >
            <FontIcon icon={FontIcons.ToolTip} color={FontIconColors.Primary} />
          </StandardTooltip>
        </label>
        {formsLoaded && <WorkfrontFormsTagger />}
        {workfrontForms?.length > 0 && projectFormsLoaded && <ProjectFormsTagger />}
        {formFieldsLoaded && <FormFieldsTagger />}
        <AddAssetLinkToggle />
      </div>
    </section>
  );
};
