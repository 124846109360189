import {
  FontColors,
  FontIcon,
  FontIcons,
  FontSizes,
  StandardPagination,
  StandardText,
} from '@brandfolder/react';
import {
  ContainerMetadata,
  ContainerNode,
  ContainerSelectorType,
} from '@integration-frontends/workflow-manager/core/model';
import React, { useEffect, useRef, useState } from 'react';
import './container-selector.scss';

export interface ContainerSelectorComponentProps {
  selectorType: ContainerSelectorType;
  displayedName: string;
  children: ContainerNode[];
  defaultContainerName?: string;
  closeSelector?: any;
  containerNamePropertyName: string;
  disableGoToParentContainer?: boolean;
  loadingChildren?: boolean;
  disabled?: boolean;
  testId?: string;
  displayPagination?: boolean;
  metadata?: ContainerMetadata;
  pageChange?: (page: number) => void;
  containerSelected: (container: ContainerNode) => void;
  goToContainer: (container: ContainerNode) => void;
  goToParentContainer: () => void;
  onContainerOpened?: () => void;
}

export function ContainerSelectorComponent({
  selectorType,
  displayedName,
  children,
  defaultContainerName,
  containerSelected,
  goToContainer,
  goToParentContainer,
  onContainerOpened,
  closeSelector,
  containerNamePropertyName,
  disableGoToParentContainer,
  loadingChildren,
  disabled,
  testId,
  displayPagination,
  metadata,
  pageChange,
}: ContainerSelectorComponentProps) {
  const selectorRef = useRef(null);
  const [containerSelectionOpen, setContainerSelectionOpen] = useState<boolean>(false);

  const handleContainerClicked = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    if (onContainerOpened && !containerSelectionOpen) {
      onContainerOpened();
    }
    setContainerSelectionOpen(!containerSelectionOpen);
  };

  const handleContainerSelected = (
    container: ContainerNode,
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    e.stopPropagation();
    setContainerSelectionOpen(false);
    containerSelected(container);
  };

  const handleGoToParentContainer = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    goToParentContainer();
  };

  const handleGoToContainer = (
    container: ContainerNode,
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.stopPropagation();
    goToContainer(container);
  };

  const handlePageChange = (page: number) => {
    pageChange(page);
  };

  const hideGoToContainerButton = (container: ContainerNode) => {
    return selectorType === ContainerSelectorType.Synchronous && !container?.children;
  };

  useEffect(() => {
    const checkForOutsideClick = (e) => {
      if (selectorRef.current && !selectorRef?.current?.contains(e.target)) {
        setContainerSelectionOpen(false);
      }
    };

    if (containerSelectionOpen) {
      document.addEventListener('mousedown', checkForOutsideClick);
      return () => document.removeEventListener('mousedown', checkForOutsideClick);
    }
  }, [selectorRef, containerSelectionOpen]);

  useEffect(() => {
    if (closeSelector) {
      setContainerSelectionOpen(false);
    }
  }, [closeSelector]);

  return (
    <div
      className={`container-selector-container ${
        disabled && 'container-selector-container-disabled'
      }`}
      ref={selectorRef}
      onClick={(e) => handleContainerClicked(e)}
      data-testid={testId || 'container-selector-container'}
    >
      <StandardText
        className={`container-folder-selected-text ${
          !displayedName && 'container-folder-placeholder-text'
        }`}
        data-testid="container-selector-selected-option-name"
      >
        {displayedName || defaultContainerName}
      </StandardText>
      <FontIcon
        icon={containerSelectionOpen ? FontIcons.CaretUp : FontIcons.CaretDown}
        iconSize={10}
        className="ml-sm"
      />
      {containerSelectionOpen && !disabled && (
        <section className="container-selector-popup">
          <div className="container-selector-popup-content">
            <div className="container-selector-popup-header">
              <button
                className={`container-selector-popup-nav-button ${
                  (disableGoToParentContainer || loadingChildren) &&
                  'pointer-events-none opacity-50'
                }`}
                onClick={(e) => handleGoToParentContainer(e)}
                disabled={loadingChildren || disableGoToParentContainer}
              >
                <FontIcon icon={FontIcons.CaretLeft} iconSize={10} />
              </button>
              {loadingChildren && selectorType === ContainerSelectorType.Asynchronous && (
                <StandardText
                  size={FontSizes.XSmall}
                  color={FontColors.Primary}
                  className="container-selector-popup-header-text"
                >
                  Loading folders…
                </StandardText>
              )}
              {!loadingChildren &&
                selectorType === ContainerSelectorType.Asynchronous &&
                children?.length === 0 && (
                  <StandardText
                    size={FontSizes.XSmall}
                    color={FontColors.Default}
                    className="container-selector-popup-header-text"
                  >
                    No child containers
                  </StandardText>
                )}
            </div>
            {!loadingChildren &&
              children?.map((container: ContainerNode) => {
                return (
                  <div
                    className="container-selector-popup-option"
                    data-testid="container-selector-option"
                    onClick={(e) => handleContainerSelected(container, e)}
                    key={`sync-to-brandfolder-${container?.id}`}
                  >
                    <StandardText>{container?.[containerNamePropertyName]}</StandardText>
                    {!hideGoToContainerButton(container) && (
                      <button
                        className="container-selector-popup-option-button"
                        onClick={(e) => handleGoToContainer(container, e)}
                      >
                        <FontIcon icon={FontIcons.CaretRight} iconSize={10} />
                      </button>
                    )}
                  </div>
                );
              })}
            {displayPagination && (
              <div
                className="container-selector-popup-pagination"
                onClick={(e) => e.stopPropagation()}
              >
                <StandardPagination
                  labels={{
                    nextLabel: 'Next',
                    previousLabel: 'Previous',
                  }}
                  onPageChange={(page: number) => handlePageChange(page)}
                  total={metadata?.total_pages}
                />
              </div>
            )}
          </div>
        </section>
      )}
    </div>
  );
}
