import React from 'react';
import { FontWeights, StandardText } from '@brandfolder/react';
import './workflows-no-results.scss';

export const WorkflowsNoResults = () => {
  return (
    <div className="workflows-no-results-container" data-testid="workflows-no-results-message">
      <StandardText weight={FontWeights.Bold} className="workflows-no-results-header">
        No results found
      </StandardText>
      <StandardText>We couldn’t find a matching workflow.</StandardText>
    </div>
  );
};
