import React from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'redux-first-history';
import {
  ButtonLooks,
  FontIcons,
  FontWeights,
  StandardButton,
  StandardText,
} from '@brandfolder/react';
import {
  setWorkflowMode,
  workflowModes,
} from '@integration-frontends/workflow-manager/core/application';
import './workflows-empty-state.scss';

export const WorkflowsEmptyState = () => {
  const dispatch = useDispatch();

  return (
    <div className="workflows-empty-state-container" data-testid="workflows-empty-state-message">
      <StandardText weight={FontWeights.Bold} className="workflows-empty-state-header">
        No workflows yet
      </StandardText>
      <StandardText>Add workflows for your client's integrations.</StandardText>
      <StandardButton
        startIcon={FontIcons.Plus}
        startIconProps={{ iconSize: 12 }}
        onClick={() => {
          dispatch(setWorkflowMode({ mode: workflowModes.create }));
          dispatch(push('create-workflow/app-info'));
        }}
        className="mt-xl"
        look={ButtonLooks.Tertiary}
      >
        Add workflow
      </StandardButton>
    </div>
  );
};
