import { FontIcon, FontIcons } from '@brandfolder/react';
import { BFSelectOption } from '@integration-frontends/common/ui';
import { selectSalsifyCustomFieldMappings } from '@integration-frontends/workflow-manager/core/application';
import React, { ReactElement, useEffect } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { AddMapping } from '../dest-config/add-mapping/add-mapping';
import { SelectCustomFieldKey } from '../dest-config/custom-field-key/select-custom-field-key';
import { DeleteMapping } from '../dest-config/delete-mapping/delete-mapping';
import {
  SalsifyFieldArrayName,
  SelectSalsifyProperty,
} from '../dest-config/salsify-property/select-salsify-property';
import './multi-select-salsify-properties.scss';

export interface MultiSelectSalsifyPropertiesProps {
  customFieldSources: BFSelectOption[];
  propsSources: BFSelectOption[];
  loadingProperties?: boolean;
}

export type CustomFieldMappings = {
  custom_field_map: { salsifyProp: string; key: string }[];
};

export const MultiSelectSalisfyProperties = ({
  customFieldSources,
  propsSources, 
  loadingProperties,
}: MultiSelectSalsifyPropertiesProps): ReactElement => {
  const { control } = useFormContext<CustomFieldMappings>();
  const {
    fields,
    append: addMapping,
    remove: deleteMapping,
  } = useFieldArray({ control, name: 'custom_field_map' });
  const selectedCustomFieldMap = useSelector(selectSalsifyCustomFieldMappings);

  useEffect(() => {
    if (!selectedCustomFieldMap?.length && !fields?.length) {
      addMapping({ salsifyProp: null, key: null });
    }
  }, []);

  return (
    <div className="salsify-to-bf-container">
      <div className="salsify-to-bf-col">
        {fields.map((field, index) => {
          return (
            <div className="custom-field-map-row custom-field-map-row-margin">
              <SelectSalsifyProperty
                propsSources={propsSources}
                fieldArrayName={SalsifyFieldArrayName.CustomFieldMap}
                fields={fields}
                index={index}
                loadingProperties={loadingProperties}
              />
              <FontIcon icon={FontIcons.ArrowRight} className="sals-bff-arrow-right" />
              <SelectCustomFieldKey
                customFieldSources={customFieldSources}
                fieldArrayName={SalsifyFieldArrayName.CustomFieldMap}
                fields={fields}
                index={index}
              />
              <DeleteMapping deleteMapping={deleteMapping} index={index} />
            </div>
          );
        })}
      </div>
      <AddMapping addMapping={addMapping} fieldArrayName={SalsifyFieldArrayName.CustomFieldMap} />
    </div>
  );
};
