import { put, takeEvery } from 'typed-redux-saga';
import { createNotification, NotificationType } from '@integration-frontends/common/notifications';
import {
  callWithTokenRefresh,
  clientEntityActions,
  createClientEmailFormatError,
} from '@integration-frontends/workflow-manager/core/application';
import { CLIENT_REPO_TOKEN, IClientRepo } from '@integration-frontends/workflow-manager/core/model';
import { DI_CONTAINER } from '@integration-frontends/core';
import {
  createClient,
  createClientInitialize,
  createClientSuccess,
  createClientFailure,
  selectClient,
} from '@integration-frontends/workflow-manager/core/application';

function* handler(action: ReturnType<typeof createClient>) {
  yield put(createClientInitialize());
  const { bfApiKey, clientName } = action.payload;
  const clientRepo: IClientRepo = DI_CONTAINER.get(CLIENT_REPO_TOKEN);
  const clientInfo = { bf_api_key: bfApiKey, client_name: clientName };
  const res = yield callWithTokenRefresh(clientRepo.createClient, clientInfo);
  if (res.client) {
    yield put(clientEntityActions.clientReceived(res.client));
    yield put(createClientSuccess());
    yield put(selectClient(res.client.id));
    yield put(
      createNotification({
        message: 'Client updated',
        location: 'topLevel',
        type: NotificationType.Success,
      }),
    );
  } else {
    yield put(createClientFailure());

    if (res.errors[0].detail.includes("Bad email format")) {
      yield put(createClientEmailFormatError({emailFormatError: true}));
    }

    yield put(
      createNotification({
        message: 'Unable to save changes',
        location: 'topLevel',
        type: NotificationType.Error,
      }),
    );
  }
}

export function* createClientEffects() {
  yield takeEvery(createClient, handler);
}
