import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  BFSelect,
  BFSelectOption,
} from '@integration-frontends/common/ui/forms/form-controls/select';
import { BFErrorLabel } from '@integration-frontends/common/ui/forms/form-controls/form-controls';
import { BFMultiSelect, BFMultiSelectOption } from '@integration-frontends/common/ui';
import {
  StandardText,
  FontSizes,
  FontWeights,
  StandardLabel,
  StandardTextfield,
} from '@brandfolder/react';
import { useFormContext } from 'react-hook-form';

import {
  selectHighspotState,
  selectBrandfolderFormInfo,
  selectHighspotSpots,
  highspotFetchSpots,
  selectSelectedCredentialId,
  highspotSetCredentialId,
  selectHighspotSelectedSections,
  selectHighspotSelectedCollections,
  highspotSetSectionsToDelete,
  highspotSetCollectionsToDelete,
  selectHighspotInitialSectionSelection,
  selectHighspotInitialCollectionSelection,
  highspotSetInitialSectionSelection,
  highspotSetInitialCollectionSelection,
  credentialEntitySelectors,
} from '@integration-frontends/workflow-manager/core/application';

import './workflow-form-highspot.scss';
import '../../create-workflow-form.scss';
import { WorkflowName } from '../../common/components/workflow-name.tsx/workflow-name';
import { WorkflowInfo } from '../../common/components/workflow-info/workflow-info';
import { alphabetizBfOptions } from '../../../common/functions/alphabetize';
import { SelectBrandfolder } from '../../common/components/select-brandfolder/select-brandfolder';

export interface CreateHighspotWorkflowContainerProps {
  formComplete: (c: boolean) => void;
}

export const CreateHighspotWorkflowFormContainer = ({
  formComplete,
}: CreateHighspotWorkflowContainerProps) => {
  const {
    register,
    formState: { errors },
    setValue,
    watch,
    reset,
  } = useFormContext();
  const dispatch = useDispatch();
  const defaultValues = useSelector(selectHighspotState);
  useEffect(() => {
    dispatch(highspotSetCredentialId({ credentialId }));
    reset(defaultValues);
  }, []);

  const credentialId = useSelector(selectSelectedCredentialId);
  const selectedCredential = useSelector(credentialEntitySelectors.credentialById(credentialId));
  const collectionKeys = useSelector(selectHighspotSelectedCollections);
  const selectionKeys = useSelector(selectHighspotSelectedSections);
  const selectInitialSectionSelection = useSelector(selectHighspotInitialSectionSelection);
  const selectInitialCollectionSelection = useSelector(selectHighspotInitialCollectionSelection);

  const initialCollectionSelection = collectionKeys;
  const initialSectionSelection = selectionKeys;

  const workflowName = watch('workflow_name');
  const bfSource = watch('bf_source_key');
  const spotId = watch('spot_id');

  useEffect(() => {
    if (workflowName && bfSource && spotId) {
      formComplete(true);
    } else {
      formComplete(false);
    }
  }, [workflowName, bfSource, spotId]);

  useEffect(() => {
    if (initialSectionSelection && !selectInitialSectionSelection) {
      dispatch(
        highspotSetInitialSectionSelection({ initial_section_selection: initialSectionSelection }),
      );
    }
  }, [initialSectionSelection]);

  useEffect(() => {
    if (initialCollectionSelection && !selectInitialCollectionSelection) {
      dispatch(
        highspotSetInitialCollectionSelection({
          initial_collection_selection: initialCollectionSelection,
        }),
      );
    }
  }, [initialCollectionSelection]);

  useEffect(() => {
    dispatch(highspotFetchSpots({ credential: selectedCredential }));
  }, []);

  useEffect(() => {
    const sectionsToDelete = selectInitialSectionSelection?.filter(
      (section_key) => !watch('selectedSections')?.includes(section_key),
    );
    dispatch(highspotSetSectionsToDelete({ section_keys: sectionsToDelete }));
  }, [watch('selectedSections')]);

  useEffect(() => {
    const collectionsToDelete = selectInitialCollectionSelection?.filter(
      (collection_key) => !watch('selectedCollections')?.includes(collection_key),
    );
    dispatch(highspotSetCollectionsToDelete({ collection_keys: collectionsToDelete }));
  }, [watch('selectedCollections')]);

  const brandfolderFormInfo = useSelector(selectBrandfolderFormInfo);

  const bfSources = brandfolderFormInfo?.map((bf) => {
    return { label: bf.name, value: bf.id, sections: bf.sections, collections: bf.collections };
  });

  const bfSections = bfSources
    ?.find((bf) => bf.value === bfSource)
    ?.sections?.data.map((section) => {
      return { label: section.name, value: section.id };
    });

  const bfCollections = bfSources
    ?.find((bf) => bf.value === bfSource)
    ?.collections?.data.map((collection) => {
      return { label: collection.name, value: collection.id };
    });

  const spots = useSelector(selectHighspotSpots);

  const handleMultiChangeSections = (values) => {
    setValue('selectedSections', values || [], { shouldDirty: true });
  };

  const handleMultiChangeCollections = (values) => {
    setValue('selectedCollections', values || [], { shouldDirty: true });
  };

  return (
    <section className="forms-content-container" data-testid="highspot-workflow-form-container">
      <WorkflowInfo linkToDocumentation="https://smar-wiki.atlassian.net/wiki/spaces/PROD/pages/70971621435/Guide+IWM+-+Highspot" />
      <WorkflowName />
      <section className="source-section workflow-info-section">
        <header className="workflow-info-section-header">
          <StandardText size={FontSizes.Large} weight={FontWeights.Bold} className="pr-sm">
            Source
          </StandardText>
          <StandardText size={FontSizes.Large}>Brandfolder</StandardText>
        </header>
        <section className="workflow-info-section-body">
          <div className="workflow-info-section-content">
            <div className="workflow-field workflow-field-margin">
              <SelectBrandfolder
                bfSource={bfSource}
                bfSources={bfSources}
                onChange={() => {
                  handleMultiChangeSections(null);
                  handleMultiChangeCollections(null);
                }}
              />
            </div>
            <div className="highspot-source-selection-container workflow-field workflow-field-margin">
              <StandardText weight={FontWeights.Medium}>
                Brandfolder sections (optional)
              </StandardText>
              <BFMultiSelect
                id="select-sections"
                placeholder={'Select Sections'}
                options={(alphabetizBfOptions(bfSections) as BFMultiSelectOption[]) || []}
                disabled={!bfSource}
                selected={bfSections?.filter((section) =>
                  watch('selectedSections').includes(section.value),
                )}
                selectedValues={watch('selectedSections')}
                onChange={handleMultiChangeSections}
                data-testid={`workflow-form-select-sections`}
                panelStyleOptions={{ width: '100%' }}
                buttonStyleOptions={{ width: '100%' }}
                getOptionKey={(o) => o.value}
                zIndex={2}
              />
            </div>
            <div className="highspot-source-selection-container workflow-field">
              <StandardText weight={FontWeights.Medium}>
                Brandfolder collections (optional)
              </StandardText>
              <BFMultiSelect
                id="select-collections"
                placeholder={'Select Collections'}
                options={(alphabetizBfOptions(bfCollections) as BFMultiSelectOption[]) || []}
                disabled={!bfSource}
                selected={bfCollections?.filter((collection) =>
                  watch('selectedCollections').includes(collection.value),
                )}
                selectedValues={watch('selectedCollections')}
                onChange={handleMultiChangeCollections}
                data-testid={`workflow-form-select-collections`}
                getOptionKey={(o) => o.value}
                zIndex={1}
                panelStyleOptions={{ width: '100%' }}
                buttonStyleOptions={{ width: '100%' }}
              />
            </div>
          </div>
        </section>
      </section>

      <section className="destination-section workflow-info-section">
        <header className="workflow-info-section-header">
          <StandardText size={FontSizes.Large} weight={FontWeights.Bold} className="pr-sm">
            Destination
          </StandardText>
          <StandardText size={FontSizes.Large}>Highspot</StandardText>
        </header>
        <section className="workflow-info-section-body">
          <div className="workflow-info-section-content">
            <div className="workflow-field workflow-field-margin">
              <StandardLabel className="mb-xxs" htmlFor="select-spot-id">
                The Spot
              </StandardLabel>
              <BFSelect
                data-testid="select-spot-id"
                id="select-spot-id"
                options={
                  alphabetizBfOptions(
                    spots?.map((spot) => ({ value: spot.id, label: spot.title })),
                  ) as BFSelectOption[]
                }
                {...register('spot_id', { required: true })}
                disabled={!spots?.length}
                placeholder={'Choose Spot'}
                value={watch('spot_id')}
                onOptionChange={(listOption): void => {
                  setValue('spot_id', listOption?.value || ('' as string), {
                    shouldValidate: true,
                    shouldDirty: true,
                  });
                }}
              />
              {errors.spot_id?.type === 'required' && (
                <BFErrorLabel role="alert">Spot is required</BFErrorLabel>
              )}
            </div>
            <div className="workflow-field">
              <StandardTextfield
                label="Integration ID (optional)"
                data-testid="select-api-host"
                id="select-api-host"
                {...register('integration_id')}
                placeholder="Enter Integration ID"
              />
            </div>
          </div>
        </section>
      </section>
    </section>
  );
};
