import { useSelector, useDispatch } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { Loader } from '@integration-frontends/common/ui';
import { CreateWorkflowNav } from '../common/components/workflow-nav/create-workflow-nav';
import { SectionTracker } from '../common/components/section-tracker/section-tracker';

import {
  selectSelectedIntegrationType,
  selectActiveSection,
  selectCreateCredentialLoading,
  credentialEntitySelectors,
  selectSelectedClientId,
  clientEntitySelectors,
  setSelectedCredentialId,
  createCredential,
  highspotSetCredFormData,
  gettySetCredFormData,
  hubspotSetCredFormData,
  salsifySetCredFormData,
  seismicSetCredFormData,
  selectCreateCredentialError,
  wrikeOauthSignIn,
  wrikeSetCredFormData,
  selectSelectedCredentialId,
  selectWorkflowMode,
  workflowModes,
  workfrontSetFormData,
  selectWorkfrontState,
  asanaSetCredFormData,
  asanaOauthSignIn,
} from '@integration-frontends/workflow-manager/core/application';

import {
  workflowServiceType,
  docsLink,
  nonCredentialIntegrations,
} from '@integration-frontends/workflow-manager/core/model';
import { CredentialSelect } from '../common/components/credential-select/credential-select';
import {
  FontColors,
  FontSizes,
  FontWeights,
  ListboxOption,
  StandardText,
} from '@brandfolder/react';
import { CredentialModal } from './credential-modal/credential-modal';
import { useForm, FormProvider } from 'react-hook-form';
import './credential-form.scss';
import { WorkflowInfo } from '../common/components/workflow-info/workflow-info';
import { ErrorBanner } from 'libs/workflow-manager/ui/src/common/error-banner/error-banner';
import { IntegrationCredentialForm } from './integration-credential-form';
import { InfoBanner } from 'libs/workflow-manager/ui/src/common/info-banner/info-banner';

interface CredentialFormProps {
  onBack: () => void;
  onNext: () => void;
}

export const CredentialForm = ({ onBack, onNext }: CredentialFormProps) => {
  const methods = useForm();

  const dispatch = useDispatch();

  const [credsComplete, setCredsComplete] = useState(false);
  const [credsData, setCredsData] = useState(null);

  const integrationType = useSelector(selectSelectedIntegrationType);
  const activeSection = useSelector(selectActiveSection);
  const loading = useSelector(selectCreateCredentialLoading);
  const [createCredOpen, setCreateCredOpen] = useState(false);

  const selectedCredentialId = useSelector(selectSelectedCredentialId);
  const selectedClientId = useSelector(selectSelectedClientId);
  const selectedClient = useSelector(clientEntitySelectors.clientById(selectedClientId));
  const existingCredentials = useSelector(
    credentialEntitySelectors.credentialsByTypeAndBfAccountId(
      selectedClient?.brandfolder_account_id.toString(),
      integrationType,
    ),
  );
  const workflowMode = useSelector(selectWorkflowMode);
  const editing = workflowMode === workflowModes.edit;

  const credentialError = useSelector(selectCreateCredentialError);
  const workfrontState = useSelector(selectWorkfrontState);

  const workflowFormState = (integration: workflowServiceType) => {
    switch (integration) {
      case workflowServiceType.workfront:
        return workfrontState;
    }
  };

  const setFormDataAndCreateCredential = (formData) => {
    const setCredFormDataActions = {
      [workflowServiceType.highspot]: highspotSetCredFormData,
      [workflowServiceType.getty]: gettySetCredFormData,
      [workflowServiceType.hubspot]: hubspotSetCredFormData,
      [workflowServiceType.salsify]: salsifySetCredFormData,
      [workflowServiceType.wrike]: [wrikeSetCredFormData, wrikeOauthSignIn],
      [workflowServiceType.seismic]: seismicSetCredFormData,
      [workflowServiceType.workfront]: workfrontSetFormData,
      [workflowServiceType.asana]: [asanaSetCredFormData, asanaOauthSignIn],
    };

    const actions = setCredFormDataActions[integrationType];
    if (actions) {
      if (Array.isArray(actions)) {
        actions.forEach((action) => dispatch(action(formData)));
      } else {
        if (nonCredentialIntegrations.includes(integrationType as workflowServiceType)) {
          dispatch(
            actions({ ...workflowFormState(integrationType as workflowServiceType), ...formData }),
          );
        } else {
          dispatch(actions(formData));
        }
      }
    }

    dispatch(createCredential());
  };

  const onSubmit = methods.handleSubmit(setFormDataAndCreateCredential);

  const onOptionChange = (e: ListboxOption) => {
    dispatch(setSelectedCredentialId({ credentialId: e?.value ? `${e.value}` : null }));
  };

  const toggleModal = () => {
    setCredsComplete(false);
    setCreateCredOpen(!createCredOpen);
  };

  const clearQueryParams = () => {
    window.history.replaceState(
      {},
      'IMW Credential Page',
      document.location.href.replace(/\?.*$/, ''),
    );
  };

  useEffect(() => {
    // if this page is entered through pressing the prev button, the credential_id needs to be removed from the url
    clearQueryParams();
  }, []);

  const handleOnNext = () => {
    // If creating credential
    if (credsComplete) {
      onSubmit(credsData);
    } else {
      // If using existing credential
      if (!selectedCredentialId) {
        dispatch(
          setSelectedCredentialId({
            credentialId: existingCredentials[0]?.id ? `${existingCredentials[0].id}` : null,
          }),
        );
      }
      onNext();
    }
  };

  const getNextText = () => {
    switch (integrationType) {
      case workflowServiceType.wrike:
        return 'Sign in to Wrike';
      case workflowServiceType.asana:
        return 'Sign in to Asana';
      default:
        return null;
    }
  };

  const handleNextEnabled = () => {
    if (
      nonCredentialIntegrations.includes(integrationType as workflowServiceType) &&
      credsComplete
    ) {
      return handleOnNext;
    } else if (
      !nonCredentialIntegrations.includes(integrationType as workflowServiceType) &&
      (credsComplete || existingCredentials.length > 0)
    ) {
      return handleOnNext;
    } else {
      return null;
    }
  };

  const getOnNextActionName = () => {
    switch (integrationType) {
      case workflowServiceType.wrike: {
        const wrikeCreds = existingCredentials.filter(
          (cred) => cred.credential_type === workflowServiceType.wrike,
        );
        return wrikeCreds.length === 0 ? 'Sign in to Wrike' : null;
      }
      case workflowServiceType.asana: {
        const asanaCreds = existingCredentials.filter(
          (cred) => cred.credential_type === workflowServiceType.asana,
        );
        return asanaCreds.length === 0 ? 'Sign in to Asana' : null;
      }
      default:
        return null;
    }
  };

  return (
    <FormProvider {...methods}>
      <CreateWorkflowNav
        onBack={onBack}
        onNext={handleNextEnabled()}
        title="App info"
        editing={editing}
        onNextActionName={getOnNextActionName()}
      />
      <section className="workflow-wizard-section">
        <SectionTracker activeSection={activeSection} />
        <section className="forms-container">
          <div className="forms-content-container">
            <InfoBanner
              headerText="Add developer username to Brandfolder"
              bodyText="Before continuing, make sure there’s a dedicated admin user with the format bf-integrations-dev+<org-slug>@smartsheet.com in the client’s Brandfolder account."
              linkName={`View documentation`}
              link={docsLink}
            />
            <WorkflowInfo
              className="credential-forms-header"
              linkToDocumentation={docsLink}
              textTestId="credential-docs-link"
              showIntegrationName={true}
            />

            {existingCredentials.length > 0 &&
            !nonCredentialIntegrations.includes(integrationType as workflowServiceType) ? (
              <div className="cred-forms-details">
                <CredentialSelect
                  existingCredentials={existingCredentials}
                  onOptionChange={onOptionChange}
                />
                <div className="divider-container">
                  <div className="divider"></div>
                  <span>Or</span>
                  <div className="divider"></div>
                </div>
                <div
                  data-testid="credential-create-new"
                  className="cursor-pointer"
                  onClick={toggleModal}
                >
                  <StandardText
                    size={FontSizes.Small}
                    color={FontColors.Primary}
                    weight={FontWeights.Medium}
                  >
                    Create a new credential
                  </StandardText>
                </div>
                {createCredOpen && (
                  <CredentialModal
                    onClose={toggleModal}
                    onSubmit={onSubmit}
                    credentialError={credentialError}
                    disableSubmit={!credsComplete}
                    nextText={getNextText()}
                  >
                    {loading ? (
                      <Loader />
                    ) : (
                      <IntegrationCredentialForm
                        integrationType={integrationType}
                        setCredsData={(data) => setCredsData(data)}
                        setCredsComplete={(c: boolean) => setCredsComplete(c)}
                      />
                    )}
                  </CredentialModal>
                )}
              </div>
            ) : (
              <section
                data-testid="no-exisiting-credentials-container"
                className="no-exisiting-credentials-container"
              >
                {credentialError && (
                  <div className="form-error-container">
                    <ErrorBanner credentialError={credentialError} header="Credential errors" />
                  </div>
                )}
                {loading ? (
                  <Loader />
                ) : (
                  <div className="no-exisiting-credentials-form">
                    <IntegrationCredentialForm
                      integrationType={integrationType}
                      setCredsData={(data) => setCredsData(data)}
                      setCredsComplete={(c: boolean) => setCredsComplete(c)}
                    />
                  </div>
                )}
              </section>
            )}
          </div>
        </section>
      </section>
    </FormProvider>
  );
};
