import { FontIcons, StandardCombobox, StandardTextfield } from '@brandfolder/react';
import { ClientSortOption } from '@integration-frontends/workflow-manager/core/model';
import React from 'react';
import { PageHeader } from '../../common';
import { SortOption } from '../clients-page';
import './clients-header.scss';

interface ClientsHeaderProps {
  handleSearchChange: (e) => void;
  handleCreateClientOpen: () => void;
  handleSetSort: (selectedOption: ClientSortOption) => void;
  sortOptions: SortOption[];
}

export const ClientsHeader = ({
  handleSearchChange,
  handleCreateClientOpen,
  handleSetSort,
  sortOptions,
}: ClientsHeaderProps) => {
  return (
    <PageHeader
      onClick={() => handleCreateClientOpen()}
      testid="clients-page-header"
      buttonTestid="add-client-button"
      title="All clients"
      actionTitle="Add client"
      filtersComponent={
        <>
          <StandardTextfield
            label={'Search for a client'}
            id={'client-page-search'}
            placeholder="Search for a client"
            leftIcon={FontIcons.Search}
            showLabel={false}
            className="clients-page-header-search"
            onChange={(e) => handleSearchChange(e)}
            data-testid="page-header-search"
          />
          <StandardCombobox
            id={'page-header-sort-toggle'}
            data-testid="page-header-sort-toggle"
            labels={{
              label: 'Sort list of clients',
              iconButtonLabel: 'Sort client icon',
              listboxLabel: 'Sort client option',
            }}
            onSelection={(option) => handleSetSort(option.value as ClientSortOption)}
            options={sortOptions.map((option) => ({
              children: <>{option.label}</>,
              key: option.value,
              value: option.value,
            }))}
            showLabel={false}
            initialSelectedIndex={0}
            className="client-header-sort-dropdown"
          />
        </>
      }
    />
  );
};
