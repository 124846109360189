import { put, select, takeEvery } from 'typed-redux-saga';
import { createNotification, NotificationType } from '@integration-frontends/common/notifications';
import {
  callWithTokenRefresh,
  seismicFetchFolders,
  seismicFetchInit,
  seismicFetchLoaded,
  seismicReceiveFolders,
  selectSelectedClientId,
} from '@integration-frontends/workflow-manager/core/application';
import {
  FORM_INFO_REPO_TOKEN,
  IFormInfoRepo,
} from '@integration-frontends/workflow-manager/core/model';
import { DI_CONTAINER } from '@integration-frontends/core';
import { pageLoadError } from '@integration-frontends/common/app';

function* handler(action: ReturnType<typeof seismicFetchFolders>) {
  yield put(seismicFetchInit());
  try {
    const { credential_id, folder_id, teamsite_id, page } = action.payload;
    const formInfoRepo: IFormInfoRepo = DI_CONTAINER.get(FORM_INFO_REPO_TOKEN);
    const clientId = yield select(selectSelectedClientId);
    const pageParam = page ? page : 1;
    const FOLDERS_PER_PAGE = 20;

    const seismicFormInfo = yield callWithTokenRefresh(formInfoRepo.getSeismicFormInfo, clientId, {
      credential_id,
      folder_id,
      teamsite_id,
      per: FOLDERS_PER_PAGE,
      page: pageParam,
    });

    if (seismicFormInfo) {
      yield put(
        seismicReceiveFolders({
          seismicFormInfo: {
            children: seismicFormInfo?.children,
            current_folder: seismicFormInfo?.current_folder,
            siblings: seismicFormInfo?.siblings,
            metadata: seismicFormInfo?.metadata,
          },
        }),
      );
      yield put(seismicFetchLoaded());
    } else {
      yield put(
        createNotification({
          message: 'Failed to fetch Seismic Folders',
          location: 'topLevel',
          type: NotificationType.Error,
        }),
      );
    }
  } catch (e) {
    yield put(pageLoadError({ error: e }));
    yield put(seismicFetchLoaded());
  }
}

export function* seismicFetchFoldersEffects() {
  yield takeEvery(seismicFetchFolders, handler);
}
