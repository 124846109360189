import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { oauthPageEntered } from '@integration-frontends/workflow-manager/core/application';
import { PrimaryLogo } from '@integration-frontends/common/ui';
import { Loader } from '@integration-frontends/common/ui';
import { useAuthState, resetAuthState } from '../lib';

import './oauth-page.scss';
import {
  ButtonLooks,
  FontSizes,
  FontWeights,
  StandardButton,
  StandardText,
} from '@brandfolder/react';
import { loginThunk } from '@integration-frontends/common/auth/core/application';
import { setRefreshToken } from 'libs/common/auth/core/application/src/lib/actions';

export const OauthPage = () => {
  const authState = useAuthState();

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(oauthPageEntered());
  }, []);

  useEffect(() => {
    if (authState.isAuthenticated) {
      // clear the authstate user so that only the user in the identity store is after initial sign in
      const accessToken = authState.user.access_token;
      const refreshToken = authState.user.refresh_token;
      authState.removeUser();

      dispatch(setRefreshToken({ refreshToken }) as any);
      dispatch(loginThunk(accessToken) as any);
    }
  }, [authState.isAuthenticated]);

  const handleSignin = () => {
    void authState.signinRedirect();
  };

  return (
    <section className="oauth-page" data-testid="oauth-page-container">
      <PrimaryLogo className="logo" data-testid="oauth-page-logo" />
      <div className="oauth-content">
        <header className="page-header flex flex-row" data-testid="oauth-page-header">
          <StandardText
            weight={FontWeights.Bold}
            size={FontSizes.XxLarge}
            className="oauth-page-header-text"
          >
            Integrations Workflow Manager
          </StandardText>
        </header>
        {authState.isLoading ? (
          <Loader />
        ) : (
          <div className="oauth-card">
            <header className="flex flex-row" data-testid="oauth-card-header">
              <StandardText weight={FontWeights.Bold} className="oauth-card-header-text">
                Sign in to your account
              </StandardText>
            </header>
            <div className="content-container" data-testid="oauth-card-content">
              {authState.error ? (
                <>
                  <div className="error-msg" data-testid="oauth-error-message">
                    Oops... {authState.error.message}
                  </div>
                  <StandardButton
                    look={ButtonLooks.Primary}
                    type="button"
                    data-testid="try-again-button"
                    onClick={() => resetAuthState(authState, dispatch)}
                  >
                    Try again
                  </StandardButton>
                </>
              ) : authState.isAuthenticated ? (
                <div>Logging you in...</div>
              ) : (
                <StandardButton
                  look={ButtonLooks.Primary}
                  type="button"
                  data-testid="sign-in-button"
                  onClick={() => handleSignin()}
                >
                  Sign in
                </StandardButton>
              )}
            </div>
          </div>
        )}
      </div>
    </section>
  );
};
