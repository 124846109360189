import {
  GetBrandfolderFormInfoBody,
  GetHighspotFormInfoBody,
  GetHubspotFormInfoBody,
  GetWrikeFormInfoBody,
  GetSalsifyFormInfoBody,
  GetSeismicFormInfoBody,
  GetAsanaFormInfoBody,
} from '@integration-frontends/common/temporal-api';

import {
  Brandfolder,
  IFormInfoRepo,
  HighspotFormInfo,
  HubspotFormInfo,
  WrikeFormInfo,
  SalsifyFormInfo,
  SeismicFormInfo,
  BrandfolderFormInfo,
  AsanaFormInfo,
} from '@integration-frontends/workflow-manager/core/model';

import { injectable } from 'inversify';
import {
  ApiListDataResponse,
  CustomFieldValueDto,
  getResponseDataOrDefault,
} from '@integration-frontends/common/brandfolder-api';

import { CustomFieldKeyResponseData } from './model';
import { RepoBase } from './repo-base';

@injectable()
export class FormInfoRepo extends RepoBase implements IFormInfoRepo {
  getAsanaFormInfo = async (
    clientId: string,
    attributes: GetAsanaFormInfoBody,
  ): Promise<AsanaFormInfo> => {
    return this.temporalApi
      .getAsanaFormInfo(await this.getApiKey(), clientId, attributes)
      .then((res) => {
        return res.data;
      });
  };

  getBrandfolderFormInfo = async (
    clientId: string,
    attributes: GetBrandfolderFormInfoBody,
  ): Promise<Brandfolder[]> => {
    return this.temporalApi
      .getBrandfolderFormInfo(await this.getApiKey(), clientId, attributes)
      .then((res) => {
        return res.data?.brandfolder_info;
      });
  };

  getCustomFieldFormInfo = async (
    clientId: string,
    attributes: GetBrandfolderFormInfoBody,
  ): Promise<BrandfolderFormInfo> => {
    return this.temporalApi
      .listCustomFieldKeys(await this.getApiKey(), clientId, attributes)
      .then((res) => {
        return res.data;
      });
  };

  getHighspotFormInfo = async (
    clientId: string,
    attributes: GetHighspotFormInfoBody,
  ): Promise<HighspotFormInfo> => {
    return this.temporalApi
      .getHighspotFormInfo(await this.getApiKey(), clientId, attributes)
      .then((res) => {
        return res.data.spots;
      });
  };

  getHubspotFormInfo = async (
    clientId: string,
    attributes: GetHubspotFormInfoBody,
  ): Promise<HubspotFormInfo> => {
    return this.temporalApi
      .getHubspotFormInfo(await this.getApiKey(), clientId, attributes)
      .then((res) => {
        return res.data;
      });
  };

  getWrikeFormInfo = async (
    clientId: string,
    attributes: GetWrikeFormInfoBody,
  ): Promise<WrikeFormInfo> => {
    return this.temporalApi
      .getWrikeFormInfo(await this.getApiKey(), clientId, attributes)
      .then((res) => {
        return res.data;
      });
  };

  getSalsifyFormInfo = async (
    clientId: string,
    attributes: GetSalsifyFormInfoBody,
  ): Promise<SalsifyFormInfo> => {
    return this.temporalApi
      .getSalsifyFormInfo(await this.getApiKey(), clientId, attributes)
      .then((res) => {
        return res.data;
      });
  };

  getSeismicFormInfo = async (
    clientId: string,
    attributes: GetSeismicFormInfoBody,
  ): Promise<SeismicFormInfo> => {
    return this.temporalApi
      .getSeismicFormInfo(await this.getApiKey(), clientId, attributes)
      .then((res) => {
        return res.data;
      });
  };
}
