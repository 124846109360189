import { StandardHeading, StandardLabel } from '@brandfolder/react';
import { BFErrorLabel, BFLabel, BFSelect, BFSelectOption } from '@integration-frontends/common/ui';
import { alphabetizBfOptions } from 'libs/workflow-manager/ui/src/common/functions/alphabetize';
import React from 'react';
import { useFormContext } from 'react-hook-form';

interface DestinationSectionProps {
  bfSource;
  bfSources;
  bfSections;
  bfCollections;
}

export const DestinationSection = ({
  bfSource,
  bfSources,
  bfSections,
  bfCollections,
}: DestinationSectionProps) => {
  const {
    register,
    formState: { errors },
    setValue,
    watch,
  } = useFormContext();

  const resetSelections = (listOption) => {
    setValue('bf_destination_section', null, { shouldValidate: true });
    setValue('bf_destination_collection', null, { shouldValidate: true });
    setValue('bf_destination_key', listOption?.value || null, {
      shouldValidate: true,
      shouldDirty: true,
    });
  };

  return (
    <section
      className="source-section workflow-info-section"
      data-testid="sync-destination-section"
    >
      <StandardHeading className="workflow-info-section-header">Destination</StandardHeading>
      <section className="workflow-info-section-body">
        <div className="workflow-info-section-content">
          <div className="workflow-field workflow-field-margin">
            <StandardLabel
              data-testid="select-brandfolder-label"
              htmlFor="select-brandfolder"
              className="mb-xxs"
            >
              Brandfolder
            </StandardLabel>
            <BFSelect
              id="select-brandfolder"
              required={true}
              data-testid="select-brandfolder-select"
              options={(alphabetizBfOptions(bfSources) as BFSelectOption[]) || []}
              value={watch('bf_destination_key')}
              {...register('bf_destination_key', { required: true })}
              onOptionChange={(listOption): void => {
                resetSelections(listOption);
              }}
              placeholder={'Select Brandfolder'}
            />
            {errors.bf_destination_key?.type === 'required' && (
              <BFErrorLabel role="alert">Brandfolder is required</BFErrorLabel>
            )}
          </div>
          <div className="workflow-field workflow-field-margin">
            <div className="flex flex-col">
              <StandardLabel
                htmlFor="select-sections"
                data-testid="select-section-label"
                className="mb-xxs"
              >
                Brandfolder sections (optional)
              </StandardLabel>
              <BFSelect
                placeholder={'Select Sections'}
                options={(alphabetizBfOptions(bfSections) as BFSelectOption[]) || []}
                disabled={!bfSource}
                value={watch('bf_destination_section')}
                onOptionChange={(listOption): void => {
                  setValue('bf_destination_section', listOption?.value || null, {
                    shouldValidate: true,
                    shouldDirty: true,
                  });
                }}
                data-testid={`workflow-form-select-sections`}
              />
            </div>
          </div>
          <div className="workflow-field">
            <div className="flex flex-col">
              <StandardLabel
                htmlFor="select-sections"
                data-testid="select-collection-label"
                className="mb-xxs"
              >
                Brandfolder collections (optional)
              </StandardLabel>
              <BFSelect
                id="select-collections"
                placeholder={'Select Collections'}
                options={(alphabetizBfOptions(bfCollections) as BFSelectOption[]) || []}
                disabled={!bfSource}
                value={watch('bf_destination_collection')}
                onOptionChange={(listOption): void => {
                  setValue('bf_destination_collection', listOption?.value || null, {
                    shouldValidate: true,
                    shouldDirty: true,
                  });
                }}
                data-testid={`workflow-form-select-collections`}
              />
            </div>
          </div>
        </div>
      </section>
    </section>
  );
};
