import { put, takeEvery, select } from 'typed-redux-saga';
import {
  credentialEntityActions,
  selectSelectedClientId,
  setActiveSection,
  selectHighspotClientSecret,
  selectHighspotClientKey,
  selectGettyClientKey,
  selectGettyClientSecret,
  selectSelectedIntegrationType,
  callWithTokenRefresh,
} from '@integration-frontends/workflow-manager/core/application';
import {
  activeSection,
  CREDENTIAL_REPO_TOKEN,
  ICredentialRepo,
  nonCredentialIntegrations,
  workflowServiceType,
} from '@integration-frontends/workflow-manager/core/model';
import { DI_CONTAINER } from '@integration-frontends/core';
import {
  createCredential,
  credentialLoading,
  credentialSuccess,
  credentialFailure,
  selectHubspotAccessToken,
  selectSalsifyApiKey,
  selectHighspotCredName,
  selectGettyCredName,
  selectSalsifyCredName,
  selectHubspotCredName,
  selectSeismicCredName,
  selectSeismicUserId,
  selectSeismicClientId,
  selectSeismicSecretId,
  selectSeismicHost,
  selectHighspotApiHost,
} from '../../index';
import {
  selectWorkfrontClientId,
  selectWorkfrontClientSecret,
  selectWorkfrontCustomerId,
  selectWorkfrontHost,
  selectWorkfrontUserId,
  setSelectedCredentialId,
} from '../../../create-workflow';
import {
  CreateCredentialBody,
  getInitialCredentialBodyState,
} from '@integration-frontends/common/temporal-api';

function* handler(action: ReturnType<typeof createCredential>) {
  yield put(credentialLoading());
  const integrationType = yield select(selectSelectedIntegrationType);
  const credentialRepo: ICredentialRepo = DI_CONTAINER.get(CREDENTIAL_REPO_TOKEN);
  const selectedClientId = yield select(selectSelectedClientId);

  const credentialPayload = yield getCredentialPayload(integrationType);
  const res = yield callWithTokenRefresh(
    credentialRepo.createCredential,
    selectedClientId,
    credentialPayload,
  );

  if (res.errors) {
    const errorMsg = res.errors
      ? res.errors.map((error) => error.title + ': ' + error.detail).join(', ')
      : 'Unknown error';
    yield put(credentialFailure({ error: errorMsg }));
  } else {
    const credential = res;
    yield put(credentialEntityActions.credentialReceived(credential));
    yield put(credentialSuccess());
    if (!nonCredentialIntegrations.includes(integrationType as workflowServiceType)) {
      yield put(setSelectedCredentialId({ credentialId: credential.id }));
    }
    yield put(setActiveSection({ activeSection: activeSection.workflowInfo }));
  }
}

export function* createCredentialEffects() {
  yield takeEvery(createCredential, handler);
}

function* getCredentialPayload(
  integrationType: workflowServiceType,
): Generator<any, CreateCredentialBody, any> {
  const payload: CreateCredentialBody = getInitialCredentialBodyState(integrationType);
  switch (integrationType) {
    case workflowServiceType.highspot:
      payload.credential_name = yield select(selectHighspotCredName);
      payload.client_key = yield select(selectHighspotClientKey);
      payload.client_secret = yield select(selectHighspotClientSecret);
      payload.host = yield select(selectHighspotApiHost);
      return payload;
    case workflowServiceType.getty:
      payload.credential_name = yield select(selectGettyCredName);
      payload.client_key = yield select(selectGettyClientKey);
      payload.client_secret = yield select(selectGettyClientSecret);
      return payload;
    case workflowServiceType.hubspot:
      payload.credential_name = yield select(selectHubspotCredName);
      payload.api_key = yield select(selectHubspotAccessToken);
      return payload;
    case workflowServiceType.salsify:
      payload.credential_name = yield select(selectSalsifyCredName);
      payload.api_key = yield select(selectSalsifyApiKey);
      return payload;
    case workflowServiceType.seismic:
      payload.credential_name = yield select(selectSeismicCredName);
      payload.external_user_id = yield select(selectSeismicUserId);
      payload.client_key = yield select(selectSeismicClientId);
      payload.client_secret = yield select(selectSeismicSecretId);
      payload.host = yield select(selectSeismicHost);
      return payload;
    case workflowServiceType.workfront:
      /*
        // This is the expected payload for Workfront credential validation
        // https://github.com/brandfolder/integration-temporal/blob/master/services/contentsync/client.go#L146-L149
        {
          "credential_type": "workfront",
          "client_secret": "workfront_client_secret",
          "client_key": "workfront_client_id",
          "host": "workfront_subdomain",
          "external_user_id": "workfront_user_id",
          "api_key": "workfront_customer_id"
        }
      */
      payload.client_secret = yield select(selectWorkfrontClientSecret);
      payload.client_key = yield select(selectWorkfrontClientId);
      payload.host = yield select(selectWorkfrontHost);
      payload.external_user_id = yield select(selectWorkfrontUserId);
      payload.api_key = yield select(selectWorkfrontCustomerId);
      return payload;
  }

  return payload;
}
