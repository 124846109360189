import { put, all, select, takeEvery } from 'typed-redux-saga';
import { createNotification, NotificationType } from '@integration-frontends/common/notifications';
import {
  callWithTokenRefresh,
  selectSelectedClientId,
  asanaRehydrateInfo,
  asanaReceiveFormInfo,
  asanaFetchInit,
  asanaFetchLoaded,
} from '@integration-frontends/workflow-manager/core/application';
import {
  AsanaFormInfo,
  FORM_INFO_REPO_TOKEN,
  IFormInfoRepo,
} from '@integration-frontends/workflow-manager/core/model';
import { DI_CONTAINER } from '@integration-frontends/core';
import { pageLoadError } from '@integration-frontends/common/app';
import { GetAsanaFormInfoBody } from '@integration-frontends/common/temporal-api';

function* handler(action: ReturnType<typeof asanaRehydrateInfo>) {
  yield put(asanaFetchInit());
  try {
    const { credential_id, workspace_id, project_id, section_id } = action.payload;
    const formInfoRepo: IFormInfoRepo = DI_CONTAINER.get(FORM_INFO_REPO_TOKEN);
    const clientId = yield select(selectSelectedClientId);

    const formInfo: AsanaFormInfo = yield callWithTokenRefresh(
      formInfoRepo.getAsanaFormInfo,
      clientId,
      {
        credential_id,
        workspace_id,
        project_id,
        section_id,
      } as GetAsanaFormInfoBody,
    );

    yield put(
      asanaReceiveFormInfo({
        workspaces: formInfo.workspaces?.data,
        projects: formInfo.projects?.data,
        sections: formInfo.sections?.data,
      }),
    );
  } catch (e) {
    yield put(
      createNotification({
        message: 'Error fetching Asana workflow data: ' + e,
        location: 'topLevel',
        type: NotificationType.Error,
      }),
    );
    yield put(pageLoadError({ error: e }));
    yield put(asanaFetchLoaded());
  }
}

export function* asanaRehydrateInfoEffects() {
  yield takeEvery(asanaRehydrateInfo, handler);
}
