import { useSelector, useDispatch } from 'react-redux';
import React, { useState, useEffect } from 'react';
import { Loader } from '@integration-frontends/common/ui';
import { CreateWorkflowNav } from '../common/components/workflow-nav/create-workflow-nav';
import { SectionTracker } from '../common/components/section-tracker/section-tracker';
import { HighspotReview } from '../workflow-forms/workflow-form-highspot';
import { GettyReview } from '../workflow-forms/workflow-form-getty';
import { HubspotReview } from '../workflow-forms/workflow-form-hubspot';

import {
  selectSelectedIntegrationType,
  selectActiveSection,
  selectHighspotCredentialID,
  selectGettyCredentialId,
  selectHubspotCredentialID,
  selectWrikeCredentialId,
  credentialEntitySelectors,
  enteredCredentialPage,
  selectSelectedCredentialId,
  selectSalsifyCredentialId,
  selectSeismicCredentialId,
  workflowModes,
  selectWorkflowMode,
  selectworkflowPageInit,
  exitReviewWorkflow,
  selectAsanaCredentialId,
} from '@integration-frontends/workflow-manager/core/application';
import { WrikeReview } from '../workflow-forms/workflow-form-wrike/review-wrike';
import { workflowServiceType } from '@integration-frontends/workflow-manager/core/model';
import { SalsifyReview } from '../workflow-forms/workflow-form-salsify/review-salsify';
import { SeismicReview } from '../workflow-forms/workflow-form-seismic';
import { WorkfrontReview } from '../workflow-forms/workflow-form-workfront';
import { ReviewHeader } from '../../common/review-page/review-header';
import { AsanaReview } from '../workflow-forms/workflow-form-asana/review-asana';

interface ReviewPageProps {
  onBack: () => void;
  onNext: () => void;
}

export const ReviewPage = ({ onBack, onNext }: ReviewPageProps) => {
  const dispatch = useDispatch();
  const workflowMode = useSelector(selectWorkflowMode);
  const reviewOnlyActive = workflowMode === workflowModes.review;
  const integrationType = useSelector(selectSelectedIntegrationType);
  const activeSection = useSelector(selectActiveSection);
  const loading = useSelector(selectworkflowPageInit);
  const [complete, setComplete] = useState(false);
  const highspotCredentialId = useSelector(selectHighspotCredentialID);
  const gettyCredentialId = useSelector(selectGettyCredentialId);
  const hubspotCredentialId = useSelector(selectHubspotCredentialID);
  const wrikeCredentialId = useSelector(selectWrikeCredentialId);
  const salsifyCredentialId = useSelector(selectSalsifyCredentialId);
  const seismicCredentialId = useSelector(selectSeismicCredentialId);
  const asanaCredentialId = useSelector(selectAsanaCredentialId);
  const defaultCredentialId = useSelector(selectSelectedCredentialId);
  const editing = workflowMode === workflowModes.edit;

  const getSelectedCredentialId = (integrationType: string): string => {
    switch (integrationType) {
      case workflowServiceType.highspot:
        return highspotCredentialId;
      case workflowServiceType.getty:
        return gettyCredentialId;
      case workflowServiceType.hubspot:
        return hubspotCredentialId;
      case workflowServiceType.wrike:
        return wrikeCredentialId;
      case workflowServiceType.salsify:
        return salsifyCredentialId;
      case workflowServiceType.seismic:
        return seismicCredentialId;
      case workflowServiceType.asana:
        return asanaCredentialId;
      default:
        return defaultCredentialId;
    }
  };

  const selectedCredentialId = getSelectedCredentialId(integrationType);
  const credential = useSelector(credentialEntitySelectors.credentialById(selectedCredentialId));
  const getOnNextAction = () => {
    if (complete && !reviewOnlyActive) {
      onNext();
    } else if (reviewOnlyActive) {
      dispatch(exitReviewWorkflow());
    } else {
      return null;
    }
  };
  useEffect(() => {
    if (selectedCredentialId && !credential) {
      // if the user just created an oauth credential, or is reviewing via the edit page,
      // refetch the credentials to get the full credential record from the DB
      dispatch(enteredCredentialPage());
    }
  }, [selectedCredentialId]);

  return (
    <>
      <CreateWorkflowNav
        hideBackButton={reviewOnlyActive}
        onBack={reviewOnlyActive ? null : onBack}
        onNext={getOnNextAction}
        onNextActionName={reviewOnlyActive ? 'Done' : 'Publish workflow'}
        title="Review"
      />
      <section className="workflow-wizard-section">
        {!reviewOnlyActive && <SectionTracker activeSection={activeSection} />}
        <section className="forms-container">
          <section className="forms-content-container">
            {loading ? (
              <Loader />
            ) : (
              <>
                {
                  /* only show banner in create mode */
                  !editing && <ReviewHeader reviewOnlyActive={reviewOnlyActive} />
                }
                {integrationType === workflowServiceType.highspot && (
                  <HighspotReview setComplete={setComplete} />
                )}
                {integrationType === workflowServiceType.getty && (
                  <GettyReview setComplete={setComplete} />
                )}
                {integrationType === workflowServiceType.hubspot && (
                  <HubspotReview setComplete={setComplete} />
                )}
                {integrationType === workflowServiceType.wrike && (
                  <WrikeReview setComplete={setComplete} />
                )}
                {integrationType === workflowServiceType.salsify && (
                  <SalsifyReview setComplete={setComplete} />
                )}
                {integrationType === workflowServiceType.seismic && (
                  <SeismicReview setComplete={setComplete} />
                )}
                {integrationType === workflowServiceType.workfront && (
                  <WorkfrontReview setComplete={setComplete} />
                )}
                {integrationType === workflowServiceType.asana && (
                  <AsanaReview setComplete={setComplete} />
                )}
              </>
            )}
          </section>
        </section>
      </section>
    </>
  );
};
