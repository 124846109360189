import React from 'react';
import './page-header.scss';
import {
  FontIcon,
  FontIcons,
  FontWeights,
  HeadingLevels,
  StandardButton,
  StandardHeading,
} from '@brandfolder/react';

export interface PageHeaderProps {
  title: string;
  actionTitle: string;
  filtersComponent?: JSX.Element;
  testid?: string;
  buttonTestid?: string;
  onClick?: () => void;
}

export const PageHeader = ({
  title,
  onClick,
  actionTitle,
  testid,
  buttonTestid,
  filtersComponent = null,
}: PageHeaderProps) => (
  <header className="page-header" data-testid={testid}>
    <StandardHeading
      level={HeadingLevels.h1}
      weight={FontWeights.Bold}
      className="page-header-title"
    >
      {title}
    </StandardHeading>
    <section className="page-header-body">
      <StandardButton data-testid={buttonTestid} onClick={onClick}>
        <FontIcon icon={FontIcons.Plus} iconSize={10} className="mr-sm" />
        {actionTitle}
      </StandardButton>
      {filtersComponent ? <div className="page-header-filters">{filtersComponent}</div> : null}
    </section>
  </header>
);
