import { AuthUiModule } from '@integration-frontends/common/auth/ui';
import { all, fork } from 'redux-saga/effects';
import { rootWorkflow } from './workflows';
import { appRootReducer } from '@integration-frontends/common/app';
import {
  enteredClientsPageEffects,
  entitiesStateReducer,
  createClientReducer,
  createWorkflowReducer,
  createCredentialReducer,
  createClientEffects,
  clientsPageReducer,
  workflowsPageReducer,
  createWorkflowEffects,
  updateWorkflowEffects,
  createCredentialEffects,
  enteredWorkflowsPageEffects,
  workflowInfoFormEffects,
  enteredCredentialPageEffects,
  hightspotFetchSpotsEffects,
  hubspotFetchFoldersEffects,
  hubspotGetFolderByIdEffects,
  wrikeFetchSpacesEffects,
  initEditWorkflowEffects,
  wrikeOauthSignInEffects,
  salsifyFetchInfoEffects,
  getCustomFieldFormInfoEffects,
  salsifySelectOrgnizationEffects,
  seismicFetchFoldersEffects,
  seismicRehydrateSyncMapsEffects,
  initReviewWorkflowEffects,
  exitReviewWorkflowEffects,
  asanaOauthSignInEffects,
  setCredentialIdFromQueryParamsEffects,
  asanaFetchFormInfoEffects,
  asanaRehydrateInfoEffects,
  seismicRehydrateSingleDestinationEffects,
} from '@integration-frontends/workflow-manager/core/application';
import storage from 'redux-persist/lib/storage';
import {
  notificationEffects,
  notificationsRootReducer,
} from '@integration-frontends/common/notifications';
import { createAppStore } from '@integration-frontends/apps/common/store/create-app-store';
import { createBrowserHistory } from 'history';

// TODO add configs ~PP
const ENVIRONMENT = 'development';

const rootSaga = function* () {
  yield all([
    fork(rootWorkflow),
    fork(notificationEffects),
    fork(enteredClientsPageEffects),
    fork(createClientEffects),
    fork(createCredentialEffects),
    fork(enteredWorkflowsPageEffects),
    fork(workflowInfoFormEffects),
    fork(createWorkflowEffects),
    fork(updateWorkflowEffects),
    fork(enteredCredentialPageEffects),
    fork(setCredentialIdFromQueryParamsEffects),
    fork(hightspotFetchSpotsEffects),
    fork(hubspotFetchFoldersEffects),
    fork(hubspotGetFolderByIdEffects),
    fork(wrikeFetchSpacesEffects),
    fork(asanaFetchFormInfoEffects),
    fork(asanaOauthSignInEffects),
    fork(asanaRehydrateInfoEffects),
    fork(salsifyFetchInfoEffects),
    fork(salsifySelectOrgnizationEffects),
    fork(getCustomFieldFormInfoEffects),
    fork(initEditWorkflowEffects),
    fork(initReviewWorkflowEffects),
    fork(exitReviewWorkflowEffects),
    fork(wrikeOauthSignInEffects),
    fork(seismicFetchFoldersEffects),
    fork(seismicRehydrateSyncMapsEffects),
    fork(seismicRehydrateSingleDestinationEffects),
  ]);
};

export const { history, persistor, store } = createAppStore({
  environment: ENVIRONMENT,
  reducers: {
    ...notificationsRootReducer,
    ...appRootReducer,
    createClientReducer,
    clientsPageReducer,
    workflowsPageReducer,
    createWorkflowReducer,
    createCredentialReducer,
    ...AuthUiModule.getRootReducer(),
    entitiesStateReducer,
  },
  rootSaga,
  persistConfig: {
    enabled: true,
    storage,
    blacklist: ['auth'],
  },
  history: createBrowserHistory(),
});
