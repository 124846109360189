import { put, takeEvery, select } from 'typed-redux-saga';
import {
  workflowFormFailure,
  getCustomFieldFormInfo,
  setRestrictedCustomFieldKeys,
  setCustomFieldValueOptions,
  selectSelectedClientId,
  selectSelectedCredentialId,
  setAllCustomFieldKeys,
} from '../../index';
import {
  BrandfolderFormInfo,
  FORM_INFO_REPO_TOKEN,
  IFormInfoRepo,
} from '@integration-frontends/workflow-manager/core/model';
import { DI_CONTAINER } from '@integration-frontends/core';
import { GetBrandfolderFormInfoBody } from '@integration-frontends/common/temporal-api';
import { callWithTokenRefresh } from '../../../../common';

function* handler(action: ReturnType<typeof getCustomFieldFormInfo>) {
  const formInfoRepo: IFormInfoRepo = DI_CONTAINER.get(FORM_INFO_REPO_TOKEN);
  const clientId = yield select(selectSelectedClientId);
  const credentialId = yield select(selectSelectedCredentialId);

  try {
    const formInfo: BrandfolderFormInfo = yield callWithTokenRefresh(
      formInfoRepo.getCustomFieldFormInfo,
      clientId,
      {
        credential_id: credentialId,
        brandfolder_key: action.payload.brandfolderId,
      } as GetBrandfolderFormInfoBody,
    );
    const customFieldKeys = formInfo.brandfolder_info[0].custom_fields.data;
    const restrictedValueKeys = customFieldKeys?.filter((customFieldKeyDatum) => {
      return customFieldKeyDatum?.restricted;
    });
    const customFieldValues = customFieldKeys?.reduce((customFieldValues, customFieldKeyDatum) => {
      if (customFieldKeyDatum.allowed_values.length) {
        customFieldKeyDatum.allowed_values.map((value) => {
          const customFieldKeyId = customFieldKeyDatum.id;
          if (customFieldValues[customFieldKeyId]) {
            customFieldValues[customFieldKeyId].push(value);
          } else {
            customFieldValues[customFieldKeyId] = [value];
          }
        });
      }
      return customFieldValues;
    }, {});

    yield put(setAllCustomFieldKeys({ customFieldKeys }));

    if (restrictedValueKeys) {
      yield put(setRestrictedCustomFieldKeys({ restrictedCustomFieldKeys: restrictedValueKeys }));
    }
    if (customFieldValues) {
      yield put(setCustomFieldValueOptions({ customFieldValues }));
    }
  } catch {
    yield put(workflowFormFailure());
  }
}

export function* getCustomFieldFormInfoEffects() {
  yield takeEvery(getCustomFieldFormInfo, handler);
}
