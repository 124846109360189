import { put, takeEvery, select } from 'typed-redux-saga';
import {
  workflowFormSuccess,
  workflowFormFailure,
  createWorkflowInfoFormEntered,
  selectSelectedCredentialId,
  setBrandfolderFormInfo,
  selectSelectedClientId,
  workflowPageLoaded,
} from '../../index';
import {
  FORM_INFO_REPO_TOKEN,
  IFormInfoRepo,
} from '@integration-frontends/workflow-manager/core/model';
import { DI_CONTAINER } from '@integration-frontends/core';
import {
  brandfolderEntityActions,
  callWithTokenRefresh,
  setCredentialIdFromQueryParams,
} from '@integration-frontends/workflow-manager/core/application';
import { NotificationType, createNotification } from '@integration-frontends/common/notifications';

function* handler(action: ReturnType<typeof createWorkflowInfoFormEntered>) {
  yield put(brandfolderEntityActions.brandfoldersRemoved());
  const formInfoRepo: IFormInfoRepo = DI_CONTAINER.get(FORM_INFO_REPO_TOKEN);
  const clientId = yield select(selectSelectedClientId);

  yield put(setCredentialIdFromQueryParams());

  const credentialId = yield select(selectSelectedCredentialId);

  if (credentialId) {
    const formInfo = yield callWithTokenRefresh(formInfoRepo.getBrandfolderFormInfo, clientId, {
      credential_id: credentialId,
    });
    if (formInfo) {
      yield put(setBrandfolderFormInfo(formInfo));
      yield put(brandfolderEntityActions.brandfoldersReceived(formInfo));
      yield put(workflowFormSuccess());
    } else {
      yield put(workflowPageLoaded());
      yield put(workflowFormFailure());
      yield put(
        createNotification({
          message: 'Failed to fetch Brandfolder form info',
          location: 'topLevel',
          type: NotificationType.Error,
        }),
      );
    }
  }
  yield put(workflowPageLoaded());
}

export function* workflowInfoFormEffects() {
  yield takeEvery(createWorkflowInfoFormEntered, handler);
}
