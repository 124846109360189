import React, { useEffect } from 'react';
import { CreateWorkflowInputField } from '../../common/components/create-workflow-input-field/create-workflow-input-field';
import { useFormContext } from 'react-hook-form';
import { lettersAndNumberMatch } from '@integration-frontends/common/utils/validation';
import { BFErrorLabel } from '@integration-frontends/common/ui';

export interface SeismicCredentialFormProps {
  credsComplete?: (c: boolean) => void;
  credFormData?: any;
}

export const SeismicCredentialForm = ({
  credsComplete,
  credFormData,
}: SeismicCredentialFormProps) => {
  const {
    register,
    formState: { errors },
    setValue,
    watch,
  } = useFormContext();

  const credName = watch('credential_name');
  const userId = watch('external_user_id');
  const clientId = watch('client_id');
  const secretId = watch('secret_id');
  const host = watch('host');

  useEffect(() => {
    if (credsComplete && credFormData) {
      if (credName && userId && clientId && secretId && host) {
        credsComplete(true);
        credFormData({
          credential_name: credName,
          external_user_id: userId,
          client_id: clientId,
          secret_id: secretId,
          host: host,
        });
      } else {
        credsComplete(false);
      }
    }
  }, [credName, userId, clientId, secretId, host]);

  return (
    <>
      <CreateWorkflowInputField
        label={'Seismic credentials name'}
        placeholder={'Enter a credential name'}
        value={credName}
        inputId={'set-seismic-cred-name'}
        tooltip="Give a descriptive name to this set of credentials."
        onChange={(e) => {
          setValue('credential_name', e.target.value || ('' as string));
        }}
        validation={register('credential_name', {
          required: true,
          maxLength: 50,
          minLength: 5,
          pattern: lettersAndNumberMatch,
        })}
      />
      {errors.credential_name?.type === 'required' && (
        <BFErrorLabel role="alert">Please choose a unique name for this credential.</BFErrorLabel>
      )}
      {errors.credential_name?.type === 'maxLength' ||
        (errors.credential_name?.type === 'minLength' && (
          <BFErrorLabel role="alert">Credential name must be 5-50 characters long</BFErrorLabel>
        ))}
      {errors.credential_name?.type === 'pattern' && (
        <BFErrorLabel role="alert">Please use only letters and numbers.</BFErrorLabel>
      )}

      <CreateWorkflowInputField
        label={'Seismic user ID'}
        placeholder={'Enter user ID'}
        value={userId}
        inputId={'set-seismic-user-id'}
        tooltip={'Ask the client to provide the user ID.'}
        onChange={(e) => {
          setValue('external_user_id', e.target.value || ('' as string));
        }}
        validation={register('external_user_id', { required: true })}
      />
      {errors.external_user_id?.type === 'required' && (
        <BFErrorLabel role="alert">Please verify the user ID.</BFErrorLabel>
      )}

      <CreateWorkflowInputField
        label={'Seismic client key'}
        placeholder={'Enter client key'}
        value={clientId}
        inputId={'set-seismic-client-id'}
        tooltip={'Ask the client to provide the client key.'}
        onChange={(e) => {
          setValue('client_id', e.target.value || ('' as string));
        }}
        validation={register('client_id', { required: true })}
      />
      {errors.client_id?.type === 'required' && (
        <BFErrorLabel role="alert">Please verify the client key.</BFErrorLabel>
      )}

      <CreateWorkflowInputField
        label={'Seismic secret key'}
        placeholder={'Enter secret key'}
        value={secretId}
        inputId={'set-seismic-secret-id'}
        tooltip={'Ask the client to provide the secret key.'}
        onChange={(e) => {
          setValue('secret_id', e.target.value || ('' as string));
        }}
        validation={register('secret_id', { required: true })}
      />
      {errors.secret_id?.type === 'required' && (
        <BFErrorLabel role="alert">Please verify the secret key.</BFErrorLabel>
      )}

      <CreateWorkflowInputField
        label={'Tenant name'}
        placeholder={'Enter tenant name'}
        value={host}
        inputId={'set-seismic-tenant-name'}
        tooltip={
          'You can find the tenant name in the client’s Seismic URL, which should have the following structure: tenantname.seismic.com.'
        }
        onChange={(e) => {
          setValue('host', e.target.value || ('' as string));
        }}
        validation={register('host', { required: true })}
      />
      {errors.host?.type === 'required' && (
        <BFErrorLabel role="alert">Please verify the tenant name.</BFErrorLabel>
      )}
    </>
  );
};
