import React, { useState, ReactElement } from 'react';
import './row-item-menu.scss';
import { IconButton, ButtonLooks, FontIcons, StandardPopout, Placements } from '@brandfolder/react';

export interface RowItemMenuProps {
  panel: ReactElement;
}

export function RowItemMenu({ panel }: RowItemMenuProps) {
  const [popoutOpen, setPopoutOpen] = useState(false);

  return (
    <StandardPopout
      content={panel}
      placement={Placements.BottomEnd}
      id="options-popout"
      setOpen={() => setPopoutOpen(false)}
      transitionDelay={0}
      transitionDuration={200}
      triggerOffset={0}
      width={160}
      open={popoutOpen}
      showArrow={false}
      showCloseButton={false}
      closeOnClickAway
      popoutDialogBodyProps={{
        style: {
          width: '160px',
          padding: '8px',
        },
      }}
      preventCutoff
    >
      <IconButton
        label="workflow-options-button"
        look={ButtonLooks.TextTertiary}
        icon={FontIcons.Ellipsis}
        onClick={() => setPopoutOpen(true)}
        data-testid="workflow-options-button"
      />
    </StandardPopout>
  );
}
