import { ButtonLooks, FontIcons, IconButton } from '@brandfolder/react';
import * as React from 'react';
import './delete-mapping.scss';

export interface DeleteMappingProps {
  deleteMapping: (index?: number) => void;
  index: number;
}

export const DeleteMapping = ({ deleteMapping, index }: DeleteMappingProps): React.ReactElement => {
  return (
    <>
      <div className="delete-mapping-container">
        <IconButton
          look={ButtonLooks.TextWarning}
          icon={FontIcons.Trash}
          label={'delete-map'}
          iconProps={{ iconSize: 16 }}
          onClick={() => deleteMapping(index)}
          data-testid="delete-mapping"
        />
      </div>
      <span className="optional-mapping-row-border" />
    </>
  );
};
