import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { IconClose, IconSize, IconAdd, Loader } from '@integration-frontends/common/ui';
import {
  selectClientEmailFormatError,
  selectCreateClientLoading,
} from '@integration-frontends/workflow-manager/core/application';
import './create-client-form.scss';
import {
  ButtonLooks,
  FontWeights,
  StandardButton,
  StandardText,
  StandardTextfield,
} from '@brandfolder/react';
import { InfoBanner } from '../../common/info-banner/info-banner';

interface CreateClientProps {
  modal?: boolean;
  onClose?: () => void;
  onSubmit?: (clientName: string, bfApiKey: string) => void;
}

export const CreateClientForm = ({ modal, onClose, onSubmit }: CreateClientProps) => {
  const [bfApiKey, setBfApiKey] = useState(null);
  const [clientName, setClientName] = useState(null);
  const loading = useSelector(selectCreateClientLoading);
  const emailFormatError = useSelector(selectClientEmailFormatError);

  const clearFields = () => {
    setBfApiKey(null);
    setClientName(null);
  };

  const handleSubmit = () => {
    onSubmit(clientName, bfApiKey);
    clearFields();
  };

  return (
    <section data-testid="create-client-modal" className={'create-client-container'}>
      <section className="create-client-form-container">
        {modal && (
          <div className="create-client-form-head">
            <div className="add-client flex flex-row">
              <IconAdd />{' '}
              <StandardText className="create-client-form-head-text">Add client</StandardText>
            </div>
            <IconClose className="cursor-pointer" iconSize={IconSize.Medium} onClick={onClose} />
          </div>
        )}
        <div className="create-client-form">
          <InfoBanner
            headerText={`Add developer username to Brandfolder`}
            bodyText={`Before continuing, make sure there’s a dedicated admin user with the format bf-integrations-dev+<org-slug>@smartsheet.com in the client’s Brandfolder account.`}
            linkName={`View documentation`}
            link={
              'https://smar-wiki.atlassian.net/wiki/spaces/PROD/pages/70971228283/CX+Guide+-+IWM+-+Integration+Workflow+Manager'
            }
          />
          {loading ? (
            <Loader />
          ) : (
            <>
              <StandardText className="mt-xxl" weight={FontWeights.Bold}>
                Client Name
              </StandardText>
              <StandardTextfield
                label="Input client name"
                id="input-client-name"
                data-testid="input-client-name"
                showLabel={false}
                onChange={(e) => {
                  setClientName(e.target.value);
                }}
                placeholder="Enter client name"
                className="mb-xl"
              />
              <StandardText weight={FontWeights.Bold}>Client Brandfolder API Key</StandardText>
              <StandardTextfield
                label="Input client name"
                id="input-bf-api-key"
                data-testid="input-bf-api-key"
                showLabel={false}
                onChange={(e) => {
                  setBfApiKey(e.target.value);
                }}
                placeholder="Enter API key"
              />
              {emailFormatError && (
                <StandardText className="mt-sm create-client-sub-text">
                  <div className="error" data-testid="create-client-email-format-error">
                    Please enter the API key for the developer admin user. Review the info banner
                    for the required email address format for this admin user.
                  </div>
                </StandardText>
              )}
              <StandardText className="mt-sm create-client-sub-text">
                Enter the API key for the developer admin user.
              </StandardText>
            </>
          )}
        </div>
        {modal && (
          <div className="flex flex-row justify-end" style={{ margin: '24px' }}>
            <StandardButton
              data-testid="close-create-client"
              onClick={onClose}
              look={ButtonLooks.Tertiary}
              className="mr-sm"
            >
              Cancel
            </StandardButton>
            <StandardButton
              data-testid="submit-create-client"
              disabled={!(!!clientName && !!bfApiKey)}
              onClick={() => handleSubmit()}
            >
              Save
            </StandardButton>
          </div>
        )}
      </section>
    </section>
  );
};
