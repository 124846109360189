import {
  FontIcon,
  FontIcons,
  FontWeights,
  HeadingLevels,
  StandardButton,
  StandardCombobox,
  StandardHeading,
  StandardTextfield,
} from '@brandfolder/react';
import {
  WorkflowsActiveOption,
  WorkflowsSortOption,
} from '@integration-frontends/workflow-manager/core/model';
import React from 'react';
import { WorkflowsSort } from './workflows-sort-filter';
import './workflows-header.scss';

interface WorkflowsHeaderProps {
  handleSearchChange: (e) => void;
  handleAddWorkflow: () => void;
  handleSetSort: (selectedOption: WorkflowsSortOption) => void;
  handleFilterActive: (selectedFilter: WorkflowsActiveOption) => void;
  sortOptions: WorkflowsSort[];
  workflows;
  activeFilters;
}

export const WorkflowsHeader = ({
  handleSearchChange,
  handleAddWorkflow,
  handleSetSort,
  handleFilterActive,
  sortOptions,
  activeFilters,
  workflows,
}: WorkflowsHeaderProps) => {
  return (
    <header className="workflows-page-header" data-testid="workflows-page-header">
      <StandardHeading
        level={HeadingLevels.h1}
        weight={FontWeights.Bold}
        className="page-header-title"
        data-testid="workflows-count-header"
      >
        {`Workflows (${workflows?.length})`}
      </StandardHeading>
      <section className="page-header-body">
        <div className="page-header-button-search">
          <StandardButton data-testid={'add-workflow-button'} onClick={handleAddWorkflow}>
            <FontIcon icon={FontIcons.Plus} iconSize={10} className="mr-sm" />
            Add workflow
          </StandardButton>
          <StandardTextfield
            label={'Search for a workflow'}
            id={'workflow-page-search'}
            placeholder="Search for a workflow"
            leftIcon={FontIcons.Search}
            showLabel={false}
            className="workflows-page-header-search"
            onChange={(e) => handleSearchChange(e)}
            data-testid="page-header-search"
          />
        </div>
        <div className="page-header-filter-sort">
          <StandardCombobox
            id={'page-header-active-filter'}
            data-testid="page-header-active-filter"
            labels={{
              label: 'Filter workflows by active',
              iconButtonLabel: 'Filter workflows by active icon',
              listboxLabel: 'Filter workflows by active',
            }}
            onSelection={(option) => handleFilterActive(option.value as WorkflowsActiveOption)}
            options={activeFilters.map((filter) => ({
              children: <>{filter.label}</>,
              key: filter.value,
              value: filter.value,
            }))}
            showLabel={false}
            initialSelectedIndex={0}
            className="workflows-header-filter-active"
          />
          <StandardCombobox
            id={'page-header-sort-toggle'}
            data-testid="page-header-sort-toggle"
            labels={{
              label: 'Sort list of workflows',
              iconButtonLabel: 'Sort workflow icon',
              listboxLabel: 'Sort workflow option',
            }}
            onSelection={(option) => handleSetSort(option.value as WorkflowsSortOption)}
            options={sortOptions.map((option) => ({
              children: <>{option.label}</>,
              key: option.value,
              value: option.value,
            }))}
            showLabel={false}
            initialSelectedIndex={0}
            className="workflows-header-sort-dropdown"
            ellipsis={true}
          />
        </div>
      </section>
    </header>
  );
};
