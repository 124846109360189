import { workflowServiceType } from '@integration-frontends/workflow-manager/core/model';
import React from 'react';
import { GettyCredentialForm } from '../workflow-forms/workflow-form-getty';
import { HighspotCredentialForm } from '../workflow-forms/workflow-form-highspot';
import { HubspotCredentialForm } from '../workflow-forms/workflow-form-hubspot';
import { SalsifyCredentialForm } from '../workflow-forms/workflow-form-salsify/credential-form-salsify';
import { SeismicCredentialForm } from '../workflow-forms/workflow-form-seismic/credential-form-seismic';
import { WorkfrontCredentialForm } from '../workflow-forms/workflow-form-workfront/credential-form-workfront';
import { WrikeCredentialForm } from '../workflow-forms/workflow-form-wrike';
import { AsanaCredentialForm } from '../workflow-forms/workflow-form-asana/credential-form-asana';

interface IntegrationCredentialFormProps {
  integrationType;
  setCredsData?;
  setCredsComplete?;
}

export const IntegrationCredentialForm = ({
  integrationType,
  setCredsData,
  setCredsComplete,
}: IntegrationCredentialFormProps) => {
  const formProps = { credFormData: setCredsData, credsComplete: setCredsComplete };

  const credentitalFormComponents = {
    [workflowServiceType.highspot]: HighspotCredentialForm,
    [workflowServiceType.getty]: GettyCredentialForm,
    [workflowServiceType.hubspot]: HubspotCredentialForm,
    [workflowServiceType.salsify]: SalsifyCredentialForm,
    [workflowServiceType.wrike]: WrikeCredentialForm,
    [workflowServiceType.asana]: AsanaCredentialForm,
    [workflowServiceType.seismic]: SeismicCredentialForm,
    [workflowServiceType.workfront]: WorkfrontCredentialForm,
  };

  const SelectedCredentialForm = credentitalFormComponents[integrationType];

  return SelectedCredentialForm ? <SelectedCredentialForm {...formProps} /> : null;
};
