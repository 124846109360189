import { put, takeEvery, select } from 'typed-redux-saga';
import {
  selectSelectedIntegrationType,
  setSelectedCredentialId,
} from '@integration-frontends/workflow-manager/core/application';
import { oauthWorkflows } from '@integration-frontends/workflow-manager/core/model';
import { setCredentialIdFromQueryParams } from '../../index';

function* handler() {
  // This was added because the redirect from the Wrike and Asana Oauth page passes back a credential_id. This ID then
  // gets wiped through the rehydrating process of redux-persist. I am setting it here before it is wiped
  const searchParams = new URLSearchParams(document.location.search);
  const integrationType = yield select(selectSelectedIntegrationType);

  if (oauthWorkflows.includes(integrationType)) {
    const credentialId = searchParams.get('credential_id');
    if (credentialId) {
      yield put(setSelectedCredentialId({ credentialId }));
    }
  }
}

export function* setCredentialIdFromQueryParamsEffects() {
  yield takeEvery(setCredentialIdFromQueryParams, handler);
}
