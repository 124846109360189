import { combineReducers } from '@reduxjs/toolkit';
import { clientSlice } from './entities/client';
import { credentialSlice } from './entities/credential';
import { workflowSlice } from './entities/workflow';
import { formInfoSlice } from './entities/form-info';
import { brandfolderSlice } from './entities';
import { sectionSlice } from './entities/section';
import { collectionSlice } from './entities/collection';
import { organizationSlice } from './entities/organizations';

export const entitiesStateReducer = combineReducers({
  brandfolders: brandfolderSlice.reducer,
  collections: collectionSlice.reducer,
  organizations: organizationSlice.reducer,
  sections: sectionSlice.reducer,
  clients: clientSlice.reducer,
  formInfo: formInfoSlice.reducer,
  workflows: workflowSlice.reducer,
  credentials: credentialSlice.reducer,
});
