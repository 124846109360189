import { createSelector } from 'reselect';
import { CreateClientState, CreateClientRootState } from './reducer';
import { StateSelector } from '@integration-frontends/common/utils/redux';

const selectCreateClientState: StateSelector<CreateClientState> = (state: CreateClientRootState) => state.createClientReducer;

export const selectCreateClientLoading = createSelector(selectCreateClientState, (state) => state.loading);

export const selectClientCreateSuccess = createSelector(selectCreateClientState, (state) => state.clientCreateSuccess);

export const selectClientEmailFormatError = createSelector(selectCreateClientState, (state) => state.emailFormatError);
