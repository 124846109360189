import { ButtonLooks, FontWeights, StandardButton, StandardText } from '@brandfolder/react';
import { IconClose, IconSize } from '@integration-frontends/common/ui';
import classNames from 'classnames';
import { ErrorBanner } from 'libs/workflow-manager/ui/src/common/error-banner/error-banner';
import React from 'react';
import './credential-modal.scss';

interface CredentialModalProps {
  modal?: boolean;
  onClose?: () => void;
  onSubmit?: (formData: any) => void;
  credentialError?: string;
  disableSubmit?: boolean;
  children: React.ReactElement<any, any>;
  nextText?: string;
}

export const CredentialModal = ({
  modal,
  onClose,
  onSubmit,
  credentialError,
  disableSubmit = false,
  children,
  nextText,
}: CredentialModalProps) => {
  return (
    <div data-testid="credential-modal" className={classNames('create-credential', { modal })}>
      <section className="create-credential-form-container">
        <div className="create-credential-form-head">
          <StandardText className="create-credential-form-head-text" weight={FontWeights.Bold}>
            Create new credential
          </StandardText>
          <IconClose className="cursor-pointer" iconSize={IconSize.Medium} onClick={onClose} />
        </div>
        {credentialError && (
          <div className="modal-error-container">
            <ErrorBanner credentialError={credentialError} header="Credential errors" />
          </div>
        )}
        <div className="create-credential-content">{children}</div>
        <div className="create-credential-button-container">
          <StandardButton
            onClick={onClose}
            data-testid="credential-modal-cancel"
            look={ButtonLooks.Tertiary}
          >
            Cancel
          </StandardButton>
          <StandardButton
            onClick={onSubmit}
            disabled={disableSubmit}
            data-testid="credential-modal-submit"
          >
            {nextText || 'Next'}
          </StandardButton>
        </div>
      </section>
    </div>
  );
};
