import { BFMultiSelectOption, BFSelectOption } from '@integration-frontends/common/ui';

export const alphabetizBfOptions = (options: BFSelectOption[] | BFMultiSelectOption[]) => {
  options?.sort((a, b) => {
    const labelA = String(a?.label).toLowerCase();
    const labelB = String(b?.label).toLowerCase();

    if (labelA < labelB) {
      return -1;
    } else if (labelA > labelB) {
      return 1;
    }

    return 0;
  });

  return options;
};
