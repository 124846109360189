import React, { useEffect } from 'react';
import { CreateWorkflowInputField } from '../../common/components/create-workflow-input-field/create-workflow-input-field';
import { useFormContext } from 'react-hook-form';
import { lettersAndNumberMatch } from '@integration-frontends/common/utils/validation';
import { BFErrorLabel } from '@integration-frontends/common/ui';

export interface GettyCredentialFormProps {
  credsComplete?: (c: boolean) => void;
  credFormData?: any;
}

export const GettyCredentialForm = ({ credsComplete, credFormData }: GettyCredentialFormProps) => {
  const {
    register,
    formState: { errors },
    setValue,
    watch,
  } = useFormContext();

  const credName = watch('credential_name');
  const clientKey = watch('client_key');
  const clientSecret = watch('client_secret');

  useEffect(() => {
    if (credsComplete && credFormData) {
      if (credName && clientKey && clientSecret) {
        credsComplete(true);
        credFormData({
          credential_name: credName,
          client_key: clientKey,
          client_secret: clientSecret,
        });
      } else {
        credsComplete(false);
      }
    }
  }, [credName, clientKey, clientSecret]);

  return (
    <>
      <CreateWorkflowInputField
        label={'Getty credentials name'}
        value={credName}
        inputId={'set-getty-cred-name'}
        tooltip="Give a descriptive name to this set of credentials."
        onChange={(e) => {
          setValue('credential_name', e.target.value || ('' as string));
        }}
        placeholder="Enter name"
        validation={register('credential_name', {
          required: true,
          maxLength: 50,
          minLength: 5,
          pattern: lettersAndNumberMatch,
        })}
      />
      {errors.credential_name?.type === 'required' && (
        <BFErrorLabel role="alert">Please choose a unique name for this credential.</BFErrorLabel>
      )}
      {errors.credential_name?.type === 'maxLength' ||
        (errors.credential_name?.type === 'minLength' && (
          <BFErrorLabel role="alert">Credential name must be 5-50 characters long</BFErrorLabel>
        ))}
      {errors.credential_name?.type === 'pattern' && (
        <BFErrorLabel role="alert">Please use only letters and numbers.</BFErrorLabel>
      )}

      <CreateWorkflowInputField
        label={'Getty API key'}
        placeholder="Enter API key"
        value={clientKey}
        inputId={'set-getty-client-key'}
        tooltip="Ask the client to provide the Getty API key."
        onChange={(e): void => {
          setValue('client_key', e.target.value || ('' as string));
        }}
        validation={register('client_key', { required: true })}
      />
      {errors.client_key?.type === 'required' && (
        <BFErrorLabel role="alert">Please verify the client key.</BFErrorLabel>
      )}

      <CreateWorkflowInputField
        label={'Getty secret key'}
        placeholder="Enter secret key"
        value={clientSecret}
        inputId={'set-getty-client-secret'}
        tooltip="Ask the client to provide the Getty secret API key."
        onChange={(e) => {
          setValue('client_secret', e.target.value || ('' as string));
        }}
        validation={register('client_secret', { required: true })}
      />
      {errors.client_secret?.type === 'required' && (
        <BFErrorLabel role="alert">Please verify the secret API key.</BFErrorLabel>
      )}
    </>
  );
};
