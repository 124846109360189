import { HeadingLevels, StandardHeading } from '@brandfolder/react';
import React from 'react';
import './banner.scss';

export const Banner = () => (
  <>
    <div className="workflow-manager-banner" data-testid="workflow-manager-banner">
      <StandardHeading className="main-heading" level={HeadingLevels.h1}>
        Integrations Workflow Manager
      </StandardHeading>
      <StandardHeading className="subheading" level={HeadingLevels.h2}>
        Add and manage client integrations and workflows.
      </StandardHeading>
      <div className="gradient-rule" />
    </div>
  </>
);
