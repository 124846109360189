import React from 'react';
import { OauthCredentialForm } from '../../common/components/oauth-credential-form/oauth-credential-form';

interface WrikeCredentialFormProps {
  credsComplete?: (c: boolean) => void;
  credFormData?: any;
}

export const WrikeCredentialForm = ({ credsComplete, credFormData }: WrikeCredentialFormProps) => {
  return (
    <OauthCredentialForm
      integrationName="Wrike"
      credFormData={credFormData}
      credsComplete={credsComplete}
    />
  );
};
