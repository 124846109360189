import React, { useEffect } from 'react';
import { StandardText } from '@brandfolder/react';
import { CreateWorkflowInputField } from '../../common/components/create-workflow-input-field/create-workflow-input-field';
import { useFormContext } from 'react-hook-form';
import { lettersAndNumberMatch } from '@integration-frontends/common/utils/validation';
import { BFErrorLabel } from '@integration-frontends/common/ui';

export interface HubspotCredentialFormProps {
  credsComplete?: (c: boolean) => void;
  credFormData?: any;
}

export const HubspotCredentialForm = ({
  credsComplete,
  credFormData,
}: HubspotCredentialFormProps) => {
  const {
    register,
    formState: { errors },
    setValue,
    watch,
  } = useFormContext();

  const credName = watch('credential_name');
  const privateAppKey = watch('private_app_key');

  useEffect(() => {
    if (credsComplete && credFormData) {
      if (credName && privateAppKey) {
        credsComplete(true);
        credFormData({
          credential_name: credName,
          private_app_key: privateAppKey,
        });
      } else {
        credsComplete(false);
      }
    }
  }, [credName, privateAppKey]);

  return (
    <>
      <StandardText
        data-testid="select-hubspot-cred-learn-how"
        className="select-hubspot-cred-learn-how"
      >
        Add the
        <span className="font-semibold"> files</span> and{' '}
        <span className="font-semibold">files.ui-hidden.read</span> scopes to HubSpot.{' '}
        <StandardText>
          <a
            className="font-semibold"
            style={{ color: '#4462c9', marginTop: '24px' }}
            href="https://developers.hubspot.com/docs/api/private-apps"
            target="_blank"
          >
            Learn how
          </a>
        </StandardText>
      </StandardText>

      <CreateWorkflowInputField
        label={'HubSpot credentials name'}
        placeholder={'Enter name'}
        value={credName}
        inputId={'set-hubspot-cred-name'}
        tooltip="Give a descriptive name to this set of credentials."
        onChange={(e) => {
          setValue('credential_name', e.target.value || ('' as string));
        }}
        validation={register('credential_name', {
          required: true,
          maxLength: 50,
          minLength: 5,
          pattern: lettersAndNumberMatch,
        })}
      />
      {errors.credential_name?.type === 'required' && (
        <BFErrorLabel role="alert">Please choose a unique name for this credential.</BFErrorLabel>
      )}
      {errors.credential_name?.type === 'maxLength' ||
        (errors.credential_name?.type === 'minLength' && (
          <BFErrorLabel role="alert">Credential name must be 5-50 characters long</BFErrorLabel>
        ))}
      {errors.credential_name?.type === 'pattern' && (
        <BFErrorLabel role="alert">Please use only letters and numbers.</BFErrorLabel>
      )}

      <CreateWorkflowInputField
        label={'HubSpot private app access token'}
        placeholder={'Enter access token'}
        value={privateAppKey}
        inputId={'set-hubspot-api-key'}
        onChange={(e) => {
          setValue('private_app_key', e.target.value || ('' as string));
        }}
        validation={register('private_app_key', { required: true })}
      />
      {errors.private_app_key?.type === 'required' && (
        <BFErrorLabel role="alert">Please verify the private app key.</BFErrorLabel>
      )}
    </>
  );
};
