import {
  GetBrandfolderFormInfoBody,
  GetHighspotFormInfoBody,
  GetHubspotFormInfoBody,
  GetWrikeFormInfoBody,
  GetSalsifyFormInfoBody,
  GetSeismicFormInfoBody,
  GetAsanaFormInfoBody,
} from '@integration-frontends/common/temporal-api';

import { Brandfolder } from './brandfolder';
import { CollectionData } from './collection';
import { SectionData } from './section';
import { CustomFieldKeyData } from './custom-field-key';

export const FORM_INFO_REPO_TOKEN = 'FORM_INFO_REPO';

// Highspot

export interface HighspotFormInfo {
  collection: { id: string; title: string }[];
}

// HubSpot

export interface HubspotCurrentFolder {
  id: string;
  name: string;
  parent_folder_id: string;
}

export interface HubspotFolder {
  id: string;
  name: string;
  parent_folder_id: string;
}

export interface HubspotFormInfo {
  children: {
    data: HubspotFolder[];
  };
  current_folder: HubspotCurrentFolder;
  siblings: { data: HubspotFolder[] };
  metadata: { total_count: number; total_pages: number };
}

// Asana

export interface AsanaResource {
  gid: string;
  name: string;
  resource_type: string;
}

export interface AsanaFormInfo {
  projects: { data: AsanaResource[] };
  sections: { data: AsanaResource[] };
  workspaces: { data: AsanaResource[] };
}

// Wrike

export interface WrikeFormInfo {
  resources: { childIds: string[]; id: string; space: boolean; title: string }[];
}

// Salsify
export const SALSIFY_ROLE_KEY = 'salsify:role';
export const SALSIFY_DATA_TYPE_KEY = 'salsify:data_type';
export const SALSIFY_NAME_KEY = 'salsify:name';
export const SALSIFY_SYSTEM_ID_KEY = 'salsify:system_id';

export interface SalsifyOrganization {
  id: string;
  name: string;
  system_id: string;
}

export type SalsifyDataMap = Record<string, string>;

export interface SalsifyProdctIdObj {
  id: string;
  name?: string;
}

export interface SalsifyDestConfigMapping {
  key: string;
  value: string;
  salsifyProp: string;
}
export interface SalsifyCustomFieldMapping {
  salsifyProp: string;
  key: string;
}

export interface SalsifyPropertiesByType {
  digitalAssetProps: SalsifyDataMap[];
  otherProps: SalsifyDataMap[];
  productIdProp: SalsifyDataMap;
}

export interface SalsifyFormInfo {
  organizations: SalsifyOrganization[];
  paginated_properties: {
    properties: SalsifyDataMap[];
    meta: {
      current_page: number;
      per_page: number;
      total_entries: number;
      total_pages: number;
    };
  };
}

export interface SalsifyFormInfoResponse {
  propertiesByType: SalsifyPropertiesByType;
  organizations: SalsifyOrganization[];
}

//  Seismic
export interface SeismicFolder {
  id: string;
  name: string;
  parentFolderId: string;
  type: string;
}

export interface SeismicFormInfo {
  children: SeismicFolder[];
  current_folder: SeismicFolder;
  siblings: SeismicFolder[];
  metadata: { total_count: number; total_pages: number };
}

// Brandfolder

export interface BrandfolderFormInfo {
  brandfolder_info: BrandfolderInfo[];
}

export interface BrandfolderInfo {
  collections: CollectionData;
  custom_fields: CustomFieldKeyData;
  sections: SectionData;
  id: string;
  name: string;
}

export interface IFormInfoRepo {
  getAsanaFormInfo: (clientId: string, attributes: GetAsanaFormInfoBody) => Promise<AsanaFormInfo>;
  getBrandfolderFormInfo: (
    clientId: string,
    attributes: GetBrandfolderFormInfoBody,
  ) => Promise<Brandfolder[]>;
  getCustomFieldFormInfo: (
    clientId: string,
    attributes: GetBrandfolderFormInfoBody,
  ) => Promise<BrandfolderFormInfo>;
  getHighspotFormInfo: (
    clientId: string,
    attributes: GetHighspotFormInfoBody,
  ) => Promise<HighspotFormInfo>;
  getHubspotFormInfo: (
    clientId: string,
    attributes: GetHubspotFormInfoBody,
  ) => Promise<HubspotFormInfo>;
  getWrikeFormInfo: (clientId: string, attributes: GetWrikeFormInfoBody) => Promise<WrikeFormInfo>;
  getSalsifyFormInfo: (
    clientId: string,
    attributes: GetSalsifyFormInfoBody,
  ) => Promise<SalsifyFormInfo>;
  getSeismicFormInfo: (
    clientId: string,
    attributes: GetSeismicFormInfoBody,
  ) => Promise<SeismicFormInfo>;
}
