import { inject, injectable } from 'inversify';
import { GET_API_KEY_TOKEN, IGetAuthToken } from './model';
import { TEMPORAL_API_TOKEN, TemporalApi } from '../../../../../common/temporal-api/src';

@injectable()
export class ServiceBase {
  @inject(GET_API_KEY_TOKEN)
  protected getApiKey: IGetAuthToken;
  @inject(TEMPORAL_API_TOKEN)
  protected temporalApi: TemporalApi;
}
