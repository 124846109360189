import { StandardLabel } from '@brandfolder/react';
import { BFErrorLabel, BFSelect, BFSelectOption } from '@integration-frontends/common/ui';
import { alphabetizBfOptions } from 'libs/workflow-manager/ui/src/common/functions/alphabetize';
import * as React from 'react';
import { useFormContext } from 'react-hook-form';
import './select-organization.scss';

export interface SelectOrganizationProps {
  salsifyOrgSources: BFSelectOption[];
  salsifyOrg: string;
}

export const SelectOrganization = ({
  salsifyOrgSources,
  salsifyOrg,
}: SelectOrganizationProps): React.ReactElement => {
  const {
    formState: { errors },
    register,
    setValue,
  } = useFormContext();

  return (
    <>
      <StandardLabel className="form-label salsify-org-label" htmlFor="select-salsify-organization">
        Salsify organization
      </StandardLabel>
      <BFSelect
        data-testid="select-salsify-organization"
        id="select-salsify-organization"
        required={true}
        options={(alphabetizBfOptions(salsifyOrgSources) as BFSelectOption[]) || []}
        {...register('org_id', { required: true })}
        onOptionChange={(listOption): void => {
          setValue('org_id', listOption?.value || ('' as string), {
            shouldValidate: true,
            shouldDirty: true,
          });
        }}
        value={salsifyOrgSources?.find((org) => org.value === salsifyOrg)?.value}
        placeholder={'Select Salsify Organization'}
      />
      {errors.workflow_name?.type === 'required' && (
        <BFErrorLabel role="alert">Salsify Organization is required</BFErrorLabel>
      )}
    </>
  );
};
