import React from 'react';
import { Credential } from '@integration-frontends/workflow-manager/core/model';
import './credential-select.scss';
import { FontWeights, ListboxOption, StandardCombobox, StandardText } from '@brandfolder/react';
import { selectSelectedCredentialId } from '@integration-frontends/workflow-manager/core/application';
import { useSelector } from 'react-redux';

interface CredentialSelectorProps {
  onOptionChange: (option: ListboxOption) => void;
  existingCredentials: Credential[];
}

export const CredentialSelect = ({
  onOptionChange,
  existingCredentials,
}: CredentialSelectorProps) => {
  const selectedCredentialId = useSelector(selectSelectedCredentialId);
  return (
    <section className="credential-select">
      <StandardText className="credential-select-header" weight={FontWeights.Bold}>
        Choose a credential
      </StandardText>
      <StandardCombobox
        labels={{
          iconButtonLabel: 'Toggle integration credential',
          label: 'Credential name',
          listboxLabel: 'List of credentials',
        }}
        disabled={existingCredentials?.length === 0}
        id="select-credentials"
        data-testid="credential-select"
        options={existingCredentials.map((option) => ({
          children: <>{option.credential_name}</>,
          key: option.id,
          value: option.id,
        }))}
        onSelection={onOptionChange}
        initialSelectedIndex={existingCredentials?.findIndex(
          (credential) => credential?.id === selectedCredentialId,
        )}
      />
    </section>
  );
};
