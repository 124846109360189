import React, { useEffect } from 'react';
import { CreateWorkflowInputField } from '../../common/components/create-workflow-input-field/create-workflow-input-field';
import { useFormContext } from 'react-hook-form';
import { lettersAndNumberMatch } from '@integration-frontends/common/utils/validation';
import { BFErrorLabel } from '@integration-frontends/common/ui';

export interface HighspotCredentialFormProps {
  credsComplete?: (c: boolean) => void;
  credFormData?: any;
}

export const HighspotCredentialForm = ({
  credsComplete,
  credFormData,
}: HighspotCredentialFormProps) => {
  const {
    register,
    formState: { errors },
    setValue,
    watch,
  } = useFormContext();

  const credName = watch('credential_name');
  const clientKey = watch('client_key');
  const clientSecret = watch('client_secret');
  const apiHost = watch('api_host');

  useEffect(() => {
    if (credsComplete && credFormData) {
      if (credName && clientKey && clientSecret && apiHost) {
        credsComplete(true);
        credFormData({
          api_host: apiHost,
          credential_name: credName,
          client_key: clientKey,
          client_secret: clientSecret,
        });
      } else {
        credsComplete(false);
      }
    }
  }, [credName, clientKey, clientSecret, apiHost]);

  return (
    <>
      <CreateWorkflowInputField
        label={'Highspot credentials name'}
        value={credName}
        inputId={'set-highspot-cred-name'}
        placeholder="Enter name"
        tooltip="Give a descriptive name to this set of credentials."
        onChange={(e): void => {
          setValue('credential_name', e.target.value || ('' as string));
        }}
        validation={register('credential_name', {
          required: true,
          maxLength: 50,
          minLength: 5,
          pattern: lettersAndNumberMatch,
        })}
      />
      {errors.credential_name?.type === 'required' && (
        <BFErrorLabel role="alert">Please choose a unique name for this credential.</BFErrorLabel>
      )}
      {errors.credential_name?.type === 'maxLength' ||
        (errors.credential_name?.type === 'minLength' && (
          <BFErrorLabel role="alert">Credential name must be 5-50 characters long</BFErrorLabel>
        ))}
      {errors.credential_name?.type === 'pattern' && (
        <BFErrorLabel role="alert">Please use only letters and numbers.</BFErrorLabel>
      )}

      <CreateWorkflowInputField
        label={'Highspot client key'}
        placeholder="Enter client key"
        value={clientKey}
        inputId={'set-highspot-client-key'}
        tooltip="Ask the client to ask their Highspot representative for the Highspot API key."
        onChange={(e): void => {
          setValue('client_key', e.target.value || ('' as string));
        }}
        validation={register('client_key', { required: true })}
      />
      {errors.client_key?.type === 'required' && (
        <BFErrorLabel role="alert">Please verify the client key.</BFErrorLabel>
      )}

      <CreateWorkflowInputField
        label={'Highspot client secret'}
        placeholder="Enter client secret"
        value={clientSecret}
        inputId={'set-highspot-secret-key'}
        tooltip="Ask the client to ask their Highspot representative for the Highspot secret key."
        onChange={(e) => {
          setValue('client_secret', e.target.value || ('' as string));
        }}
        validation={register('client_secret', { required: true })}
      />
      {errors.client_secret?.type === 'required' && (
        <BFErrorLabel role="alert">Please verify the secret API key.</BFErrorLabel>
      )}

      <CreateWorkflowInputField
        label={'Highspot API host'}
        value={apiHost}
        inputId={'set-highspot-api-host'}
        tooltip="The API host can vary between integrations. The default value is https://api.highspot.com/v0.5"
        placeholder="Enter API host"
        onChange={(e) => {
          setValue('api_host', e.target.value || ('' as string));
        }}
        validation={register('api_host', { required: true })}
      />
      {errors.api_host?.type === 'required' && (
        <BFErrorLabel role="alert">Please verify the API host.</BFErrorLabel>
      )}
    </>
  );
};
