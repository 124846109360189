import {
  CustomFieldAllowedValueOptions,
  HighspotSpot,
  HubspotFolder,
  SeismicSyncMap,
  WrikeFolder,
  WrikeSpace,
} from '@integration-frontends/common/temporal-api';
import { withPayloadType } from '@integration-frontends/common/utils/redux';
import {
  UpdateWorkflowBody,
  SalsifyOrganization,
  SalsifyDataMap,
  SalsifyProdctIdObj,
  SalsifyDestConfigMapping,
  SeismicFormInfo,
  HubspotFormInfo,
  SalsifyCustomFieldMapping,
  Credential,
  CustomFieldKeyDatum,
  WorkfrontProjectFormOption,
  WorkfrontFormOption,
  AsanaResource,
  activeSection,
} from '@integration-frontends/workflow-manager/core/model';
import { createAction } from '@reduxjs/toolkit';
import { workflowModes } from './reducer';

export const createClient = createAction(
  'CREATE_CLIENT',
  withPayloadType<{ clientName: string; bfApiKey: string }>(),
);

export const selectClient = createAction('SELECT_CLIENT', withPayloadType<{ clientId: string }>());

export const workflowPageInit = createAction('WORKFLOW_PAGE_INIT');
export const workflowPageLoaded = createAction('WORKFLOW_PAGE_LOADED');
export const setIntegrationType = createAction(
  'SELECT_INTEGRATION_TYPE',
  withPayloadType<{ integrationType: string }>(),
);

export const workflowFormLoading = createAction('WORKFLOW_FORM_LOADING');
export const workflowFormSuccess = createAction('WORKFLOW_FORM_SUCCESS');
export const workflowFormFailure = createAction('WORKFLOW_FORM_FAILURE');

export const createWorkflowInitialize = createAction('WORKFLOW_CREATE_INIT');
export const createWorkflowSuccess = createAction('WORKFLOW_CREATE_SUCCESS');
export const createWorkflowFailure = createAction(
  'WORKFLOW_CREATE_FAILURE',
  withPayloadType<{ error: string }>(),
);

export const workflowPageLoadError = createAction(
  'WORKFLOW_PAGE_ERROR',
  withPayloadType<{ error: string }>(),
);

export const setActiveSection = createAction(
  'SET_SECTION',
  withPayloadType<{ activeSection: activeSection }>(),
);

export const setWorkflowMode = createAction('SET_MODE', withPayloadType<{ mode: workflowModes }>());

export const createWorkflowInfoFormEntered = createAction('CREATE_WORKFLOW_INFO_FORM_ENTERED');

export const initEditWorkflow = createAction(
  'INIT_EDIT_WORKFLOW',
  withPayloadType<{
    workflowId: string;
    integrationType: string;
  }>(),
);

export const setEditWorkflowInProgress = createAction(
  'SET_EDIT_IN_PROGRESS',
  withPayloadType<{
    editWorkflowInProgress: boolean;
  }>(),
);

export const initReviewWorkflow = createAction(
  'INIT_REVIEW_WORKFLOW',
  withPayloadType<{
    workflowId: string;
    integrationType: string;
  }>(),
);

export const exitReviewWorkflow = createAction('EXIT_REVIEW_WORKFLOW');

export const setSelectedWorkflowId = createAction(
  'SET_SELECTED_WORKFLOW_ID',
  withPayloadType<{ workflowId: string }>(),
);

export const createWorkflow = createAction('CREATE_WORKFLOW');
export const updateWorkflow = createAction(
  'UPDATE_WORKFLOW',
  withPayloadType<{
    workflowId: string;
    totalAssetsAffected: number;
    data: UpdateWorkflowBody;
  }>(),
);

export const setSelectedCredentialId = createAction(
  'SET_SELECTED_CREDENTIAL',
  withPayloadType<{ credentialId: string }>(),
);

export const setBrandfolderFormInfo = createAction(
  'SET_BRANDFOLDER_FORM_INFO',
  withPayloadType<{ brandfolderFormInfo: any }>(),
);

export const getCustomFieldFormInfo = createAction(
  'GET_CUSTOM_FIELD_FORM_INFO',
  withPayloadType<{ brandfolderId: string }>(),
);

export const setRestrictedCustomFieldKeys = createAction(
  'SET_RESTRICTED_CUSTOM_FIELD_KEYS',
  withPayloadType<{ restrictedCustomFieldKeys: CustomFieldKeyDatum[] }>(),
);

export const setAllCustomFieldKeys = createAction(
  'SET_ALL_CUSTOM_FIELD_KEYS',
  withPayloadType<{ customFieldKeys: CustomFieldKeyDatum[] }>(),
);

export const setCustomFieldValueOptions = createAction(
  'SET_CUSTOM_FIELD_VALUE_OPTIONS',
  withPayloadType<{
    customFieldValues: CustomFieldAllowedValueOptions;
  }>(),
);

// HIGH SPOT

export const highspotReceiveSpots = createAction(
  'HIGHSPOT_SET_SPOTS',
  withPayloadType<{
    spots: HighspotSpot[];
  }>(),
);

export const highspotFetchSpots = createAction(
  'HIGHSPOT_FETCH_SPOTS',
  withPayloadType<{
    credential: Credential;
  }>(),
);

export const highspotSetSectionsToDelete = createAction(
  'HIGHSPOT_SET_SECTIONS_TO_DELETE',
  withPayloadType<{
    section_keys: string[];
  }>(),
);

export const highspotSetInitialSectionSelection = createAction(
  'HIGHSPOT_INITIAL_SECTION_SELECTION',
  withPayloadType<{
    initial_section_selection: string[];
  }>(),
);

export const highspotSetCollectionsToDelete = createAction(
  'HIGHSPOT_SET_COLLECTIONS_TO_DELETE',
  withPayloadType<{
    collection_keys: string[];
  }>(),
);

export const highspotSetInitialCollectionSelection = createAction(
  'HIGHSPOT_INITIAL_COLLECTION_SELECTION',
  withPayloadType<{
    initial_collection_selection: string[];
  }>(),
);

export const highspotSetCredentialId = createAction(
  'HIGHSPOT_SET_CREDENTIAL_ID',
  withPayloadType<{
    credentialId: string;
  }>(),
);

export const highspotSetFormData = createAction(
  'HIGHSPOT_SET_FORM_DATA',
  withPayloadType<{
    active: boolean;
    workflow_name: string;
    bf_source_key: string;
    selectedSections: string[];
    selectedCollections: string[];
    integration_id: string;
    spot_id: string;
  }>(),
);

// Getty

export const gettySetWorkflowName = createAction(
  'GETTY_SET_WORKFLOW_NAME',
  withPayloadType<{
    workflow_name: string;
  }>(),
);

export const gettySetStartDate = createAction(
  'GETTY_SET_START_DATE',
  withPayloadType<{
    sync_start_date: string;
  }>(),
);

export const gettySetEndDate = createAction(
  'GETTY_SET_END_DATE',
  withPayloadType<{
    sync_end_date: string;
  }>(),
);

export const gettySetSyncInterval = createAction(
  'GETTY_SET_SYNC_INTERVAL',
  withPayloadType<{
    cron_schedule: string;
  }>(),
);

export const gettySetSection = createAction(
  'GETTY_SET_SECTION',
  withPayloadType<{
    bf_destination_section: string;
  }>(),
);

export const gettySetCollection = createAction(
  'GETTY_SET_COLLECTION',
  withPayloadType<{
    bf_destination_collection: string;
  }>(),
);

export const gettySetBrandfolder = createAction(
  'GETTY_SET_BRANDFOLDER',
  withPayloadType<{
    bf_destination_key: string;
  }>(),
);

export const gettySetCredentialId = createAction(
  'GETTY_SET_CREDENTIAL_ID',
  withPayloadType<{
    credential_id: string;
  }>(),
);

export const gettySetResetLastSyncedDate = createAction(
  'GETTY_SET_RESET_LAST_SYNCED_DATE',
  withPayloadType<{
    reset: boolean;
  }>(),
);

export const gettySetFormData = createAction(
  'GETTY_SET_FORM_DATA',
  withPayloadType<{
    active: boolean;
    workflow_name: string;
    sync_start_date: string;
    sync_end_date: string;
    last_synced_date: string;
    allow_expired_license: boolean;
    cron_schedule: string;
    bf_destination_key: string;
    bf_destination_section: string;
    bf_destination_collection: string;
  }>(),
);

// HUBSPOT

export const hubspotReceiveFolders = createAction(
  'HUBSPOT_SET_FOLDERS',
  withPayloadType<{
    folders: HubspotFormInfo;
  }>(),
);

export const hubspotSetFolder = createAction(
  'HUBSPOT_SET_FOLDER',
  withPayloadType<{
    sync_folder: HubspotFolder;
  }>(),
);

export const hubspotSetPrivateAppKey = createAction(
  'HUBSPOT_SET_PRIVATE_APP_KEY',
  withPayloadType<{
    private_app_key: string;
  }>(),
);

export const hubspotSetExistingSectionSelection = createAction(
  'HUBSPOT_SET_EXISTING_SECTION_SELECTION',
  withPayloadType<{
    hubspot_existing_section_selection: string[];
  }>(),
);

export const hubspotFetchFolders = createAction(
  'HUBSPOT_FETCH_FOLDERS',
  withPayloadType<{
    credential_id: string;
    sync_folder_id?: string;
    page?: number;
  }>(),
);
export const hubspotFetchInit = createAction('HUBSPOT_FETCH_INIT');

export const hubspotFetchLoaded = createAction('HUBSPOT_FETCH_LOADED');

export const hubspotGetFolderById = createAction(
  'HUBSPOT_GET_FOLDER_BY_ID',
  withPayloadType<{
    credential_id: string;
    sync_folder_id?: string;
  }>(),
);

export const hubspotSetCredentialId = createAction(
  'HUBSPOT_SET_CREDENTIAL_ID',
  withPayloadType<{
    credential_id: string;
  }>(),
);

export const hubspotSetFormData = createAction(
  'HUBSPOT_SET_FORM_DATA',
  withPayloadType<{
    active: boolean;
    workflow_name: string;
    bf_source_key: string;
    section_keys: string[];
    collection_key: string;
    sync_folder_id: string;
  }>(),
);

// Wrike

export const wrikeSetCredentialId = createAction(
  'WRIKE_SET_CREDENTIAL_ID',
  withPayloadType<{
    credential_id: string;
  }>(),
);

export const wrikeReceiveSpaces = createAction(
  'WRIKE_SET_SPACES',
  withPayloadType<{
    spaces: WrikeSpace[];
  }>(),
);

export const wrikeOauthSignIn = createAction(
  'WRIKE_OAUTH_SIGN_IN',
  withPayloadType<{
    credential_id: string;
    integration_name: string;
    redirect_to: string;
  }>(),
);

export const wrikeFetchSpaces = createAction(
  'WRIKE_FETCH_SPACES',
  withPayloadType<{
    credential_id: string;
  }>(),
);

export const wrikeSetFormData = createAction(
  'WRIKE_SET_FORM_DATA',
  withPayloadType<{
    active: boolean;
    bf_destination_key: string;
    resource: WrikeFolder;
    resource_id: string;
    resource_type: string;
    recursive: boolean;
    collection_key: string;
    section_key: string;
    workflow_name: string;
  }>(),
);

// ASANA

export const asanaFetchInit = createAction('ASANA_FETCH_INIT');

export const asanaFetchLoaded = createAction('ASANA_FETCH_LOADED');

export const asanaRehydrateInfo = createAction(
  'ASANA_REHYDRATE_INFO',
  withPayloadType<{
    credential_id: string;
    workspace_id: string;
    project_id?: string;
    section_id?: string;
  }>(),
);

export const asanaOauthSignIn = createAction(
  'ASANA_OAUTH_SIGN_IN',
  withPayloadType<{
    credential_id: string;
    integration_name: string;
    redirect_to: string;
  }>(),
);

export const asanaSetCredentialId = createAction(
  'ASANA_SET_CREDENTIAL_ID',
  withPayloadType<{
    credential_id: string;
  }>(),
);

export const asanaFetchFormInfo = createAction(
  'ASANA_FETCH_FORM_INFO',
  withPayloadType<{
    clientId: string;
    credential_id: string;
    workspace_id?: string;
    project_id?: string;
    section_id?: string;
  }>(),
);

export const asanaReceiveFormInfo = createAction(
  'ASANA_RECEIVE_FORM_INFO',
  withPayloadType<{
    workspaces: AsanaResource[];
    projects: AsanaResource[];
    sections: AsanaResource[];
  }>(),
);

export const asanaSetWorkspacesLoading = createAction(
  'ASANA_SET_WORKSPACES_LOADING',
  withPayloadType<{
    workspacesLoading: boolean;
  }>(),
);

export const asanaSetProjectsLoading = createAction(
  'ASANA_SET_PROJECTS_LOADING',
  withPayloadType<{
    projectsLoading: boolean;
  }>(),
);

export const asanaSetSectionsLoading = createAction(
  'ASANA_SET_SECTIONS_LOADING',
  withPayloadType<{
    sectionsLoading: boolean;
  }>(),
);

export const asanaSetInitialTags = createAction(
  'ASANA_SET_INITIAL_TAGS',
  withPayloadType<{
    initial_tags: string[];
  }>(),
);

export const asanaClearFormInfo = createAction('ASANA_CLEAR_FORM_INFO');

export const asanaClearFormInfoSections = createAction('ASANA_CLEAR_FORM_INFO_SECTIONS');

export const asanaSetFormData = createAction(
  'ASANA_SET_FORM_DATA',
  withPayloadType<{
    active: boolean;
    workflow_name: string;
    bf_destination_key: string;
    bf_destination_section: string;
    bf_destination_collection: string;
    workspace_id: string;
    project_id: string;
    section_id: string;
    asset_name_template: string;
    attachment_name_template: string;
    tag_templates: string[];
    custom_field_map: {
      [key: string]: string;
    };
  }>(),
);

//SALSIFY

export const salsifyFetchInit = createAction('SALSIFY_WORKFLOW_FORM_ENTERED');

export const salsifyFetchInfo = createAction(
  'SALSIFY_FETCH_INFO',
  withPayloadType<{
    clientId: string;
    credential_id: string;
    salsify_org_id?: string;
  }>(),
);

export const salsifySetCredentialId = createAction(
  'SALSIFY_SET_CREDENTIAL_ID',
  withPayloadType<{
    credential_id: string;
  }>(),
);

export const salsifyRecieveOrganizations = createAction(
  'SALSIFY_SET_ORGANIZATIONS',
  withPayloadType<{
    organizations: SalsifyOrganization[];
  }>(),
);

export const salsifySetProductIdPropName = createAction(
  'SALSIFY_SET_PRODUCT_ID_PROP_NAME',
  withPayloadType<{
    productIdPropName: string;
  }>(),
);

export const salsifySelectOrganization = createAction(
  'SALSIFY_SELECT_ORGANIZATION',
  withPayloadType<{
    clientId: string;
    credential_id: string;
    org_id: string;
  }>(),
);

export const salsifySetProductId = createAction(
  'SALSIFY_SET_PRODUCT_ID',
  withPayloadType<{
    salsifyProductId: SalsifyProdctIdObj;
  }>(),
);

export const salsifySetDigitalAssetProps = createAction(
  'SALSIFY_SET_DIGITAL_ASSET_PROPS',
  withPayloadType<{
    salsifyDigitalAssetProps: SalsifyDataMap[];
  }>(),
);

export const salsifySetStandardProps = createAction(
  'SALSIFY_SET_STANDARD_PROPS',
  withPayloadType<{
    salsifyStandardProps: SalsifyDataMap[];
  }>(),
);

export const salsifySetExistingDestConfig = createAction(
  'SALSIFY_SET_EXISTING_DEST_CONFIG',
  withPayloadType<{
    destConfig: SalsifyDestConfigMapping[];
  }>(),
);

export const salsifySetExistingCustomFieldMap = createAction(
  'SALSIFY_SET_EXISTING_CUSTOM_FIELD_MAP',
  withPayloadType<{
    customFieldMap: SalsifyCustomFieldMapping[];
  }>(),
);

export const salsifySetFormData = createAction(
  'SALSIFY_SET_FORM_DATA',
  withPayloadType<{
    bf_source_key: string;
    brandfolder_identifier_custom_field: string;
    custom_field_map: { salsifyProp: string; key: string }[];
    dest_config: SalsifyDestConfigMapping[] | null;
    default_property: string;
    org_id: string;
    salsify_product_id: { id: string; name?: string };
    workflow_name: string;
  }>(),
);

export const salsifySetLoadingProperties = createAction(
  'SALSIFY_SET_LOADING_PROPERTIES',
  withPayloadType<{
    loadingProperties: boolean;
  }>(),
);

// Seismic

export const seismicFetchFolders = createAction(
  'SEISMIC_FETCH_FOLDERS',
  withPayloadType<{
    credential_id: string;
    folder_id?: string;
    teamsite_id: string;
    page?: number;
  }>(),
);

export const seismicFetchInit = createAction('SEISMIC_FETCH_INIT');

export const seismicFetchLoaded = createAction('SEISMIC_FETCH_LOADED');

export const seismicEditFetchInit = createAction('SEISMIC_EDIT_FETCH_INIT');

export const seismicEditFetchLoaded = createAction('SEISMIC_EDIT_FETCH_LOADED');

export const seismicReceiveFolders = createAction(
  'SEISMIC_SET_FOLDERS',
  withPayloadType<{
    seismicFormInfo: SeismicFormInfo;
  }>(),
);

export const seismicSetCurrentFolderName = createAction(
  'SEISMIC_SET_CURRENT_FOLDER_NAME',
  withPayloadType<{
    currentFolderName: string;
  }>(),
);

export const seismicSetCredentialId = createAction(
  'SEISMIC_SET_CREDENTIAL_ID',
  withPayloadType<{
    credential_id: string;
  }>(),
);

export const seismicRehydrateSyncMaps = createAction(
  'SEIMSIC_REHYDRATE_SYNC_MAPS',
  withPayloadType<{
    bf_info: any;
    credential_id: string;
    sync_map_object: { [src_id: string]: string };
    teamsite_id: string;
    sync_map_type: 'section_to_folder' | 'collection_to_folder';
  }>(),
);

export const seismicRehydrateSingleDestination = createAction(
  'SEIMSIC_REHYDRATE_SINGLE_DESTINATION',
  withPayloadType<{
    credential_id: string;
    teamsite_id: string;
    folder_id: string;
  }>(),
);

export const seismicRehydratedSectionMaps = createAction(
  'SEISMIC_REHYDRATED_SECTION_MAPS',
  withPayloadType<{
    maps: SeismicSyncMap[];
  }>(),
);

export const seismicRehydratedCollectionMaps = createAction(
  'SEISMIC_REHYDRATED_COLLECTION_MAPS',
  withPayloadType<{
    maps: SeismicSyncMap[];
  }>(),
);

export const seismicSetFolderId = createAction(
  'SEISMIC_SET_FOLDER_ID',
  withPayloadType<{
    folder_id: string;
  }>(),
);

export const seismicSetExistingSectionMaps = createAction(
  'SEISMIC_SET_EXISTING_SECTION_MAPS',
  withPayloadType<{
    seismic_existing_section_maps: any;
  }>(),
);

export const seismicSetExistingCollectionMaps = createAction(
  'SEISMIC_SET_EXISTING_COLLECTION_MAPS',
  withPayloadType<{
    seismic_existing_collection_maps: any;
  }>(),
);

export const seismicSetSyncEntireBrandfolderOptionSelected = createAction(
  'SEISMIC_SET_SYNC_ENTIRE_BRANDFOLDER_OPTION_SELECTED',
  withPayloadType<{
    value: boolean;
  }>(),
);

export const seismicSetSyncOptionalResourceOptionSelected = createAction(
  'SEISMIC_SET_SYNC_OPTIONAL_RESOURCE_OPTION_SELECTED',
  withPayloadType<{
    value: boolean;
  }>(),
);

export const seismicSetFormData = createAction(
  'SEISMIC_SET_FORM_DATA',
  withPayloadType<{
    active: boolean;
    bf_source_key: string;
    collection_to_folder_map?: SeismicSyncMap[];
    folder_id: string;
    section_to_folder_map?: SeismicSyncMap[];
    teamsite_id: string;
    workflow_name: string;
  }>(),
);

// Workfront

export const workfrontSetFormData = createAction(
  'WORKFRONT_SET_FORM_DATA',
  withPayloadType<{
    active: boolean;
    workflow_name: string;
    workfront_forms: WorkfrontFormOption[];
    workfront_project_forms: WorkfrontProjectFormOption[];
    document_custom_fields: boolean;
    user_custom_fields: boolean;
    project_custom_fields: boolean;
    portfolio_custom_fields: boolean;
    program_custom_fields: boolean;
    tags: string[];
    description_link: boolean;
    email: string;
    host: string;
    subdomain: string;
    client_id: string;
    user_id: string;
    customer_id: string;
    client_secret: string;
  }>(),
);
