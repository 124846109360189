import { withPayloadType } from '@integration-frontends/common/utils/redux';
import { createAction } from '@reduxjs/toolkit';

export const createClientInitialize = createAction('CLIENT_CREATE_INITIALIZE');
export const createClientSuccess = createAction('CLIENT_CREATE_SUCCESS');
export const createClientFailure = createAction('CLIENT_CREATE_FAILURE');
export const createClientEmailFormatError = createAction(
  'CLIENT_CREATE_EMAIL_FORMAT_ERROR',
  withPayloadType<{
    emailFormatError: boolean;
  }>(),
);
