import { put, select, takeEvery } from 'typed-redux-saga';
import { createNotification, NotificationType } from '@integration-frontends/common/notifications';
import {
  callWithTokenRefresh,
  seismicEditFetchInit,
  seismicEditFetchLoaded,
  seismicRehydrateSingleDestination,
  seismicSetCurrentFolderName,
  selectSelectedClientId,
} from '@integration-frontends/workflow-manager/core/application';
import {
  FORM_INFO_REPO_TOKEN,
  IFormInfoRepo,
} from '@integration-frontends/workflow-manager/core/model';
import { DI_CONTAINER } from '@integration-frontends/core';
import { pageLoadError } from '@integration-frontends/common/app';

function* handler(action: ReturnType<typeof seismicRehydrateSingleDestination>) {
  yield put(seismicEditFetchInit());
  try {
    const { credential_id, teamsite_id, folder_id } = action.payload;
    const formInfoRepo: IFormInfoRepo = DI_CONTAINER.get(FORM_INFO_REPO_TOKEN);
    const clientId = yield select(selectSelectedClientId);
    const FOLDERS_PER_PAGE = 20;

    const folderInfo = yield callWithTokenRefresh(formInfoRepo.getSeismicFormInfo, clientId, {
      credential_id,
      folder_id,
      teamsite_id,
      per: FOLDERS_PER_PAGE,
      page: 1,
    });

    if (folderInfo) {
      yield put(seismicSetCurrentFolderName({ currentFolderName: folderInfo?.current_folder?.name }));
      yield put(seismicEditFetchLoaded);
    } else {
      yield put(
        createNotification({
          message: 'Failed to recover Seismic Folders',
          location: 'topLevel',
          type: NotificationType.Error,
        }),
      );
    }
  } catch (e) {
    yield put(pageLoadError({ error: e }));
    yield put(seismicEditFetchLoaded);
  }
}

export function* seismicRehydrateSingleDestinationEffects() {
  yield takeEvery(seismicRehydrateSingleDestination, handler);
}
