import { createReducer } from '@reduxjs/toolkit';
import cronTime from 'cron-time-generator';
import {
  AsanaResource,
  CustomFieldKeyDatum,
  SalsifyCustomFieldMapping,
  SalsifyDataMap,
  SalsifyDestConfigMapping,
  SalsifyOrganization,
  SalsifyProdctIdObj,
  workflowServiceType,
} from '@integration-frontends/workflow-manager/core/model';
import {
  selectClient,
  workflowPageInit,
  workflowPageLoaded,
  workflowPageLoadError,
  setActiveSection,
  highspotReceiveSpots,
  highspotSetFormData,
  highspotSetInitialSectionSelection,
  highspotSetInitialCollectionSelection,
  gettySetWorkflowName,
  gettySetStartDate,
  gettySetEndDate,
  gettySetSyncInterval,
  gettySetSection,
  gettySetCollection,
  gettySetBrandfolder,
  gettySetCredentialId,
  setSelectedCredentialId,
  setBrandfolderFormInfo,
  workflowFormLoading,
  createWorkflowInitialize,
  createWorkflowSuccess,
  createWorkflowFailure,
  workflowFormSuccess,
  workflowFormFailure,
  setIntegrationType,
  highspotSetCredentialId,
  gettySetFormData,
  hubspotSetFormData,
  hubspotReceiveFolders,
  hubspotSetFolder,
  hubspotSetCredentialId,
  setWorkflowMode,
  setSelectedWorkflowId,
  initEditWorkflow,
  hubspotSetPrivateAppKey,
  hubspotSetExistingSectionSelection,
  wrikeSetCredentialId,
  wrikeOauthSignIn,
  wrikeReceiveSpaces,
  wrikeSetFormData,
  salsifyRecieveOrganizations,
  setRestrictedCustomFieldKeys,
  salsifySelectOrganization,
  setCustomFieldValueOptions,
  highspotSetSectionsToDelete,
  highspotSetCollectionsToDelete,
  seismicSetFormData,
  seismicReceiveFolders,
  seismicRehydratedSectionMaps,
  seismicRehydratedCollectionMaps,
  seismicSetCredentialId,
  salsifySetFormData,
  salsifySetCredentialId,
  salsifySetDigitalAssetProps,
  seismicFetchLoaded,
  seismicFetchInit,
  salsifySetExistingDestConfig,
  hubspotFetchLoaded,
  hubspotFetchInit,
  seismicEditFetchInit,
  seismicEditFetchLoaded,
  seismicSetExistingSectionMaps,
  seismicSetExistingCollectionMaps,
  salsifySetExistingCustomFieldMap,
  setAllCustomFieldKeys,
  salsifySetStandardProps,
  workfrontSetFormData,
  initReviewWorkflow,
  exitReviewWorkflow,
  asanaSetCredentialId,
  asanaOauthSignIn,
  asanaReceiveFormInfo,
  asanaClearFormInfo,
  asanaClearFormInfoSections,
  asanaSetFormData,
  asanaFetchInit,
  asanaFetchLoaded,
  asanaSetInitialTags,
  asanaSetProjectsLoading,
  asanaSetSectionsLoading,
  asanaSetWorkspacesLoading,
  gettySetResetLastSyncedDate,
  setEditWorkflowInProgress,
  seismicSetSyncEntireBrandfolderOptionSelected,
  seismicSetSyncOptionalResourceOptionSelected,
  seismicSetCurrentFolderName,
  salsifySetProductIdPropName,
  salsifySetLoadingProperties,
  salsifyFetchInit,
} from './actions';
import {
  SeismicSyncMap,
  CustomFieldAllowedValueOptions,
  WrikeFolder,
} from '@integration-frontends/common/temporal-api';
import { HubspotFolder } from '@integration-frontends/common/temporal-api';

export enum workflowModes {
  create = 'create',
  edit = 'edit',
  review = 'review',
}

/**
 * Many of the workflow interfaces use a mix of camelCase and snake_case.
 *
 * The reason for snake_case is to match fields as closely as possible to the request body of the API,
 * which is snake_case. In generaly, camelCase is used as per normal Typescript convention
 * for non-request body fields.
 *
 * Additionally, developers will notice that many of the names provided to the `watch(...)` hooks in
 * form component files use the same snake_case names as the workflow interfaces defined here.
 * This is intentionaly because the react-hook-form library requires that the field names match the
 * name of the input element in the form.
 *
 * ~TC 2024-01-05
 */

export interface CreateWorkflowState {
  loading: boolean;
  pageLoadError: string;
  createWorkflowError: string;
  createWorkflowSuccess: boolean;
  selectedClientId: string;
  selectedCredentialId: string;
  selectedIntegrationType: string;
  uploading: boolean;
  asana: AsanaWorkflowFormState;
  highspot: HighspotWorkflowFormState;
  getty: GettyWorkflowFormState;
  hubspot: HubspotWorkflowFormState;
  wrike: WrikeWorkflowFormState;
  salsify: SalsifyWorkflowFormState;
  seismic: SeismicWorkflowFormState;
  workfront: WorkfrontWorkflowFormState;
  activeSection: string;
  credential: Credential | null;
  brandfolderFormInfo: any;
  customFieldRestrictedKeys: CustomFieldKeyDatum[];
  allCustomFieldKeys: CustomFieldKeyDatum[];
  customFieldValueOptions: CustomFieldAllowedValueOptions;
  mode: workflowModes;
  selectedWorkflowId: string;
  editWorkflowInProgress: boolean;
}

export interface HighspotWorkflowFormState {
  active: boolean;
  bf_source_key: string;
  client_id: string;
  workflow_name: string;
  collection_to_folder_map: {
    [key: string]: string;
  };
  initial_collection_selection: string[];
  credential_id: string;
  integration_id: string;
  section_to_folder_map: {
    [key: string]: string;
  };
  initial_section_selection: string[];
  selectedSections: string[];
  section_keys_to_delete: string[];
  selectedCollections: string[];
  collection_keys_to_delete: string[];
  spot_id: string;
  selected_section_id: string;
  spots: any;
}

export const highspotWorkflowInitialState: HighspotWorkflowFormState = {
  active: false,
  bf_source_key: null,
  selected_section_id: null,
  client_id: null,
  credential_id: null,
  workflow_name: '',
  collection_to_folder_map: {},
  integration_id: '',
  section_to_folder_map: {},
  selectedSections: [],
  initial_section_selection: null,
  section_keys_to_delete: [],
  selectedCollections: [],
  initial_collection_selection: null,
  collection_keys_to_delete: [],
  spot_id: null,
  spots: [],
};

export interface GettyWorkflowFormState {
  active: boolean;
  allow_expired_license: boolean;
  bf_destination_collection: string;
  bf_destination_key: string;
  bf_destination_section: string;
  credential_id: string;
  cron_schedule: string;
  sync_start_date: string;
  sync_end_date: string;
  last_synced_date: string;
  reset_last_synced_date: boolean;
  workflow_name: string;
}

export const gettyWorkflowInitialState: GettyWorkflowFormState = {
  active: false,
  allow_expired_license: false,
  bf_destination_collection: null,
  bf_destination_key: null,
  bf_destination_section: null,
  credential_id: null,
  cron_schedule: cronTime.everyDay(),
  sync_start_date: null,
  sync_end_date: '2100-01-01',
  last_synced_date: null,
  reset_last_synced_date: false,
  workflow_name: null,
};

export interface HubspotWorkflowFormState {
  active: boolean;
  bf_source_key: string;
  client_id: string;
  workflow_name: string;
  credential_id: string;
  integration_id: string;
  private_app_key: string;
  section_to_folder_map: {
    [key: string]: string;
  };
  section_keys: string[];
  hubspot_existing_section_selection: string[];
  collection_key: string;
  sync_folder_id: string;
  sync_folder: HubspotFolder;
  selected_section_id: string;
  folders: any;
  loading: boolean;
}

export const hubspotWorkflowInitialState: HubspotWorkflowFormState = {
  active: false,
  bf_source_key: null,
  selected_section_id: null,
  client_id: null,
  credential_id: null,
  workflow_name: null,
  integration_id: null,
  private_app_key: null,
  section_to_folder_map: null,
  section_keys: null,
  hubspot_existing_section_selection: null,
  collection_key: null,
  sync_folder_id: 'None',
  sync_folder: null,
  folders: null,
  loading: true,
};

export interface WrikeWorkflowFormState {
  active: boolean;
  editing: boolean;
  bf_destination_key: string;
  workflow_name: string;
  credential_id: string;
  spaces: any;
  resource: WrikeFolder;
  resource_id: string;
  resource_type: string;
  recursive: boolean;
  collection_key: string;
  section_key: string;
}

export const wrikeWorkflowInitialState: WrikeWorkflowFormState = {
  active: false,
  editing: false,
  bf_destination_key: null,
  workflow_name: null,
  credential_id: null,
  spaces: null,
  resource: null,
  resource_id: null,
  resource_type: null,
  recursive: false,
  collection_key: null,
  section_key: null,
};

export interface AsanaWorkflowFormState {
  active: boolean;
  editing: boolean;
  workflow_name: string;
  credential_id: string;
  workspaces: AsanaResource[];
  projects: AsanaResource[];
  sections: AsanaResource[];
  workspacesLoading: boolean;
  projectsLoading: boolean;
  sectionsLoading: boolean;
  workspace_id: string;
  project_id: string;
  section_id: string;
  asset_name_template: string;
  attachment_name_template: string;
  tag_templates: string[];
  initial_tags: string[];
  custom_field_map: {
    [key: string]: string;
  };
  bf_destination_key: string;
  bf_destination_section: string;
  bf_destination_collection: string;
  sync_on_complete: boolean;
  loading: boolean;
}

export const asanaWorkflowInitialState: AsanaWorkflowFormState = {
  active: false,
  editing: false,
  workflow_name: null,
  credential_id: null,
  workspaces: null,
  projects: null,
  sections: null,
  workspacesLoading: false,
  projectsLoading: false,
  sectionsLoading: false,
  workspace_id: null,
  project_id: null,
  section_id: null,
  asset_name_template: null,
  attachment_name_template: null,
  tag_templates: [],
  initial_tags: [],
  custom_field_map: {},
  bf_destination_key: null,
  bf_destination_section: null,
  bf_destination_collection: null,
  sync_on_complete: true, // default to true
  loading: false,
};

export interface SalsifyWorkflowFormState {
  credential_id: string;
  digitalAssetProps: SalsifyDataMap[];
  standardProps: SalsifyDataMap[];
  organizations: SalsifyOrganization[];
  salsify_product_id: SalsifyProdctIdObj;
  productIdPropName: string;
  bf_source_key: string;
  brandfolder_identifier_custom_field: string;
  default_property: string;
  org_id: string;
  workflow_name: string;
  existingDestConfig: SalsifyDestConfigMapping[];
  existingCustomFieldMap: SalsifyCustomFieldMapping[];
  dest_config: SalsifyDestConfigMapping[];
  custom_field_map: SalsifyCustomFieldMapping[];
  loadingProperties: boolean;
}

export const SalsifyWorkflowInitialState: SalsifyWorkflowFormState = {
  credential_id: null,
  digitalAssetProps: null,
  standardProps: null,
  organizations: null,
  salsify_product_id: null,
  productIdPropName: null,
  bf_source_key: null,
  brandfolder_identifier_custom_field: null,
  default_property: null,
  org_id: null,
  workflow_name: '',
  existingCustomFieldMap: null,
  existingDestConfig: null,
  dest_config: [],
  custom_field_map: null,
  loadingProperties: false,
};

export interface SeismicWorkflowFormState {
  active: boolean;
  bf_source_key: string;
  seismic_existing_collection_maps?: any;
  collection_to_folder_map?: SeismicSyncMap[];
  rehydrated_collection_maps?: SeismicSyncMap[];
  folder_id: string; // folder ID if entire BF is synced to just one Seismic folder
  currentFolderName?: string; // folder name if entire BF is synced to just one Seismic folder
  seismic_existing_section_maps?: any;
  section_to_folder_map?: SeismicSyncMap[];
  rehydrated_section_maps?: SeismicSyncMap[];
  workflow_name: string;
  seismic_folders?: any;
  credential_id: string;
  teamsite_id: string;
  loading: boolean;
  loading_edit: boolean;
  syncEntireBrandfolderOptionSelected?: boolean;
  syncOptionalResourceOptionSelected?: boolean;
}

export const SeismicWorkflowInitialState: SeismicWorkflowFormState = {
  active: false,
  bf_source_key: '',
  collection_to_folder_map: null,
  rehydrated_collection_maps: null,
  seismic_existing_collection_maps: null,
  folder_id: null,
  currentFolderName: null,
  seismic_existing_section_maps: null,
  section_to_folder_map: null,
  rehydrated_section_maps: null,
  workflow_name: null,
  credential_id: null,
  teamsite_id: null,
  loading: true,
  loading_edit: false,
  syncEntireBrandfolderOptionSelected: false,
  syncOptionalResourceOptionSelected: false,
};

export interface WorkfrontWorkflowFormState {
  active: boolean;
  workflow_name: string;
  document_custom_fields: boolean;
  user_custom_fields: boolean;
  project_custom_fields: boolean;
  portfolio_custom_fields: boolean;
  program_custom_fields: boolean;
  tags: string[];
  description_link: boolean;
  host: string;
  client_id: string;
  client_secret: string;
  user_id: string;
  customer_id: string;
}

export const WorkfrontWorkflowInitialState: WorkfrontWorkflowFormState = {
  active: false,
  workflow_name: null,
  document_custom_fields: false,
  user_custom_fields: false,
  project_custom_fields: false,
  portfolio_custom_fields: false,
  program_custom_fields: false,
  tags: null,
  description_link: false,
  host: null,
  client_id: null,
  client_secret: null,
  user_id: null,
  customer_id: null,
};

export const createWorkflowInitialState: CreateWorkflowState = {
  loading: false,
  pageLoadError: '',
  createWorkflowError: '',
  createWorkflowSuccess: false,
  selectedClientId: null,
  selectedCredentialId: null,
  selectedIntegrationType: null,
  uploading: false,
  asana: asanaWorkflowInitialState,
  highspot: highspotWorkflowInitialState,
  getty: gettyWorkflowInitialState,
  hubspot: hubspotWorkflowInitialState,
  wrike: wrikeWorkflowInitialState,
  salsify: SalsifyWorkflowInitialState,
  seismic: SeismicWorkflowInitialState,
  workfront: WorkfrontWorkflowInitialState,
  credential: null,
  activeSection: 'app-info',
  brandfolderFormInfo: null,
  allCustomFieldKeys: null,
  customFieldRestrictedKeys: null,
  customFieldValueOptions: null,
  mode: workflowModes.create,
  selectedWorkflowId: null,
  editWorkflowInProgress: false,
};

export const createWorkflowReducer = createReducer(createWorkflowInitialState, (builder) =>
  builder
    // client
    .addCase(selectClient, (state, action) => {
      state.selectedClientId = action.payload.clientId;
    })

    // workflow page
    .addCase(workflowPageInit, (state) => {
      state.pageLoadError = '';
      state.loading = true;
      state.createWorkflowSuccess = false;
      state.activeSection = 'integration-info';
      state.highspot = highspotWorkflowInitialState;
      state.getty = gettyWorkflowInitialState;
      state.hubspot = hubspotWorkflowInitialState;
      state.wrike = wrikeWorkflowInitialState;
      state.seismic = SeismicWorkflowInitialState;
      state.asana = asanaWorkflowInitialState;
      state.selectedWorkflowId = null;
      state.selectedCredentialId = null;
      state.selectedIntegrationType = null;
    })
    .addCase(setIntegrationType, (state, action) => {
      state.selectedIntegrationType = action.payload.integrationType;
    })
    .addCase(workflowPageLoaded, (state) => {
      state.loading = false;
    })
    .addCase(workflowPageLoadError, (state, action) => {
      state.pageLoadError = action.payload.error;
    })
    .addCase(setWorkflowMode, (state, action) => {
      state.mode = action.payload.mode;
    })
    .addCase(setSelectedWorkflowId, (state, action) => {
      state.selectedWorkflowId = action.payload.workflowId;
    })

    // workflow wizard
    .addCase(workflowFormLoading, (state) => {
      state.loading = true;
    })
    .addCase(workflowFormSuccess, (state) => {
      state.loading = false;
    })
    .addCase(workflowFormFailure, (state) => {
      state.loading = false;
    })
    .addCase(setActiveSection, (state, action) => {
      state.activeSection = action.payload.activeSection;
    })
    .addCase(setSelectedCredentialId, (state, action) => {
      state.selectedCredentialId = action.payload.credentialId;
    })
    .addCase(setBrandfolderFormInfo, (state, action) => {
      state.brandfolderFormInfo = action.payload;
    })
    .addCase(setRestrictedCustomFieldKeys, (state, action) => {
      state.customFieldRestrictedKeys = action.payload.restrictedCustomFieldKeys;
    })
    .addCase(setAllCustomFieldKeys, (state, action) => {
      state.allCustomFieldKeys = action.payload.customFieldKeys;
    })
    .addCase(setCustomFieldValueOptions, (state, action) => {
      state.customFieldValueOptions = action.payload.customFieldValues;
    })
    .addCase(setEditWorkflowInProgress, (state, action) => {
      state.editWorkflowInProgress = action.payload.editWorkflowInProgress;
    })
    .addCase(initEditWorkflow, (state, action) => {
      const { integrationType, workflowId } = action.payload;
      state.loading = true;
      state.mode = workflowModes.edit;
      state.activeSection = 'workflow-info';
      state.selectedIntegrationType = integrationType as workflowServiceType;
      state.selectedWorkflowId = workflowId;
      state.editWorkflowInProgress = false;
    })
    .addCase(initReviewWorkflow, (state, action) => {
      const { integrationType, workflowId } = action.payload;
      state.loading = true;
      state.mode = workflowModes.review;
      state.selectedIntegrationType = integrationType as workflowServiceType;
      state.selectedWorkflowId = workflowId;
    })
    .addCase(exitReviewWorkflow, (state) => {
      state.mode = workflowModes.create;
    })

    // publish workflow
    .addCase(createWorkflowInitialize, (state) => {
      state.createWorkflowError = '';
      state.createWorkflowSuccess = false;
      state.loading = false;
    })
    .addCase(createWorkflowSuccess, (state) => {
      state.loading = false;
      state.createWorkflowSuccess = true;
    })
    .addCase(createWorkflowFailure, (state, action) => {
      state.createWorkflowError = action.payload.error;
      state.loading = false;
    })

    //Highspot
    .addCase(highspotReceiveSpots, (state, action) => {
      state.highspot.spots = action.payload.spots;
    })
    .addCase(highspotSetCredentialId, (state, action) => {
      state.highspot.credential_id = action.payload.credentialId;
    })
    .addCase(highspotSetSectionsToDelete, (state, action) => {
      state.highspot.section_keys_to_delete = action.payload.section_keys;
    })
    .addCase(highspotSetInitialSectionSelection, (state, action) => {
      state.highspot.initial_section_selection = action.payload.initial_section_selection;
    })
    .addCase(highspotSetCollectionsToDelete, (state, action) => {
      state.highspot.collection_keys_to_delete = action.payload.collection_keys;
    })
    .addCase(highspotSetInitialCollectionSelection, (state, action) => {
      state.highspot.initial_collection_selection = action.payload.initial_collection_selection;
    })

    .addCase(highspotSetFormData, (state, action) => {
      const {
        active,
        workflow_name,
        bf_source_key,
        selectedSections,
        selectedCollections,
        integration_id,
        spot_id,
      } = action.payload;

      state.highspot.active = active;
      state.highspot.workflow_name = workflow_name;
      state.highspot.bf_source_key = bf_source_key;
      state.highspot.selectedSections = selectedSections;
      state.highspot.selectedCollections = selectedCollections;
      state.highspot.integration_id = integration_id;
      state.highspot.spot_id = spot_id;
    })

    // Getty
    .addCase(gettySetWorkflowName, (state, action) => {
      state.getty.workflow_name = action.payload.workflow_name;
    })
    .addCase(gettySetStartDate, (state, action) => {
      state.getty.sync_start_date = action.payload.sync_start_date;
    })
    .addCase(gettySetEndDate, (state, action) => {
      state.getty.sync_end_date = action.payload.sync_end_date;
    })
    .addCase(gettySetSyncInterval, (state, action) => {
      state.getty.cron_schedule = action.payload.cron_schedule;
    })
    .addCase(gettySetSection, (state, action) => {
      state.getty.bf_destination_section = action.payload.bf_destination_section;
    })
    .addCase(gettySetCollection, (state, action) => {
      state.getty.bf_destination_collection = action.payload.bf_destination_collection;
    })
    .addCase(gettySetBrandfolder, (state, action) => {
      state.getty.bf_destination_key = action.payload.bf_destination_key;
    })
    .addCase(gettySetCredentialId, (state, action) => {
      state.getty.credential_id = action.payload.credential_id;
    })
    .addCase(gettySetResetLastSyncedDate, (state, action) => {
      state.getty.reset_last_synced_date = action.payload.reset;
    })
    .addCase(gettySetFormData, (state, action) => {
      const {
        active,
        workflow_name,
        sync_start_date,
        sync_end_date,
        last_synced_date,
        allow_expired_license,
        cron_schedule,
        bf_destination_key,
        bf_destination_section,
        bf_destination_collection,
      } = action.payload;

      state.getty.active = active;
      state.getty.workflow_name = workflow_name;
      state.getty.sync_start_date = sync_start_date;
      state.getty.sync_end_date = sync_end_date;
      state.getty.last_synced_date = last_synced_date;
      state.getty.allow_expired_license = allow_expired_license;
      state.getty.cron_schedule = cron_schedule;
      state.getty.bf_destination_key = bf_destination_key;
      state.getty.bf_destination_section = bf_destination_section;
      state.getty.bf_destination_collection = bf_destination_collection;
    })

    // HubSpot
    .addCase(hubspotReceiveFolders, (state, action) => {
      state.hubspot.folders = action.payload.folders;
    })
    .addCase(hubspotSetFolder, (state, action) => {
      state.hubspot.sync_folder = action.payload.sync_folder;
    })
    .addCase(hubspotSetCredentialId, (state, action) => {
      state.hubspot.credential_id = action.payload.credential_id;
    })
    .addCase(hubspotSetPrivateAppKey, (state, action) => {
      state.hubspot.private_app_key = action.payload.private_app_key;
    })
    .addCase(hubspotFetchInit, (state) => {
      state.hubspot.loading = true;
    })
    .addCase(hubspotFetchLoaded, (state) => {
      state.hubspot.loading = false;
    })
    .addCase(hubspotSetExistingSectionSelection, (state, action) => {
      state.hubspot.hubspot_existing_section_selection =
        action.payload.hubspot_existing_section_selection;
    })
    .addCase(hubspotSetFormData, (state, action) => {
      const { active, workflow_name, bf_source_key, section_keys, collection_key, sync_folder_id } =
        action.payload;

      state.hubspot.active = active;
      state.hubspot.workflow_name = workflow_name;
      state.hubspot.bf_source_key = bf_source_key;
      state.hubspot.section_keys = section_keys;
      state.hubspot.collection_key = collection_key;
      state.hubspot.sync_folder_id = sync_folder_id;
    })

    // Asana
    .addCase(asanaSetCredentialId, (state, action) => {
      state.asana.credential_id = action.payload.credential_id;
    })
    .addCase(asanaOauthSignIn, (state, action) => {
      const { credential_id } = action.payload;
      state.asana.credential_id = credential_id;
    })
    .addCase(asanaReceiveFormInfo, (state, action) => {
      state.asana.workspaces = action.payload.workspaces;
      state.asana.projects = action.payload.projects;
      state.asana.sections = action.payload.sections;

      // reset the loading states to default values
      state.asana.workspacesLoading = false;
      state.asana.projectsLoading = false;
      state.asana.sectionsLoading = false;
    })
    .addCase(asanaSetWorkspacesLoading, (state, action) => {
      state.asana.workspacesLoading = action.payload.workspacesLoading;
    })
    .addCase(asanaSetProjectsLoading, (state, action) => {
      state.asana.projectsLoading = action.payload.projectsLoading;
    })
    .addCase(asanaSetSectionsLoading, (state, action) => {
      state.asana.sectionsLoading = action.payload.sectionsLoading;
    })
    .addCase(asanaClearFormInfo, (state) => {
      state.asana.workspaces = null;
      state.asana.projects = null;
      state.asana.sections = null;
    })
    .addCase(asanaClearFormInfoSections, (state) => {
      state.asana.sections = null;
    })
    .addCase(asanaSetFormData, (state, action) => {
      state.asana = {
        ...state.asana,
        ...action.payload,
        credential_id: state.asana.credential_id,
        workspaces: state.asana.workspaces,
        projects: state.asana.projects,
        sections: state.asana.sections,
      };
    })
    .addCase(asanaFetchInit, (state) => {
      state.asana.loading = true;
    })
    .addCase(asanaFetchLoaded, (state) => {
      state.asana.loading = false;
    })
    .addCase(asanaSetInitialTags, (state, action) => {
      state.asana.initial_tags = action.payload.initial_tags;
    })

    // Wrike
    .addCase(wrikeSetCredentialId, (state, action) => {
      state.wrike.credential_id = action.payload.credential_id;
    })
    .addCase(wrikeOauthSignIn, (state, action) => {
      const { credential_id } = action.payload;
      state.wrike.credential_id = credential_id;
    })
    .addCase(wrikeReceiveSpaces, (state, action) => {
      state.wrike.spaces = action.payload.spaces;
    })
    .addCase(wrikeSetFormData, (state, action) => {
      const {
        active,
        workflow_name,
        bf_destination_key,
        section_key,
        collection_key,
        resource,
        resource_id,
        resource_type,
        recursive,
      } = action.payload;

      state.wrike.active = active;
      state.wrike.workflow_name = workflow_name;
      state.wrike.bf_destination_key = bf_destination_key;
      state.wrike.section_key = section_key;
      state.wrike.collection_key = collection_key;
      state.wrike.resource = resource;
      state.wrike.resource_id = resource_id;
      state.wrike.resource_type = resource_type;
      state.wrike.recursive = recursive;
    })

    //Salsify
    .addCase(salsifyFetchInit, (state) => {
      state.salsify.loadingProperties = false;
    })
    .addCase(salsifySetCredentialId, (state, action) => {
      state.salsify.credential_id = action.payload.credential_id;
    })
    .addCase(salsifyRecieveOrganizations, (state, action) => {
      state.salsify.organizations = action.payload.organizations;
    })
    .addCase(salsifySetProductIdPropName, (state, action) => {
      state.salsify.productIdPropName = action.payload.productIdPropName;
    })
    .addCase(salsifySelectOrganization, (state, action) => {
      state.salsify.org_id = action.payload.org_id;
    })
    .addCase(salsifySetDigitalAssetProps, (state, action) => {
      state.salsify.digitalAssetProps = action.payload.salsifyDigitalAssetProps;
    })
    .addCase(salsifySetStandardProps, (state, action) => {
      state.salsify.standardProps = action.payload.salsifyStandardProps;
    })
    .addCase(salsifySetExistingDestConfig, (state, action) => {
      state.salsify.existingDestConfig = action.payload.destConfig;
    })
    .addCase(salsifySetExistingCustomFieldMap, (state, action) => {
      state.salsify.existingCustomFieldMap = action.payload.customFieldMap;
    })
    .addCase(salsifySetLoadingProperties, (state, action) => {
      state.salsify.loadingProperties = action.payload.loadingProperties;
    })
    .addCase(salsifySetFormData, (state, action) => {
      const {
        bf_source_key,
        brandfolder_identifier_custom_field,
        dest_config,
        custom_field_map,
        default_property,
        org_id,
        salsify_product_id,
        workflow_name,
      } = action.payload;

      state.salsify.bf_source_key = bf_source_key;
      state.salsify.brandfolder_identifier_custom_field = brandfolder_identifier_custom_field;
      state.salsify.dest_config = dest_config;
      state.salsify.default_property = default_property;
      state.salsify.org_id = org_id;
      state.salsify.salsify_product_id = salsify_product_id;
      state.salsify.workflow_name = workflow_name;
      state.salsify.custom_field_map = custom_field_map;
    })

    // Seismic
    .addCase(seismicSetFormData, (state, action) => {
      const {
        active,
        bf_source_key,
        collection_to_folder_map,
        folder_id,
        section_to_folder_map,
        teamsite_id,
        workflow_name,
      } = action.payload;
      state.seismic.active = active;
      state.seismic.bf_source_key = bf_source_key;
      state.seismic.collection_to_folder_map = collection_to_folder_map;
      state.seismic.folder_id = folder_id;
      state.seismic.section_to_folder_map = section_to_folder_map;
      state.seismic.teamsite_id = teamsite_id;
      state.seismic.workflow_name = workflow_name;
    })
    .addCase(seismicReceiveFolders, (state, action) => {
      state.seismic.seismic_folders = action.payload.seismicFormInfo;
    })
    .addCase(seismicSetCurrentFolderName, (state, action) => {
      state.seismic.currentFolderName = action.payload.currentFolderName;
    })
    .addCase(seismicRehydratedSectionMaps, (state, action) => {
      state.seismic.rehydrated_section_maps = action.payload.maps;
    })
    .addCase(seismicRehydratedCollectionMaps, (state, action) => {
      state.seismic.rehydrated_collection_maps = action.payload.maps;
    })
    .addCase(seismicSetExistingSectionMaps, (state, action) => {
      state.seismic.seismic_existing_section_maps = action.payload.seismic_existing_section_maps;
    })
    .addCase(seismicSetExistingCollectionMaps, (state, action) => {
      state.seismic.seismic_existing_collection_maps =
        action.payload.seismic_existing_collection_maps;
    })
    .addCase(seismicSetSyncEntireBrandfolderOptionSelected, (state, action) => {
      state.seismic.syncEntireBrandfolderOptionSelected = action.payload.value;
    })
    .addCase(seismicSetSyncOptionalResourceOptionSelected, (state, action) => {
      state.seismic.syncOptionalResourceOptionSelected = action.payload.value;
    })
    .addCase(seismicFetchInit, (state) => {
      state.seismic.loading = true;
    })
    .addCase(seismicFetchLoaded, (state) => {
      state.seismic.loading = false;
    })
    .addCase(seismicEditFetchInit, (state) => {
      state.seismic.loading_edit = true;
    })
    .addCase(seismicEditFetchLoaded, (state) => {
      state.seismic.loading_edit = false;
    })
    .addCase(seismicSetCredentialId, (state, action) => {
      state.seismic.credential_id = action.payload.credential_id;
    })

    // Workfront
    .addCase(workfrontSetFormData, (state, action) => {
      const {
        active,
        workflow_name,
        document_custom_fields,
        user_custom_fields,
        project_custom_fields,
        portfolio_custom_fields,
        program_custom_fields,
        tags,
        description_link,
        host,
        subdomain,
        client_id,
        user_id,
        customer_id,
        client_secret,
      } = action.payload;

      state.workfront.active = active;
      state.workfront.workflow_name = workflow_name;
      state.workfront.document_custom_fields = document_custom_fields;
      state.workfront.user_custom_fields = user_custom_fields;
      state.workfront.project_custom_fields = project_custom_fields;
      state.workfront.portfolio_custom_fields = portfolio_custom_fields;
      state.workfront.program_custom_fields = program_custom_fields;
      state.workfront.tags = tags;
      state.workfront.description_link = description_link;
      state.workfront.client_id = client_id;
      state.workfront.user_id = user_id;
      state.workfront.customer_id = customer_id;
      state.workfront.client_secret = client_secret;
      // when we get the workflow when editing, host is named subdomain
      state.workfront.host = subdomain ? subdomain : host;
    }),
);

export interface CreateWorkflowRootState {
  createWorkflowReducer: CreateWorkflowState;
}
