import { put, select, takeEvery } from 'typed-redux-saga';
import { createNotification, NotificationType } from '@integration-frontends/common/notifications';
import { selectSelectedClientId, wrikeFetchSpaces, wrikeReceiveSpaces } from '../../index';
import {
  FORM_INFO_REPO_TOKEN,
  IFormInfoRepo,
  WrikeFormInfo,
} from '@integration-frontends/workflow-manager/core/model';
import { DI_CONTAINER } from '@integration-frontends/core';
import { pageLoadError } from '@integration-frontends/common/app';
import { callWithTokenRefresh } from '../../../../common';

function* handler(action: ReturnType<typeof wrikeFetchSpaces>) {
  try {
    const { credential_id } = action.payload;
    const formInfoRepo: IFormInfoRepo = DI_CONTAINER.get(FORM_INFO_REPO_TOKEN);
    const clientId = yield select(selectSelectedClientId);

    const formInfo: WrikeFormInfo = yield callWithTokenRefresh(formInfoRepo.getWrikeFormInfo, clientId, {
      credential_id,
    });
    const spaces = formInfo.resources;

    if (spaces) {
      yield put(wrikeReceiveSpaces({ spaces: spaces }));
    } else {
      yield put(
        createNotification({
          message: 'Failed to fetch Wrike Spaces',
          location: 'topLevel',
          type: NotificationType.Error,
        }),
      );
    }
  } catch (e) {
    yield put(pageLoadError({ error: e }));
  }
}

export function* wrikeFetchSpacesEffects() {
  yield takeEvery(wrikeFetchSpaces, handler);
}
