import { createSelector } from 'reselect';
import { ClientsPageRootState, ClientsPageState } from './reducer';
import { StateSelector } from '@integration-frontends/common/utils/redux';

const SelectClientsPageState: StateSelector<ClientsPageState> = (state: ClientsPageRootState) =>
  state.clientsPageReducer;

export const selectClientsLoading = createSelector(
  SelectClientsPageState,
  (state) => state.clientsLoading,
);
