import { FontSizes, FontWeights, StandardText } from '@brandfolder/react';
import {
  getCustomFieldFormInfo,
  salsifyFetchInfo,
  salsifySelectOrganization,
  salsifySetCredentialId,
  selectBrandfolderFormInfo,
  selectCustomFieldRestrictedKeys,
  selectSalsifyDigitalAssetProps,
  selectSalsifyOrganizations,
  selectSalsifyProductId,
  selectSelectedClientId,
  selectSelectedCredentialId,
  selectSalsifyOrganization,
  selectallCustomFieldKeys,
  selectSalsifyStandardProps,
  selectSalsifyState,
  selectSalsifyLoadingProperties,
  salsifyFetchInit,
} from '@integration-frontends/workflow-manager/core/application';
import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { SelectBrandfolder } from '../../common/components/select-brandfolder/select-brandfolder';
import { WorkflowInfo } from '../../common/components/workflow-info/workflow-info';
import { WorkflowName } from '../../common/components/workflow-name.tsx/workflow-name';

import '../../create-workflow-form.scss';
import { DestConfig } from './dest-config/dest-config';
import { CustomFieldToProductId } from './custom-field-to-product-id/custom-field-to-product-id';
import { MultiSelectSalisfyProperties } from './multi-select-salsify-properties/multi-select-salsify-properties';
import { SelectDefaultProperty } from './select-default-property/select-default-property';
import { SelectOrganization } from './select-organization.tsx/select-organization';
import './workflow-form-salsify.scss';
import { Loader } from '@integration-frontends/common/ui';

export interface CreateSalsifyWorkflowContainerProps {
  formComplete: (c: boolean) => void;
}

export const CreateSalsifyWorkflowFormContainer = ({
  formComplete,
}: CreateSalsifyWorkflowContainerProps) => {
  const dispatch = useDispatch();
  const {
    reset,
    watch,
    formState: { errors },
    setValue,
  } = useFormContext();

  const defaultValues = useSelector(selectSalsifyState);
  const clientId = useSelector(selectSelectedClientId);
  const credential_id = useSelector(selectSelectedCredentialId);
  const brandfolderFormInfo = useSelector(selectBrandfolderFormInfo);
  const salsifyOrganizations = useSelector(selectSalsifyOrganizations);
  const allCfKeys = useSelector(selectallCustomFieldKeys);
  const restrictedCfKeys = useSelector(selectCustomFieldRestrictedKeys);
  const salsifyProductId = useSelector(selectSalsifyProductId);
  const salsifyDigitalAssetProps = useSelector(selectSalsifyDigitalAssetProps);
  const salsifyStandardProps = useSelector(selectSalsifyStandardProps);
  const selectedSalsifyOrg = useSelector(selectSalsifyOrganization);
  const salsifyLoadingProperties = useSelector(selectSalsifyLoadingProperties);

  const salsifyOrgSources = salsifyOrganizations?.map((org) => {
    return { label: org.name, value: org.id };
  });
  const bfSources = brandfolderFormInfo?.map((bf) => {
    return { label: bf.name, value: bf.id, sections: bf.sections, collections: bf.collections };
  });
  const allCustomFieldSources = allCfKeys?.map((customField) => {
    return { label: customField.name, value: customField.id };
  });
  const restrictedCustomFieldSources = restrictedCfKeys?.map((customField) => {
    return { label: customField.name, value: customField.id };
  });
  const digitalAssetPropsSources = salsifyDigitalAssetProps?.map((prop) => {
    return { label: prop['salsify:name'], value: prop['salsify:system_id'] };
  });
  const standardPropsSources = salsifyStandardProps?.map((prop) => {
    return { label: prop['salsify:name'], value: prop['salsify:system_id'] };
  });

  const workflowName = watch('workflow_name');
  const salsifyOrgId: string = watch('org_id');
  const bfSource: string = watch('bf_source_key');
  const customFieldSource: string = watch('brandfolder_identifier_custom_field');
  const salsifyDefaultProperty: string = watch('default_property');

  useEffect(() => {
    reset(defaultValues);
  }, []);

  useEffect(() => {
    if (workflowName && salsifyOrgId && bfSource && customFieldSource && salsifyProductId?.id) {
      formComplete(true);
    } else {
      formComplete(false);
    }
  }, [workflowName, salsifyOrgId, bfSource, customFieldSource]);

  useEffect(() => {
    if (salsifyOrgSources?.length === 1 && !selectedSalsifyOrg) {
      setValue('org_id', salsifyOrgSources[0]?.value);
    }
  }, [salsifyOrgSources?.length]);

  useEffect(() => {
    if (salsifyOrgId) {
      dispatch(
        salsifySelectOrganization({
          clientId,
          credential_id,
          org_id: salsifyOrgId,
        }),
      );
    }
  }, [salsifyOrgId]);

  useEffect(() => {
    if (bfSource) {
      const brandfolderId = bfSources?.find((bf) => bf.value === bfSource)?.value;
      dispatch(getCustomFieldFormInfo({ brandfolderId }));
    }
  }, [bfSource]);

  useEffect(() => {
    dispatch(salsifyFetchInit());
    dispatch(salsifySetCredentialId({ credential_id }));
    dispatch(salsifyFetchInfo({ clientId, credential_id }));
  }, []);

  return (
    <section className="forms-content-container" data-testid="salsify-workflow-form-container">
      <WorkflowInfo
        linkToDocumentation={
          'https://smar-wiki.atlassian.net/wiki/spaces/PROD/pages/71012581478/Guide+IWM+-+Salsify'
        }
      />
      <WorkflowName />
      <SelectOrganization salsifyOrgSources={salsifyOrgSources} salsifyOrg={salsifyOrgId} />
      {salsifyOrgId && (
        <section className="brandfolder-to-salsify-container">
          <StandardText
            className="workflow-form-salsify-header"
            size={FontSizes.XxLarge}
            weight={FontWeights.Bold}
          >
            Brandfolder to Salsify
          </StandardText>
          <div className="salsify-brandfolder-container">
            <SelectBrandfolder bfSource={bfSource} bfSources={bfSources} />
          </div>
          <CustomFieldToProductId
            customFieldSource={customFieldSource}
            customFieldSources={allCustomFieldSources}
            customFieldDisabled={!watch('bf_source_key')}
            salsifyProductId={salsifyProductId}
            loadingProperties={salsifyLoadingProperties}
          />
          <SelectDefaultProperty
            digitalAssetPropsSources={digitalAssetPropsSources}
            salsifyDefaultProperty={salsifyDefaultProperty}
            loadingProperties={salsifyLoadingProperties}
          />
          <DestConfig
            brandfolderId={bfSources?.find((bf) => bf.value === bfSource)?.value}
            customFieldSources={restrictedCustomFieldSources}
            digitalAssetPropsSources={digitalAssetPropsSources}
            loadingProperties={salsifyLoadingProperties}
          />
          <StandardText
            className="workflow-form-salsify-header workflow-form-salsify-optional-header"
            size={FontSizes.XxLarge}
            weight={FontWeights.Bold}
          >
            Salsify to Brandfolder
          </StandardText>
          <StandardText
            className="workflow-form-salsify-sub-header"
            size={FontSizes.Small}
            weight={FontWeights.Book}
          >
            <span>To have Salsify properties update in Brandfolder, set up a webhook. </span>
            <a style={{ color: '#4462c9' }} href={''} target="_blank">
              Learn how
            </a>
          </StandardText>
          <MultiSelectSalisfyProperties
            propsSources={standardPropsSources}
            customFieldSources={allCustomFieldSources}
            loadingProperties={salsifyLoadingProperties}
          />
        </section>
      )}
    </section>
  );
};
