import {
  Colors,
  FontIcon,
  FontIconColors,
  FontIcons,
  ListboxOption,
  Placements,
  StandardLabel,
  StandardTagger,
  StandardText,
  StandardTooltip,
  TooltipTypes,
  Widths,
} from '@brandfolder/react';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import './form-fields-tagger.scss';

export const FormFieldsTagger = () => {
  const { setValue, watch } = useFormContext();
  type UpdateTagOption = 'create' | 'remove' | 'select';
  const workfrontTags = watch('tags');

  const handleUpdateTagger = (): ((
    tags: ListboxOption[],
    action: UpdateTagOption,
    tag: ListboxOption,
  ) => void) => {
    return (tags) => {
      setValue(
        'tags',
        tags.map((obj) => obj.value),
        { shouldDirty: true },
      );
    };
  };

  return (
    <>
      <label className="workfront-forms-label-container">
        <StandardLabel htmlFor={'workfront-form-fields'}>Form fields to tags</StandardLabel>
        <StandardTooltip
          color={Colors.Primary}
          defaultOpen={false}
          id="workfront-form-fields-tooltip"
          placement={Placements.TopCenter}
          tooltip="You can enter the Workfront form fields you want to sync as asset tags in Brandfolder. If a tag doesn’t exist, we’ll create it when the customer syncs the document."
          triggerOffset={8}
          type={TooltipTypes.Description}
          width={Widths.Medium}
        >
          <FontIcon icon={FontIcons.ToolTip} color={FontIconColors.Primary} />
        </StandardTooltip>
      </label>
      <StandardTagger
        id="workfront-form-fields-tagger"
        data-testid="workfront-form-fields-tagger"
        labels={{
          iconButtonLabel: 'Toggle list of form fields',
          label: 'Form fields to tags',
          listboxLabel: 'List of form fields',
          removeTagLabel: 'Remove form field',
        }}
        options={[
          {
            children: null,
            disabled: false,
            key: 'enter-fields-placeholder',
            placeholder: 'Enter fields',
            search: '',
            value: '',
          },
        ]}
        showLabel={false}
        onTagged={handleUpdateTagger()}
        allowCustomValue={true}
        search={false}
        allowDuplicates={false}
        initialTags={workfrontTags?.map((value) => ({
          value: value as string,
          children: <>{value}</>,
          disabled: false,
          key: value,
          search: value as string,
        }))}
      />
      <StandardText className="form-fields-sub-label">
        Enter fields separated by commas.
      </StandardText>
    </>
  );
};
