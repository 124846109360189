import React, { useEffect } from 'react';
import { push } from 'redux-first-history';
import { useDispatch, useSelector } from 'react-redux';
import { ReviewSection, ReviewSectionLine } from '../../../common';
import {
  credentialEntitySelectors,
  initEditWorkflow,
  selectCreateWorkflowSuccess,
  selectGettyAllowExpiredLicense,
  selectGettyCredentialId,
  selectGettyLastSyncedDate,
  selectGettyResetLastSyncedDate,
  selectGettyWorkflowName,
  selectSelectedWorkflowId,
} from '@integration-frontends/workflow-manager/core/application';
import cronstrue from 'cronstrue';

import {
  selectSelectedIntegrationType,
  selectSelectedClientId,
  clientEntitySelectors,
  brandfolderEntitySelectors,
  selectGettyBrandfolder,
  selectGettySection,
  selectGettyCollection,
  selectGettyStartDate,
  selectGettySyncInterval,
} from '@integration-frontends/workflow-manager/core/application';
import { workflowTitle } from '@integration-frontends/workflow-manager/core/model';
import { format } from 'date-fns';
import { workflowServiceType } from '@integration-frontends/workflow-manager/core/model';

export const GettyReview = ({ setComplete }) => {
  const dispatch = useDispatch();

  const createWorkflowSuccess = useSelector(selectCreateWorkflowSuccess);

  useEffect(() => {
    if (createWorkflowSuccess) {
      dispatch(push('/workflows'));
    }
  }, [createWorkflowSuccess]);

  const integrationType = useSelector(selectSelectedIntegrationType);
  const workflowId = useSelector(selectSelectedWorkflowId);
  const selectWorkflowName = useSelector(selectGettyWorkflowName);
  const selectedClientId = useSelector(selectSelectedClientId);
  const selectedClient = useSelector((state) =>
    clientEntitySelectors.selectById(state, selectedClientId),
  );

  const selectedBrandfolderId = useSelector(selectGettyBrandfolder);
  const selectedBrandfolder = useSelector((state) =>
    brandfolderEntitySelectors.selectById(state, selectedBrandfolderId),
  );

  const startDate = useSelector(selectGettyStartDate);
  const syncInterval = useSelector(selectGettySyncInterval);
  const allowExpiredLicense = useSelector(selectGettyAllowExpiredLicense);
  const lastSyncedDate = useSelector(selectGettyLastSyncedDate);
  const resetLastSyncedDate = useSelector(selectGettyResetLastSyncedDate);
  const mountainTimeZoneOffsetMs = 6 * 3600 * 1000;

  const sectionId = useSelector(selectGettySection);
  const collectionId = useSelector(selectGettyCollection);

  const selectedCredentialId = useSelector(selectGettyCredentialId);
  const credential = useSelector(credentialEntitySelectors.credentialById(selectedCredentialId));

  const sectionName = selectedBrandfolder?.sections.data.find(
    (section) => section.id === sectionId,
  )?.name;
  const collectionName = selectedBrandfolder?.collections.data.find(
    (collection) => collection.id === collectionId,
  )?.name;

  const onEdit = () => {
    dispatch(initEditWorkflow({ integrationType: workflowServiceType.getty, workflowId }));
  };

  const source = [selectedBrandfolder?.name, sectionName, collectionName]
    .filter((item) => !!item)
    .join(' / ');

  useEffect(() => {
    setComplete(!!(selectedClientId && selectedBrandfolderId && startDate && syncInterval));
  }, [selectedClientId, selectedBrandfolderId, startDate, syncInterval]);

  return (
    <>
      <ReviewSection title="Organization info" dataTestId="getty-organization-review-section">
        <ReviewSectionLine
          title="Client"
          value={<div data-testid="getty-selected-client-name">{selectedClient?.client_name}</div>}
        />
        <ReviewSectionLine
          title="Brandfolder API key"
          value={<div data-testid="getty-brandfolder-api-key">{credential?.api_key}</div>}
        />
      </ReviewSection>

      <ReviewSection title="Integration info" dataTestId="getty-integration-review-section">
        <ReviewSectionLine
          title="Integration"
          value={<div>{workflowTitle[integrationType]}</div>}
        />
        <ReviewSectionLine
          title="Getty credential name"
          value={<div data-testid="getty-credential-name">{credential?.credential_name}</div>}
        />
        <ReviewSectionLine
          title="Getty client key"
          value={<div data-testid="getty-client-key">{credential?.client_key}</div>}
        />
        <ReviewSectionLine
          title="Getty secret API key"
          value={<div data-testid="getty-secret-api-key">{credential?.client_secret}</div>}
        />
      </ReviewSection>

      <ReviewSection
        title="Workflow info"
        dataTestId="getty-workflow-review-section"
        editAction={onEdit}
      >
        <ReviewSectionLine
          title="Workflow name"
          value={<div data-testid="getty-workflow-name">{selectWorkflowName}</div>}
        />
        {workflowId && (
          <ReviewSectionLine
            title="Workflow ID"
            value={<div data-testid="getty-workflow-id">{workflowId || ''}</div>}
          />
        )}
        <ReviewSectionLine
          title="Start date"
          value={
            <div data-testid="getty-start-date">
              {format(new Date(startDate).getTime() + mountainTimeZoneOffsetMs, 'MM/dd/yyyy')}
            </div>
          }
        />
        <ReviewSectionLine
          title="Sync interval"
          value={<div data-testid="getty-sync-interval">{cronstrue.toString(syncInterval)}</div>}
        />
        <ReviewSectionLine
          title="Sync assets with expired licenses"
          value={
            <div data-testid="getty-allow-expired-license">
              {allowExpiredLicense ? 'Yes' : 'No'}
            </div>
          }
        />
        {resetLastSyncedDate && (
          <ReviewSectionLine
            title="Re-sync assets from start date"
            value={<div data-testid="getty-reset-last-synced-date">Yes</div>}
          />
        )}
        <ReviewSectionLine
          title="Source (Brandfolder)"
          value={<div data-testid="getty-brandfolder-source">{source}</div>}
        />
      </ReviewSection>
    </>
  );
};
