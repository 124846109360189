import React, { useEffect } from 'react';
import { push } from 'redux-first-history';
import { useDispatch, useSelector } from 'react-redux';
import { ReviewSection, ReviewSectionLine } from '../../../common';
import {
  brandfolderEntitySelectors,
  initEditWorkflow,
  selectCreateWorkflowSuccess,
  selectSalsifyBrandfolder,
  selectBrandfolderIdentifierCustomField,
  selectSalsifyCustomFieldMappings,
  selectSalsifyDefaultProp,
  selectSalsifyDestConfig,
  selectSalsifyDigitalAssetProps,
  selectSalsifyOrganization,
  selectSalsifyOrganizations,
  selectSalsifyProductId,
  selectSalsifyStandardProps,
  selectSalsifyWorkflowName,
  selectSelectedWorkflowId,
  selectallCustomFieldKeys,
  selectSalsifyProductIdPropName,
  selectSalsifyLoadingProperties,
} from '@integration-frontends/workflow-manager/core/application';
import { MoreInfoTooltip, ShimmerLoader } from '@brandfolder/react';
import { OrganizationInfo } from '../../review-page/organization-info/organization-info';
import { workflowServiceType } from '@integration-frontends/workflow-manager/core/model';

interface SalsifyReviewProps {
  setComplete: React.Dispatch<React.SetStateAction<boolean>>;
}

export const SalsifyReview = ({ setComplete }: SalsifyReviewProps) => {
  const dispatch = useDispatch();

  const createWorkflowSuccess = useSelector(selectCreateWorkflowSuccess);

  useEffect(() => {
    if (createWorkflowSuccess) {
      dispatch(push('/workflows'));
    }
  }, [createWorkflowSuccess]);

  const workflowId = useSelector(selectSelectedWorkflowId);
  const workflowName = useSelector(selectSalsifyWorkflowName);
  const salsifyOrganizations = useSelector(selectSalsifyOrganizations);
  const selectedSalsifyOrganization = useSelector(selectSalsifyOrganization);
  const selectedSalsifyOrganizationName = salsifyOrganizations?.find(
    (org) => org.id === selectedSalsifyOrganization,
  )?.name;
  const selectedBrandfolderId = useSelector(selectSalsifyBrandfolder);
  const selectedBrandfolderName = useSelector((state) =>
    brandfolderEntitySelectors?.selectById(state, selectedBrandfolderId),
  )?.name;
  const allCfKeys = useSelector(selectallCustomFieldKeys);
  const selectedCustomFieldId = useSelector(selectBrandfolderIdentifierCustomField);
  const productId = useSelector(selectSalsifyProductId);
  const salsifyProductIdPropName = useSelector(selectSalsifyProductIdPropName);
  const digitalAssetProps = useSelector(selectSalsifyDigitalAssetProps);
  const standardProps = useSelector(selectSalsifyStandardProps);
  const allSalsifyProps = digitalAssetProps?.concat(standardProps);
  const defaultSalsifyPropId = useSelector(selectSalsifyDefaultProp);
  const destConfig = useSelector(selectSalsifyDestConfig);
  const customFieldMap = useSelector(selectSalsifyCustomFieldMappings);
  const salsifyLoadingProperties = useSelector(selectSalsifyLoadingProperties);

  const getSalsifyName = (id: string) => {
    const salsifyProp = allSalsifyProps?.find((prop) => {
      return prop && prop['salsify:system_id'] === id;
    });
    return salsifyProp ? salsifyProp['salsify:name'] : undefined;
  };

  const getCustomFieldKeyName = (id: string) => {
    const customField = allCfKeys?.find((cf) => {
      return cf.id === id;
    });
    return customField?.name;
  };

  const returnDestConfigMappings = destConfig?.map((mapping) => {
    return mapping?.value && mapping?.salsifyProp
      ? `${mapping?.value} to ${getSalsifyName(mapping?.salsifyProp)}`
      : '';
  });

  const returnSalsifyCustomFieldMap = customFieldMap?.map((mapping) => {
    return mapping?.salsifyProp && mapping?.key
      ? `${getCustomFieldKeyName(mapping?.key)} to ${getSalsifyName(mapping?.salsifyProp)}`
      : '';
  });

  const onEdit = () => {
    dispatch(initEditWorkflow({ integrationType: workflowServiceType.salsify, workflowId }));
  };

  useEffect(() => {
    setComplete(
      !!(
        workflowName &&
        selectedSalsifyOrganizationName &&
        selectedBrandfolderName &&
        selectedCustomFieldId &&
        productId
      ),
    );
  }, [
    workflowName,
    selectedSalsifyOrganizationName,
    selectedBrandfolderName,
    selectedCustomFieldId,
    productId,
  ]);

  return (
    <>
      <OrganizationInfo />

      <ReviewSection title="Salsify info">
        <ReviewSectionLine title="Salsify API Key" value={<div>*********</div>} />
      </ReviewSection>

      <ReviewSection title="Workflow info" editAction={onEdit}>
        <ReviewSectionLine
          title="Workflow name"
          value={<div data-testid="review-salsify-workflow-name">{workflowName}</div>}
        />
        {workflowId && (
          <ReviewSectionLine
            title="Workflow ID"
            value={<div data-testid="salsify-workflow-id">{workflowId || ''}</div>}
          />
        )}
        <ReviewSectionLine
          title="Salsify Organization"
          value={<div data-testid="review-salsify-org">{selectedSalsifyOrganizationName}</div>}
        />
        <ReviewSectionLine
          title="Brandfolder"
          value={
            <div data-testid="review-salsify-selected-brandfolder">
              {selectedBrandfolderName || <ShimmerLoader />}
            </div>
          }
        />
        <ReviewSectionLine
          title="Brandfolder custom field to Salsify product ID"
          value={
            <div data-testid="review-salsify-custom-field-to-product-id">
              {salsifyLoadingProperties ? (
                <ShimmerLoader />
              ) : (
                <>
                  {getCustomFieldKeyName(selectedCustomFieldId)} to {salsifyProductIdPropName}
                </>
              )}
            </div>
          }
        />
        <ReviewSectionLine
          title="Default Salsify property"
          value={
            <div data-testid="review-salsify-default-property">
              {salsifyLoadingProperties ? (
                <ShimmerLoader />
              ) : (
                <>{getSalsifyName(defaultSalsifyPropId)}</>
              )}
            </div>
          }
          tooltip={
            <MoreInfoTooltip
              id="default-salsify-prop"
              iconLabel="Default Salsify property tooltip"
              tooltip="This is where assets will appear in Salsify."
            />
          }
        />
        <ReviewSectionLine
          title="Custom field mapping (optional)"
          value={
            salsifyLoadingProperties ? (
              <ShimmerLoader />
            ) : (
              returnDestConfigMappings?.map((mapping) => (
                <div data-testid="review-salsify-dest-config">{mapping}</div>
              ))
            )
          }
          tooltip={
            <MoreInfoTooltip
              id="custom-field-mapping"
              iconLabel="Custom field mapping"
              tooltip="Where images with certain Brandfolder custom field values will appear in Salsify."
            />
          }
        />
        <ReviewSectionLine
          title="Salsify properties to custom fields (optional)"
          value={
            salsifyLoadingProperties ? (
              <ShimmerLoader />
            ) : (
              returnSalsifyCustomFieldMap?.map((mapping) => (
                <div data-testid="review-salsify-custom-field-map">{mapping}</div>
              ))
            )
          }
          tooltip={
            <MoreInfoTooltip
              id="salsify-props-to-custom-fields"
              iconLabel="Salsify props to custom fields (optional)"
              tooltip="The Salsify properties that will map to Brandfolder custom fields."
            />
          }
        ></ReviewSectionLine>
      </ReviewSection>
    </>
  );
};
