import { CreateClientBody } from '@integration-frontends/common/temporal-api';
import {
  Client,
  IClientRepo,
  clientsMockData,
  ClientApiResponseData,
  clientsDataMockData,
} from '@integration-frontends/workflow-manager/core/model';
import { injectable } from 'inversify';

@injectable()
export class ClientRepoMock implements IClientRepo {
  listClients(): Promise<ClientApiResponseData> {
    return Promise.resolve(clientsDataMockData);
  }
  createClient(clientBody: CreateClientBody): Promise<Client> {
    return Promise.resolve(clientsMockData[0]);
  }
}
