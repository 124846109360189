import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  BFLabel,
  BFErrorLabel,
} from '@integration-frontends/common/ui/forms/form-controls/form-controls';
import {
  FontSizes,
  FontWeights,
  StandardText,
  FontIcon,
  FontIcons,
  FontIconColors,
  StandardTooltip,
  Colors,
  Placements,
  TooltipTypes,
  Widths,
} from '@brandfolder/react';
import { useFormContext } from 'react-hook-form';

import {
  selectHubspotState,
  hubspotFetchFolders,
  selectSelectedCredentialId,
  hubspotSetCredentialId,
  selectBrandfolderFormInfo,
  hubspotGetFolderById,
  hubspotSetFolder,
  selectHubspotFolder,
  selectHubspotFolders,
  selectHubspotContainersLoading,
  selectWorkflowMode,
  workflowModes,
} from '@integration-frontends/workflow-manager/core/application';

import '../../create-workflow-form.scss';
import './workflow-form-hubspot.scss';

import {
  BFSelect,
  BFSelectOption,
} from '@integration-frontends/common/ui/forms/form-controls/select';
import {
  BFMultiSelect,
  BFMultiSelectOption,
} from '@integration-frontends/common/ui/forms/form-controls/multi-select';
import { BFStandardCheckbox } from '@integration-frontends/common/ui';
import { AsyncContainerSelector } from '../../common/components/container-selector/async-container-selector';
import { ContainerFormInfo } from '@integration-frontends/workflow-manager/core/model';
import { WorkflowName } from '../../common/components/workflow-name.tsx/workflow-name';
import { SelectBrandfolder } from '../../common/components/select-brandfolder/select-brandfolder';
import { WorkflowInfo } from '../../common/components/workflow-info/workflow-info';
import { alphabetizBfOptions } from '../../../common/functions/alphabetize';

export const CreateHubspotWorkflowFormContainer = ({ formComplete }) => {
  const {
    formState: { errors },
    setValue,
    reset,
    watch,
  } = useFormContext();

  const bfSourceKey = watch('bf_source_key');
  const workflowName = watch('workflow_name');
  const syncFolderId = watch('sync_folder_id');
  const collectionKey = watch('collection_key');
  const sectionKeys = watch('section_keys');

  const workflowMode = useSelector(selectWorkflowMode);
  const editing = workflowMode === workflowModes.edit;
  const defaultValues = useSelector(selectHubspotState);
  const syncFolder = useSelector(selectHubspotFolder);
  const credentialId = useSelector(selectSelectedCredentialId);
  const brandfolderFormInfo = useSelector(selectBrandfolderFormInfo);
  const containers = useSelector(selectHubspotFolders);
  const containerFormInfo: ContainerFormInfo = {
    currentContainer: containers?.current_folder,
    siblings: containers?.siblings?.data,
    children: containers?.children?.data,
    metadata: containers?.metadata,
  };

  const hasFetchedFromEdit = useRef(false);

  const dispatch = useDispatch();

  const [syncSection, setSyncSection] = useState(
    sectionKeys !== null && sectionKeys !== undefined && sectionKeys?.length !== 0,
  );
  const [syncCollection, setSyncCollection] = useState(
    collectionKey !== null && collectionKey !== undefined,
  );

  useEffect(() => {
    dispatch(hubspotSetCredentialId({ credential_id: credentialId }));
    reset(defaultValues);
  }, []);

  useEffect(() => {
    // If the page is entered via edit...
    if (editing && !hasFetchedFromEdit.current) {
      // We need to use this action to get the folder name because it is not passed back in the HubSpot workflow info
      dispatch(
        hubspotGetFolderById({
          credential_id: credentialId,
          sync_folder_id: watch('sync_folder_id'),
        }),
      );
      hasFetchedFromEdit.current = true;
    } else {
      dispatch(
        hubspotFetchFolders({
          credential_id: credentialId,
          sync_folder_id: watch('sync_folder_id'),
        }),
      );
    }
  }, [syncFolderId]);

  // Opens Sync Brandfolder Section on edit if existing values
  useEffect(() => {
    if (sectionKeys && sectionKeys?.length !== 0) {
      setSyncSection(true);
    }
  }, [sectionKeys]);

  // Opens Sync Brandfolder Collection on edit if existing values
  useEffect(() => {
    if (collectionKey) {
      setSyncCollection(true);
    }
  }, [collectionKey]);

  // Checks if the form is complete, disables Next if not
  useEffect(() => {
    const isFormComplete = () => {
      if (syncCollection && !collectionKey) {
        return false;
      }
      if (syncSection && (!sectionKeys || sectionKeys.length === 0)) {
        return false;
      }
      if (!bfSourceKey || !workflowName || syncFolderId === 'None') {
        return false;
      }
      return true;
    };
    formComplete(isFormComplete());
  }, [
    bfSourceKey,
    workflowName,
    syncFolderId,
    sectionKeys,
    collectionKey,
    syncCollection,
    syncSection,
  ]);

  const bfSources = brandfolderFormInfo?.map((bf) => {
    return { label: bf.name, value: bf.id, sections: bf.sections, collections: bf.collections };
  });

  const bfSections = bfSources
    ?.find((bf) => bf.value === bfSourceKey)
    ?.sections?.data.map((section) => {
      return { label: section.name, value: section.id };
    });

  const bfCollections = bfSources
    ?.find((bf) => bf.value === bfSourceKey)
    ?.collections?.data.map((collection) => {
      return { label: collection.name, value: collection.id };
    });

  const handleMultiChangeSections = (values) => {
    setValue('section_keys', values || null, { shouldDirty: true });
  };

  const handleSelectCollection = (value) => {
    setValue('collection_key', value || null);
  };

  return (
    <section className="forms-content-container" data-testid="hubspot-workflow-form-container">
      <WorkflowInfo
        linkToDocumentation={
          'https://smar-wiki.atlassian.net/wiki/spaces/PROD/pages/70971162970/Guide+IWM+-+HubSpot'
        }
        linkTestId="hubspot-documentation-link"
      />
      <div className="workflow-info-container">
        <WorkflowName />
        <div className="workflow-info-selection-section">
          <div className="workflow-info-selection-row-item">
            <SelectBrandfolder
              bfSource={bfSourceKey}
              bfSources={bfSources}
              onChange={() => {
                handleMultiChangeSections(null);
                handleSelectCollection(null);
              }}
            />
          </div>
          <div className="hubspot-arrow-icon-container">
            <FontIcon className="arrow-icon" icon={FontIcons.ArrowRight} />
          </div>
          <div className="workflow-info-selection-row-item">
            <div className="workflow-info-selection-row-item-label row-item-responsive-margin">
              <BFLabel className="form-label mr-xxs">HubSpot sync folder</BFLabel>
              <span className="hubspot-sync-folder-tooltip">
                <StandardTooltip
                  color={Colors.Primary}
                  defaultOpen={false}
                  id="workflow-optional-info-tooltip"
                  placement={Placements.TopCenter}
                  tooltip={`The selected Brandfolder will map to the selected root sync folder in HubSpot. Sections within that Brandfolder will automatically appear as new subfolders within the selected root sync folder in HubSpot.`}
                  triggerOffset={8}
                  type={TooltipTypes.Description}
                  width={Widths.Medium}
                >
                  <FontIcon icon={FontIcons.ToolTip} iconSize={13} color={FontIconColors.Primary} />
                </StandardTooltip>
              </span>
            </div>
            <AsyncContainerSelector
              displayedName={syncFolder?.name}
              onOptionChange={(folder) => {
                setValue(`sync_folder_id`, folder?.id || ('' as string), {
                  shouldValidate: true,
                  shouldDirty: true,
                });
                dispatch(hubspotSetFolder({ sync_folder: folder }));
              }}
              defaultContainerName={
                syncFolder?.id === 'None' ? 'Choose folder' : syncFolder?.name || 'Choose folder'
              }
              defaultContainerId={syncFolder?.id || 'None'}
              rootContainerId={'None'}
              parentContainerPropertyName={'parent_folder_id'}
              containerNamePropertyName={'name'}
              containerFormInfo={containerFormInfo}
              fetchContainers={(folderId, page) =>
                hubspotFetchFolders({
                  credential_id: credentialId,
                  sync_folder_id: folderId,
                  page,
                })
              }
              loading={useSelector(selectHubspotContainersLoading)}
            />
            {errors.sync_folder_id?.type === 'required' && (
              <BFErrorLabel role="alert">Folder is required</BFErrorLabel>
            )}
          </div>
        </div>
        <section className="workflow-optional-info-section">
          <div className="mb-lg flex items-center">
            <StandardText
              size={FontSizes.XxSmall}
              weight={FontWeights.Medium}
              className={'section-header-text mr-xxs'}
            >
              OPTIONAL
            </StandardText>
            <StandardTooltip
              color={Colors.Primary}
              defaultOpen={false}
              id="workflow-optional-info-tooltip"
              placement={Placements.TopCenter}
              tooltip={`If you choose to sync section(s) and/or a collection, subfolders will be automatically generated within the root sync folder and populated with the assets in the selected section(s) and/or collection. Only the assets in those section(s) and/or collections will sync to HubSpot.
              `}
              triggerOffset={8}
              type={TooltipTypes.Description}
              width={Widths.Medium}
            >
              <FontIcon icon={FontIcons.ToolTip} iconSize={13} color={FontIconColors.Primary} />
            </StandardTooltip>
          </div>
          <div className="mb-xl">
            <BFStandardCheckbox
              data-testid="hubspot-select-section-checkbox"
              id="select-section-checkbox"
              label="Sync Brandfolder Section(s)"
              style={{ height: '16px', marginRight: '12px' }}
              checked={syncSection}
              onChange={() => {
                setSyncSection(!syncSection);
                setValue('section_keys', null, { shouldDirty: true });
              }}
              disabled={!bfSourceKey}
            />
            {syncSection && (
              <div className="option-select-container">
                <StandardText weight={FontWeights.Bold}>Brandfolder Section(s)</StandardText>
                <BFMultiSelect
                  id="select-sections"
                  placeholder={'Select Sections'}
                  options={(alphabetizBfOptions(bfSections) as BFMultiSelectOption[]) || []}
                  disabled={!bfSourceKey}
                  selected={bfSections?.filter((section) => sectionKeys?.includes(section?.value))}
                  selectedValues={sectionKeys || []}
                  onChange={handleMultiChangeSections}
                  data-testid={`hubspot-select-section`}
                  panelStyleOptions={{ width: '100%' }}
                  buttonStyleOptions={{ width: '100%' }}
                  getOptionKey={(o) => o.value}
                />
              </div>
            )}
            {bfSourceKey && syncSection && (!sectionKeys || sectionKeys?.length === 0) && (
              <BFErrorLabel role="alert">No section selected</BFErrorLabel>
            )}
          </div>
          <div>
            <BFStandardCheckbox
              data-testid="hubspot-select-collection-checkbox"
              id="select-collection-checkbox"
              label="Sync Brandfolder Collection"
              style={{ height: '16px', marginRight: '12px' }}
              checked={syncCollection}
              onChange={() => {
                setSyncCollection(!syncCollection);
                setValue('collection_key', null, { shouldDirty: true });
              }}
              disabled={!bfSourceKey}
            />
            <div className="flex flex-col">
              {syncCollection && (
                <div className="option-select-container">
                  <StandardText weight={FontWeights.Bold}>Brandfolder Collection</StandardText>
                  <BFSelect
                    id="select-collections"
                    placeholder={'Select Collection'}
                    options={(alphabetizBfOptions(bfCollections) as BFSelectOption[]) || []}
                    disabled={!bfSourceKey}
                    value={collectionKey}
                    onOptionChange={(listOption): void => {
                      setValue('collection_key', listOption?.value || (null as string), {
                        shouldValidate: true,
                      });
                    }}
                    data-testid={`hubspot-select-collection`}
                  />
                </div>
              )}
              {bfSourceKey && syncCollection && !collectionKey && (
                <BFErrorLabel role="alert">No collection selected</BFErrorLabel>
              )}
            </div>
          </div>
        </section>
      </div>
    </section>
  );
};
