import { HeadingLevels, StandardHeading, StandardLabel, StandardText } from '@brandfolder/react';
import {
  BFDatePicker,
  BFErrorLabel,
  BFSelect,
  BFSelectOption,
  BFStandardCheckbox,
} from '@integration-frontends/common/ui';
import {
  gettySetResetLastSyncedDate,
  selectGettyLastSyncedDate,
  selectGettyResetLastSyncedDate,
} from '@integration-frontends/workflow-manager/core/application';
import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

interface SyncTimeframeSectionProps {
  cronOptions: BFSelectOption[];
  editing: boolean;
}

export const SyncTimeframeSection = ({ cronOptions, editing }: SyncTimeframeSectionProps) => {
  const {
    register,
    formState: { errors },
    setValue,
    watch,
  } = useFormContext();

  const syncStartDate = watch('sync_start_date');
  const syncEndDate = watch('sync_end_date');
  const cronSchedule = watch('cron_schedule');
  const allowExpiredLicense = watch('allow_expired_license');
  const originalLastSyncedDate = useSelector(selectGettyLastSyncedDate);
  const resetLastSyncedDate = useSelector(selectGettyResetLastSyncedDate);
  const dispatch = useDispatch();

  const allowExpiredSectionMarginClass = editing
    ? 'workflow-field workflow-field-margin'
    : 'workflow-field';

  useEffect(() => {
    if (resetLastSyncedDate) {
      setValue('last_synced_date', null, { shouldDirty: true });
    } else {
      setValue('last_synced_date', originalLastSyncedDate, { shouldDirty: true });
    }
  }, [resetLastSyncedDate]);

  return (
    <section
      className="destination-section workflow-info-section"
      data-testid="sync-timeframe-section"
    >
      <StandardHeading level={HeadingLevels.h2} className="workflow-info-section-header">
        Sync timeframe
      </StandardHeading>
      <section className="workflow-info-section-body">
        <div className="workflow-info-section-content">
          <div className="workflow-field workflow-field-margin">
            <StandardLabel
              data-testid="getty-start-date-label"
              htmlFor="select-start-date"
              className="mb-xxs"
            >
              Start date
            </StandardLabel>
            <BFDatePicker
              id="select-start-date"
              data-testid="getty-start-date-picker"
              value={syncStartDate || ''}
              {...register('sync_start_date', {
                required: true,
                validate: {
                  syncStartDateValid: (value) => {
                    return value < syncEndDate;
                  },
                },
              })}
              onChange={(e): void => {
                setValue('sync_start_date', e.target.value || ('' as string), {
                  shouldValidate: true,
                  shouldDirty: true,
                });
              }}
            />
            {errors.sync_start_date?.type === 'required' && (
              <BFErrorLabel role="alert">Start date is required</BFErrorLabel>
            )}
            {errors.sync_start_date?.type === 'syncStartDateValid' && (
              <BFErrorLabel role="alert">Start date must be before End date</BFErrorLabel>
            )}
            <StandardText className="workflow-field-sublabel">
              The date from which you want the files to be synced.
            </StandardText>
          </div>
          <div className="workflow-field workflow-field-margin">
            <StandardLabel
              data-testid="getty-end-date-label"
              htmlFor="select-end-date"
              className="mb-xxs"
            >
              End date
            </StandardLabel>
            <BFDatePicker
              id="select-end-date"
              data-testid="getty-end-date-picker"
              value={syncEndDate}
              {...register('sync_end_date', {
                required: true,
                validate: {
                  syncEndDateValid: (value) => {
                    return value > syncStartDate;
                  },
                },
              })}
              onChange={(e): void => {
                setValue('sync_end_date', e.target.value || ('' as string), {
                  shouldValidate: true,
                  shouldDirty: true,
                });
              }}
            />
            {errors.sync_end_date?.type === 'required' && (
              <BFErrorLabel role="alert">End date is required</BFErrorLabel>
            )}
            {errors.sync_end_date?.type === 'syncEndDateValid' && (
              <BFErrorLabel role="alert">Start date must be before End date</BFErrorLabel>
            )}
            <StandardText className="workflow-field-sublabel">
              The date at which you want to stop syncing.
            </StandardText>
          </div>
          <div className="workflow-field workflow-field-margin">
            <StandardLabel
              data-testid="getty-sync-interval-label"
              htmlFor="select-sync-interval"
              className="mb-xxs"
            >
              Sync interval
            </StandardLabel>
            <BFSelect
              id="select-sync-interval"
              required={true}
              data-testid="getty-sync-interval-picker"
              options={cronOptions || []}
              value={cronSchedule}
              {...register('cron_schedule', { required: true })}
              onOptionChange={(listOption): void => {
                setValue('cron_schedule', listOption?.value || ('' as string), {
                  shouldValidate: true,
                  shouldDirty: true,
                });
              }}
              placeholder={'Select Sync Interval'}
            />
            {errors.cron_schedule?.type === 'required' && (
              <BFErrorLabel role="alert">Sync interval is required</BFErrorLabel>
            )}
            <StandardText className="workflow-field-sublabel">
              How often you want the files to sync.
            </StandardText>
          </div>
          <div className={allowExpiredSectionMarginClass}>
            <BFStandardCheckbox
              data-testid="getty-allow-expired-license-checkbox"
              id="getty-allow-expired-license-checkbox"
              style={{ height: '16px', marginRight: '12px' }}
              checked={allowExpiredLicense}
              onChange={() => {
                setValue('allow_expired_license', !allowExpiredLicense, { shouldDirty: true });
              }}
              label={'Sync assets with expired licenses'}
              labelClassName="getty-checkbox-label"
            />
            <StandardText
              data-testid="getty-allow-expired-license-sublabel"
              className="workflow-field-sublabel"
            >
              Select only if the customer explicitly wants to sync assets with expired license
              agreements.
            </StandardText>
          </div>
          {editing && (
            <div className="workflow-field">
              <BFStandardCheckbox
                data-testid="getty-last-synced-date-checkbox"
                id="getty-last-synced-date-checkbox"
                style={{ height: '16px', marginRight: '12px' }}
                checked={resetLastSyncedDate}
                onChange={() => {
                  dispatch(gettySetResetLastSyncedDate({ reset: !resetLastSyncedDate }));
                }}
                label={'Re-sync assets from start date'}
              />
              <StandardText className="workflow-field-sublabel">
                If selected, the workflow will re-sync all assets from the start date.
              </StandardText>
            </div>
          )}
        </div>
      </section>
    </section>
  );
};
