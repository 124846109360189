import { StateSelector } from '@integration-frontends/common/utils/redux';
import { clientAdapter } from '../entities';
import { Client, ClientSortOption } from '@integration-frontends/workflow-manager/core/model';
import { createSelector, EntityState } from '@reduxjs/toolkit';

export function createClientSelectors(stateSelector: StateSelector<EntityState<Client>>) {
  const baseSelectors = clientAdapter.getSelectors(stateSelector);

  const workflows = (clientId: string) =>
    createSelector(baseSelectors.selectAll, (clients) => {
      const foundClient: Client = clients.find((client) => client.id === clientId);
      return foundClient.workflows;
    });

  const clientById = (clientId: string) =>
    createSelector(baseSelectors.selectAll, (clients) => {
      return clients.find((client) => client.id === clientId);
    });

  const filterClients = (clientString: string, sortOption: ClientSortOption) =>
    createSelector(baseSelectors.selectAll, (clients) => {
      return (
        clients
          // look into using localCompare instead of toUpperCase().includes(string.toUpperCase())
          .filter((client) => client.client_name.toUpperCase().includes(clientString.toUpperCase()))
          .sort((a, b) => {
            if (sortOption === 'az') {
              return a.client_name.toUpperCase() < b.client_name.toUpperCase() ? -1 : 1;
            } else if (sortOption === 'za') {
              return a.client_name.toUpperCase() >= b.client_name.toUpperCase() ? -1 : 1;
            } else if (sortOption === 'updatedOldest') {
              return a.updated_at < b.updated_at ? -1 : 1;
            } else if (sortOption === 'updatedNewest') {
              return a.updated_at >= b.updated_at ? -1 : 1;
            }
            return 0;
          })
      );
    });

  return {
    ...baseSelectors,
    workflows,
    clientById,
    filterClients,
  };
}
