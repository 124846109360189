import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect } from 'react';
import { Loader } from '@integration-frontends/common/ui';
import { CreateWorkflowNav } from '../common/components/workflow-nav/create-workflow-nav';
import { SectionTracker } from '../common/components/section-tracker/section-tracker';

import {
  setIntegrationType,
  selectSelectedIntegrationType,
  selectActiveSection,
  selectCreateCredentialLoading,
  enteredCredentialPage,
  setSelectedCredentialId,
  selectSelectedClientId,
  selectWorkflowMode,
  workflowModes,
} from '@integration-frontends/workflow-manager/core/application';
import {
  FontSizes,
  FontWeights,
  ListboxOption,
  StandardCombobox,
  StandardText,
} from '@brandfolder/react';

interface CredentialFormProps {
  onBack: () => void;
  onNext: () => void;
}

export const IntegrationForm = ({ onBack, onNext }: CredentialFormProps) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (!selectedClientId) {
      onBack();
    }
    dispatch(enteredCredentialPage());
  }, []);

  const selectedClientId = useSelector(selectSelectedClientId);
  const integrationType = useSelector(selectSelectedIntegrationType);
  const activeSection = useSelector(selectActiveSection);
  const loading = useSelector(selectCreateCredentialLoading);
  const workflowMode = useSelector(selectWorkflowMode);
  const editing = workflowMode === workflowModes.edit;

  const integrationTypes = [
    { label: 'Highspot', value: 'highspot' },
    { label: 'Getty', value: 'getty' },
    { label: 'HubSpot', value: 'hubspot' },
    { label: 'Wrike', value: 'wrike' },
    { label: 'Salsify', value: 'salsify' },
    { label: 'Seismic', value: 'seismic' },
    { label: 'Workfront', value: 'workfront' },
    { label: 'Asana', value: 'asana' },
  ].sort((a, b) => (a.value > b.value ? 1 : -1));

  if (process.env.NODE_ENV === 'development') {
    // Work-in-progress integrations can be added here and moved up when ready for production
    // integrationTypes.push()
  }

  const onOptionChange = (e: ListboxOption) => {
    dispatch(setIntegrationType({ integrationType: e?.value ? `${e?.value}` : null }));
    dispatch(setSelectedCredentialId({ credentialId: null }));
  };

  return (
    <>
      <CreateWorkflowNav
        onBack={onBack}
        onNext={onNext}
        editing={editing}
        title="Integration info"
      />
      <section className="workflow-wizard-section" data-testid="integration-info-form">
        <SectionTracker activeSection={activeSection} />
        <section className="forms-container">
          <section className="forms-content-container">
            {loading ? (
              <Loader />
            ) : (
              <>
                <StandardText
                  className="forms-header"
                  size={FontSizes.XxLarge}
                  weight={FontWeights.Bold}
                >
                  Choose integration
                </StandardText>
                <StandardCombobox
                  id={'select-integration-type'}
                  labels={{
                    iconButtonLabel: 'Choose integration button',
                    label: 'Choose integration',
                    listboxLabel: 'Choose integration option',
                  }}
                  onSelection={onOptionChange}
                  options={[
                    {
                      children: null,
                      key: 0,
                      placeholder: 'Choose integration',
                      value: null,
                    },
                    ...integrationTypes.map((option) => ({
                      children: <>{option.label}</>,
                      key: option.value,
                      value: option.value,
                    })),
                  ]}
                  showPlaceholderOption
                  initialSelectedIndex={
                    integrationTypes.findIndex((type) => integrationType === type?.value) + 1 || 0
                  }
                  className="select-integration-type-combobox"
                  data-testid="integration-selector"
                />
              </>
            )}
          </section>
        </section>
      </section>
    </>
  );
};
