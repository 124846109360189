import { put, takeEvery, select } from 'typed-redux-saga';
import {
  activeSection,
  IOauthService,
  OAUTH_SERVICE_TOKEN,
} from '@integration-frontends/workflow-manager/core/model';
import { DI_CONTAINER } from '@integration-frontends/core';
import {
  selectSelectedClientId,
  selectSelectedCredentialId,
  selectSelectedIntegrationType,
  setActiveSection,
  wrikeOauthSignIn,
} from '../../index';
import { getInitialOauthSignInState, OauthBody } from '@integration-frontends/common/temporal-api';
import { createNotification, NotificationType } from '@integration-frontends/common/notifications';
import { push } from 'redux-first-history';
import { selectWrikeCredName } from '../../../../create-credential';
import { callWithTokenRefresh } from '../../../../common';

function* handler() {
  const oAuthService: IOauthService = DI_CONTAINER.get(OAUTH_SERVICE_TOKEN);
  const signInPayload = yield getSignInPayload();
  const clientId = yield select(selectSelectedClientId);

  const signIn = yield callWithTokenRefresh(oAuthService.signInOauth, clientId, signInPayload);

  if (signIn) {
    yield put(push(signIn.url));
    yield put(setActiveSection({ activeSection: activeSection.workflowInfo }));
  } else {
    yield put(
      createNotification({
        message: 'Error signing into Wrike',
        location: 'topLevel',
        type: NotificationType.Error,
      }),
    );
  }
}

function* getSignInPayload(): Generator<any, OauthBody, any> {
  const oAuthService: IOauthService = DI_CONTAINER.get(OAUTH_SERVICE_TOKEN);
  const hostUrl = oAuthService.getHostUrl();
  const payload: OauthBody = getInitialOauthSignInState();
  payload.credential_id = yield select(selectSelectedCredentialId);
  payload.credential_name = yield select(selectWrikeCredName);
  payload.integration_name = yield select(selectSelectedIntegrationType);
  payload.redirect_to = `${hostUrl}/create-workflow/app-info`;

  return payload;
}

export function* wrikeOauthSignInEffects() {
  yield takeEvery(wrikeOauthSignIn, handler);
}
