import { StandardText } from '@brandfolder/react';
import React from 'react';
import './status-badge.scss';

interface StatusBadgeProps {
  active: boolean;
}

export const StatusBadge = ({ active }: StatusBadgeProps) => {
  return (
    <div
      className={`status-badge-container ${
        active ? 'status-badge-active' : 'status-badge-inactive'
      }`}
    >
      <StandardText>{active ? 'Active' : 'Inactive'}</StandardText>
    </div>
  );
};
