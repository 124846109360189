import {
  FontColors,
  FontIcon,
  FontIcons,
  FontSizes,
  FontWeights,
  StandardText,
} from '@brandfolder/react';
import React, { useEffect } from 'react';
import { Control, useFieldArray, useFormContext } from 'react-hook-form';
import './create-workflow-sync-row.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
  seismicRehydrateSyncMaps,
  seismicSetCredentialId,
  seismicSetSyncEntireBrandfolderOptionSelected,
  seismicSetSyncOptionalResourceOptionSelected,
  selectSeismicContainersEditLoading,
  selectSeismicRehydratedCollectionMaps,
  selectSeismicRehydratedSectionMaps,
  selectSeismicState,
  selectSeismicTeamsiteId,
  selectSelectedCredentialId,
} from '@integration-frontends/workflow-manager/core/application';
import { CreateWorkflowSyncRowMap } from '../create-workflow-sync-row-map/create-workflow-sync-row-map';
import { SeismicSyncMap } from '@integration-frontends/common/temporal-api';
import { kebabCase } from 'lodash';

export interface SelectionData {
  label: string;
  placeHolder: string;
  allowMultiSelect?: boolean;
  selectionOptions?: any;
  tooltip?: string;
  onChange?: any;
}

interface CreateWorkflowSyncRowProps {
  sectionLabel?: string;
  label: string;
  disabled?: boolean;
  bfInfo?: any;
  rehydratedMaps: SeismicSyncMap[];
  src?: SelectionData;
  destination: SelectionData;
  control: Control<any>;
  mapType?: string;
  sectionType?: sectionType;
  displaySection: boolean;
}

export type sectionType = 'optional' | 'entire';

export const CreateWorkflowSyncRow = ({
  sectionLabel,
  bfInfo,
  rehydratedMaps,
  src,
  destination,
  mapType,
  sectionType,
  displaySection,
  control,
}: CreateWorkflowSyncRowProps) => {
  const { reset, setValue, watch } = useFormContext();

  const {
    fields,
    append: addMapping,
    remove: removeMapping,
  } = useFieldArray({
    control,
    name: `${mapType}_map`,
  });

  const watchFieldArray = watch(`${mapType}_map`);
  const folderId = watch('folder_id');
  const selectorOpen = watch(`${sectionType}_folder_map_selected`);

  const credentialId = useSelector(selectSelectedCredentialId);
  const teamsiteId = useSelector(selectSeismicTeamsiteId);
  const defaultValues = useSelector(selectSeismicState);
  const seismicRehydratedSectionMaps = useSelector(selectSeismicRehydratedSectionMaps);
  const seismicRehydratedCollectionMaps = useSelector(selectSeismicRehydratedCollectionMaps);
  const loadingRehydrateContainers = useSelector(selectSeismicContainersEditLoading);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(seismicSetCredentialId({ credential_id: credentialId }));
    reset(defaultValues);
  }, []);

  const fetchMapsForEdit = (bfInfo, credentialId, syncMapObject, teamsiteId, syncMapType) => {
    dispatch(
      seismicRehydrateSyncMaps({
        bf_info: bfInfo,
        credential_id: credentialId,
        sync_map_object: syncMapObject,
        teamsite_id: teamsiteId,
        sync_map_type: syncMapType,
      }),
    );
  };

  // Fetches appropriate sync maps if the page is entered through editing
  useEffect(() => {
    if (
      typeof watch(`section_to_folder_map`) === 'object' &&
      watchFieldArray !== null &&
      !Array.isArray(watchFieldArray)
    ) {
      if (bfInfo && folderId === '') {
        fetchMapsForEdit(bfInfo, credentialId, watchFieldArray, teamsiteId, mapType);
      }
    }
  }, [watchFieldArray, folderId]);

  const rehydrateOptionalMaps = () => {
    if (
      sectionType === 'optional' &&
      seismicRehydratedSectionMaps &&
      seismicRehydratedCollectionMaps
    ) {
      setValue(`section_to_folder_map`, seismicRehydratedSectionMaps);
      setValue(`collection_to_folder_map`, seismicRehydratedCollectionMaps);
    }
  };

  useEffect(() => {
    rehydrateOptionalMaps();
  }, [rehydratedMaps]);

  useEffect(() => {
    let toggleFn;
    switch (sectionType) {
      case 'optional':
        toggleFn = seismicSetSyncOptionalResourceOptionSelected;
        break;
      case 'entire':
        toggleFn = seismicSetSyncEntireBrandfolderOptionSelected;
        break;
      default:
        break;
    }

    // If the selector row is not empty and the selector is not open, that means this component
    // was reached from an edit, 'Back' button being pressed, or page being refreshed..
    // We need to open the selecor row so that it can be populated with the existing data
    if (watchFieldArray && !selectorOpen) {
      // If the section_to_folder_map is an "object" that means the data came from an edit
      if (typeof watch(`section_to_folder_map`) === 'object') {
        // The situation where the optional section needs to be opened after edit
        if (Object.keys(watchFieldArray).length !== 0) {
          dispatch(toggleFn({ value: true }));
        }
      } else {
        // The situation where optional or entire brandfolder section need to be opened after back button
        dispatch(toggleFn({ value: true }));
      }
      // The situation where sync entire brandfolder section needs to be opened after editing
    } else if (folderId && folderId !== '' && !selectorOpen && watchFieldArray !== null) {
      dispatch(toggleFn({ value: true }));
    }
  }, [watchFieldArray, folderId]);

  // Populates the sync row with a default row
  useEffect(() => {
    // Only adds a default row if the selector is empty
    if (selectorOpen && !watchFieldArray) handleAddMapping();
  }, [selectorOpen]);

  // Calls the append useFieldArray method
  const handleAddMapping = () => {
    addMapping({
      bfSrcContainer: { id: null, name: null },
      seismicDestinationContainer: { id: null, name: null },
    });
  };

  // Calls the remove useFieldArray method
  const handleRemoveMapping = (id: number) => {
    removeMapping(id);
  };

  return (
    <section
      className={`sync-row-container ${kebabCase(mapType)}-sync-row`}
      data-testid={`${kebabCase(mapType)}-sync-row`}
    >
      {displaySection && (
        <div className="sync-row-content-container">
          {sectionLabel && <hr className="sync-row-content-divider" />}
          <StandardText
            size={FontSizes.XxSmall}
            weight={FontWeights.Medium}
            className="workflow-sync-row-section-label"
          >
            {sectionLabel}
          </StandardText>
          {loadingRehydrateContainers ? (
            <StandardText color={FontColors.Primary}>Loading folders…</StandardText>
          ) : (
            <>
              {fields.map((field, index) => (
                <CreateWorkflowSyncRowMap
                  index={index}
                  key={index}
                  src={src}
                  destination={destination}
                  mapType={mapType}
                  credentialId={credentialId}
                  teamsiteId={watch('teamsite_id')}
                  handleRemoveMapping={(id: number) => handleRemoveMapping(id)}
                />
              ))}
              {src && (
                <StandardText
                  data-testid={`add-${kebabCase(mapType)}-sync-map`}
                  weight={FontWeights.Medium}
                  color={FontColors.Primary}
                  onClick={() => handleAddMapping()}
                  className="seismic-add-mapping"
                >
                  <FontIcon icon={FontIcons.Plus} iconSize={12} className="pr-sm pb-sm" />
                  Add mapping
                </StandardText>
              )}
            </>
          )}
        </div>
      )}
    </section>
  );
};
