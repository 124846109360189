import { takeEvery, select, put } from 'typed-redux-saga';
import {
  createWorkflow,
  selectSelectedClientId,
  selectHighspotSpotId,
  selectGettyState,
  selectHighspotState,
  selectHubspotFolderId,
  selectHubspotState,
  selectHubspotExistingSectionSelection,
  selectWrikeState,
  selectWrikeSelectedResource,
  selectWrikeSelectedSection,
  selectWrikeSelectedCollection,
  selectSelectedWrikeSyncingPreferences,
  selectHighspotSelectedSectionsToDelete,
  selectHighspotSelectedCollectionsToDelete,
  selectSalsifyState,
  selectSalsifyWorkflowName,
  selectSalsifyBrandfolder,
  selectSalsifyCredentialId,
  selectSalsifyDefaultProp,
  selectSalsifyProductId,
  selectBrandfolderIdentifierCustomField,
  selectSalsifyOrganization,
  selectSalsifyCustomFieldMappings,
  selectSalsifyDestConfig,
  selectSalsifyExistingDestConfig,
  selectSeismicState,
  selectSeismicCollectionToFolderMap,
  selectSeismicSectionToFolderMap,
  selectSeismicExistingCollectionMaps,
  selectSeismicExistingSectionMaps,
  selectSalsifyExistingCustomFieldMap,
  workflowModes,
  selectWorkfrontState,
  selectWorkfrontAddAssetLink,
  selectWorkfrontClientId,
  selectWorkfrontHost,
  selectWorkfrontTags,
  selectWorkfrontDocumentCustomFields,
  selectWorkfrontUserCustomFields,
  selectWorkfrontProjectCustomFields,
  selectWorkfrontPortfolioCustomFields,
  selectWorkfrontProgramCustomFields,
  selectAsanaState,
  selectAsanaAssetNameTemplate,
  selectAsanaAttachmentNameTemplate,
  selectAsanaTagTemplates,
  selectAsanaCustomFieldMap,
} from '../../index';
import {
  CreateWorkflowBody,
  SalsifyCreateWorkflowBody,
  SalsifyCustomFieldMapping,
  SalsifyDestConfigMapping,
  SeismicCreateWorkflowBody,
  workflowServiceType,
  WORKFLOW_REPO_TOKEN,
  WorkfrontCreateWorkflowBody,
  AsanaCreateWorkflowBody,
} from '@integration-frontends/workflow-manager/core/model';
import { DI_CONTAINER } from '@integration-frontends/core';
import {
  IWorkflowRepo,
  HighspotCreateWorkflowBody,
  GettyCreateWorkflowBody,
  HubspotCreateWorkflowBody,
  WrikeCreateWorkflowBody,
  WorkflowListItem,
} from '@integration-frontends/workflow-manager/core/model';
import { callWithTokenRefresh, workflowEntityActions } from '../../../common';
import {
  createWorkflowInitialize,
  createWorkflowFailure,
  createWorkflowSuccess,
  selectSelectedIntegrationType,
  selectHighspotSelectedSections,
  selectHighspotSelectedCollections,
  selectSelectedWorkflowId,
  selectWorkflowMode,
  selectHubspotSelectedCollection,
  selectHubspotSelectedSections,
} from '@integration-frontends/workflow-manager/core/application';
import { createNotification, NotificationType } from '@integration-frontends/common/notifications';
import { SeismicSyncMap } from '@integration-frontends/common/temporal-api';

function* handler(action: ReturnType<typeof createWorkflow>) {
  yield put(createWorkflowInitialize());

  const workflowRepo: IWorkflowRepo = DI_CONTAINER.get(WORKFLOW_REPO_TOKEN);
  const selectedClientId = yield select(selectSelectedClientId);
  const integrationType = yield select(selectSelectedIntegrationType);
  const workflowMode = yield select(selectWorkflowMode);
  const postBody = yield getBodyForIntegrationType(integrationType);
  const selectedWorkflowId = yield select(selectSelectedWorkflowId);

  const res =
    workflowMode === workflowModes.create
      ? yield callWithTokenRefresh(workflowRepo.createWorkflow, selectedClientId, postBody)
      : yield callWithTokenRefresh(
          workflowRepo.updateWorkflow,
          selectedClientId,
          selectedWorkflowId,
          postBody,
        );

  if (res.errors) {
    const errorMsg = res.errors
      ? res.errors.map((error) => error.title + ': ' + error.detail).join(', ')
      : 'Unknown error';
    yield put(createWorkflowFailure({ error: errorMsg }));
    yield put(
      createNotification({
        message: 'Error creating workflow: ' + errorMsg,
        location: 'topLevel',
        type: NotificationType.Error,
      }),
    );
  } else {
    const workflow: WorkflowListItem = {
      id: res.id,
      workflow_name: res.workflow_name,
      active: res.active,
      brandfolder: res.bf_source_key,
      brandfolder_account_id: res.bf_account_id,
      created_at: res.created_at,
      updated_at: res.updated_at,
      integration_type: res.integration_type,
      last_updated_by: res.last_updated_by,
      total_assets_affected: res.total_assets_affected,
    };
    yield put(workflowEntityActions.workflowReceived(workflow));
    yield put(createWorkflowSuccess());
  }
}

export function* createWorkflowEffects() {
  yield takeEvery(createWorkflow, handler);
}

export function* getBodyForIntegrationType(
  integrationType: workflowServiceType,
): Generator<any, CreateWorkflowBody, any> {
  const service = yield select(selectSelectedIntegrationType);
  switch (integrationType) {
    case workflowServiceType.highspot: {
      const highspotState: HighspotCreateWorkflowBody = yield select(selectHighspotState);
      const spot_id = yield select(selectHighspotSpotId);
      const selectedSections = yield select(selectHighspotSelectedSections);
      const highspotSelectedSectionsToDelete = yield select(selectHighspotSelectedSectionsToDelete);
      const selectedCollections = yield select(selectHighspotSelectedCollections);
      const highspotSelectedCollectionsToDelete = yield select(
        selectHighspotSelectedCollectionsToDelete,
      );
      const section_to_folder_map = {};
      selectedSections.forEach((sectionKey: string) => {
        section_to_folder_map[sectionKey] = spot_id;
      });
      highspotSelectedSectionsToDelete.forEach((sectionKey: string) => {
        section_to_folder_map[sectionKey] = null;
      });
      const collection_to_folder_map = {};
      selectedCollections.forEach((collectionKey: string) => {
        collection_to_folder_map[collectionKey] = spot_id;
      });
      highspotSelectedCollectionsToDelete.forEach((collectionKey: string) => {
        collection_to_folder_map[collectionKey] = null;
      });
      return {
        ...highspotState,
        collection_to_folder_map,
        section_to_folder_map,
        service,
      };
    }
    case workflowServiceType.getty: {
      const gettyBody: GettyCreateWorkflowBody = yield select(selectGettyState);
      return { ...gettyBody, service };
    }
    case workflowServiceType.hubspot: {
      const hubspotState: HubspotCreateWorkflowBody = yield select(selectHubspotState);
      const syncFolderId = yield select(selectHubspotFolderId);
      const hubspotSelectedSections = yield select(selectHubspotSelectedSections);
      const hubspotExistingSectionSelection = yield select(selectHubspotExistingSectionSelection);
      const collectionKey = yield select(selectHubspotSelectedCollection);

      const hubspotSectionToFolderMap = {};
      if (!hubspotSelectedSections && !hubspotExistingSectionSelection) {
        // User has not changed section selections,
        // and previous workflow config did not have existing section selections.
        // In this case, do nothing.
      } else if (hubspotSelectedSections && !hubspotExistingSectionSelection) {
        // Previous workflow config did not have existing section selections,
        // but user has added or changed section selections.

        hubspotSelectedSections.forEach((sectionKey: string) => {
          // Add with empty values (i.e. "") because we do not know the Hubspot folder ID.
          // Backend will see empty value state and insert the appropriate Hubspot folder ID as the value.
          hubspotSectionToFolderMap[sectionKey] = '';
        });
      } else if (!hubspotSelectedSections && hubspotExistingSectionSelection) {
        // Previous workflow config had existing section selections,
        // but user has removed all section selections.
        // Delete all existing selection key:val pairs from the map.
        hubspotExistingSectionSelection.forEach((sectionKey: string) => {
          hubspotSectionToFolderMap[sectionKey] = null;
        });
      } else if (
        hubspotSelectedSections &&
        hubspotExistingSectionSelection &&
        !hubspotSelectedSections.every(
          // if hubspotSelectedSections and hubspotExistingSectionSelection contain the exact same values, do nothing
          (value, index) => value === hubspotExistingSectionSelection[index],
        )
      ) {
        // Previous workflow config had existing section selections,
        // and user has added, changed, or removed section selections.
        // Determine which section keys were removed, and delete those from the mapping.
        // Determine the new section keys to add, and add them to the map
        const sectionsToDelete = hubspotExistingSectionSelection?.filter(
          (section) => !hubspotSelectedSections?.includes(section),
        );
        const newSectionsToAdd = hubspotSelectedSections?.filter(
          (section) => !hubspotExistingSectionSelection?.includes(section),
        );

        newSectionsToAdd.forEach((sectionKey: string) => {
          // Add with empty values (i.e. "") because we do not know the Hubspot folder ID.
          // Backend will see empty value state and insert the appropriate Hubspot folder ID as the value.
          hubspotSectionToFolderMap[sectionKey] = '';
        });

        sectionsToDelete.forEach((sectionKey: string) => {
          hubspotSectionToFolderMap[sectionKey] = null;
        });
      }

      return {
        ...hubspotState,
        section_keys: !hubspotSelectedSections ? [] : hubspotSelectedSections,
        collection_key: collectionKey,
        section_to_folder_map: hubspotSectionToFolderMap,
        service,
      };
    }
    case workflowServiceType.wrike: {
      const wrikeState: WrikeCreateWorkflowBody = yield select(selectWrikeState);
      const resource = yield select(selectWrikeSelectedResource);
      const resource_id = resource.id;
      const wrike_section_key = yield select(selectWrikeSelectedSection);
      const wrike_collection_key = yield select(selectWrikeSelectedCollection);
      const recursive = yield select(selectSelectedWrikeSyncingPreferences);
      return {
        ...wrikeState,
        recursive,
        resource_id,
        collection_key: wrike_collection_key,
        section_key: wrike_section_key,
        service,
      };
    }
    case workflowServiceType.salsify: {
      const salsifyState: SalsifyCreateWorkflowBody = yield select(selectSalsifyState);
      const workflow_name: string = yield select(selectSalsifyWorkflowName);
      const org_id: string = yield select(selectSalsifyOrganization);
      const bf_source_key: string = yield select(selectSalsifyBrandfolder);
      const credential_id: string = yield select(selectSalsifyCredentialId);
      const existingDestConfigMappings: SalsifyDestConfigMapping[] = yield select(
        selectSalsifyExistingDestConfig,
      );
      const newDestConfigMappings: SalsifyDestConfigMapping[] = yield select(
        selectSalsifyDestConfig,
      );
      const deletedDestConfigMappings =
        existingDestConfigMappings?.map((existingMapping) => {
          const matchingMapping = newDestConfigMappings?.find(
            (newMapping) =>
              newMapping.key === existingMapping.key &&
              newMapping.value === existingMapping.value &&
              newMapping.salsifyProp === existingMapping.salsifyProp,
          );
          if (!matchingMapping) return { ...existingMapping, value: null, salsifyProp: null };
        }) ?? [];
      const mergedDestConfigMappings = [
        ...deletedDestConfigMappings,
        ...newDestConfigMappings,
      ].filter((mapping) => mapping !== undefined);
      const destConfig = mergedDestConfigMappings?.reduce((destConfigMap, mapping) => {
        if (mapping.key && mapping.value && mapping.salsifyProp) {
          if (!destConfigMap[mapping.key]) {
            destConfigMap[mapping.key] = {
              [mapping.value]: mapping.salsifyProp,
            };
          } else {
            destConfigMap[mapping.key] = {
              ...destConfigMap[mapping.key],
              [mapping.value]: mapping.salsifyProp,
            };
          }
        } else if (mapping.key && mapping.value === null) {
          destConfigMap[mapping.key] = null;
        }
        return destConfigMap;
      }, {});
      const newCustomFieldMappings: SalsifyCustomFieldMapping[] = yield select(
        selectSalsifyCustomFieldMappings,
      );
      const existingCustomFieldMap: SalsifyCustomFieldMapping[] = yield select(
        selectSalsifyExistingCustomFieldMap,
      );
      const deletedCustomFieldMappings =
        existingCustomFieldMap?.map((existingMapping) => {
          const matchingMapping = newCustomFieldMappings?.find((newMapping) => {
            newMapping.salsifyProp === existingMapping.salsifyProp &&
              newMapping.key === existingMapping.key;
          });
          if (!matchingMapping) return { ...existingMapping, key: null };
        }) ?? [];
      const mergedCustomFieldMappings = [
        ...deletedCustomFieldMappings,
        ...newCustomFieldMappings,
      ].filter((mapping) => mapping !== undefined);
      const custom_field_map = mergedCustomFieldMappings?.reduce((customFieldMap, mapping) => {
        if (mapping.salsifyProp && mapping.key) {
          customFieldMap[mapping.salsifyProp] = mapping.key;
        } else if (mapping.salsifyProp && mapping.key === null) {
          customFieldMap[mapping.salsifyProp] = null;
        }
        return customFieldMap;
      }, {});
      const default_prop: string = yield select(selectSalsifyDefaultProp);
      const dest_config = {
        custom_fields_to_prop_id: {
          ...destConfig,
        },
        default: default_prop,
      };
      const salsifyProductIdObj: { id: string; name: string } = yield select(
        selectSalsifyProductId,
      );
      const salsifyProductId = salsifyProductIdObj.id;
      const brandfolderCustomFieldId: string = yield select(selectBrandfolderIdentifierCustomField);
      const identifier_map = {
        [salsifyProductId]: brandfolderCustomFieldId,
      };

      return {
        ...salsifyState,
        bf_source_key,
        org_id,
        custom_field_map,
        identifier_map,
        workflow_name,
        credential_id,
        service,
        dest_config,
      } as SalsifyCreateWorkflowBody;
    }
    case workflowServiceType.seismic: {
      const seismicState: SeismicCreateWorkflowBody = yield select(selectSeismicState);

      const findItemsToDelete = (existingMaps, currentMaps) => {
        if (!existingMaps) {
          return null;
        } else if (existingMaps && !currentMaps) {
          return Object.keys(existingMaps);
        } else if (!Object.keys(currentMaps).length) {
          return null;
        }
        
        const bKeys = currentMaps?.map((item) => item?.bfSrcContainer?.id);
        return Object.keys(existingMaps)?.filter((key) => !bKeys.includes(key));
      };

      const collectionToFolderMap: SeismicSyncMap[] = yield select(
        selectSeismicCollectionToFolderMap,
      );
      const existingCollectionToFolderMaps = yield select(selectSeismicExistingCollectionMaps);
      const sectionToFolderMap: SeismicSyncMap[] = yield select(selectSeismicSectionToFolderMap);
      const existingSectionToFolderMaps = yield select(selectSeismicExistingSectionMaps);

      const convertFormat = (
        mapping: SeismicSyncMap[],
        foldersToDelete: string[],
      ): { [key: string]: string | null } => {
        if (!mapping?.[0]?.seismicDestinationContainer?.id || !mapping?.[0]?.bfSrcContainer?.id) {
          if (foldersToDelete && foldersToDelete.length > 0) {
            return foldersToDelete.reduce((acc, folder) => ({ ...acc, [folder]: null }), {});
          }
          return {};
        }

        return mapping.reduce(
          (acc, item) => ({
            ...acc,
            [item.bfSrcContainer.id]: item.seismicDestinationContainer.id,
          }),
          foldersToDelete
            ? foldersToDelete.reduce((acc, folder) => ({ ...acc, [folder]: null }), {})
            : {},
        );
      };

      return {
        ...seismicState,
        collection_to_folder_map: convertFormat(
          collectionToFolderMap,
          findItemsToDelete(existingCollectionToFolderMaps, collectionToFolderMap),
        ),
        section_to_folder_map: convertFormat(
          sectionToFolderMap,
          findItemsToDelete(existingSectionToFolderMaps, sectionToFolderMap),
        ),
        custom_fields_map: {},
        service,
      } as SeismicCreateWorkflowBody;
    }

    case workflowServiceType.workfront: {
      const workfrontState: WorkfrontCreateWorkflowBody = yield select(selectWorkfrontState);
      const documentCustomFields = yield select(selectWorkfrontDocumentCustomFields);
      const userCustomFields = yield select(selectWorkfrontUserCustomFields);
      const projectCustomFields = yield select(selectWorkfrontProjectCustomFields);
      const portfolioCustomFields = yield select(selectWorkfrontPortfolioCustomFields);
      const programCustomFields = yield select(selectWorkfrontProgramCustomFields);
      const tags = yield select(selectWorkfrontTags);
      const descriptionLink = yield select(selectWorkfrontAddAssetLink);
      const subdomain = yield select(selectWorkfrontHost);
      return {
        ...workfrontState,
        document_custom_fields: documentCustomFields,
        user_custom_fields: userCustomFields,
        project_custom_fields: projectCustomFields,
        portfolio_custom_fields: portfolioCustomFields,
        program_custom_fields: programCustomFields,
        description_link: descriptionLink,
        tags,
        subdomain,
        service,
      } as WorkfrontCreateWorkflowBody;
    }

    case workflowServiceType.asana: {
      const asanaState: AsanaCreateWorkflowBody = yield select(selectAsanaState);
      const asset_name_template = yield select(selectAsanaAssetNameTemplate);
      const attachment_name_template = yield select(selectAsanaAttachmentNameTemplate);
      const tag_templates = yield select(selectAsanaTagTemplates);
      const custom_field_map = yield select(selectAsanaCustomFieldMap);

      const publishPayload: AsanaCreateWorkflowBody = {
        ...asanaState,
        asset_name_template: asset_name_template || '{{task_name}}',
        attachment_name_template: attachment_name_template || '{{attachment_name}}',
        tag_templates: tag_templates || [],
        custom_field_map: custom_field_map || {},
        service,
      };

      [
        'editing',
        'workspaces',
        'projects',
        'sections',
        'initial_tags',
        'loading',
        'webhook_id',
        'webhook_secret',
      ].forEach((key) => {
        delete publishPayload[key];
      });

      return publishPayload;
    }
  }
}
