export const workflowTableBreakpoints = {
  1: 1020,
  2: 944,
  3: 866,
};

export const breakPointColumnOmissions = {
  1: ['created'],
  2: ['created', 'totalAssetsAffected'],
  3: ['created', 'totalAssetsAffected', 'updatedBy'],
};
