import { FontIcon, FontIcons, StandardText } from '@brandfolder/react';
import { BFSelectOption } from '@integration-frontends/common/ui';
import { SalsifyProdctIdObj } from '@integration-frontends/workflow-manager/core/model';
import * as React from 'react';
import { SelectCustomField } from '../../../common/components/select-custom-field/select-custom-field';
import { ProductId } from '../product-id/product-id';
import './custom-field-to-product-id.scss';

export interface CustomFieldToProductIdProps {
  customFieldSource: string;
  customFieldSources: BFSelectOption[];
  customFieldDisabled: boolean;
  salsifyProductId: SalsifyProdctIdObj;
  loadingProperties?: boolean;
}

export const CustomFieldToProductId = ({
  customFieldSource,
  customFieldSources,
  customFieldDisabled,
  salsifyProductId,
  loadingProperties,
}: CustomFieldToProductIdProps): React.ReactElement => {
  return (
    <div className="salsify-form-row responsive-row">
      <StandardText className="responsive-row-header">CUSTOM FIELD MAPPING</StandardText>
      <SelectCustomField
        customFieldSource={customFieldSource}
        customFieldSources={customFieldSources}
        disabled={customFieldDisabled}
      />
      <FontIcon className="sals-bff-arrow-right" icon={FontIcons.ArrowRight} />
      <ProductId loadingProperties={loadingProperties} salsifyProductId={salsifyProductId} />
    </div>
  );
};
