import React, { useEffect } from 'react';
import { push } from 'redux-first-history';
import { useDispatch, useSelector } from 'react-redux';
import { ReviewSection, ReviewSectionLine } from '../../../common';
import {
  credentialEntitySelectors,
  initEditWorkflow,
  selectCreateWorkflowSuccess,
  selectHighspotWorkflowName,
  selectSelectedWorkflowId,
} from '@integration-frontends/workflow-manager/core/application';

import {
  selectSelectedIntegrationType,
  selectSelectedClientId,
  selectHighspotBrandfolder,
  selectHighspotSpot,
  clientEntitySelectors,
  selectHighspotCredentialID,
  brandfolderEntitySelectors,
  selectHighspotSelectedSectionNames,
  selectHighspotSelectedCollectionNames,
} from '@integration-frontends/workflow-manager/core/application';
import { workflowTitle } from '@integration-frontends/workflow-manager/core/model';
import { workflowServiceType } from '@integration-frontends/workflow-manager/core/model';

export const HighspotReview = ({ setComplete }) => {
  const dispatch = useDispatch();

  const createWorkflowSuccess = useSelector(selectCreateWorkflowSuccess);

  useEffect(() => {
    if (createWorkflowSuccess) {
      dispatch(push('/workflows'));
    }
  }, [createWorkflowSuccess]);

  const workflowId = useSelector(selectSelectedWorkflowId);
  const selectWorkflowName = useSelector(selectHighspotWorkflowName);
  const selectedClientId = useSelector(selectSelectedClientId);
  const selectedClient = useSelector((state) =>
    clientEntitySelectors.selectById(state, selectedClientId),
  );

  const selectedBrandfolderId = useSelector(selectHighspotBrandfolder);
  const selectedBrandfolder = useSelector((state) =>
    brandfolderEntitySelectors.selectById(state, selectedBrandfolderId),
  );

  const selectedCredentialId = useSelector(selectHighspotCredentialID);
  const credential = useSelector(credentialEntitySelectors.credentialById(selectedCredentialId));

  const selectedSpot = useSelector(selectHighspotSpot);
  const integrationType = useSelector(selectSelectedIntegrationType);

  const sectionNames = useSelector(selectHighspotSelectedSectionNames);
  const collectionNames = useSelector(selectHighspotSelectedCollectionNames);

  const onEdit = () => {
    dispatch(initEditWorkflow({ integrationType: workflowServiceType.highspot, workflowId }));
  };

  useEffect(() => {
    setComplete(!!(selectedClientId && selectedBrandfolderId && selectedSpot && integrationType));
  }, [selectedClientId, selectedBrandfolderId, selectedSpot, integrationType]);

  const source = [selectedBrandfolder.name, ...sectionNames, ...collectionNames].join(' / ');
  return (
    <>
      <ReviewSection title="Organization info">
        <ReviewSectionLine
          title="Client"
          value={<div data-testid="highspot-review-client-name">{selectedClient?.client_name}</div>}
        />
        <ReviewSectionLine
          title="Brandfolder API key"
          value={<div data-testid="highspot-review-bf-api-key">{credential?.api_key}</div>}
        />
      </ReviewSection>

      <ReviewSection title="Integration info">
        <ReviewSectionLine
          title="Integration"
          value={
            <div data-testid="highspot-review-integration-type">
              {workflowTitle[integrationType]}
            </div>
          }
        />
        <ReviewSectionLine
          title="Highspot credential name"
          value={
            <div data-testid="highspot-review-credential-name">{credential?.credential_name}</div>
          }
        />
        <ReviewSectionLine
          title="Highspot client key"
          value={<div data-testid="highspot-review-client-key">{credential?.client_key}</div>}
        />
        <ReviewSectionLine
          title="Highspot secret API key"
          value={<div data-testid="highspot-review-api-key">{credential?.client_secret}</div>}
        />
      </ReviewSection>

      <ReviewSection title="Workflow info" editAction={onEdit}>
        <ReviewSectionLine
          title="Workflow name"
          value={<div data-testid="highspot-workflow-name">{selectWorkflowName}</div>}
        />
        {workflowId && (
          <ReviewSectionLine
            title="Workflow ID"
            value={<div data-testid="highspot-workflow-id">{workflowId || ''}</div>}
          />
        )}
        <ReviewSectionLine
          title="Source (Brandfolder)"
          value={<div data-testid="highspot-review-source">{source}</div>}
        />
        <ReviewSectionLine
          title="Destination (Highspot)"
          value={<div data-testid="highspot-review-destination">{selectedSpot?.title}</div>}
        />
      </ReviewSection>
    </>
  );
};
