import React, { useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';
import { ContainerSelectorComponent } from './container-selector-component';
import {
  ContainerFormInfo,
  ContainerMetadata,
  ContainerNode,
  ContainerSelectorType,
} from '@integration-frontends/workflow-manager/core/model';

interface ContainerSelectorProps {
  onOptionChange: any;
  defaultContainerName: string;
  defaultContainerId: string;
  rootContainerId: string;
  parentContainerPropertyName: string;
  containerNamePropertyName: string;
  containerFormInfo: ContainerFormInfo;
  fetchContainers: any;
  displayedName: string;
  loading?: boolean;
  selectorReset?: boolean;
  testId?: string;
  handleOpened?: () => void;
}

enum Actions {
  initialized = 'Selector Component Initialized',
  open = 'Opened Selector',
  select = 'Selected Collection',
  goToParent = 'Go to Parent',
  goToChild = 'Go to Child',
}

export const AsyncContainerSelector = ({
  onOptionChange,
  defaultContainerName,
  defaultContainerId,
  rootContainerId,
  parentContainerPropertyName,
  containerNamePropertyName,
  containerFormInfo,
  fetchContainers,
  displayedName,
  loading,
  selectorReset,
  testId,
  handleOpened,
}: ContainerSelectorProps) => {
  const currentContainer: ContainerNode = {
    id: containerFormInfo?.currentContainer?.id,
    name: containerFormInfo?.currentContainer?.[containerNamePropertyName],
    parentId: containerFormInfo?.currentContainer?.[parentContainerPropertyName],
  };
  const children: ContainerNode[] = containerFormInfo?.children;
  const metadata: ContainerMetadata = containerFormInfo?.metadata;

  const [selectedContainer, setSelectedContainer] = useState<ContainerNode>({
    id: defaultContainerId,
    name: defaultContainerName,
    parentId: null,
  });
  const [closeSelector, setCloseSelector] = useState<boolean>(false);
  const [lastAction, setLastAction] = useState<Actions>(Actions.initialized);

  const dispatch = useDispatch();

  useEffect(() => {
    setSelectedContainer({
      id: defaultContainerId,
      name: defaultContainerName,
      parentId: null,
    });
  }, [defaultContainerId]);

  useEffect(() => {
    if (selectorReset) {
      setSelectedContainer({
        id: rootContainerId,
        name: defaultContainerName,
        parentId: null,
      });
    }
  }, [selectorReset]);

  useEffect(() => {
    if (children?.length === 0 && lastAction === Actions.goToChild) {
      setCloseSelector(true);
      handleContainerSelected(currentContainer);
    }
  }, [containerFormInfo?.currentContainer?.id]);

  const handleSelectorOpened = () => {
    if (handleOpened) {
      handleOpened();
    }
    setLastAction(Actions.open);
    setCloseSelector(false);
    dispatch(fetchContainers(selectedContainer.id));
  };

  const handleContainerSelected = (container: ContainerNode) => {
    setLastAction(Actions.select);
    setSelectedContainer(container);
    onOptionChange(container);
  };

  const handleGoToContainer = (container: ContainerNode) => {
    setLastAction(Actions.goToChild);
    dispatch(fetchContainers(container.id));
  };

  const handleGoToParentContainer = () => {
    if (currentContainer?.parentId === '') {
      dispatch(fetchContainers(rootContainerId));
    } else if (currentContainer?.parentId) {
      setLastAction(Actions.goToParent);
      dispatch(fetchContainers(currentContainer?.parentId));
    }
  };

  const handlePageChange = (page?: number) => {
    if (page) {
      dispatch(fetchContainers(currentContainer.id, page));
    } else {
      dispatch(fetchContainers(currentContainer.id));
    }
  };

  const checkDisableGoToParentContainer = () => {
    if (currentContainer?.parentId === '') {
      return false;
    } else if (!currentContainer?.parentId || currentContainer?.id === rootContainerId) {
      return true;
    }
  };

  const checkDisplayPagination = () => {
    return metadata?.total_pages > 1;
  };

  return (
    <ContainerSelectorComponent
      testId={testId}
      selectorType={ContainerSelectorType.Asynchronous}
      displayedName={displayedName}
      children={children}
      defaultContainerName={defaultContainerName}
      containerSelected={(container) => handleContainerSelected(container)}
      onContainerOpened={() => handleSelectorOpened()}
      goToContainer={(container) => handleGoToContainer(container)}
      goToParentContainer={() => handleGoToParentContainer()}
      disableGoToParentContainer={checkDisableGoToParentContainer()}
      closeSelector={closeSelector}
      containerNamePropertyName={containerNamePropertyName}
      loadingChildren={loading}
      pageChange={(page) => handlePageChange(page)}
      displayPagination={checkDisplayPagination()}
      metadata={metadata}
    />
  );
};
