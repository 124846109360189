import { takeEvery, select, put } from 'typed-redux-saga';
import { updateWorkflow, selectSelectedClientId } from '../../index';
import {
  UpdateWorkflowBody,
  WORKFLOW_REPO_TOKEN,
} from '@integration-frontends/workflow-manager/core/model';
import { DI_CONTAINER } from '@integration-frontends/core';
import { IWorkflowRepo } from '@integration-frontends/workflow-manager/core/model';
import { callWithTokenRefresh, workflowEntityActions } from '../../../common';
import {
  workflowPageInit,
  workflowPageLoadError,
} from '@integration-frontends/workflow-manager/core/application';
import { createNotification, NotificationType } from '@integration-frontends/common/notifications';

function* handler(action: ReturnType<typeof updateWorkflow>) {
  yield put(workflowPageInit());

  const workflowRepo: IWorkflowRepo = DI_CONTAINER.get(WORKFLOW_REPO_TOKEN);
  const selectedClientId = yield select(selectSelectedClientId);

  const putBody: UpdateWorkflowBody = {
    active: action.payload.data.active,
    service: action.payload.data.service,
  };

  const res = yield callWithTokenRefresh(
    workflowRepo.updateWorkflow,
    selectedClientId,
    action.payload.workflowId,
    putBody,
  );

  if (res.errors) {
    const errorMsg = res.errors
      ? res.errors.map((error) => error.title + ': ' + error.detail).join(', ')
      : 'Unknown error';
    yield put(workflowPageLoadError({ error: errorMsg }));
    yield put(
      createNotification({
        message: 'Error creating workflow: ' + errorMsg,
        location: 'topLevel',
        type: NotificationType.Error,
      }),
    );
  } else {
    const workflows = yield callWithTokenRefresh(workflowRepo.listWorkflows, selectedClientId);
    yield put(workflowEntityActions.workflowsReceived(workflows));
  }
}

export function* updateWorkflowEffects() {
  yield takeEvery(updateWorkflow, handler);
}
