import {
  FontColors,
  FontSizes,
  FontWeights,
  StandardText,
  StandardTextfield,
} from '@brandfolder/react';
import {
  seismicFetchFolders,
  seismicSetCurrentFolderName,
  seismicSetFolderId,
  seismicSetSyncEntireBrandfolderOptionSelected,
  seismicSetSyncOptionalResourceOptionSelected,
  selectBrandfolderFormInfo,
  selectSeismicContainers,
  selectSeismicContainersEditLoading,
  selectSeismicContainersLoading,
  selectSeismicCurrentFolderName,
  selectSeismicRehydratedCollectionMaps,
  selectSeismicRehydratedSectionMaps,
  selectSeismicState,
  selectSeismicSyncEntireBrandfolderOptionSelected,
  selectSeismicSyncOptionalResourceOptionSelected,
  selectSelectedCredentialId,
} from '@integration-frontends/workflow-manager/core/application';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { SelectBrandfolder } from '../../common/components/select-brandfolder/select-brandfolder';
import { WorkflowInfo } from '../../common/components/workflow-info/workflow-info';
import { CreateWorkflowSyncRow } from '../../common/components/create-workflow-sync-row/create-workflow-sync-row';

import '../../create-workflow-form.scss';
import './workflow-form-seismic.scss';
import '../../common/components/create-workflow-sync-row/create-workflow-sync-row.scss';
import { BFStandardRadio, BFSelectOption } from '@integration-frontends/common/ui';
import { WorkflowName } from '../../common/components/workflow-name.tsx/workflow-name';
import { alphabetizBfOptions } from '../../../common/functions/alphabetize';
import { AsyncContainerSelector } from '../../common/components/container-selector/async-container-selector';
import { ContainerFormInfo } from '@integration-frontends/workflow-manager/core/model';

export interface CreateSeismicWorkflowContainerProps {
  formComplete: (c: boolean) => void;
}

export const CreateSeismicWorkflowFormContainer = ({
  formComplete,
}: CreateSeismicWorkflowContainerProps) => {
  const {
    formState: { errors, dirtyFields },
    setValue,
    watch,
    reset,
  } = useFormContext();

  const dispatch = useDispatch();
  const selectedCredentialId = useSelector(selectSelectedCredentialId);
  const folders = useSelector(selectSeismicContainers); // need to populate these when toggling
  const currentFolderName = useSelector(selectSeismicCurrentFolderName);
  const brandfolderFormInfo = useSelector(selectBrandfolderFormInfo);
  const defaultValues = useSelector(selectSeismicState);
  const seismicRehydratedSectionMaps = useSelector(selectSeismicRehydratedSectionMaps);
  const seismicRehydratedCollectionMaps = useSelector(selectSeismicRehydratedCollectionMaps);
  const syncEntireBrandfolderOptionSelected = useSelector(
    selectSeismicSyncEntireBrandfolderOptionSelected,
  );
  const syncOptionalResourceOptionSelected = useSelector(
    selectSeismicSyncOptionalResourceOptionSelected,
  );
  const seismicContainersLoading = useSelector(selectSeismicContainersLoading);
  const loadingRehydrateContainers = useSelector(selectSeismicContainersEditLoading);
  const containers = useSelector(selectSeismicContainers);

  const [folderReset, setFolderReset] = useState<boolean>(false);

  const containerFormInfo: ContainerFormInfo = {
    currentContainer: containers?.current_folder,
    siblings: containers?.siblings,
    children: containers?.children,
    metadata: containers?.metadata,
  };

  const workflowName = watch('workflow_name');
  const bfSource = watch('bf_source_key');
  const currentFolderId = watch('folder_id');
  const teamsiteId = watch('teamsite_id');
  const sectionToFolderMap = watch('section_to_folder_map');
  const collectionToFolderMap = watch('collection_to_folder_map');

  const bfSources = brandfolderFormInfo?.map((bf) => {
    return { label: bf.name, value: bf.id, sections: bf.sections, collections: bf.collections };
  });

  const bfSections = bfSources
    ?.find((bf) => bf.value === bfSource)
    ?.sections?.data.map((section) => {
      return { label: section.name, value: section.id };
    });

  const bfCollections = bfSources
    ?.find((bf) => bf.value === bfSource)
    ?.collections?.data.map((collection) => {
      return { label: collection.name, value: collection.id };
    });

  const { control } = useFormContext<any>();

  useEffect(() => {
    reset(defaultValues);
  }, []);

  useEffect(() => {
    // avoid race condition during hydration by checking that teamsite_id has been changed by the user
    if (dirtyFields?.teamsite_id) {
      dispatch(seismicSetFolderId({ folder_id: null }));
      dispatch(seismicSetCurrentFolderName({ currentFolderName: null }));
      setFolderReset(true);
    }
  }, [teamsiteId]);

  const handleFolderSelectorOpened = () => {
    setFolderReset(false);
  };

  // Checks if the entireBfMap is complete
  const entireBfMapComplete = () => {
    if (currentFolderName && currentFolderId) {
      return true;
    }
    return false;
  };

  // Checks if the collectionToFolderMap or sectionToFolderMap is complete
  const optionalMapComplete = (optionalFolderMap) => {
    if (!optionalFolderMap) return false;
    if (optionalFolderMap && optionalFolderMap?.length > 0) {
      return optionalFolderMap?.every(({ seismicDestinationContainer, bfSrcContainer }) =>
        Boolean(
          seismicDestinationContainer?.id !== null &&
            seismicDestinationContainer?.name !== null &&
            bfSrcContainer?.id !== null &&
            bfSrcContainer?.name !== null,
        ),
      );
    }
  };

  // Checks if all values within a sync map are null
  const areMapsEmpty = (maps) => {
    if (maps?.length > 0) {
      return maps.every(
        (map) =>
          !map.seismicDestinationContainer?.id &&
          !map.seismicDestinationContainer?.name &&
          !map.bfSrcContainer?.id &&
          !map.bfSrcContainer?.name,
      );
    }
  };

  // Determines whether the optional sync maps section is complete
  const selectedSectionComplete = () => {
    // If syncEntireBrandfolderOptionSelected is true, just check the values for completion
    if (syncEntireBrandfolderOptionSelected) {
      return entireBfMapComplete();
      // If syncOptionalResourceOptionSelected is true ...
    } else if (syncOptionalResourceOptionSelected) {
      const isSectionToFolderMapEmpty =
        sectionToFolderMap?.length === 0 || areMapsEmpty(sectionToFolderMap);
      const isCollectionToFolderMapEmpty =
        collectionToFolderMap?.length === 0 || areMapsEmpty(collectionToFolderMap);
      // If there is nothing in the section to folder maps, just check the collection to folder maps
      if (isSectionToFolderMapEmpty) {
        return optionalMapComplete(collectionToFolderMap);
        // If there is nothing in the collection to folder maps, just check the section to folder maps
      } else if (isCollectionToFolderMapEmpty) {
        return optionalMapComplete(sectionToFolderMap);
      } else {
        // Otherwise check to make sure there are no null values in one or both of the sync maps
        return (
          optionalMapComplete(sectionToFolderMap) || optionalMapComplete(collectionToFolderMap)
        );
      }
    } else {
      // If neither section is selected, return false
      return false;
    }
  };

  useEffect(() => {
    if (workflowName && bfSource && teamsiteId && selectedSectionComplete()) {
      formComplete(true);
    } else {
      formComplete(false);
    }
  }, [
    workflowName,
    teamsiteId,
    bfSource,
    bfSections,
    bfCollections,
    syncEntireBrandfolderOptionSelected,
    syncOptionalResourceOptionSelected,
  ]);

  const resetMappings = () => {
    setValue('section_to_folder_map', null, { shouldDirty: true });
    setValue('collection_to_folder_map', null, { shouldDirty: true });
  };

  const closeSections = () => {
    dispatch(seismicSetSyncEntireBrandfolderOptionSelected({ value: false }));
    dispatch(seismicSetSyncOptionalResourceOptionSelected({ value: false }));
  };

  return (
    <section className="forms-content-container" data-testid="seismic-workflow-form-container">
      <WorkflowInfo
        linkToDocumentation={
          'https://smar-wiki.atlassian.net/wiki/spaces/PROD/pages/71011762183/Guide+IWM+-+Seismic'
        }
        linkTestId="seismic-documentation-link"
      />
      <section className="seismic-init-info-container">
        <WorkflowName />
        <div className="workflow-input-container">
          <SelectBrandfolder
            bfSource={bfSource}
            bfSources={bfSources}
            onChange={() => {
              resetMappings();
              closeSections();
            }}
          />
        </div>
        <StandardTextfield
          label={'Seismic Teamsite ID'}
          placeholder={'Enter teamsite ID'}
          value={teamsiteId}
          id={'set-seismic-teamsite-id'}
          data-testid={'set-seismic-teamsite-id-input'}
          className={'workflow-input-container'}
          onChange={(e) => {
            setValue('teamsite_id', e.target.value || ('' as string));
            resetMappings();
            closeSections();
          }}
        />
      </section>
      <StandardText
        className="sync-options-section-header"
        size={FontSizes.XLarge}
        weight={FontWeights.Bold}
      >
        Sync options
      </StandardText>
      <section className="sync-option-section">
        <BFStandardRadio
          checked={syncEntireBrandfolderOptionSelected}
          onChange={() => {
            dispatch(seismicSetSyncEntireBrandfolderOptionSelected({ value: true }));
            dispatch(seismicSetSyncOptionalResourceOptionSelected({ value: false }));
            resetMappings();
          }}
          // Address input styling that is wrapped around whole create-workflow
          className="sync-option-radio-button"
          name="entire_folder_map"
          value="entire_folder_map"
          label={'Sync entire Brandfolder'}
          disabled={!bfSource || !teamsiteId}
          data-testid="entire-folder-map-radio"
        />
        {loadingRehydrateContainers && !currentFolderId && !currentFolderName ? (
          <StandardText color={FontColors.Primary}>Loading folders…</StandardText>
        ) : (
          syncEntireBrandfolderOptionSelected && (
            <div className="entire-bf-sync-row-section">
              <StandardText
                size={FontSizes.XxSmall}
                weight={FontWeights.Medium}
                className="sync-row-section-label"
              >
                Seismic folder
              </StandardText>
              <AsyncContainerSelector
                testId={'seismic-entire-bf-dest-selector'}
                displayedName={currentFolderName}
                onOptionChange={(folder) => {
                  setValue('folder_id', folder?.id || ('' as string), {
                    shouldValidate: true,
                    shouldDirty: true,
                  });
                  dispatch(seismicSetCurrentFolderName({ currentFolderName: folder?.name }));
                }}
                defaultContainerName={'Choose folder'}
                defaultContainerId={currentFolderId || 'root'}
                rootContainerId={'root'}
                parentContainerPropertyName={'parentFolderId'}
                containerNamePropertyName={'name'}
                containerFormInfo={containerFormInfo}
                fetchContainers={(folderId, page) =>
                  seismicFetchFolders({
                    credential_id: selectedCredentialId,
                    folder_id: folderId,
                    teamsite_id: teamsiteId,
                    page,
                  })
                }
                loading={seismicContainersLoading}
                selectorReset={folderReset}
                handleOpened={handleFolderSelectorOpened}
              />
            </div>
          )
        )}
      </section>
      <section className="sync-option-section">
        <BFStandardRadio
          checked={syncOptionalResourceOptionSelected}
          onChange={() => {
            dispatch(seismicSetSyncEntireBrandfolderOptionSelected({ value: false }));
            dispatch(seismicSetSyncOptionalResourceOptionSelected({ value: true }));
            resetMappings();
          }}
          // Address input styling that is wrapped around whole create-workflow
          className="sync-option-radio-button"
          name="entire_folder_map"
          value="entire_folder_map"
          label={'Sync Brandfolder section(s) and/or collection(s)'}
          disabled={!bfSource || !teamsiteId}
          data-testid="optional-map-radio"
        />
        <CreateWorkflowSyncRow
          sectionLabel="SECTION(S)"
          label="Sync Brandfoldere section(s)"
          bfInfo={bfSections}
          rehydratedMaps={seismicRehydratedSectionMaps}
          src={{
            label: 'Brandfolder section',
            placeHolder: 'Choose section',
            allowMultiSelect: true,
            selectionOptions: alphabetizBfOptions(bfSections) as BFSelectOption[],
          }}
          destination={{
            label: 'Seismic folder',
            placeHolder: 'Choose folder',
            selectionOptions: folders,
          }}
          disabled={!bfSource || !teamsiteId}
          mapType="section_to_folder"
          sectionType="optional"
          control={control}
          displaySection={syncOptionalResourceOptionSelected}
        />
        <CreateWorkflowSyncRow
          sectionLabel="COLLECTION(S)"
          label="Sync Brandfolder collections(s)"
          bfInfo={bfCollections}
          rehydratedMaps={seismicRehydratedCollectionMaps}
          src={{
            label: 'Brandfolder collection',
            placeHolder: 'Choose collection',
            allowMultiSelect: true,
            selectionOptions: alphabetizBfOptions(bfCollections) as BFSelectOption[],
          }}
          destination={{
            label: 'Seismic folder',
            placeHolder: 'Choose folder',
            selectionOptions: folders,
          }}
          disabled={!bfSource || !teamsiteId}
          mapType="collection_to_folder"
          sectionType="optional"
          control={control}
          displaySection={syncOptionalResourceOptionSelected}
        />
      </section>
    </section>
  );
};
