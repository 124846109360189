import React from 'react';
import { FontSizes, FontWeights, StandardText } from '@brandfolder/react';
import './review-page.scss';
import { InfoBanner } from '../info-banner/info-banner';

export interface ReviewHeaderProps {
  reviewOnlyActive: boolean;
}

export const ReviewHeader = ({ reviewOnlyActive }: ReviewHeaderProps) => {
  return (
    <>
      {!reviewOnlyActive && (
        <InfoBanner
          headerText="Workflow will be published as inactive"
          bodyText="All workflows are published as inactive to give colleagues the chance to review them. You
          can make a workflow active on the client’s Workflows page. When a workflow is made active,
          all assets will be synced."
        />
      )}
      <StandardText
        className="review-page-header"
        size={FontSizes.XxLarge}
        weight={FontWeights.Bold}
        data-testid="review-page-header"
      >
        Review
      </StandardText>
    </>
  );
};
