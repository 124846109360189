import {  put, takeEvery } from 'typed-redux-saga';
import { callWithTokenRefresh, clientEntityActions } from '@integration-frontends/workflow-manager/core/application';
import { CLIENT_REPO_TOKEN, IClientRepo } from '@integration-frontends/workflow-manager/core/model';
import { DI_CONTAINER } from '@integration-frontends/core';
import { pageLoaded, pageLoadError } from '@integration-frontends/common/app';
import {
  clientsLoading,
  clientsLoadingFail,
  clientsLoadingSuccess,
  clientsPageEntered,
} from '../../index';

function* fetchAllClients() {
  yield put(clientsLoading());
  try {
    const clientRepo: IClientRepo = DI_CONTAINER.get(CLIENT_REPO_TOKEN);
    const perPage = '50'; // Number of results per page
    let page = 1; // Start with the first page
    let allClients = []; // Accumulate all clients here
    let totalPages = 1; // Initialize to 1 to enter the loop

    while (page <= totalPages) {
      // Fetch the current page of data
      const response = yield callWithTokenRefresh(() => clientRepo.listClients(perPage, page.toString()));
      const { clients } = response.data;
      // Add the clients to the result array
      allClients = allClients.concat(clients);

      // Update the page and total pages counters
      page++;
      totalPages = response.meta.total_pages;
    }
    yield put(clientsLoadingSuccess());
    return allClients;
  } catch (e) {
    yield put(clientsLoadingFail());
    yield put(pageLoadError({ error: e }));
  }
}

function* handler() {
  try {
    const clients = yield fetchAllClients();
    yield put(clientEntityActions.clientsReceived(clients));
    yield put(pageLoaded());
  } catch (e) {
    yield put(pageLoadError({ error: e }));
  }
}

export function* enteredClientsPageEffects() {
  yield takeEvery(clientsPageEntered, handler);
}
