import { call, put, takeEvery } from 'typed-redux-saga';
import { createNotification, NotificationType } from '@integration-frontends/common/notifications';
import {
  salsifyFetchInfo,
  salsifyFetchPaginatedProperties,
  salsifyRecieveOrganizations,
  salsifySetLoadingProperties,
  salsifySetProductIdPropName,
} from '@integration-frontends/workflow-manager/core/application';
import {
  SALSIFY_NAME_KEY,
  SalsifyOrganization,
  SalsifyFormInfoResponse,
} from '@integration-frontends/workflow-manager/core/model';
import { pageLoadError } from '@integration-frontends/common/app';

function* handler(action: ReturnType<typeof salsifyFetchInfo>) {
  try {
    const { clientId, credential_id, salsify_org_id } = action.payload;

    if (salsify_org_id) {
      // if org id is present in the payload, then the current operation is to fetch properties.
      yield put(salsifySetLoadingProperties({ loadingProperties: true }));
      yield put(
        createNotification({
          message: 'Loading Salsify properties. This may take a few minutes.',
          location: 'topLevel',
          type: NotificationType.Info,
          style: { whiteSpace: 'nowrap' },
        }),
      );
    }

    const data: SalsifyFormInfoResponse = yield call(
      salsifyFetchPaginatedProperties,
      clientId,
      credential_id,
      salsify_org_id,
    );

    if ((data.organizations as SalsifyOrganization[]).length) {
      yield put(salsifyRecieveOrganizations({ organizations: data.organizations }));
    } else if (data.propertiesByType) {
      yield put(
        salsifySetProductIdPropName({
          productIdPropName: data.propertiesByType.productIdProp[SALSIFY_NAME_KEY],
        }),
      );
    } else {
      throw new Error('Failed to fetch Salsify form info: empty response.');
    }
  } catch (e) {
    yield put(pageLoadError({ error: e }));
    yield put(
      createNotification({
        message: 'Failed to fetch Salsify Organizations',
        location: 'topLevel',
        type: NotificationType.Error,
      }),
    );
  }
}

export function* salsifyFetchInfoEffects() {
  yield takeEvery(salsifyFetchInfo, handler);
}
