import { StateSelector } from "@integration-frontends/common/utils/redux";
import { credentialAdapter } from "../entities";
import { Credential } from "@integration-frontends/workflow-manager/core/model";
import { createSelector, EntityState } from '@reduxjs/toolkit';

export function createCredentialSelectors(stateSelector: StateSelector<EntityState<Credential>>) {
  const baseSelectors = credentialAdapter.getSelectors(stateSelector);

  const credentialsByType = (type: string) =>
    createSelector(baseSelectors.selectAll, (credentials) => {
      return credentials.filter(credential => credential.credential_type === type);
    })

  const credentialById = (credentialId: string) =>
    createSelector(baseSelectors.selectAll, (credentials) => {
      return credentials
        .find(credential => credential?.id === credentialId)
    })

  const credentialsByTypeAndBfAccountId = (brandfolderAccountId: string, type: string) =>
    createSelector(baseSelectors.selectAll, (credentials) => {
      return credentials
        .filter(credential => credential?.credential_type === type)
        .filter(credential => credential?.brandfolder_account_id.toString() === brandfolderAccountId);
    })

  return {
    ...baseSelectors,
    credentialsByType,
    credentialById,
    credentialsByTypeAndBfAccountId,
  }
}
