import { ReviewSection, ReviewSectionLine } from '../../../common';
import { push } from 'redux-first-history';
import {
  selectSelectedClientId,
  clientEntitySelectors,
  selectCreateWorkflowSuccess,
  selectWorkfrontWorkflowName,
  selectWorkfrontDocumentCustomFields,
  selectWorkfrontUserCustomFields,
  selectWorkfrontProjectCustomFields,
  selectWorkfrontPortfolioCustomFields,
  selectWorkfrontProgramCustomFields,
  selectWorkfrontTags,
  selectWorkfrontAddAssetLink,
  selectWorkfrontHost,
  initEditWorkflow,
  selectSelectedWorkflowId,
  selectWorkfrontClientId,
  selectWorkfrontUserId,
  selectWorkfrontCustomerId,
  selectWorkfrontClientSecret,
} from '@integration-frontends/workflow-manager/core/application';

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { workflowServiceType } from '@integration-frontends/workflow-manager/core/model';
import { generateWorkfrontForms, generateWorkfrontProjectForms } from './common/utils';

export const WorkfrontReview = ({ setComplete }) => {
  const selectedClientId = useSelector(selectSelectedClientId);
  const selectedClient = useSelector((state) =>
    clientEntitySelectors.selectById(state, selectedClientId),
  );
  const workflowName = useSelector(selectWorkfrontWorkflowName);
  const workfrontDocumentCustomFields = useSelector(selectWorkfrontDocumentCustomFields);
  const workfrontUserCustomFields = useSelector(selectWorkfrontUserCustomFields);
  const workfrontProjectCustomFields = useSelector(selectWorkfrontProjectCustomFields);
  const workfrontPortfolioCustomFields = useSelector(selectWorkfrontPortfolioCustomFields);
  const workfrontProgramCustomFields = useSelector(selectWorkfrontProgramCustomFields);
  const tags = useSelector(selectWorkfrontTags);
  const addAssetLink = useSelector(selectWorkfrontAddAssetLink);
  const subDomain = useSelector(selectWorkfrontHost);
  const workflowId = useSelector(selectSelectedWorkflowId);
  const clientId = useSelector(selectWorkfrontClientId);
  const clientSecret = useSelector(selectWorkfrontClientSecret);
  const userId = useSelector(selectWorkfrontUserId);
  const customerId = useSelector(selectWorkfrontCustomerId);
  const dispatch = useDispatch();

  const createWorkflowSuccess = useSelector(selectCreateWorkflowSuccess);

  const onEdit = () => {
    dispatch(initEditWorkflow({ integrationType: workflowServiceType.workfront, workflowId }));
  };

  useEffect(() => {
    if (createWorkflowSuccess) {
      dispatch(push('/workflows'));
    }
  }, [createWorkflowSuccess]);

  useEffect(() => {
    setComplete(!!(workflowName && selectedClient));
  }, [workflowName]);

  const workfrontForms = generateWorkfrontForms(
    workfrontDocumentCustomFields,
    workfrontUserCustomFields,
    workfrontProjectCustomFields,
  );

  const workfrontProjectForms = generateWorkfrontProjectForms(
    workfrontPortfolioCustomFields,
    workfrontProgramCustomFields,
  );

  return (
    <>
      <ReviewSection title="Organization info" dataTestId="workfront-organization-review-section">
        <ReviewSectionLine
          title="Client"
          value={
            <div data-testid="workfront-selected-client-name">{selectedClient?.client_name}</div>
          }
        />
      </ReviewSection>

      <ReviewSection title="Workfront info" dataTestId="workfront-info-review-section">
        <ReviewSectionLine
          title="Workfront subdomain"
          value={<div data-testid="workfront-subdomain">{subDomain}</div>}
        />
        <ReviewSectionLine
          title="Workfront client ID"
          value={<div data-testid="workfront-client-id">{clientId}</div>}
        />
        <ReviewSectionLine
          title="Workfront client secret"
          value={<div data-testid="workfront-client-secret">{clientSecret}</div>}
        />
        <ReviewSectionLine
          title="Workfront user ID"
          value={<div data-testid="workfront-user-id">{userId}</div>}
        />
        <ReviewSectionLine
          title="Workfront customer ID"
          value={<div data-testid="workfront-customer-id">{customerId}</div>}
        />
      </ReviewSection>

      <ReviewSection
        title="Workflow info"
        dataTestId="workfront-workflow-review-section"
        editAction={onEdit}
      >
        <ReviewSectionLine
          title="Workflow name"
          value={<div data-testid="workfront-workflow-name">{workflowName}</div>}
        />
        {workflowId && (
          <ReviewSectionLine
            title="Workflow ID"
            value={<div data-testid="workfront-workflow-id">{workflowId || ''}</div>}
          />
        )}
        {workfrontForms?.length > 0 && (
          <ReviewSectionLine
            title="Workfront forms (optional)"
            value={
              <ul data-testid="workfront-forms">
                {workfrontForms?.map((form) => (
                  <li key={form}>
                    {Object.entries(form).map(([key, value]) => (
                      <span>{value}</span>
                    ))}
                  </li>
                ))}
              </ul>
            }
          />
        )}
        {workfrontProjectForms?.length > 0 && (
          <ReviewSectionLine
            title="Project forms (optional)"
            value={
              <ul data-testid="workfront-project-forms">
                {workfrontProjectForms?.map((projectForm) => (
                  <li key={projectForm}>
                    {Object.entries(projectForm).map(([key, value]) => (
                      <span>{value}</span>
                    ))}
                  </li>
                ))}
              </ul>
            }
          />
        )}
        {tags?.length > 0 && (
          <ReviewSectionLine
            title="Form fields to tags (optional)"
            value={<div data-testid="workfront-form-fields">{tags?.join(', ')}</div>}
          />
        )}
        <ReviewSectionLine
          title="Add asset link to document description"
          value={
            <div data-testid="workfront-add-asset-link">{addAssetLink === true ? 'Yes' : 'No'}</div>
          }
        />
      </ReviewSection>
    </>
  );
};
