import React, { useEffect } from 'react';
import { push } from 'redux-first-history';
import { useDispatch, useSelector } from 'react-redux';
import { ReviewSection, ReviewSectionLine } from '../../../common';
import {
  credentialEntitySelectors,
  initEditWorkflow,
  selectCreateWorkflowSuccess,
  selectSelectedWorkflowId,
  selectSelectedWrikeSyncingPreferences,
  selectWrikeBrandfolder,
  selectWrikeCredentialId,
  selectWrikeSelectedResourceId,
  selectWrikeSpaces,
  selectWrikeWorkflowName,
} from '@integration-frontends/workflow-manager/core/application';

import {
  selectSelectedIntegrationType,
  selectSelectedClientId,
  clientEntitySelectors,
  brandfolderEntitySelectors,
  selectWrikeSelectedSectionName,
  selectWrikeSelectedCollectionName,
} from '@integration-frontends/workflow-manager/core/application';
import { WrikeFolder } from '@integration-frontends/common/temporal-api';
import { workflowServiceType } from '@integration-frontends/workflow-manager/core/model';

export const WrikeReview = ({ setComplete }) => {
  const dispatch = useDispatch();

  const createWorkflowSuccess = useSelector(selectCreateWorkflowSuccess);

  useEffect(() => {
    if (createWorkflowSuccess) {
      dispatch(push('/workflows'));
    }
  }, [createWorkflowSuccess]);

  const selectedCredentialId = useSelector(selectWrikeCredentialId);
  const credential = useSelector(credentialEntitySelectors.credentialById(selectedCredentialId));

  const workflowId = useSelector(selectSelectedWorkflowId);
  const selectWorkflowName = useSelector(selectWrikeWorkflowName);
  const selectedClientId = useSelector(selectSelectedClientId);
  const selectedClient = useSelector((state) =>
    clientEntitySelectors.selectById(state, selectedClientId),
  );

  const selectedBrandfolderId = useSelector(selectWrikeBrandfolder);
  const selectedBrandfolder = useSelector((state) =>
    brandfolderEntitySelectors.selectById(state, selectedBrandfolderId),
  );
  const selectWrikeSyncingPreferences = useSelector(selectSelectedWrikeSyncingPreferences);
  const wrikeSpaces: WrikeFolder[] = useSelector(selectWrikeSpaces);
  const wrikeResourceId = useSelector(selectWrikeSelectedResourceId);
  const selectedFolder = wrikeSpaces?.find((space) => space.id === wrikeResourceId);
  const integrationType = useSelector(selectSelectedIntegrationType);

  const sectionName = useSelector(selectWrikeSelectedSectionName);
  const collectionName = useSelector(selectWrikeSelectedCollectionName);

  const brandfolderDestinations = [selectedBrandfolder?.name, sectionName, collectionName];

  const onEdit = () => {
    dispatch(initEditWorkflow({ integrationType: workflowServiceType.salsify, workflowId }));
  };

  useEffect(() => {
    setComplete(!!(selectedClientId && selectedBrandfolderId && selectedFolder && integrationType));
  }, [selectedClientId, selectedBrandfolderId, selectedFolder, integrationType]);

  return (
    <>
      <ReviewSection title="Organization info" dataTestId="wrike-organization-review-section">
        <ReviewSectionLine
          title="Client"
          value={<div data-testid="wrike-selected-client-name">{selectedClient.client_name}</div>}
        />
        <ReviewSectionLine
          title="Brandfolder API key"
          value={<div data-testid="wrike-brandfolder-api-key">{credential?.api_key}</div>}
        />
      </ReviewSection>

      <ReviewSection title="Wrike info" dataTestId="wrike-info-review-section">
        <ReviewSectionLine
          title="Credential"
          value={<div data-testid="wrike-credential-name">{credential?.credential_name}</div>}
        />
      </ReviewSection>

      <ReviewSection
        title="Workflow info"
        dataTestId="wrike-workflow-review-section"
        editAction={onEdit}
      >
        <ReviewSectionLine
          title="Workflow name"
          value={<div data-testid="wrike-workflow-name">{selectWorkflowName}</div>}
        />
        {workflowId && (
          <ReviewSectionLine
            title="Workflow ID"
            value={<div data-testid="wrike-workflow-id">{workflowId || ''}</div>}
          />
        )}
        <ReviewSectionLine
          title="Wrike source"
          value={<div data-testid="wrike-source-name">{selectedFolder?.title}</div>}
        />
        <ReviewSectionLine
          title="Syncing preference"
          value={
            <div data-testid="wrike-syncing-preferences">
              {selectWrikeSyncingPreferences
                ? 'Sync assets in this folder and any child folders'
                : 'Only sync assets in this folder'}
            </div>
          }
        />
        <ReviewSectionLine
          title="Brandfolder destinations"
          value={
            <div data-testid="wrike-brandfolder-destinations">
              {brandfolderDestinations.filter((item) => item !== undefined).join('  -  ')}
            </div>
          }
        />
      </ReviewSection>
    </>
  );
};
