import React from 'react';
import {
  Colors,
  FontIcon,
  FontIconColors,
  FontIcons,
  Placements,
  StandardLabel,
  StandardTextfield,
  StandardTooltip,
  TooltipTypes,
  Widths,
} from '@brandfolder/react';
import './create-workflow-input-field.scss';
import { UseFormRegisterReturn } from 'react-hook-form';

interface InputFieldProps {
  label: string;
  value?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  inputId?: string;
  tooltip?: string;
  placeholder?: string;
  validation?: UseFormRegisterReturn;
  className?: string;
}

export const CreateWorkflowInputField = ({
  label,
  value,
  onChange,
  inputId,
  tooltip,
  placeholder,
  validation,
}: InputFieldProps) => {
  return (
    <div className="create-workflow-input-field-container" data-testid={inputId}>
      <div className="create-workflow-input-container">
        <StandardLabel htmlFor={inputId}>{label}</StandardLabel>
        {tooltip && (
          <div className="create-workflow-tooltip-icon">
            <StandardTooltip
              color={Colors.Primary}
              defaultOpen={false}
              id={`${inputId}-tooltip`}
              placement={Placements.TopCenter}
              tooltip={tooltip}
              triggerOffset={8}
              type={TooltipTypes.Description}
              width={Widths.Medium}
            >
              <FontIcon icon={FontIcons.ToolTip} color={FontIconColors.Primary} />
            </StandardTooltip>
          </div>
        )}
      </div>
      <StandardTextfield
        id={inputId}
        label={label}
        showLabel={false}
        data-testid={`${inputId}-input`}
        value={value}
        placeholder={placeholder}
        {...validation}
        onChange={(e) => {
          validation.onChange(e), onChange(e);
        }}
        className="create-workflow-input-field-input"
      />
    </div>
  );
};
