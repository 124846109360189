import {
  CreateCredentialBody,
} from '@integration-frontends/common/temporal-api';

export const CREDENTIAL_REPO_TOKEN = 'CREDENTIAL_REPO';
export const DEFAULT_HIGHSPOT_API_HOST = 'https://api.highspot.com/v0.5'

export interface ICredentialRepo {
  listCredentials: (clientId: string) => Promise<any[]>;
  createCredential: (clientId: string, credentialBody: CreateCredentialBody) => Promise<any>;
}
