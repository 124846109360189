import {
  Colors,
  FontIcon,
  FontIconColors,
  FontIcons,
  Placements,
  StandardTooltip,
  TooltipTypes,
  Widths,
} from '@brandfolder/react';
import { BFLabel } from '@integration-frontends/common/ui';
import * as React from 'react';

export const OptionalCustomFieldMappingsLabel = (): React.ReactElement => {
  return (
    <div className="salsify-property-label-wrapper">
      <BFLabel
        className="select-default-salsify-property"
        htmlFor="select-default-salsify-property"
      >
        OPTIONAL CUSTOM FIELD MAPPING
        <StandardTooltip
          color={Colors.Primary}
          defaultOpen={false}
          id="optional-custom-field-mapping"
          placement={Placements.TopCenter}
          tooltip={
            'Choose where you want images with certain Brandfolder custom field values to appear in Salsify.'
          }
          triggerOffset={8}
          type={TooltipTypes.Description}
          width={Widths.Medium}
        >
          <FontIcon icon={FontIcons.ToolTip} iconSize={13} color={FontIconColors.Primary} />
        </StandardTooltip>
      </BFLabel>
    </div>
  );
};
