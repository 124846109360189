import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { BFSelectOption } from '@integration-frontends/common/ui/forms/form-controls/select';
import cronTime from 'cron-time-generator';
import { useFormContext } from 'react-hook-form';

import {
  selectGettyState,
  selectSelectedCredentialId,
  gettySetCredentialId,
  selectBrandfolderFormInfo,
  workflowModes,
  selectWorkflowMode,
} from '@integration-frontends/workflow-manager/core/application';

import '../../create-workflow-form.scss';
import { WorkflowInfo } from '../../common/components/workflow-info/workflow-info';
import { SyncTimeframeSection } from '../../common/components/sync-folder-section/sync-timeframe-section';
import { DestinationSection } from '../../common/components/destination-section/destination-section';
import { WorkflowName } from '../../common/components/workflow-name.tsx/workflow-name';

export interface CreateGettyWorkflowContainerProps {
  formComplete: (c: boolean) => void;
}

export const CreateGettyWorkflowFormContainer = ({
  formComplete,
}: CreateGettyWorkflowContainerProps) => {
  const { watch, reset } = useFormContext();
  const dispatch = useDispatch();
  const credential_id = useSelector(selectSelectedCredentialId);
  const defaultValues = useSelector(selectGettyState);
  const mode = useSelector(selectWorkflowMode);
  const editing = mode === workflowModes.edit;

  const workflowName = watch('workflow_name');
  const syncStartDate = watch('sync_start_date');
  const syncEndDate = watch('sync_end_date');
  const cronSchedule = watch('cron_schedule');
  const bfDestinationkey = watch('bf_destination_key');

  useEffect(() => {
    dispatch(gettySetCredentialId({ credential_id }));
    reset(defaultValues);
  }, []);

  // Checks if the form is complete, disables Next if not
  useEffect(() => {
    const validSyncDates = syncStartDate < syncEndDate;
    if (
      workflowName &&
      syncStartDate &&
      syncEndDate &&
      validSyncDates &&
      bfDestinationkey &&
      cronSchedule
    ) {
      formComplete(true);
    } else {
      formComplete(false);
    }
  }, [workflowName, syncStartDate, syncEndDate, bfDestinationkey, cronSchedule]);

  const cronOptions: BFSelectOption[] = [
    { label: 'Every 10 Minutes', value: cronTime.every(10).minutes() },
    { label: 'Every 15 Minutes', value: cronTime.every(15).minutes() },
    { label: 'Every Hour', value: cronTime.everyHour() },
    { label: 'Every Day', value: cronTime.everyDay() },
    { label: 'Every Sunday', value: cronTime.everySunday() },
    { label: 'Every Monday', value: cronTime.everyMonday() },
    { label: 'Every Tuesday', value: cronTime.everyTuesday() },
    { label: 'Every Wednesday', value: cronTime.everyWednesday() },
    { label: 'Every Thursday', value: cronTime.everyThursday() },
    { label: 'Every Friday', value: cronTime.everyFriday() },
    { label: 'Every Saturday', value: cronTime.everySaturday() },
    { label: 'Every WeekDay', value: cronTime.everyWeekDay() },
    { label: 'Every Weekend', value: cronTime.everyWeekend() },
    { label: 'Every Week', value: cronTime.everyWeek() },
  ];

  const brandfolderFormInfo = useSelector(selectBrandfolderFormInfo);

  const bfSources = brandfolderFormInfo?.map((bf) => {
    return { label: bf.name, value: bf.id, sections: bf.sections, collections: bf.collections };
  });

  const bfSource = watch('bf_destination_key');

  const bfSections = bfSources
    ?.find((bf) => bf.value === bfSource)
    ?.sections?.data.map((section) => {
      return { label: section.name, value: section.id };
    });

  const bfCollections = bfSources
    ?.find((bf) => bf.value === bfSource)
    ?.collections?.data.map((collection) => {
      return { label: collection.name, value: collection.id };
    });

  return (
    <section className="forms-content-container" data-testid="getty-workflow-form-container">
      <WorkflowInfo
        linkToDocumentation={
          'https://smar-wiki.atlassian.net/wiki/spaces/PROD/pages/70971457682/Guide+IWM+-+Getty'
        }
        linkTestId="getty-documentation-link"
      />
      <WorkflowName />
      <SyncTimeframeSection cronOptions={cronOptions} editing={editing} />
      <DestinationSection
        bfSource={bfSource}
        bfSources={bfSources}
        bfSections={bfSections}
        bfCollections={bfCollections}
      />
    </section>
  );
};
