import { BFLabel, BFSelect, BFSelectOption } from '@integration-frontends/common/ui';
import React from 'react';
import { FieldArrayWithId, useFormContext, useWatch } from 'react-hook-form';
import { Mappings } from '../dest-config';
import { CustomFieldMappings } from '../../multi-select-salsify-properties/multi-select-salsify-properties';
import './select-salsify-property.scss';
import classNames from 'classnames';
import { ShimmerLoader } from '@brandfolder/react';

export enum SalsifyFieldArrayName {
  CustomFieldMap = 'custom_field_map',
  DestConfig = 'dest_config',
}

export type FieldArrayType =
  | FieldArrayWithId<Mappings, SalsifyFieldArrayName.DestConfig, 'id'>
  | FieldArrayWithId<CustomFieldMappings, SalsifyFieldArrayName.CustomFieldMap, 'id'>;

export interface SelectSalsifyPropertyProps {
  propsSources: BFSelectOption[];
  fieldArrayName: SalsifyFieldArrayName.DestConfig | SalsifyFieldArrayName.CustomFieldMap;
  fields: FieldArrayType[];
  index: number;
  loadingProperties?: boolean;
}

export const SelectSalsifyProperty = ({
  propsSources,
  fieldArrayName,
  index,
  loadingProperties,
}: SelectSalsifyPropertyProps): React.ReactElement => {
  const {
    control,
    formState: { errors },
    register,
    setValue,
    watch,
  } = useFormContext<Mappings | CustomFieldMappings>();

  const mappings = useWatch({
    name: fieldArrayName,
    control,
  }) as unknown as FieldArrayType;

  let customFieldKey;
  let customFieldValue;

  if (fieldArrayName === SalsifyFieldArrayName.DestConfig) {
    customFieldKey = watch(`dest_config.${index}.key`);
    customFieldValue = watch(`dest_config.${index}.value`);
  }
  return (
    <div className="optional-mapping-row-item">
      <BFLabel className="form-label" htmlFor="optional-salsify-prop">
        Salsify property
      </BFLabel>
      {loadingProperties ? (
        <ShimmerLoader
          className={`workflow-shimmer-loader ${
            fieldArrayName === SalsifyFieldArrayName.DestConfig && 'workflow-shimmer-loader-narrow'
          }`}
        />
      ) : (
        <BFSelect
          className={classNames({
            'select-salsify-dest-config': fieldArrayName === SalsifyFieldArrayName.DestConfig,
            'select-salsify-custom-field-map':
              fieldArrayName === SalsifyFieldArrayName.CustomFieldMap,
          })}
          data-testid={classNames({
            [`select-salsify-dest-config-${index}`]:
              fieldArrayName === SalsifyFieldArrayName.DestConfig,
            [`select-salsify-custom-field-map-${index}`]:
              fieldArrayName === SalsifyFieldArrayName.CustomFieldMap,
          })}
          id="salsify-prop"
          required={true}
          options={propsSources || []}
          {...(fieldArrayName === SalsifyFieldArrayName.DestConfig &&
            customFieldKey &&
            customFieldValue &&
            register(`${fieldArrayName}.${index}.salsifyProp`))}
          {...(fieldArrayName === SalsifyFieldArrayName.CustomFieldMap &&
            register(`${fieldArrayName}.${index}.salsifyProp`))}
          onOptionChange={(listOption): void => {
            setValue(
              `${fieldArrayName}.${index}.salsifyProp`,
              (listOption?.value as string) || ('' as string),
              {
                shouldValidate: true,
                shouldDirty: true,
              },
            );
          }}
          value={propsSources?.find((da) => da.value === mappings[index].salsifyProp)?.value}
          placeholder={'Choose Property'}
        />
      )}
    </div>
  );
};
