import {
  WorkflowsActiveOption,
  WorkflowsSortOption,
} from '@integration-frontends/workflow-manager/core/model';

export interface WorkflowsSort {
  label: string;
  value: WorkflowsSortOption;
}

export interface WorkflowsActiveFilter {
  label: string;
  placeholder?: boolean;
  value?: WorkflowsActiveOption;
}

export const workflowsSortOptions: WorkflowsSort[] = [
  { label: 'Workflow name (A-Z)', value: 'az' },
  { label: 'Workflow name (Z-A)', value: 'za' },
  { label: 'Created (oldest)', value: 'createdOldest' },
  { label: 'Created (newest)', value: 'createdNewest' },
  { label: 'Updated (oldest)', value: 'updatedOldest' },
  { label: 'Updated (newest)', value: 'updatedNewest' },
  { label: 'Integration (A-Z)', value: 'integrationAz' },
  { label: 'Integration (Z-A)', value: 'integrationZa' },
];

export const activeFilterOptions: WorkflowsActiveFilter[] = [
  { label: 'All', value: 'all' },
  { label: 'Active', value: 'active' },
  { label: 'Inactive', value: 'inactive' },
];
