import React, { useState } from 'react';
import { VerticalDividerLine, IconSize, IconCloseThin } from '@integration-frontends/common/ui';
import { ExitModal } from '../exit-modal';
import { ButtonLooks, FontWeights, StandardButton, StandardText } from '@brandfolder/react';
import './create-workflow-nav.scss';

export interface CreateWorkflowNavProps {
  title: string;
  editing?: boolean;
  // hideBackButton provides a general override if Back button should be hidden in non-editting scenarios
  hideBackButton?: boolean;
  onBack?: () => void;
  onNext?: () => void;
  onNextActionName?: string;
}

// Change editing to just editting? bool and if it's true, hide the back button and do the cancel button instead.
// Cancel button should fire the onBack function if it exists, otherwise just go back to the /workflows route to show client workflows.

export const CreateWorkflowNav = ({
  hideBackButton,
  title,
  onBack,
  onNext,
  onNextActionName,
  editing,
}: CreateWorkflowNavProps) => {
  const [closePromptOpen, setClosePromptOpen] = useState(false);
  return (
    <>
      <nav data-testid="create-workflow-nav" className="create-workflow-nav">
        <div className="create-workflow-nav-left">
          <IconCloseThin
            className="icon-close-thin"
            iconSize={IconSize.Small}
            onClick={() => setClosePromptOpen(true)}
          />
          <VerticalDividerLine height={48} />
          <StandardText
            className="create-workflow-nav-title"
            data-testid="create-workflow-nav-title"
            weight={FontWeights.Bold}
          >
            {title}
          </StandardText>
        </div>
        <div>
          {!hideBackButton &&
            (editing ? (
              // when editing, back button should be hidden and replaced with cancel button
              <StandardButton
                data-testid="prev-nav-cancel"
                onClick={onBack}
                disabled={!onBack}
                look={ButtonLooks.Tertiary}
                className="mr-md"
              >
                Cancel
              </StandardButton>
            ) : (
              <StandardButton
                data-testid="prev-nav"
                onClick={onBack}
                disabled={!onBack}
                look={ButtonLooks.Tertiary}
                className="mr-md"
              >
                Back
              </StandardButton>
            ))}
          <StandardButton data-testid="next-nav" onClick={onNext} disabled={!onNext}>
            {onNextActionName || 'Next'}
          </StandardButton>
        </div>
      </nav>
      {closePromptOpen && <ExitModal setOpen={setClosePromptOpen} />}
    </>
  );
};
