import {
  Colors,
  FontIcon,
  FontIconColors,
  FontIcons,
  Placements,
  ShimmerLoader,
  StandardTooltip,
  TooltipTypes,
  Widths,
} from '@brandfolder/react';
import { BFLabel, BFSelect, BFSelectOption } from '@integration-frontends/common/ui';
import { alphabetizBfOptions } from 'libs/workflow-manager/ui/src/common/functions/alphabetize';
import * as React from 'react';
import { useFormContext } from 'react-hook-form';
import './select-default-property.scss';

export interface SelectDefaultPropertyProps {
  digitalAssetPropsSources: BFSelectOption[];
  salsifyDefaultProperty: string;
  loadingProperties?: boolean;
}

export const SelectDefaultProperty = ({
  digitalAssetPropsSources,
  salsifyDefaultProperty,
  loadingProperties,
}: SelectDefaultPropertyProps): React.ReactElement => {
  const {
    register,
    setValue,
    formState: { errors },
  } = useFormContext();

  return (
    <div className="salsify-form-row">
      <div className="salsify-form-row-item">
        <div className="salsify-property-label-wrapper">
          <BFLabel className="form-label" htmlFor="select-default-salsify-property">
            Default Salsify property
            <StandardTooltip
              color={Colors.Primary}
              defaultOpen={false}
              id="workflow-optional-info-tooltip"
              placement={Placements.TopCenter}
              tooltip={'This is where assets will appear in Salsify.'}
              triggerOffset={8}
              type={TooltipTypes.Description}
              width={Widths.Medium}
            >
              <FontIcon icon={FontIcons.ToolTip} iconSize={13} color={FontIconColors.Primary} />
            </StandardTooltip>
          </BFLabel>
        </div>
        {loadingProperties ? (
          <ShimmerLoader className="workflow-shimmer-loader" />
        ) : (
          <BFSelect
            data-testid="select-default-salsify-property"
            id="select-default-salsify-property"
            required={true}
            options={(alphabetizBfOptions(digitalAssetPropsSources) as BFSelectOption[]) || []}
            {...register('default_property')}
            onOptionChange={(listOption): void => {
              setValue('default_property', listOption?.value || ('' as string), {
                shouldValidate: true,
                shouldDirty: true,
              });
            }}
            value={
              digitalAssetPropsSources?.find((da) => da.value === salsifyDefaultProperty)?.value
            }
            placeholder={'Choose property'}
          />
        )}
      </div>
    </div>
  );
};
