import React, { useEffect } from 'react';
import { push } from 'redux-first-history';
import { useDispatch, useSelector } from 'react-redux';
import { ReviewSection, ReviewSectionLine } from '../../../common';
import {
  credentialEntitySelectors,
  initEditWorkflow,
  selectCreateWorkflowSuccess,
  selectHubspotWorkflowName,
  selectSelectedWorkflowId,
} from '@integration-frontends/workflow-manager/core/application';

import {
  selectSelectedIntegrationType,
  selectSelectedClientId,
  selectHubspotBrandfolder,
  selectHubspotFolderName,
  clientEntitySelectors,
  brandfolderEntitySelectors,
  selectHubspotSelectedSectionNames,
  selectHubspotSelectedCollectionName,
  selectHubspotCredentialID,
} from '@integration-frontends/workflow-manager/core/application';
import { workflowTitle } from '@integration-frontends/workflow-manager/core/model';
import { workflowServiceType } from '@integration-frontends/workflow-manager/core/model';
import { ShimmerLoader } from '@brandfolder/react';

export const HubspotReview = ({ setComplete }) => {
  const dispatch = useDispatch();

  const createWorkflowSuccess = useSelector(selectCreateWorkflowSuccess);

  useEffect(() => {
    if (createWorkflowSuccess) {
      dispatch(push('/workflows'));
    }
  }, [createWorkflowSuccess]);

  const workflowId = useSelector(selectSelectedWorkflowId);
  const selectWorkflowName = useSelector(selectHubspotWorkflowName);
  const selectedClientId = useSelector(selectSelectedClientId);
  const selectedClient = useSelector((state) =>
    clientEntitySelectors.selectById(state, selectedClientId),
  );

  const selectedBrandfolderId = useSelector(selectHubspotBrandfolder);
  const selectedBrandfolder = useSelector((state) =>
    brandfolderEntitySelectors.selectById(state, selectedBrandfolderId),
  );

  const selectedFolderName = useSelector(selectHubspotFolderName);
  const integrationType = useSelector(selectSelectedIntegrationType);

  const sectionNames = useSelector(selectHubspotSelectedSectionNames);
  const collectionName = useSelector(selectHubspotSelectedCollectionName);

  const selectedCredentialId = useSelector(selectHubspotCredentialID);
  const credential = useSelector(credentialEntitySelectors.credentialById(selectedCredentialId));

  const onEdit = () => {
    dispatch(initEditWorkflow({ integrationType: workflowServiceType.hubspot, workflowId }));
  };

  useEffect(() => {
    setComplete(
      !!(selectedClientId && selectedBrandfolderId && selectedFolderName && integrationType),
    );
  }, [selectedClientId, selectedBrandfolderId, selectedFolderName, integrationType]);

  return (
    <>
      <ReviewSection title="Organization info" dataTestId="hubspot-organization-review-section">
        <ReviewSectionLine
          title="Client"
          value={
            <div data-testid="hubspot-selected-client-name">{selectedClient?.client_name}</div>
          }
        />
        <ReviewSectionLine
          title="Brandfolder API key"
          value={<div data-testid="hubspot-brandfolder-api-key">{credential?.api_key}</div>}
        />
      </ReviewSection>

      <ReviewSection title="Integration info" dataTestId="hubspot-integration-review-section">
        <ReviewSectionLine
          title="Integration"
          value={<div data-testid="hubspot-integration-type">{workflowTitle[integrationType]}</div>}
        />
        <ReviewSectionLine
          title="HubSpot credential name"
          value={<div data-testid="hubspot-credential-name">{credential?.credential_name}</div>}
        />
      </ReviewSection>

      <ReviewSection
        title="Workflow info"
        dataTestId="hubspot-workflow-review-section"
        editAction={onEdit}
      >
        <ReviewSectionLine
          title="Workflow name"
          value={<div data-testid="hubspot-workflow-name">{selectWorkflowName}</div>}
        />
        {workflowId && (
          <ReviewSectionLine
            title="Workflow ID"
            value={<div data-testid="hubspot-workflow-id">{workflowId || ''}</div>}
          />
        )}
        <ReviewSectionLine
          title="Brandfolder"
          value={
            <div data-testid="hubspot-selected-brandfolder-name">
              {selectedBrandfolder?.name || <ShimmerLoader />}
            </div>
          }
        />
        {sectionNames && (
          <ReviewSectionLine
            title="Brandfolder section(s)"
            value={
              <div data-testid="hubspot-selected-section-names">{sectionNames.join(', ')}</div>
            }
          />
        )}
        {collectionName && (
          <ReviewSectionLine
            title="Brandfolder collection"
            value={<div data-testid="hubspot-selected-collection-name">{collectionName}</div>}
          />
        )}
        <ReviewSectionLine
          title="HubSpot sync folder"
          value={<div data-testid="hubspot-sync-folder">{selectedFolderName}</div>}
        />
      </ReviewSection>
    </>
  );
};
