import { createReducer } from '@reduxjs/toolkit';
import {
  credentialLoading,
  credentialSuccess,
  credentialFailure,
  highspotSetClientKey,
  highspotSetClientSecret,
  gettySetClientKey,
  gettySetClientSecret,
  hubspotSetAccessToken,
  enteredCredentialPage,
  gettySetCredName,
  salsifyApiKey,
  highspotSetCredFormData,
  gettySetCredFormData,
  hubspotSetCredFormData,
  salsifySetCredFormData,
  wrikeSetCredFormData,
  seismicSetCredFormData,
  asanaSetCredFormData,
} from './actions';
import { DEFAULT_HIGHSPOT_API_HOST } from '@integration-frontends/workflow-manager/core/model';

export interface CreateCredentialState {
  credentialLoading: boolean;
  uploading: boolean;
  createCredentialError: string | null;
  highspot: HighspotCredential | null;
  getty: GettyCredential | null;
  hubspot: HubspotCredential | null;
  wrike: WrikeCredential | null;
  asana: AsanaCredential | null;
  salsify: SalsifyCredential | null;
  seismic: SeismicCredential | null;
}

export interface HighspotCredential {
  api_host: string;
  credential_name: string;
  client_key: string;
  client_secret: string;
}

export interface GettyCredential {
  credential_name: string;
  client_key: string;
  client_secret: string;
}

export interface HubspotCredential {
  credential_name: string;
  private_app_key: string;
}

export interface WrikeCredential {
  credential_name: string;
  credential_id: string;
}

export interface AsanaCredential {
  credential_name: string;
  credential_id: string;
}

export interface SalsifyCredential {
  credential_name: string;
  api_key: string;
}

export interface SeismicCredential {
  credential_name: string;
  external_user_id: string;
  client_id: string;
  secret_id: string;
  host: string;
}

export const highspotCredentialInitialState: HighspotCredential = {
  api_host: DEFAULT_HIGHSPOT_API_HOST,
  credential_name: '',
  client_key: '',
  client_secret: '',
};

export const gettyCredentialInitialState: GettyCredential = {
  credential_name: '',
  client_key: '',
  client_secret: '',
};

export const HubspotCredentialInitalState: HubspotCredential = {
  credential_name: '',
  private_app_key: '',
};

export const WrikeCredentialInitialState: WrikeCredential = {
  credential_name: '',
  credential_id: '',
};

export const AsanaCredentialInitialState: AsanaCredential = {
  credential_name: '',
  credential_id: '',
};

export const SalsifyCredentialInitialState: SalsifyCredential = {
  credential_name: '',
  api_key: '',
};

export const SeismicCredentialInitialState: SeismicCredential = {
  credential_name: '',
  external_user_id: '',
  client_id: '',
  secret_id: '',
  host: '',
};

export const createCredentialInitialState: CreateCredentialState = {
  credentialLoading: false,
  createCredentialError: null,
  uploading: false,
  highspot: highspotCredentialInitialState,
  getty: gettyCredentialInitialState,
  hubspot: HubspotCredentialInitalState,
  asana: AsanaCredentialInitialState,
  wrike: WrikeCredentialInitialState,
  salsify: SalsifyCredentialInitialState,
  seismic: SeismicCredentialInitialState,
};

export const createCredentialReducer = createReducer(createCredentialInitialState, (builder) =>
  builder
    .addCase(credentialLoading, (state) => {
      state.credentialLoading = true;
      state.createCredentialError = null;
    })
    .addCase(credentialSuccess, (state) => {
      state.credentialLoading = false;
      state.createCredentialError = null;
    })
    .addCase(credentialFailure, (state, action) => {
      state.credentialLoading = false;
      state.createCredentialError = action.payload.error;
    })
    .addCase(enteredCredentialPage, (state) => {
      return createCredentialInitialState;
    })

    //Highspot

    .addCase(highspotSetClientKey, (state, action) => {
      state.highspot.client_key = action.payload.client_key;
    })
    .addCase(highspotSetClientSecret, (state, action) => {
      state.highspot.client_secret = action.payload.client_secret;
    })
    .addCase(highspotSetCredFormData, (state, action) => {
      const { credential_name, client_key, client_secret, api_host } = action.payload;

      state.highspot.credential_name = credential_name;
      state.highspot.client_key = client_key;
      state.highspot.client_secret = client_secret;
      state.highspot.api_host = api_host;
    })

    //Getty

    .addCase(gettySetCredName, (state, action) => {
      state.getty.credential_name = action.payload.credential_name;
    })
    .addCase(gettySetClientKey, (state, action) => {
      state.getty.client_key = action.payload.client_key;
    })
    .addCase(gettySetClientSecret, (state, action) => {
      state.getty.client_secret = action.payload.client_secret;
    })
    .addCase(gettySetCredFormData, (state, action) => {
      const { credential_name, client_key, client_secret } = action.payload;

      state.getty.credential_name = credential_name;
      state.getty.client_key = client_key;
      state.getty.client_secret = client_secret;
    })

    //HubSpot

    .addCase(hubspotSetAccessToken, (state, action) => {
      state.hubspot.private_app_key = action.payload.private_app_key;
    })
    .addCase(hubspotSetCredFormData, (state, action) => {
      const { credential_name, private_app_key } = action.payload;

      state.hubspot.credential_name = credential_name;
      state.hubspot.private_app_key = private_app_key;
    })

    //Salsify

    .addCase(salsifyApiKey, (state, action) => {
      state.salsify.api_key = action.payload.api_key;
    })
    .addCase(salsifySetCredFormData, (state, action) => {
      const { credential_name, api_key } = action.payload;

      state.salsify.credential_name = credential_name;
      state.salsify.api_key = api_key;
    })

    // Wrike

    .addCase(wrikeSetCredFormData, (state, action) => {
      const { credential_name } = action.payload;

      state.wrike.credential_name = credential_name;
    })

    // Asana

    .addCase(asanaSetCredFormData, (state, action) => {
      const { credential_name } = action.payload;

      state.asana.credential_name = credential_name;
    })

    // Seismic

    .addCase(seismicSetCredFormData, (state, action) => {
      const { credential_name, external_user_id, client_id, secret_id, host } = action.payload;

      state.seismic.credential_name = credential_name;
      state.seismic.external_user_id = external_user_id;
      state.seismic.client_id = client_id;
      state.seismic.secret_id = secret_id;
      state.seismic.host = host;
    }),
);

export interface CreateCredentialRootState {
  createCredentialReducer: CreateCredentialState;
}
