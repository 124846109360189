import {
  AUTHENTICATE_SERVICE_TOKEN,
  IAuthenticateService,
  IDENTITY_STORE_TOKEN,
  IIdentityStore,
} from '@integration-frontends/common/auth/core/model';
import {
  DI_CONTAINER,
  getObservabilityService,
  integrationFrontendsDD,
} from '@integration-frontends/core';
import { call, put } from 'redux-saga/effects';
import { init, initSuccess } from '@integration-frontends/common/app';
import { select, take } from 'typed-redux-saga';
import { persistor } from '../store';
import { getPersistedRoute } from '@integration-frontends/apps/common/routing/route-persistence';
import { sendSegmentIdentifyAction } from '@integration-frontends/common/analytics';
import { clear } from '@integration-frontends/common/auth/core/application';
import { push } from 'redux-first-history';
import {
  selectSelectedCredentialId,
  setSelectedCredentialId,
} from '@integration-frontends/workflow-manager/core/application';
import { environment } from '../../environments/environment';

function* handleInit() {
  yield put(sendSegmentIdentifyAction({}));
  yield resolveStartRoute();
  yield put(initSuccess());
}

function* setCredFromQueryParam() {
  const searchParams = new URLSearchParams(document.location.search);
  yield put(setSelectedCredentialId({ credentialId: searchParams.get('credential_id') }));
}

function* resolveStartRoute() {
  const newParams = new URLSearchParams();
  const apiKeyFromUrlParams = newParams?.get('apikey');
  const token: string = apiKeyFromUrlParams ? apiKeyFromUrlParams : null;
  const identityStore: IIdentityStore = DI_CONTAINER.get(IDENTITY_STORE_TOKEN);
  const authService: IAuthenticateService = DI_CONTAINER.get(AUTHENTICATE_SERVICE_TOKEN);
  const service = 'workflows-admin-ui';

  if (token) {
    const identity = yield call(authService.authenticate, token);
    yield call(identityStore.set, identity);
  }

  const identity = yield call(identityStore.get);
  const isAuthed = yield call(authService.isAuthenticated, identity?.token);

  if (!isAuthed) {
    yield put(clear());
    yield put(push('/getting-started'));
  } else {
    yield call(getObservabilityService().initObservability, {
      enableSessionRecordings: true,
      env: environment?.environment,
      datadogCredentials: integrationFrontendsDD,
      service,
    });
    yield call(getObservabilityService().sendIdentity, identity, environment?.environment);
    yield put(sendSegmentIdentifyAction({ identity }));

    const credentialId = yield select(selectSelectedCredentialId);
    const savedRoute: Location = yield call(getPersistedRoute);
    if (savedRoute) {
      persistor.persist();
      if (credentialId) {
        // this is added because the query params are wiped by redux-persist upon redirect
        yield put(push(savedRoute.pathname + `?credential_id=${credentialId}`));
      } else {
        yield put(push(savedRoute));
      }
    }
  }
}

export function* initWorkflow() {
  // pull the credential_id from the query params before init wipes the query params
  yield setCredFromQueryParam();

  yield take(init);
  yield handleInit();
}
