import { call, put, select, takeEvery } from 'typed-redux-saga';
import { createNotification, NotificationType } from '@integration-frontends/common/notifications';
import {
  salsifySelectOrganization,
  salsifySetDigitalAssetProps,
  salsifySetFormData,
  salsifySetProductIdPropName,
  salsifySetStandardProps,
  selectSalsifyState,
  salsifyFetchPaginatedProperties,
  salsifySetLoadingProperties,
} from '@integration-frontends/workflow-manager/core/application';
import { SALSIFY_NAME_KEY, SALSIFY_SYSTEM_ID_KEY, SalsifyFormInfoResponse, } from '@integration-frontends/workflow-manager/core/model';
import { pageLoadError } from '@integration-frontends/common/app';

function* handler(action: ReturnType<typeof salsifySelectOrganization>) {
  try {
    const { clientId, credential_id, org_id: salsify_org_id } = action.payload;

    if (salsify_org_id) {
      // if org id is present in the payload, then the current operation is to fetch properties.
      yield put(salsifySetLoadingProperties({ loadingProperties: true }));
      yield put(
        createNotification({
          message: 'Loading Salsify properties. This may take a few minutes.',
          location: 'topLevel',
          type: NotificationType.Info,
          style: { whiteSpace: 'nowrap' },
        }),
      );
    }

    const data: SalsifyFormInfoResponse = yield call(
      salsifyFetchPaginatedProperties,
      clientId,
      credential_id,
      salsify_org_id,
    );
    const propsByType = data.propertiesByType;

    const salsifyProductIdPropFromAPI = {
      id: propsByType.productIdProp ? propsByType.productIdProp[SALSIFY_SYSTEM_ID_KEY] : null,
      name: propsByType.productIdProp ? propsByType.productIdProp[SALSIFY_NAME_KEY]: null,
    };

    const salsifyDigitalAssetProps = propsByType.digitalAssetProps;
    const salsifyStandardProps = propsByType.otherProps;
    const salsifyFormData = yield select(selectSalsifyState);

    if (salsifyProductIdPropFromAPI) {
      const {
        salsify_product_id,
        bf_source_key,
        brandfolder_identifier_custom_field,
        default_property,
        org_id,
        workflow_name,
        dest_config,
        custom_field_map,
      } = salsifyFormData;

      let product_id_prop;
      if (salsifyProductIdPropFromAPI.name) {
        yield put(salsifySetProductIdPropName({ productIdPropName: salsifyProductIdPropFromAPI.name }));
        if (salsifyProductIdPropFromAPI.id) {
          product_id_prop = salsifyProductIdPropFromAPI;
        } else {
          product_id_prop = salsify_product_id; // keep the old value
        }
      }

      yield put(
        salsifySetFormData({
          bf_source_key,
          brandfolder_identifier_custom_field,
          dest_config,
          default_property,
          org_id,
          workflow_name,
          custom_field_map,
          salsify_product_id: product_id_prop,
        }),
      );
    } else {
      yield put(
        createNotification({
          message: 'Failed to fetch Salsify product ID',
          location: 'topLevel',
          type: NotificationType.Error,
        }),
      );
    }

    if (salsifyDigitalAssetProps) {
      yield put(salsifySetDigitalAssetProps({ salsifyDigitalAssetProps }));
    }
    if (salsifyStandardProps) {
      yield put(salsifySetStandardProps({ salsifyStandardProps }));
    }

    yield put(salsifySetLoadingProperties({ loadingProperties: false }));
  } catch (e) {
    yield put(salsifySetLoadingProperties({ loadingProperties: false }));
    yield put(pageLoadError({ error: e }));
    yield put(
      createNotification({
        message: 'Failed to fetch Salsify properties',
        location: 'topLevel',
        type: NotificationType.Error,
      }),
    );
  }
}

export function* salsifySelectOrgnizationEffects() {
  yield takeEvery(salsifySelectOrganization, handler);
}
