import { StandardTextfield } from '@brandfolder/react';
import { BFErrorLabel } from '@integration-frontends/common/ui';
import { lettersAndNumberMatch } from '@integration-frontends/common/utils/validation';
import * as React from 'react';
import { useFormContext } from 'react-hook-form';
import './workflow-name.scss';

export const WorkflowName = (): React.ReactElement => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <>
      <StandardTextfield
        label="Workflow name"
        data-testid="workflow-name"
        id="select-workflow-name"
        {...register('workflow_name', {
          required: true,
          maxLength: 50,
          minLength: 5,
          pattern: lettersAndNumberMatch,
        })}
        placeholder="Enter workflow name"
      />
      {errors.workflow_name?.type === 'required' && (
        <BFErrorLabel role="alert">Workflow name is required</BFErrorLabel>
      )}
      {errors.workflow_name?.type === 'maxLength' ||
        (errors.workflow_name?.type === 'minLength' && (
          <BFErrorLabel role="alert">Workflow name must be 5-50 characters long</BFErrorLabel>
        ))}
      {errors.workflow_name?.type === 'pattern' && (
        <BFErrorLabel role="alert">
          Workflow name can only contain letters, numbers, and spaces.
        </BFErrorLabel>
      )}
    </>
  );
};
