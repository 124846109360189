import { injectable } from 'inversify';
import {
  AuthError,
  IAuthenticateService,
  Identity,
} from '@integration-frontends/common/auth/core/model';

@injectable()
export class AuthServiceMock implements IAuthenticateService {
  authenticate(credentials: unknown): Promise<Identity | AuthError> {
    return Promise.resolve({
      email: 'test@test.com',
      firstName: 'test',
      lastName: 'test',
      token: 'test',
      userId: 'test',
    } as Identity);
  }
  isAuthenticated(token: string): Promise<boolean> {
    return Promise.resolve(true);
  }
  refreshToken(): Promise<string> {
    return Promise.resolve('token');
  }
}
