import React from 'react';
import './workflows-menu-popout.scss';
import {
  activeSection,
  WorkflowListItem,
  workflowServiceType,
} from '@integration-frontends/workflow-manager/core/model';
import {
  updateWorkflow,
  initEditWorkflow,
  setActiveSection,
  initReviewWorkflow,
} from '@integration-frontends/workflow-manager/core/application';
import { useDispatch } from 'react-redux';
import { ButtonLooks, StandardButton } from '@brandfolder/react';

interface WorkflowsMenuPopoutProps {
  workflow: WorkflowListItem;
  integrationType: workflowServiceType;
}

export const WorkflowsMenuPopout = ({ workflow, integrationType }: WorkflowsMenuPopoutProps) => {
  const dispatch = useDispatch();

  const toggleActive = () =>
    dispatch(
      updateWorkflow({
        workflowId: workflow.id,
        totalAssetsAffected: workflow.total_assets_affected,
        data: {
          active: !workflow.active,
          service: integrationType as workflowServiceType,
        },
      }),
    );

  const onEdit = () => {
    dispatch(initEditWorkflow({ integrationType, workflowId: workflow.id }));
  };
  const onReview = () => {
    dispatch(setActiveSection({ activeSection: activeSection.review }));
    dispatch(initReviewWorkflow({ integrationType, workflowId: workflow.id }));
  };

  return (
    <div className="menu-card-popover" data-testid="workflows-menu-popout">
      <StandardButton
        onClick={onEdit}
        fullWidth
        className="option-button mb-xxs"
        look={ButtonLooks.TextTertiary}
      >
        Edit workflow
      </StandardButton>
      <StandardButton
        onClick={onReview}
        fullWidth
        className="option-button mb-xxs"
        look={ButtonLooks.TextTertiary}
      >
        Review workflow
      </StandardButton>
      <StandardButton
        onClick={toggleActive}
        fullWidth
        className="option-button"
        look={ButtonLooks.TextTertiary}
      >
        {workflow.active ? 'Make inactive' : 'Make active'}
      </StandardButton>
    </div>
  );
};
