import { injectable } from 'inversify';
import { IOauthService } from '../../services/oauth';
import { OauthBody } from '@integration-frontends/common/temporal-api';

@injectable()
export class OauthServiceMock implements IOauthService {
  signInOauth(clientId, oauthBody: OauthBody): Promise<any> {
    return Promise.resolve({});
  }
  getHostUrl: () => '';
  refreshBrandfolderOauthToken(refreshToken: string): Promise<any> {
    return Promise.resolve({
      access_token: 'test',
      expires_in: 3599,
      id_token: 'test',
      refresh_token: 'test',
      scope: 'openid offline_access',
      token_type: 'bearer',
    });
  }
}
