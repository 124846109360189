import { BFSelectOption } from '@integration-frontends/common/ui';
import {
  selectSalsifyBrandfolder,
  selectSalsifyDestConfig,
} from '@integration-frontends/workflow-manager/core/application';
import React, { useEffect } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { AddMapping } from './add-mapping/add-mapping';
import { SelectCustomFieldKey } from './custom-field-key/select-custom-field-key';
import { SelectCustomFieldValue } from './custom-field-value/select-custom-field-value';
import { DeleteMapping } from './delete-mapping/delete-mapping';
import { OptionalCustomFieldMappingsLabel } from './label/label';
import {
  SalsifyFieldArrayName,
  SelectSalsifyProperty,
} from './salsify-property/select-salsify-property';
import './dest-config.scss';

export interface DestConfigProps {
  brandfolderId: string;
  customFieldSources: BFSelectOption[];
  digitalAssetPropsSources: BFSelectOption[];
  loadingProperties?: boolean;
}
export type Mappings = {
  dest_config: {
    key: string;
    salsifyProp: string;
    value?: string;
  }[];
};
export const DestConfig = ({
  brandfolderId,
  customFieldSources,
  digitalAssetPropsSources,
  loadingProperties,
}: DestConfigProps): React.ReactElement => {
  const { control } = useFormContext<Mappings>();
  const {
    fields,
    append: addMapping,
    remove: deleteMapping,
  } = useFieldArray({
    control,
    name: 'dest_config',
  });
  const selectedBrandfolder = useSelector(selectSalsifyBrandfolder);
  const selectedDestConfig = useSelector(selectSalsifyDestConfig);

  useEffect(() => {
    if (selectedBrandfolder !== brandfolderId) {
      deleteMapping();
      addMapping({ key: null, value: null, salsifyProp: null });
    }
  }, [brandfolderId]);

  useEffect(() => {
    if (!selectedDestConfig?.length) {
      deleteMapping();
      addMapping({ key: null, value: null, salsifyProp: null });
    }
  }, []);

  return (
    <div className="optional-custom-field-mapping-container">
      <OptionalCustomFieldMappingsLabel />
      {fields.map((field, index) => {
        return (
          <div className="optional-mapping-row">
            <SelectCustomFieldKey
              brandfolderId={brandfolderId}
              customFieldSources={customFieldSources}
              fieldArrayName={SalsifyFieldArrayName.DestConfig}
              index={index}
            />
            <SelectCustomFieldValue fields={fields} index={index} />
            <SelectSalsifyProperty
              propsSources={digitalAssetPropsSources}
              index={index}
              fields={fields}
              fieldArrayName={SalsifyFieldArrayName.DestConfig}
              loadingProperties={loadingProperties}
            />
            <DeleteMapping deleteMapping={deleteMapping} index={index} />
          </div>
        );
      })}
      <AddMapping addMapping={addMapping} fieldArrayName={SalsifyFieldArrayName.DestConfig} />
    </div>
  );
};
