import { FontIcon, FontIconColors, FontIcons, StandardText } from '@brandfolder/react';
import React from 'react';
import { UseFieldArrayAppend } from 'react-hook-form';
import { CustomFieldMappings } from '../../multi-select-salsify-properties/multi-select-salsify-properties';
import { Mappings } from '../dest-config';
import { SalsifyFieldArrayName } from '../salsify-property/select-salsify-property';
import './add-mapping.scss';
import classNames from 'classnames';

export interface AddMappingArgs {
  key: string | null;
  salsifyProp: string | null;
  value?: string | null;
}

export interface AddMappingProps {
  addMapping:
    | UseFieldArrayAppend<Mappings, 'dest_config'>
    | UseFieldArrayAppend<CustomFieldMappings, 'custom_field_map'>;
  fieldArrayName: SalsifyFieldArrayName;
}

export const AddMapping = ({ addMapping, fieldArrayName }: AddMappingProps): React.ReactElement => {
  return (
    <div className="add-mapping-wrapper">
      <div
        data-testid={classNames({
          'add-mapping-dest-config': fieldArrayName === SalsifyFieldArrayName.DestConfig,
          'add-mapping-custom-field-map': fieldArrayName === SalsifyFieldArrayName.CustomFieldMap,
        })}
        className="add-mapping-button-container"
        onClick={() =>
          fieldArrayName === SalsifyFieldArrayName.DestConfig
            ? addMapping({ key: null, value: null, salsifyProp: null })
            : addMapping({ key: null, salsifyProp: null })
        }
      >
        <FontIcon icon={FontIcons.Plus} iconSize={12} color={FontIconColors.Primary} />
        <StandardText className="add-mapping-text">Add mapping</StandardText>
      </div>
    </div>
  );
};
