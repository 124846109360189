import React, { useEffect, useState } from 'react';
import { push } from 'redux-first-history';
import { useDispatch, useSelector } from 'react-redux';
import { Loader } from '@integration-frontends/common/ui';
import { dateFormatter } from '@integration-frontends/common/utils/date-formatter';
import {
  workflowEntitySelectors,
  selectSelectedClientId,
  clientEntitySelectors,
  workflowsPageEntered,
  setWorkflowMode,
  workflowModes,
  selectWorkflowsLoading,
} from '@integration-frontends/workflow-manager/core/application';

import './workflows-page.scss';
import { RowItemMenu } from '../common/row-item-menu';
import {
  WorkflowsActiveOption,
  workflowServiceType,
  WorkflowsSortOption,
  workflowTitle,
} from '@integration-frontends/workflow-manager/core/model';
import {
  Colors,
  FontColors,
  FontIcon,
  FontIconColors,
  FontIcons,
  HeadingLevels,
  StandardHeading,
  StandardTable,
  StandardText,
  StandardTooltip,
  TooltipTypes,
} from '@brandfolder/react';
import { WorkflowsHeader } from './workflows-header/workflows-header';
import { StatusBadge } from '../common/status-badge/status-badge';
import { WorkflowsEmptyState } from './workflows-empty-state/workflows-empty-state';
import { WorkflowsMenuPopout } from './workflows-menu-popout/workflows-menu-popout';
import { WorkflowsNoResults } from './workflows-no-results/workflows-no-results';
import {
  activeFilterOptions,
  workflowsSortOptions,
} from './workflows-header/workflows-sort-filter';
import { workflowsTableColumns } from './workflows-columns';
import { breakPointColumnOmissions, workflowTableBreakpoints } from './workflows-table-breakpoints';

export const WorkflowsPage = () => {
  const [search, setSearch] = useState('');
  const [sort, setSort] = useState('az' as WorkflowsSortOption);
  const [filterActive, setFilterActive] = useState('all' as WorkflowsActiveOption);
  const [filteredTableColumns, setTableColumns] = useState(workflowsTableColumns);
  const dispatch = useDispatch();

  const selectedClientId = useSelector(selectSelectedClientId);
  const selectedClient = useSelector((state) =>
    clientEntitySelectors.selectById(state, selectedClientId),
  );
  const unfilteredWorkflows = useSelector(workflowEntitySelectors.selectAll).filter(
    (workflowListItem) => {
      if (workflowListItem.brandfolder_account_id === selectedClient?.brandfolder_account_id) {
        return workflowListItem;
      }
    },
  );
  const workflows = useSelector(
    workflowEntitySelectors.filterWorkflows(search, sort, filterActive),
  ).filter((workflowListItem) => {
    if (workflowListItem.brandfolder_account_id === selectedClient?.brandfolder_account_id) {
      return workflowListItem;
    }
  });

  const loading = useSelector(selectWorkflowsLoading);

  useEffect(() => {
    dispatch(workflowsPageEntered());
  }, []);

  const goBack = () => {
    dispatch(push('/clients'));
  };

  const handleSetSort = (selectedOption: WorkflowsSortOption) => {
    setSort(selectedOption);
  };

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const handleFilterActive = (selectedFilter: WorkflowsActiveOption) => {
    setFilterActive(selectedFilter);
  };

  const handleAddWorkflow = () => {
    dispatch(setWorkflowMode({ mode: workflowModes.create }));
    dispatch(push('/create-workflow/app-info'));
  };

  const convertedWorkflowsFormat = () => {
    return workflows.map((workflow) => {
      return {
        integration: <span id={workflow.id}>{workflowTitle[workflow?.integration_type]}</span>,
        workflowName: (
          <StandardTooltip
            color={Colors.Primary}
            id="workflow-name-tooltip"
            tooltip={workflow?.workflow_name}
            type={TooltipTypes.Label}
            transitionDelay={200}
          >
            <span>{workflow?.workflow_name}</span>
          </StandardTooltip>
        ),
        brandfolder:
          workflow?.integration_type === 'workfront' ? (
            <div className="workfront-brandfolder-tooltip-container">
              <div className="mr-xxs workfront-brandfolder-tooltip-text-container">
                <StandardTooltip
                  color={Colors.Primary}
                  id="brandfolder-name-tooltip"
                  tooltip={workflow?.brandfolder}
                  type={TooltipTypes.Label}
                  transitionDelay={200}
                >
                  <p>{workflow?.brandfolder}</p>
                </StandardTooltip>
              </div>
              <StandardTooltip
                color={Colors.Primary}
                id="workfront-brandfolder-tooltip"
                tooltip={
                  'Within Workfront, users will be able to send documents to different Brandfolders.'
                }
                type={TooltipTypes.Label}
                transitionDelay={200}
              >
                <FontIcon icon={FontIcons.ToolTip} color={FontIconColors.Primary} />
              </StandardTooltip>
            </div>
          ) : (
            <StandardTooltip
              color={Colors.Primary}
              id="brandfolder-name-tooltip"
              tooltip={workflow?.brandfolder}
              type={TooltipTypes.Label}
              transitionDelay={200}
            >
              <span>{workflow?.brandfolder}</span>
            </StandardTooltip>
          ),
        totalAssetsAffected: <span>{workflow?.total_assets_affected}</span>,
        status: <StatusBadge active={workflow?.active} />,
        created: <span>{dateFormatter(workflow?.created_at, 'MM/DD/YY')}</span>,
        lastUpdated: <span>{dateFormatter(workflow?.updated_at, 'MM/DD/YY')}</span>,
        updatedBy: (
          <StandardTooltip
            color={Colors.Primary}
            id="last-updated-by-tooltip"
            tooltip={workflow?.last_updated_by}
            type={TooltipTypes.Label}
            transitionDelay={200}
          >
            <span>{workflow?.last_updated_by}</span>
          </StandardTooltip>
        ),
        options: (
          <RowItemMenu
            panel={
              <WorkflowsMenuPopout
                workflow={workflow}
                integrationType={workflow.integration_type as workflowServiceType}
              />
            }
          />
        ),
      };
    });
  };

  const breakpointColumns = {
    1: workflowsTableColumns.filter(
      (column) => !breakPointColumnOmissions[1].includes(column.rowKey),
    ),
    2: workflowsTableColumns.filter(
      (column) => !breakPointColumnOmissions[2].includes(column.rowKey),
    ),
    3: workflowsTableColumns.filter(
      (column) => !breakPointColumnOmissions[3].includes(column.rowKey),
    ),
  };

  useEffect(() => {
    // This is used to listen to changes in the screen size, and remove columns when appropriate
    const handleResize = () => {
      if (window.innerWidth < workflowTableBreakpoints[3]) {
        const updatedColumns = breakpointColumns[3];
        setTableColumns(updatedColumns);
      } else if (window.innerWidth < workflowTableBreakpoints[2]) {
        const updatedColumns = breakpointColumns[2];
        setTableColumns(updatedColumns);
      } else if (window.innerWidth < workflowTableBreakpoints[1]) {
        const updatedColumns = breakpointColumns[1];
        setTableColumns(updatedColumns);
      } else if (window.innerWidth >= workflowTableBreakpoints[1]) {
        setTableColumns(workflowsTableColumns);
      }
    };
    handleResize(); // Check initial screen width
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <section className="workflows-page" data-testid="workflows-page">
      <a className="back-button" data-testid="workflows-back-button" onClick={() => goBack()}>
        <FontIcon
          color={FontIconColors.Primary}
          icon={FontIcons.ArrowLeft}
          iconSize={12}
          className="mr-sm"
        />
        <StandardText color={FontColors.Primary}>Back</StandardText>
      </a>
      <div
        className="workflows-page-breadcrumbs-container"
        data-testid="workflows-page-breadcrumbs"
      >
        <StandardText onClick={() => goBack()} className="cursor-pointer" role="button">
          Clients
        </StandardText>
        <FontIcon icon={FontIcons.CaretRight} iconSize={8} className="caret-icon" />
        <StandardText data-testid="workflows-page-breadcrumbs-name">
          {selectedClient?.client_name}
        </StandardText>
      </div>
      <StandardHeading
        className="client-name-header"
        data-testid="client-name-header"
        level={HeadingLevels.h1}
      >
        {selectedClient?.client_name}
      </StandardHeading>
      <WorkflowsHeader
        workflows={workflows}
        handleSearchChange={handleSearchChange}
        handleSetSort={handleSetSort}
        handleFilterActive={handleFilterActive}
        handleAddWorkflow={handleAddWorkflow}
        sortOptions={workflowsSortOptions}
        activeFilters={activeFilterOptions}
      />
      <StandardTable
        data-testid="workflows-page-table"
        breakpoints={[0]}
        caption={'List of workflows'}
        showCaption={false}
        tableProps={{ className: 'workflow-page-table', id: 'workflow-page-table' }}
        tbodyProps={{
          className: workflows.length > 0 && !loading && 'workflow-page-table-border-bottom',
        }}
        columns={filteredTableColumns}
        id={'workflows-page-table'}
        thProps={{ className: 'workflow-row-header' }}
        trProps={{ className: 'workflow-row-container' }}
        tdProps={{ className: 'workflow-data-container' }}
        hover={false}
        rows={convertedWorkflowsFormat()}
        emptyContent={
          loading ? (
            <Loader />
          ) : unfilteredWorkflows.length > 0 ? (
            <WorkflowsNoResults />
          ) : (
            <WorkflowsEmptyState />
          )
        }
        scrollX={false}
      />
    </section>
  );
};
