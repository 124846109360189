import {
  clientEntitySelectors,
  selectSelectedClientId,
} from '@integration-frontends/workflow-manager/core/application';
import React from 'react';
import { useSelector } from 'react-redux';
import { ReviewSection, ReviewSectionLine } from '../../../common';

export const OrganizationInfo = () => {
  const selectedClientId = useSelector(selectSelectedClientId);
  const selectedClient = useSelector((state) =>
    clientEntitySelectors.selectById(state, selectedClientId),
  );

  return (
    <ReviewSection title="Organization info">
      <ReviewSectionLine title="Client" value={<div>{selectedClient.client_name}</div>} />
      <ReviewSectionLine title="Brandfolder API key" value={<div>*********</div>} />
    </ReviewSection>
  );
};
