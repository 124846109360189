import { CreateClientForm } from '../../clients-page/create-client-form';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectSelectedClientId,
  clientEntitySelectors,
  selectActiveSection,
  clientsPageEntered,
  selectClient,
  selectWorkflowMode,
  workflowModes,
} from '@integration-frontends/workflow-manager/core/application';
import { CreateWorkflowNav } from '../common/components/workflow-nav/create-workflow-nav';
import { SectionTracker } from '../common/components/section-tracker/section-tracker';
import './client-info.scss';
import {
  FontColors,
  FontIcon,
  FontIconColors,
  FontIcons,
  FontSizes,
  FontWeights,
  StandardCombobox,
  StandardText,
} from '@brandfolder/react';
import { InfoBanner } from '../../common/info-banner/info-banner';

interface CreateClientFormSectionProps {
  onNext: () => void;
}

export const CreateClientFormSection = ({ onNext }: CreateClientFormSectionProps) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(clientsPageEntered());
  }, []);
  const selectedClientId = useSelector(selectSelectedClientId);
  const workflowMode = useSelector(selectWorkflowMode);
  const editing = workflowMode === workflowModes.edit;

  const clients = useSelector(clientEntitySelectors.selectAll).map((client) => ({
    label: client.client_name,
    value: client.id,
  }));
  const [createClientOpen, setCreateClientOpen] = useState(false);

  const activeSection = useSelector(selectActiveSection);
  return (
    <>
      <CreateWorkflowNav
        onNext={!!selectedClientId && onNext}
        title="Client info"
        editing={editing}
      />
      <section className="workflow-wizard-section">
        <SectionTracker activeSection={activeSection} />
        <section className="forms-container">
          <section className="forms-content-container">
            <InfoBanner
              headerText={`Add developer username to Brandfolder`}
              bodyText={`Before continuing, make sure there’s a dedicated admin user with the format bf-integrations-dev+<org-slug>@smartsheet.com in the client’s Brandfolder account.`}
              linkName={`View documentation`}
              link={
                'https://smar-wiki.atlassian.net/wiki/spaces/PROD/pages/70971228283/CX+Guide+-+IWM+-+Integration+Workflow+Manager'
              }
            />
            <StandardText
              className="forms-header mt-xxl pb-sm"
              size={FontSizes.XxLarge}
              weight={FontWeights.Bold}
            >
              Select client
            </StandardText>
            <StandardCombobox
              id="select-clients-id"
              labels={{
                iconButtonLabel: 'Choose client button',
                label: 'Choose client',
                listboxLabel: 'Choose client option',
              }}
              showLabel={false}
              onSelection={({ value }) =>
                dispatch(selectClient({ clientId: value ? `${value}` : null }))
              }
              leftIcon={FontIcons.Search}
              placeholder={'Select client'}
              options={[
                ...clients.map((option) => ({
                  children: <>{option.label}</>,
                  key: option.value,
                  value: option.value,
                })),
              ]}
              showPlaceholderOption
              initialSelectedIndex={
                clients.findIndex((type) => selectedClientId === type?.value) || 0
              }
              className="select-clients-type-combobox"
              data-testid="integration-selector"
            />
            {createClientOpen && (
              <CreateClientForm modal={true} onClose={() => setCreateClientOpen(false)} />
            )}
            <div className="add-client-link-container">
              <FontIcon
                className="mr-sm"
                icon={FontIcons.Plus}
                iconSize={12}
                color={FontIconColors.Primary}
              />
              <StandardText
                weight={FontWeights.Medium}
                onClick={() => setCreateClientOpen(true)}
                color={FontColors.Primary}
              >
                Add client
              </StandardText>
            </div>
          </section>
        </section>
      </section>
    </>
  );
};
