import { FontSizes, FontWeights, StandardText } from '@brandfolder/react';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { selectSelectedIntegrationType } from '@integration-frontends/workflow-manager/core/application';
import './workflow-info.scss';
import { workflowTitle } from '@integration-frontends/workflow-manager/core/model';

export interface WorkflowInfoProps {
  linkToDocumentation: string;
  linkTestId?: string;
  textTestId?: string;
  className?: string;
  showIntegrationName?: boolean;
  extraText?: string;
}

export const WorkflowInfo = ({
  linkToDocumentation,
  linkTestId,
  textTestId,
  className,
  showIntegrationName,
  extraText,
}: WorkflowInfoProps): React.ReactElement => {
  const integrationType = useSelector(selectSelectedIntegrationType);
  return (
    <div className={`workflow-info-container ${className}`}>
      <StandardText
        className="workflow-info-header"
        size={FontSizes.XxLarge}
        weight={FontWeights.Bold}
        data-testid="workflow-info-header"
      >
        {showIntegrationName ? `${workflowTitle[integrationType]} info` : `Workflow info`}
      </StandardText>
      <StandardText size={FontSizes.Small} weight={FontWeights.Book} data-testid={textTestId}>
        <span>View </span>
        <a
          style={{ color: '#4462c9' }}
          href={linkToDocumentation}
          target="_blank"
          data-testid={linkTestId}
        >
          documentation
        </a>
        <span>{` for setting up ${workflowTitle[integrationType]} workflows.`}</span>
        {extraText && <span>{extraText}</span>}
      </StandardText>
    </div>
  );
};
