import { createReducer } from '@reduxjs/toolkit';
import { workflowsPageEntered, workflowsPageLoaded } from './actions';

export interface WorkflowsPageState {
  workflowsLoading: boolean;
}

export const workflowsPageInitialState: WorkflowsPageState = {
  workflowsLoading: false,
};

export const workflowsPageReducer = createReducer(workflowsPageInitialState, (builder) =>
  builder
    .addCase(workflowsPageEntered, (state) => {
      state.workflowsLoading = true;
    })
    .addCase(workflowsPageLoaded, (state) => {
      state.workflowsLoading = false;
    }),
);

export interface WorkflowsPageRootState {
  workflowsPageReducer: WorkflowsPageState;
}
