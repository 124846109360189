import { put, takeEvery, select, call } from 'typed-redux-saga';
import {
  workflowEntityActions,
  selectSelectedClientId,
  callWithTokenRefresh,
  setSelectedCredentialId,
} from '@integration-frontends/workflow-manager/core/application';
import {
  WORKFLOW_REPO_TOKEN,
  IWorkflowRepo,
} from '@integration-frontends/workflow-manager/core/model';
import { DI_CONTAINER, getObservabilityService } from '@integration-frontends/core';
import {
  workflowsLoading,
  workflowsLoadingSuccess,
  workflowsPageEntered,
  workflowsPageLoaded,
} from '../index';
import { workflowPageLoadError, workflowPageInit } from '../../create-workflow';
import { push } from 'redux-first-history';

function* handler() {
  yield put(workflowPageInit());
  yield put(workflowsLoading());

  const selectedClientId = yield select(selectSelectedClientId);
  if (!selectedClientId) {
    yield put(push('/clients'));
  }

  try {
    const workflowRepo: IWorkflowRepo = DI_CONTAINER.get(WORKFLOW_REPO_TOKEN);
    const workflows = yield callWithTokenRefresh(workflowRepo.listWorkflows, selectedClientId);
    yield put(workflowEntityActions.workflowsReceived(workflows));
    yield put(workflowsLoadingSuccess());
    yield put(workflowsPageLoaded());
  } catch (e) {
    yield call(getObservabilityService().addError, e);
    yield put(workflowPageLoadError({ error: e.message }));
    yield put(workflowsPageLoaded());
  }
}

export function* enteredWorkflowsPageEffects() {
  yield takeEvery(workflowsPageEntered, handler);
}
