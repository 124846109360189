import {
  ApiListDataResponse,
  CustomFieldKeyResponseData,
  CustomFieldValueDto,
} from '@integration-frontends/common/brandfolder-api';
import {
  GetBrandfolderFormInfoBody,
  GetHighspotFormInfoBody,
  GetHubspotFormInfoBody,
  GetWrikeFormInfoBody,
  GetSalsifyFormInfoBody,
  GetSeismicFormInfoBody,
  GetAsanaFormInfoBody,
} from '@integration-frontends/common/temporal-api';
import {
  Brandfolder,
  IFormInfoRepo,
  brandfolderFormInfoMockData,
  customFieldKeyResponseMockData,
  highspotFormInfoMockData,
  HighspotFormInfo,
  HubspotFormInfo,
  hubspotFormInfoMockData,
  wrikeFormInfoMockData,
  SalsifyFormInfo,
  salsifyFormInfoMockData,
  SeismicFormInfo,
  seismicFormInfoMockData,
  WrikeFormInfo,
  BrandfolderFormInfo,
  AsanaFormInfo,
  asanaFormInfoMockData,
} from '@integration-frontends/workflow-manager/core/model';
import { injectable } from 'inversify';

@injectable()
export class FormInfoRepoMock implements IFormInfoRepo {
  getAsanaFormInfo(clientId: string, attributes: GetAsanaFormInfoBody): Promise<AsanaFormInfo>{
    return Promise.resolve(asanaFormInfoMockData);
  }

  getBrandfolderFormInfo(
    clientId: string,
    attributes: GetBrandfolderFormInfoBody,
  ): Promise<Brandfolder[]> {
    return Promise.resolve(brandfolderFormInfoMockData);
  }

  getCustomFieldFormInfo(brandfolderId: string): Promise<BrandfolderFormInfo> {
    return Promise.resolve(customFieldKeyResponseMockData);
  }

  getHighspotFormInfo(
    clientId: string,
    attributes: GetHighspotFormInfoBody,
  ): Promise<HighspotFormInfo> {
    return Promise.resolve(highspotFormInfoMockData);
  }

  getHubspotFormInfo(
    clientId: string,
    attributes: GetHubspotFormInfoBody,
  ): Promise<HubspotFormInfo> {
    return Promise.resolve(hubspotFormInfoMockData);
  }

  getWrikeFormInfo(clientId: string, attributes: GetWrikeFormInfoBody): Promise<WrikeFormInfo> {
    return Promise.resolve(wrikeFormInfoMockData);
  }

  getSalsifyFormInfo(
    clientId: string,
    attributes: GetSalsifyFormInfoBody,
  ): Promise<SalsifyFormInfo> {
    return Promise.resolve(salsifyFormInfoMockData);
  }

  getSeismicFormInfo(
    clientId: string,
    attributes: GetSeismicFormInfoBody,
  ): Promise<SeismicFormInfo> {
    return Promise.resolve(seismicFormInfoMockData);
  }
}
