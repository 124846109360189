import { useSelector } from 'react-redux';
import { AuthContextProps, useAuth } from "react-oidc-context";
import { selectError } from '@integration-frontends/common/auth/core/application';
import { logoutThunk } from '@integration-frontends/common/auth/core/application';
import { loginError } from 'libs/common/auth/core/application/src/lib/actions';
import { Dispatch } from 'react';
import { AnyAction } from '@reduxjs/toolkit';


export function useAuthState() {
    const auth = useAuth();
    const authError = useSelector(selectError)
    auth.error = authError || auth.error
    return auth
}

export function resetAuthState(auth: AuthContextProps, dispatch: Dispatch<AnyAction>) {
    dispatch(logoutThunk() as any)
    dispatch(loginError({ error: null }))
    auth.error = null
    auth.isAuthenticated = false
    auth.user = null
}