import { HeadingLevels, StandardHeading, StandardLink, StandardText } from '@brandfolder/react';
import { IconInfoFilled, IconSize } from '@integration-frontends/common/ui';
import React from 'react';
import './info-banner.scss';

interface InfoBannerProps {
  headerText: string;
  bodyText: string;
  linkName?: string;
  link?: string;
}

export const InfoBanner = ({ bodyText, headerText, linkName, link }: InfoBannerProps) => {
  return (
    <div className="info-banner-container" data-testid="info-banner">
      <IconInfoFilled className="icon-info-filled" iconSize={IconSize.ExtraLarge} />
      <div className="info-banner-text-container">
        <StandardHeading
          level={HeadingLevels.h2}
          className="info-banner-header-text"
          data-testid="info-banner-header"
        >
          {headerText}
        </StandardHeading>
        <StandardText className="info-banner-body-text" data-testid="info-banner-body">
          {bodyText}
        </StandardText>
        {linkName && (
          <StandardLink
            className="info-banner-body-link"
            href={link}
            target="_blank"
            data-testid="info-banner-body-link"
          >
            {linkName}
          </StandardLink>
        )}
      </div>
    </div>
  );
};
