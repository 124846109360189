import { put, takeEvery } from 'redux-saga/effects';
import { exitReviewWorkflow } from '../actions';
import { push } from 'redux-first-history';

function* handler() {
  yield put(push('/workflows'));
}

export function* exitReviewWorkflowEffects() {
  yield takeEvery(exitReviewWorkflow, handler);
}
