import { createAction } from '@reduxjs/toolkit';
import { withPayloadType } from '@integration-frontends/common/utils/redux';

export const clientsPageEntered = createAction('ALL_CLIENTS_PAGE_ENTERED');

export const clientPageEntered = createAction(
  'CLIENT_PAGE_ENTERED',
  withPayloadType<{ clientSlug: string }>(),
);
export const clientsLoading = createAction('CLIENTS_LOADING');

export const clientsLoadingSuccess = createAction('CLIENTS_LOADING_SUCCESS');

export const clientsLoadingFail = createAction('CLIENTS_LOADING_FAIL');
