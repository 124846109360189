import React, { useEffect } from 'react';
import { StandardText } from '@brandfolder/react';
import { BFErrorLabel } from '@integration-frontends/common/ui';
import { lettersAndNumberMatch } from '@integration-frontends/common/utils/validation';
import { useFormContext } from 'react-hook-form';
import { CreateWorkflowInputField } from '../create-workflow-input-field/create-workflow-input-field';
import { InfoBanner } from 'libs/workflow-manager/ui/src/common/info-banner/info-banner';

interface OauthCredentialFormProps {
  credsComplete?: (c: boolean) => void;
  credFormData?: any;
  integrationName: string;
}

export const OauthCredentialForm = ({
  credsComplete,
  credFormData,
  integrationName,
}: OauthCredentialFormProps) => {
  const {
    register,
    formState: { errors },
    setValue,
    watch,
  } = useFormContext();

  const credName = watch('credential_name');
  const link = integrationName === 'Asana' ? 'https://asana.com/' : 'https://wrike.com/';

  useEffect(() => {
    if (credsComplete && credFormData) {
      if (credName) {
        credsComplete(true);
        credFormData({
          credential_name: credName,
        });
      } else {
        credsComplete(false);
      }
    }
  }, [credName]);

  return (
    <>
      <InfoBanner
        headerText={`Sign out of ${integrationName}`}
        bodyText={`To create a credential for the correct client account, please visit ${integrationName} and make sure you're not signed in to another account.`}
        linkName={`Visit ${integrationName}`}
        link={link}
      />
      <CreateWorkflowInputField
        label={'Credential name'}
        value={credName}
        inputId={'set-oauth-cred-name'}
        placeholder={'Enter a credential name'}
        tooltip="Give a descriptive name to this set of credentials."
        onChange={(e) => {
          setValue('credential_name', e.target.value || ('' as string));
        }}
        validation={register('credential_name', {
          required: true,
          maxLength: 50,
          minLength: 5,
          pattern: lettersAndNumberMatch,
        })}
      />
      {errors.credential_name?.type === 'required' && (
        <BFErrorLabel role="alert">Please choose a unique name for this credential.</BFErrorLabel>
      )}
      {errors.credential_name?.type === 'maxLength' ||
        (errors.credential_name?.type === 'minLength' && (
          <BFErrorLabel role="alert">Credential name must be 5-50 characters long</BFErrorLabel>
        ))}
      {errors.credential_name?.type === 'pattern' && (
        <BFErrorLabel role="alert">Please use only letters and numbers.</BFErrorLabel>
      )}
    </>
  );
};
