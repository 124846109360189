import { FontSizes, FontWeights, StandardLabel, StandardText } from '@brandfolder/react';
import { BFStandardRadio, Loader } from '@integration-frontends/common/ui';
import { BFErrorLabel } from '@integration-frontends/common/ui/forms/form-controls/form-controls';
import {
  BFSelect,
  BFSelectOption,
} from '@integration-frontends/common/ui/forms/form-controls/select';
import {
  selectBrandfolderFormInfo,
  selectSelectedCredentialId,
  selectWrikeState,
  wrikeFetchSpaces,
  wrikeSetCredentialId,
  selectWrikeSpaces,
  selectWorkflowMode,
  workflowModes,
} from '@integration-frontends/workflow-manager/core/application';
import React, { useState, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { SynchronousContainerSelector } from '../../common/components/container-selector/synchronous-container-selector';
import { SelectBrandfolder } from '../../common/components/select-brandfolder/select-brandfolder';
import { WorkflowInfo } from '../../common/components/workflow-info/workflow-info';
import { WorkflowName } from '../../common/components/workflow-name.tsx/workflow-name';

import './workflow-form-wrike.scss';
import '../../create-workflow-form.scss';
import { alphabetizBfOptions } from '../../../common/functions/alphabetize';

export const CreateWrikeWorkflowFormContainer = ({ formComplete }) => {
  const {
    register,
    formState: { errors },
    setValue,
    reset,
    watch,
  } = useFormContext();

  const dispatch = useDispatch();
  const workflowMode = useSelector(selectWorkflowMode);
  const editing = workflowMode === workflowModes.edit;
  const defaultValues = useSelector(selectWrikeState);
  const credentialId = useSelector(selectSelectedCredentialId);
  const spaces = useSelector(selectWrikeSpaces);
  const [folderTree, setFolderTree] = useState<any[]>();

  const workflowName = watch('workflow_name');
  const resourceId = watch('resource_id');
  const bfDestinationKey = watch('bf_destination_key');
  const sectionkey = watch('section_key');
  const recursive = watch('recursive');

  useEffect(() => {
    reset(defaultValues);
  }, []);

  useEffect(() => {
    if (workflowName && resourceId && bfDestinationKey && sectionkey) {
      formComplete(true);
    } else {
      formComplete(false);
    }
  }, [workflowName, resourceId, bfDestinationKey, sectionkey]);

  useEffect(() => {
    dispatch(wrikeSetCredentialId({ credential_id: credentialId }));
    if (credentialId) {
      dispatch(wrikeFetchSpaces({ credential_id: credentialId }));
    }
  }, [credentialId]);

  const createFolderTree = (folders) => {
    if (folders) {
      const object = new Map(folders.map((n) => [n.id, n]));
      const tree = (folder) => {
        object.delete(folder?.id);
        return folder?.childIds.length
          ? {
              id: folder.id,
              children: folder.childIds.map((c) => tree(object.get(c))),
              title: folder.title,
              space: folder.space,
            }
          : folder;
      };
      const constructedTree = [];
      folders.forEach((f) => {
        if (object.has(f.id)) constructedTree.push(tree(f));
      });
      setFolderTree(constructedTree);
    }
  };

  const flattenArray = (folders) => {
    let result = [];
    folders?.forEach(function (a) {
      result.push(a);
      if (Array.isArray(a?.children)) {
        result = result.concat(flattenArray(a.children));
      }
    });
    return result;
  };

  const getFolderId = (id: string) => {
    const folder = flattenArray(folderTree).find((folder) => folder?.id === id);
    setValue('resource', folder);
    return folder;
  };

  useEffect(() => {
    createFolderTree(spaces);
  }, [spaces]);

  const brandfolderFormInfo = useSelector(selectBrandfolderFormInfo);
  const bfSources = brandfolderFormInfo?.map((bf) => {
    return { label: bf.name, value: bf.id, sections: bf.sections, collections: bf.collections };
  });

  const bfSourceKey = watch('bf_destination_key');

  const bfSections = bfSources
    ?.find((bf) => bf.value === bfSourceKey)
    ?.sections?.data.map((section) => {
      return { label: section.name, value: section.id };
    });

  const bfCollections = bfSources
    ?.find((bf) => bf.value === bfSourceKey)
    ?.collections?.data.map((collection) => {
      return { label: collection.name, value: collection.id };
    });

  const handleMultiChangeSection = (values) => {
    setValue('section_key', values || null);
  };

  const handleSelectCollection = (value) => {
    setValue('collection_key', value || null);
  };

  return (
    <>
      {credentialId && (
        <section className="forms-content-container" data-testid="wrike-workflow-form-container">
          <WorkflowInfo
            linkToDocumentation={
              'https://smar-wiki.atlassian.net/wiki/spaces/PROD/pages/70989611009/Guide+IWM+-+Wrike'
            }
            linkTestId="wrike-documentation-link"
          />
          <WorkflowName />
          <StandardText className="wrike-section-headings" weight={FontWeights.Bold}>
            Source
          </StandardText>
          <StandardText className="wrike-description-text">
            The Wrike source can’t be edited after the workflow is published. To edit the source,
            deactivate this workflow and then create a new one.
          </StandardText>
          <div className="workflow-info-selection-section">
            <div className="workflow-info-selection-row-item">
              <StandardText className="mb-xxs" weight={FontWeights.Medium}>
                Wrike
              </StandardText>
              <div className="wrike-page-selector-container">
                <SynchronousContainerSelector
                  onChange={(folder) => {
                    setValue('resource', folder || null, { shouldValidate: true });
                    setValue('resource_id', folder?.id || null, { shouldValidate: true });
                    setValue('resource_type', folder?.space ? 'spaces' : 'folders' || null, {
                      shouldValidate: true,
                    });
                  }}
                  containerNamePropertyName={'title'}
                  containers={folderTree}
                  displayedName={getFolderId(watch('resource_id'))?.title || 'Choose folder'}
                  currentContainer={watch('resource_id')}
                  disabled={editing}
                />
              </div>
              <div
                className={`wrike-radio-buttons mt-xxl ${editing && 'wrike-radio-button-disabled'}`}
              >
                <BFStandardRadio
                  checked={!recursive}
                  onChange={() => {
                    setValue('recursive', false);
                  }}
                  className="mr-sm"
                  name="asset-sync-config"
                  value="asset-sync-config"
                  label={'Only sync assets in this folder'}
                  style={{ height: '10px' }}
                  disabled={editing}
                  data-testid="non-recursive-sync-radio-button"
                />
              </div>
              <div
                className={`wrike-radio-buttons mt-xl ${editing && 'wrike-radio-button-disabled'}`}
              >
                <BFStandardRadio
                  checked={recursive}
                  onChange={() => {
                    setValue('recursive', true);
                  }}
                  className="mr-sm"
                  name="asset-sync-config"
                  value="asset-sync-config"
                  label={'Sync assets in this folder and any child folder'}
                  disabled={editing}
                  data-testid="recursive-sync-radio-button"
                />
              </div>
            </div>
          </div>
          <section className="mb-xl">
            <StandardText
              className="wrike-section-headings"
              size={FontSizes.XxLarge}
              weight={FontWeights.Bold}
            >
              Destination
            </StandardText>
            <div className="wrike-page-selector-container mt-xxl">
              <SelectBrandfolder
                valueName="bf_destination_key"
                bfSource={bfSourceKey}
                bfSources={bfSources}
                onChange={() => {
                  handleMultiChangeSection(null);
                  handleSelectCollection(null);
                }}
              />
            </div>
            <div className="mt-xl">
              <StandardLabel className="mb-xxs" htmlFor={'select-section'}>
                Brandfolder Section
              </StandardLabel>
              <div className="wrike-page-selector-container">
                <BFSelect
                  style={{ width: '100%' }}
                  id="select-section"
                  placeholder={'Select Section'}
                  options={(alphabetizBfOptions(bfSections) as BFSelectOption[]) || []}
                  disabled={!bfSourceKey}
                  value={watch('section_key')}
                  onOptionChange={(listOption): void => {
                    setValue('section_key', listOption?.value || null, {
                      shouldValidate: true,
                      shouldDirty: true,
                    });
                  }}
                  data-testid={`workflow-form-select-section`}
                />
                {bfDestinationKey && sectionkey === null && (
                  <BFErrorLabel role="alert">Section is required</BFErrorLabel>
                )}
              </div>
            </div>
            <div className="mt-xl">
              <StandardLabel className="mb-xxs" htmlFor={'select-collection'}>
                Brandfolder Collection (optional)
              </StandardLabel>
              <div className="wrike-page-selector-container">
                <BFSelect
                  style={{ width: '100%' }}
                  id="select-collection"
                  placeholder={'Select Collection'}
                  options={(alphabetizBfOptions(bfCollections) as BFSelectOption[]) || []}
                  disabled={!bfSourceKey}
                  value={watch('collection_key')}
                  onOptionChange={(listOption): void => {
                    setValue('collection_key', listOption?.value || null, {
                      shouldValidate: true,
                      shouldDirty: true,
                    });
                    setValue('resource', listOption);
                  }}
                  data-testid={`workflow-form-select-collection`}
                />
              </div>
            </div>
          </section>
        </section>
      )}
      {!credentialId && <Loader />}
    </>
  );
};
