import { createReducer } from '@reduxjs/toolkit';
import {
  createClientInitialize,
  createClientSuccess,
  createClientFailure,
  createClientEmailFormatError,
} from './actions';

export interface CreateClientState {
  loading: boolean;
  clientCreateSuccess: boolean;
  emailFormatError: boolean;
}

export const createClientInitialState: CreateClientState = {
  loading: false,
  clientCreateSuccess: false,
  emailFormatError: false,
};

export const createClientReducer = createReducer(createClientInitialState, (builder) =>
  builder
    .addCase(createClientInitialize, (state) => {
      state.loading = true; 
      state.clientCreateSuccess = false;
      state.emailFormatError = false;
    })
    .addCase(createClientSuccess, state => {
      state.loading = false;
      state.clientCreateSuccess = true;
    })
    .addCase(createClientFailure, (state) => {
      state.loading = false;
    })
    .addCase(createClientEmailFormatError, (state, action) => {
      state.emailFormatError = action.payload.emailFormatError;
    })
);

export interface CreateClientRootState {
  createClientReducer: CreateClientState;
}
