import { DI_CONTAINER } from '@integration-frontends/core';
import {
  FORM_INFO_REPO_TOKEN,
  IFormInfoRepo,
  SALSIFY_DATA_TYPE_KEY,
  SALSIFY_ROLE_KEY,
  SalsifyDataMap,
  SalsifyFormInfo,
  SalsifyOrganization,
  SalsifyPropertiesByType,
  SalsifyFormInfoResponse,
  SALSIFY_NAME_KEY,
} from '@integration-frontends/workflow-manager/core/model';
import { callWithTokenRefresh } from '../../../../common';

const PER_PAGE = 50;

/**
 * Helper function to fetch paginated Salsify properties from the IWM API
 * @param clientId
 * @param credential_id
 * @param salsify_org_id
 */
export function* salsifyFetchPaginatedProperties(
  clientId: string,
  credential_id: string,
  salsify_org_id: string,
): Generator<any, SalsifyFormInfoResponse, any> {
  const formInfoRepo: IFormInfoRepo = DI_CONTAINER.get(FORM_INFO_REPO_TOKEN);

  let currentPage = 1;
  let properties: SalsifyDataMap[] = [];
  let totalPages = 1;
  const organizations: SalsifyOrganization[] = [];
  while (currentPage <= totalPages) {
    const response = yield callWithTokenRefresh(formInfoRepo.getSalsifyFormInfo, clientId, {
      credential_id,
      salsify_org_id,
      per: PER_PAGE,
      page: currentPage,
    });

    if (
      !(response as SalsifyFormInfo)?.paginated_properties?.properties &&
      !(response as SalsifyFormInfo)?.organizations
    ) {
      throw new Error('Error fetching Salsify properties. Empty response.');
    }

    if (response.organizations) {
      response.organizations.forEach((org) => {
        if (!organizations.find((o) => o.id === org.id)) {
          organizations.push(org);
        }
      });
    }

    if ((response as SalsifyFormInfo)?.paginated_properties?.properties) {
      properties = properties.concat(response?.paginated_properties?.properties);
    }

    totalPages =
      response.paginated_properties?.meta?.total_pages &&
      response.paginated_properties.meta.total_pages;
    currentPage++;
  }

  const propertiesByType: SalsifyPropertiesByType = {
    productIdProp: null,
    digitalAssetProps: [],
    otherProps: [],
  };

  properties.forEach((prop) => {
    if (prop[SALSIFY_ROLE_KEY] === 'product_id') {
      propertiesByType.productIdProp = prop;
    } else if (prop[SALSIFY_DATA_TYPE_KEY] === 'digital_asset') {
      propertiesByType?.digitalAssetProps.push(prop);
    } else {
      propertiesByType?.otherProps.push(prop);
    }
  });

  // sort alphabetically 
  propertiesByType.digitalAssetProps.sort((a, b) => {
    return a[SALSIFY_NAME_KEY].toUpperCase() > b[SALSIFY_NAME_KEY].toUpperCase() ? 1 : -1;
  });
  propertiesByType.otherProps.sort((a, b) => {
    return a[SALSIFY_NAME_KEY].toUpperCase() > b[SALSIFY_NAME_KEY].toUpperCase() ? 1 : -1;
  });

  return { propertiesByType, organizations } as SalsifyFormInfoResponse;
}
