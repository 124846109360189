import {
  ButtonLooks,
  Colors,
  FontIcon,
  FontIconColors,
  FontIcons,
  FontWeights,
  IconButton,
  Placements,
  StandardText,
  StandardTooltip,
  TooltipTypes,
  Widths,
} from '@brandfolder/react';
import {
  BFSelect,
  BFSelectOption,
} from '@integration-frontends/common/ui/forms/form-controls/select';
import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  seismicFetchFolders,
  selectSeismicContainers,
  selectSeismicContainersLoading,
} from '@integration-frontends/workflow-manager/core/application';
import { useSelector } from 'react-redux';
import './create-workflow-sync-row-map.scss';
import { SelectionData } from '../create-workflow-sync-row/create-workflow-sync-row';
import { AsyncContainerSelector } from '../container-selector/async-container-selector';
import { ContainerFormInfo } from '@integration-frontends/workflow-manager/core/model';
import { kebabCase } from 'lodash';

interface CreateWorkflowSyncRowMapProps {
  index: number;
  src: SelectionData;
  destination: SelectionData;
  mapType: string;
  credentialId: string;
  teamsiteId: string;
  handleRemoveMapping: any;
}

export const CreateWorkflowSyncRowMap = ({
  index,
  src,
  destination,
  mapType,
  credentialId,
  teamsiteId,
  handleRemoveMapping,
}: CreateWorkflowSyncRowMapProps) => {
  const { setValue, watch } = useFormContext();

  const containers = useSelector(selectSeismicContainers);

  const containerFormInfo: ContainerFormInfo = {
    currentContainer: containers?.current_folder,
    siblings: containers?.siblings,
    children: containers?.children,
    metadata: containers?.metadata,
  };

  const watchFieldArray = watch(`${mapType}_map`);
  const [lastCollectionDeleted, setLastCollectionDeleted] = useState<boolean>(false);

  const handleChangeSrc = (id: string, name: string, value: string) => {
    let updatedMapping = watchFieldArray;
    updatedMapping[id] = { ...updatedMapping[id], bfSrcContainer: { name: name, id: value } };

    setValue(`${mapType}_map`, updatedMapping || ('' as string), {
      shouldValidate: true,
    });
  };

  const handleChangeDest = (id: string, value: string, name: string) => {
    let updatedMapping = watchFieldArray;
    updatedMapping[id] = {
      ...updatedMapping[id],
      seismicDestinationContainer: { name: name, id: value },
    };

    setValue(`${mapType}_map`, updatedMapping || ('' as string), {
      shouldValidate: true,
    });
  };

  const setValuePath = (
    type: string,
    index: string,
    location: string,
    property: string,
  ): string => {
    return `${type}_map.${index}.${location}.${property}`;
  };

  const handleResetMapRow = (id: number) => {
    handleRemoveMapping(id);
    setValue(setValuePath(mapType, '0', 'bfSrcContainer', 'id'), null);
    setValue(setValuePath(mapType, '0', 'bfSrcContainer', 'name'), null);
    setValue(setValuePath(mapType, '0', 'seismicDestinationContainer', 'id'), null);
    setValue(setValuePath(mapType, '0', 'seismicDestinationContainer', 'name'), null);
  };

  const handleDeleteMapping = (id: number) => {
    // If it is the only item in the section, just clear the fields
    if (watchFieldArray?.length === 1) {
      handleResetMapRow(id);
      setLastCollectionDeleted(true);
    } else {
      handleRemoveMapping(id);
    }
  };

  const handleDestSelectorOpened = () => {
    setLastCollectionDeleted(false);
  };

  const selectableSrcOptions = (): BFSelectOption[] => {
    const placeHolderOption = { label: src?.placeHolder, value: '' };
    const selectableOptions = src?.selectionOptions;
    const optionsToFilterWith =
      mapType === 'section_to_folder'
        ? watch('section_to_folder_map')
        : watch('collection_to_folder_map');
    return (
      [
        placeHolderOption,
        ...selectableOptions.filter(
          (section) =>
            !optionsToFilterWith?.some(
              (obj) =>
                watch(`${mapType}_map.${index}.bfSrcContainer.id`) !== section.value &&
                obj?.bfSrcContainer?.id === section.value,
            ),
        ),
      ] || []
    );
  };

  return (
    <div
      className={`sync-row-mapping-row ${kebabCase(mapType)}-sync-row-map`}
      data-testid={`${kebabCase(mapType)}-sync-row-map`}
      key={index.toString()}
    >
      {src && src?.selectionOptions && (
        <div className={`sync-row-selection ${kebabCase(mapType)}-sync-row-selection`}>
          <div className="sync-row-selection-label">
            <StandardText weight={FontWeights.Bold}>{src.label}</StandardText>
            {src.tooltip && (
              <StandardTooltip
                color={Colors.Primary}
                defaultOpen={false}
                id="workflow-optional-info-tooltip"
                placement={Placements.TopCenter}
                tooltip={src.tooltip}
                triggerOffset={8}
                type={TooltipTypes.Description}
                width={Widths.Medium}
              >
                <FontIcon icon={FontIcons.ToolTip} iconSize={13} color={FontIconColors.Primary} />
              </StandardTooltip>
            )}
          </div>
          <BFSelect
            data-testid={`${kebabCase(mapType)}-sync-row-src-selector`}
            style={{ width: '100%', textOverflow: 'ellipsis' }}
            id="select-section"
            options={selectableSrcOptions()}
            disabled={!src?.selectionOptions}
            value={
              src?.selectionOptions?.find(
                (folder) => folder.value === watch(`${mapType}_map.${index}.bfSrcContainer.id`),
              )?.value
            }
            onOptionChange={(listOption): void => {
              setValue(
                `${mapType}_map.${index}.bfSrcContainer.id`,
                listOption?.value || ('' as string),
                {
                  shouldValidate: true,
                  shouldDirty: true,
                },
              );
              setValue;
              handleChangeSrc(
                index.toString(),
                listOption?.label.toString(),
                listOption?.value.toString(),
              );
            }}
          />
        </div>
      )}
      {src && <FontIcon icon={FontIcons.ArrowRight} className="sync-row-selection-arrow" />}
      <div
        className={`sync-row-selection sync-row-selection-margin ${mapType.replace(
          /_/g,
          '-',
        )}-sync-row-selection`}
      >
        <div className="sync-row-selection-label">
          <StandardText weight={FontWeights.Bold}>{destination?.label}</StandardText>
          {destination.tooltip && (
            <StandardTooltip
              color={Colors.Primary}
              defaultOpen={false}
              id="workflow-optional-info-tooltip"
              placement={Placements.TopCenter}
              tooltip={destination.tooltip}
              triggerOffset={8}
              type={TooltipTypes.Description}
              width={Widths.Medium}
            >
              <FontIcon icon={FontIcons.ToolTip} iconSize={13} color={FontIconColors.Primary} />
            </StandardTooltip>
          )}
        </div>
        <AsyncContainerSelector
          testId={`${kebabCase(mapType)}-sync-row-dest-selector`}
          displayedName={watch(`${mapType}_map.${index}.seismicDestinationContainer.name`)}
          onOptionChange={(folder) => {
            setValue(
              `${mapType}_map.${index}.seismicDestinationContainer.id`,
              folder?.value || ('' as string),
              {
                shouldValidate: true,
                shouldDirty: true,
              },
            );
            handleChangeDest(index.toString(), folder?.id, folder?.name);
          }}
          defaultContainerName={destination?.placeHolder}
          defaultContainerId={
            watch(`${mapType}_map.${index}.seismicDestinationContainer.id`) || 'root'
          }
          rootContainerId={'root'}
          parentContainerPropertyName={'parentFolderId'}
          containerNamePropertyName={'name'}
          containerFormInfo={containerFormInfo}
          fetchContainers={(folderId, page) =>
            seismicFetchFolders({
              credential_id: credentialId,
              folder_id: folderId,
              teamsite_id: teamsiteId,
              page,
            })
          }
          loading={useSelector(selectSeismicContainersLoading)}
          selectorReset={lastCollectionDeleted}
          handleOpened={handleDestSelectorOpened}
        />
      </div>
      {src && (
        <IconButton
          data-testid={`delete-${kebabCase(mapType)}-sync-row-map`}
          label=""
          icon={FontIcons.Trash}
          onClick={() => handleDeleteMapping(index)}
          className="sync-row-map-trash-icon"
          look={ButtonLooks.TextWarning}
        />
      )}
    </div>
  );
};
