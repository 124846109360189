import { put, takeEvery, select, call } from 'typed-redux-saga';
import {
  workflowFormFailure,
  selectSelectedClientId,
  selectSelectedCredentialId,
  asanaFetchFormInfo,
  asanaReceiveFormInfo,
  selectAsanaWorkspaces,
  selectAsanaProjects,
  selectAsanaSections,
  workflowPageLoadError,
  asanaSetProjectsLoading,
  asanaSetSectionsLoading,
  asanaSetWorkspacesLoading,
} from '../../index';
import {
  AsanaFormInfo,
  FORM_INFO_REPO_TOKEN,
  IFormInfoRepo,
} from '@integration-frontends/workflow-manager/core/model';
import { DI_CONTAINER, getObservabilityService } from '@integration-frontends/core';
import { GetAsanaFormInfoBody } from '@integration-frontends/common/temporal-api';
import { callWithTokenRefresh } from '../../../../common';

function* handler(action: ReturnType<typeof asanaFetchFormInfo>) {
  const formInfoRepo: IFormInfoRepo = DI_CONTAINER.get(FORM_INFO_REPO_TOKEN);
  const clientId = yield select(selectSelectedClientId);
  const credentialId = yield select(selectSelectedCredentialId);
  const currentFormInfo = {
    workspaces: yield select(selectAsanaWorkspaces),
    projects: yield select(selectAsanaProjects),
    sections: yield select(selectAsanaSections),
  };

  if (!action.payload.workspace_id) {
    yield put(asanaSetWorkspacesLoading({ workspacesLoading: true }));
  } else if (action.payload.workspace_id && !action.payload.project_id) {
    yield put(asanaSetProjectsLoading({ projectsLoading: true }));
  } else if (action.payload.project_id && !action.payload.section_id) {
    yield put(asanaSetSectionsLoading({ sectionsLoading: true }));
  }

  try {
    const formInfo: AsanaFormInfo = yield callWithTokenRefresh(
      formInfoRepo.getAsanaFormInfo,
      clientId,
      {
        credential_id: credentialId,
        workspace_id: action.payload.workspace_id,
        project_id: action.payload.project_id,
        section_id: action.payload.section_id,
      } as GetAsanaFormInfoBody,
    );

    // overwrite empty form info with current form info so that data is not cleared from the form
    Object.keys(currentFormInfo).forEach((resourceType) => {
      if (formInfo[resourceType]?.data?.length > 0) {
        currentFormInfo[resourceType] = formInfo[resourceType].data;
      }
    });

    yield put(
      asanaReceiveFormInfo({
        workspaces: currentFormInfo.workspaces,
        projects: currentFormInfo.projects,
        sections: currentFormInfo.sections,
      }),
    );
  } catch (e) {
    yield call(getObservabilityService().addError, e);
    yield put(workflowPageLoadError({ error: e.message }));
    yield put(workflowFormFailure());
  }
}

export function* asanaFetchFormInfoEffects() {
  yield takeEvery(asanaFetchFormInfo, handler);
}
