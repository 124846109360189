import { Client, ClientApiResponseData } from '@integration-frontends/workflow-manager/core/model';

// Need more than 25 clients to demonstrate pagination
export const mockClients: Client[] = [
  {
    brandfolder_account_id: 0,
    client_name: 'Client2',
    id: '2',
    workflows: [{ salsify: '1' }, { salsify: '2' }, { highspot: '3' }, { highspot: '4' }],
    updated_at: '2022-12-12T23:47:29.676786Z',
  },
  {
    brandfolder_account_id: 0,
    client_name: 'Client1',
    id: '1',
    workflows: [{ salsify: '1' }, { salsify: '2' }, { highspot: '3' }, { highspot: '4' }],
    updated_at: '2022-12-12T23:47:29.676786Z',
  },
  {
    brandfolder_account_id: 0,
    client_name: 'Client3',
    id: '3',
    workflows: [{ salsify: '1' }, { salsify: '2' }, { highspot: '3' }, { highspot: '4' }],
    updated_at: '2022-12-12T23:47:29.676786Z',
  },
  {
    brandfolder_account_id: 0,
    client_name: 'Client4',
    id: '4',
    workflows: [{ salsify: '1' }, { salsify: '2' }, { highspot: '3' }, { highspot: '4' }],
    updated_at: '2022-12-12T23:47:29.676786Z',
  },
  {
    brandfolder_account_id: 0,
    client_name: 'Client5',
    id: '5',
    workflows: [{ salsify: '1' }, { salsify: '2' }, { highspot: '3' }, { highspot: '4' }],
    updated_at: '2022-12-12T23:47:29.676786Z',
  },
  {
    brandfolder_account_id: 0,
    client_name: 'Client6',
    id: '6',
    workflows: [{ salsify: '1' }, { salsify: '2' }, { highspot: '3' }, { highspot: '4' }],
    updated_at: '2022-12-12T23:47:29.676786Z',
  },
  {
    brandfolder_account_id: 0,
    client_name: 'Client7',
    id: '7',
    workflows: [{ salsify: '1' }, { salsify: '2' }, { highspot: '3' }, { highspot: '4' }],
    updated_at: '2022-12-12T23:47:29.676786Z',
  },
  {
    brandfolder_account_id: 0,
    client_name: 'Client8',
    id: '8',
    workflows: [{ salsify: '1' }, { salsify: '2' }, { highspot: '3' }, { highspot: '4' }],
    updated_at: '2022-12-12T23:47:29.676786Z',
  },
  {
    brandfolder_account_id: 0,
    client_name: 'Client9',
    id: '9',
    workflows: [{ salsify: '1' }, { salsify: '2' }, { highspot: '3' }, { highspot: '4' }],
    updated_at: '2022-12-12T23:47:29.676786Z',
  },
  {
    brandfolder_account_id: 0,
    client_name: 'Client10',
    id: '10',
    workflows: [{ salsify: '1' }, { salsify: '2' }, { highspot: '3' }, { highspot: '4' }],
    updated_at: '2022-12-12T23:47:29.676786Z',
  },
  {
    brandfolder_account_id: 0,
    client_name: 'Client11',
    id: '11',
    workflows: [{ salsify: '1' }, { salsify: '2' }, { highspot: '3' }, { highspot: '4' }],
    updated_at: '2022-12-12T23:47:29.676786Z',
  },
  {
    brandfolder_account_id: 0,
    client_name: 'Client12',
    id: '12',
    workflows: [{ salsify: '1' }, { salsify: '2' }, { highspot: '3' }, { highspot: '4' }],
    updated_at: '2022-12-12T23:47:29.676786Z',
  },
  {
    brandfolder_account_id: 0,
    client_name: 'Client13',
    id: '13',
    workflows: [{ salsify: '1' }, { salsify: '2' }, { highspot: '3' }, { highspot: '4' }],
    updated_at: '2022-12-12T23:47:29.676786Z',
  },
  {
    brandfolder_account_id: 0,
    client_name: 'Client14',
    id: '14',
    workflows: [{ salsify: '1' }, { salsify: '2' }, { highspot: '3' }, { highspot: '4' }],
    updated_at: '2022-12-12T23:47:29.676786Z',
  },
  {
    brandfolder_account_id: 0,
    client_name: 'Client15',
    id: '15',
    workflows: [{ salsify: '1' }, { salsify: '2' }, { highspot: '3' }, { highspot: '4' }],
    updated_at: '2022-12-12T23:47:29.676786Z',
  },
  {
    brandfolder_account_id: 0,
    client_name: 'Client12',
    id: '16',
    workflows: [{ salsify: '1' }, { salsify: '2' }, { highspot: '3' }, { highspot: '4' }],
    updated_at: '2022-12-12T23:47:29.676786Z',
  },
  {
    brandfolder_account_id: 0,
    client_name: 'Client13',
    id: '17',
    workflows: [{ salsify: '1' }, { salsify: '2' }, { highspot: '3' }, { highspot: '4' }],
    updated_at: '2022-12-12T23:47:29.676786Z',
  },
  {
    brandfolder_account_id: 0,
    client_name: 'Client18',
    id: '18',
    workflows: [{ salsify: '1' }, { salsify: '2' }, { highspot: '3' }, { highspot: '4' }],
    updated_at: '2022-12-12T23:47:29.676786Z',
  },
  {
    brandfolder_account_id: 0,
    client_name: 'Client19',
    id: '19',
    workflows: [{ salsify: '1' }, { salsify: '2' }, { highspot: '3' }, { highspot: '4' }],
    updated_at: '2022-12-12T23:47:29.676786Z',
  },
  {
    brandfolder_account_id: 0,
    client_name: 'Client20',
    id: '20',
    workflows: [{ salsify: '1' }, { salsify: '2' }, { highspot: '3' }, { highspot: '4' }],
    updated_at: '2022-12-12T23:47:29.676786Z',
  },
  {
    brandfolder_account_id: 0,
    client_name: 'Client21',
    id: '21',
    workflows: [{ salsify: '1' }, { salsify: '2' }, { highspot: '3' }, { highspot: '4' }],
    updated_at: '2022-12-12T23:47:29.676786Z',
  },
  {
    brandfolder_account_id: 0,
    client_name: 'Client22',
    id: '22',
    workflows: [{ salsify: '1' }, { salsify: '2' }, { highspot: '3' }, { highspot: '4' }],
    updated_at: '2022-12-12T23:47:29.676786Z',
  },
  {
    brandfolder_account_id: 0,
    client_name: 'Client23',
    id: '23',
    workflows: [{ salsify: '1' }, { salsify: '2' }, { highspot: '3' }, { highspot: '4' }],
    updated_at: '2022-12-12T23:47:29.676786Z',
  },
  {
    brandfolder_account_id: 0,
    client_name: 'Client24',
    id: '24',
    workflows: [{ salsify: '1' }, { salsify: '2' }, { highspot: '3' }, { highspot: '4' }],
    updated_at: '2022-12-12T23:47:29.676786Z',
  },
  {
    brandfolder_account_id: 0,
    client_name: 'Client25',
    id: '25',
    workflows: [{ salsify: '1' }, { salsify: '2' }, { highspot: '3' }, { highspot: '4' }],
    updated_at: '2022-12-12T23:47:29.676786Z',
  },
  {
    brandfolder_account_id: 0,
    client_name: 'Client26',
    id: '26',
    workflows: [{ salsify: '1' }, { salsify: '2' }, { highspot: '3' }, { highspot: '4' }],
    updated_at: '2022-12-12T23:47:29.676786Z',
  },
  {
    brandfolder_account_id: 0,
    client_name: 'Client27',
    id: '27',
    workflows: [{ salsify: '1' }, { salsify: '2' }, { highspot: '3' }, { highspot: '4' }],
    updated_at: '2022-12-12T23:47:29.676786Z',
  },
  {
    brandfolder_account_id: 0,
    client_name: 'Client28',
    id: '28',
    workflows: [{ salsify: '1' }, { salsify: '2' }, { highspot: '3' }, { highspot: '4' }],
    updated_at: '2022-12-12T23:47:29.676786Z',
  },
  {
    brandfolder_account_id: 0,
    client_name: 'Client29',
    id: '29',
    workflows: [{ salsify: '1' }, { salsify: '2' }, { highspot: '3' }, { highspot: '4' }],
    updated_at: '2022-12-12T23:47:29.676786Z',
  },
  {
    brandfolder_account_id: 0,
    client_name: 'Client30',
    id: '30',
    workflows: [{ salsify: '1' }, { salsify: '2' }, { highspot: '3' }, { highspot: '4' }],
    updated_at: '2022-12-12T23:47:29.676786Z',
  },
];

export const mockClientsData: ClientApiResponseData = {
  data: { clients: mockClients },
  meta: {
    total_count: mockClients.length,
    total_pages: 1,
  },
};
