import React, { useEffect } from 'react';
import { CreateWorkflowInputField } from '../../common/components/create-workflow-input-field/create-workflow-input-field';
import { useFormContext } from 'react-hook-form';
import { lettersAndNumberMatch } from '@integration-frontends/common/utils/validation';
import { BFErrorLabel } from '@integration-frontends/common/ui';

export interface SalsifyCredentialFormProps {
  credsComplete?: (c: boolean) => void;
  credFormData?: any;
}

export const SalsifyCredentialForm = ({
  credsComplete,
  credFormData,
}: SalsifyCredentialFormProps) => {
  const {
    register,
    formState: { errors },
    setValue,
    watch,
  } = useFormContext();

  const credName = watch('credential_name');
  const apiKey = watch('api_key');

  useEffect(() => {
    if (credsComplete && credFormData) {
      if (credName && apiKey) {
        credsComplete(true);
        credFormData({
          credential_name: credName,
          api_key: apiKey,
        });
      } else {
        credsComplete(false);
      }
    }
  }, [credName, apiKey]);

  return (
    <>
      <CreateWorkflowInputField
        label={'Salsify credentials name'}
        value={credName}
        inputId={'set-salsify-cred-name'}
        placeholder={'Enter a credential name'}
        tooltip="Give a descriptive name to this set of credentials."
        onChange={(e) => {
          setValue('credential_name', e.target.value || ('' as string));
        }}
        validation={register('credential_name', {
          required: true,
          maxLength: 50,
          minLength: 5,
          pattern: lettersAndNumberMatch,
        })}
      />
      {errors.credential_name?.type === 'required' && (
        <BFErrorLabel role="alert">Please choose a unique name for this credential.</BFErrorLabel>
      )}
      {errors.credential_name?.type === 'maxLength' ||
        (errors.credential_name?.type === 'minLength' && (
          <BFErrorLabel role="alert">Credential name must be 5-50 characters long</BFErrorLabel>
        ))}
      {errors.credential_name?.type === 'pattern' && (
        <BFErrorLabel role="alert">Please use only letters and numbers.</BFErrorLabel>
      )}

      <CreateWorkflowInputField
        label={'Salsify API key'}
        placeholder={'Enter API key'}
        value={apiKey}
        inputId={'set-salsify-api-key'}
        onChange={(e) => {
          setValue('api_key', e.target.value || ('' as string));
        }}
        validation={register('api_key', { required: true })}
      />
      {errors.api_key?.type === 'required' && (
        <BFErrorLabel role="alert">Please verify the API key.</BFErrorLabel>
      )}
    </>
  );
};
