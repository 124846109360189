import { ILogger, getObservabilityService } from '@integration-frontends/core';
import { injectable } from 'inversify';
import {
  ApiDataResponseError,
  CreateClientBody,
  CreateCredentialBody,
  OauthBody,
  GetBrandfolderFormInfoBody,
  GetHighspotFormInfoBody,
  GetHubspotFormInfoBody,
  GetSalsifyFormInfoBody,
  Options,
  GetWrikeFormInfoBody,
  GetSeismicFormInfoBody,
  GetAsanaFormInfoBody,
} from './model';
import * as _promiseRetry from 'promise-retry';
import { default as _rollupPromiseRetry } from 'promise-retry';
import { isEmpty } from 'ramda';
import {
  CreateWorkflowBody,
  UpdateWorkflowBody,
} from '@integration-frontends/workflow-manager/core/model';
import { environment } from '@integration-frontends/apps/workflows-admin-ui/src/environments/environment';

// using "* as" breaks the rollup build so we need to use this workaround
// more info: https://github.com/rollup/rollup/issues/1267
const promiseRetry = _rollupPromiseRetry || _promiseRetry;

export const TEMPORAL_API_TOKEN = 'TEMPORAL_API';
const RETRY_COUNT = 3;

const getRequestsInFlight = {};
const createCredentialEndpoint = '/v1/admin/credentials';

export type GetOptions = Options & {
  batchRequests?: boolean;
};

@injectable()
export class TemporalApi {
  constructor(private baseUrl: string, private logger: ILogger) {}

  async createHighspotWorkflow(apiKey: string, attributes: any) {
    return await this.post(apiKey, '/highspot/workflow', attributes);
  }

  async createHubspotWorkflow(apiKey: string, attributes: any) {
    return await this.post(apiKey, '/hubspot/workflow', attributes);
  }

  // used for Wrike and Asana
  async oauthSignIn(apiKey: string, attributes: OauthBody, clientId) {
    return await this.post(
      apiKey,
      '/v1/oauth',
      { data: attributes },
      { headers: { 'Wfm-Client-Id': clientId } },
    );
  }

  async listClients(apiKey: string, per: string, page: string) {
    return await this.get(apiKey, `/v1/workflow-manager/clients?per=${per}&page=${page}`);
  }

  async createClient(apiKey: string, attributes: CreateClientBody) {
    return await this.post(apiKey, '/v1/workflow-manager/clients', { data: { attributes } });
  }

  async listCredentials(apiKey: string, clientId: string) {
    return await this.get(
      apiKey,
      createCredentialEndpoint,
      {},
      { headers: { 'Wfm-Client-Id': clientId } },
    );
  }

  async createIntegrationCredential(
    apiKey: string,
    attributes: CreateCredentialBody,
    clientId: string,
  ) {
    return await this.post(
      apiKey,
      createCredentialEndpoint,
      { data: attributes },
      { headers: { 'Wfm-Client-Id': clientId } },
    );
  }

  async listWorkflows(apiKey: string, clientId: string) {
    return await this.get(
      apiKey,
      `/v1/workflow-manager/clients/${clientId}/workflows`,
      {},
      { headers: { 'Wfm-Client-Id': clientId } },
    );
  }

  async createWorkflow(apiKey: string, clientId: string, data: CreateWorkflowBody) {
    return await this.post(
      apiKey,
      `/v2/workflows/${data.service}`,
      { data },
      { headers: { 'Wfm-Client-Id': clientId } },
    );
  }

  async updateWorkflow(
    apiKey: string,
    clientId: string,
    workflowId: string,
    data: UpdateWorkflowBody,
  ) {
    return await this.put(
      apiKey,
      `/v2/workflows/${data.service}/${workflowId}`,
      { data },
      { headers: { 'Wfm-Client-Id': clientId } },
    );
  }

  async getWorkflow(apiKey: string, clientId: string, workflowId: string, service: string) {
    return await this.get(
      apiKey,
      `/v2/workflows/${service}/${workflowId}`,
      {},
      { headers: { 'Wfm-Client-Id': clientId } },
    );
  }

  async getAsanaFormInfo(apiKey: string, clientId: string, attributes: GetAsanaFormInfoBody) {
    return await this.post(
      apiKey,
      `/v1/workflow-manager/asana/form-info`,
      { data: attributes },
      { headers: { 'Wfm-Client-Id': clientId } },
    );
  }

  async getHighspotFormInfo(apiKey: string, clientId: string, attributes: GetHighspotFormInfoBody) {
    return await this.post(
      apiKey,
      `/v1/workflow-manager/highspot/form-info`,
      { data: attributes },
      { headers: { 'Wfm-Client-Id': clientId } },
    );
  }

  async getHubspotFormInfo(apiKey: string, clientId: string, attributes: GetHubspotFormInfoBody) {
    let url;

    if (attributes?.per && attributes?.page) {
      url = `/v1/workflow-manager/hubspot/form-info?per=${attributes.per}&page=${attributes.page}`;
    } else if (attributes?.per) {
      url = `/v1/workflow-manager/hubspot/form-info?per=${attributes.per}&page=1`;
    }

    return await this.post(
      apiKey,
      url,
      { data: attributes },
      { headers: { 'Wfm-Client-Id': clientId } },
    );
  }

  async getWrikeFormInfo(apiKey: string, clientId: string, attributes: GetWrikeFormInfoBody) {
    return await this.post(
      apiKey,
      `/v1/workflow-manager/wrike/form-info`,
      { data: attributes },
      { headers: { 'Wfm-Client-Id': clientId } },
    );
  }

  async getSalsifyFormInfo(apiKey: string, clientId: string, attributes: GetSalsifyFormInfoBody) {
    return await this.post(
      apiKey,
      `/v1/workflow-manager/salsify/form-info`,
      { data: attributes },
      {
        headers: { 'Wfm-Client-Id': clientId },
      },
    );
  }

  async getSeismicFormInfo(apiKey: string, clientId: string, attributes: GetSeismicFormInfoBody) {
    let url;

    if (attributes?.per && attributes?.page) {
      url = `/v1/workflow-manager/seismic/form-info?per=${attributes.per}&page=${attributes.page}`;
    } else if (attributes?.per) {
      url = `/v1/workflow-manager/seismic/form-info?per=${attributes.per}&page=1`;
    }

    return await this.post(
      apiKey,
      url,
      { data: attributes },
      { headers: { 'Wfm-Client-Id': clientId } },
    );
  }

  async getBrandfolderFormInfo(
    apiKey: string,
    clientId: string,
    attributes: GetBrandfolderFormInfoBody,
  ) {
    return await this.post(
      apiKey,
      `/v1/workflow-manager/brandfolder/form-info`,
      { data: attributes },
      { headers: { 'Wfm-Client-Id': clientId } },
    );
  }

  async listCustomFieldKeys(
    apiKey: string,
    clientId: string,
    attributes: GetBrandfolderFormInfoBody,
  ) {
    return await this.post(
      apiKey,
      `/v1/workflow-manager/brandfolder/form-info?brandfolder_key=${attributes.brandfolder_key}`,
      { data: attributes },
      { headers: { 'Wfm-Client-Id': clientId } },
    );
  }

  async refreshBrandfolderOauthToken(refreshToken: string): Promise<any> {
    const init: RequestInit = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: `grant_type=refresh_token&refresh_token=${refreshToken}&scope=openid offline_access&client_id=${environment.oauthClientId}`,
    };

    const resp = await fetch(`${environment.oauthBaseUrl}/oauth2/token`, init);

    if (isError(resp)) {
      const body = await resp.json();
      const errResp: ApiDataResponseError = {
        errors: [{ title: resp.statusText, detail: body }],
      };
      return errResp;
    }

    const json = await resp.json();
    return json;
  }

  private async get(
    apiKey: string,
    path: string,
    options: GetOptions = {},
    init: RequestInit = {},
  ) {
    const { batchRequests = true } = options;
    const callString = `${apiKey}${path}`;

    function fetchData(this: TemporalApi) {
      return promiseRetry(async (retry, counter) => {
        function handleRetry() {
          if (counter <= RETRY_COUNT) {
            retry();
          } else {
            return null;
          }
        }

        try {
          const data = await this.fetchFromApi(apiKey, `${path}`, {
            ...init,
            method: 'GET',
          });

          if (!data || isEmpty(data)) {
            return handleRetry();
          }

          return data;
        } catch (e) {
          getObservabilityService().addError(e);
          return handleRetry();
        }
      });
    }

    if (batchRequests) {
      if (!getRequestsInFlight[callString]) {
        const dataPromise = fetchData.bind(this)();
        getRequestsInFlight[callString] = dataPromise;
        dataPromise.then(() => (getRequestsInFlight[callString] = false));
      }
      return await getRequestsInFlight[callString];
    } else {
      return await fetchData.bind(this)();
    }
  }

  private async post(apiKey: string, path: string, body: any, init: RequestInit = {}) {
    const response = await this.fetchFromApi(apiKey, `${path}`, {
      ...init,
      method: 'POST',
      body: JSON.stringify(body),
    });
    return response;
  }

  private async put(apiKey: string, path: string, body: any, init: RequestInit = {}) {
    const response = await this.fetchFromApi(apiKey, `${path}`, {
      ...init,
      method: 'PUT',
      body: JSON.stringify(body),
    });
    return response;
  }

  private async fetchFromApi(apiKey: string, path: string, init: RequestInit = {}) {
    try {
      const response = await fetch(`${this.baseUrl}${path}`, {
        ...init,
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${apiKey}`,
          'Content-Type': 'application/json',
          ...init.headers,
        },
      });

      if (isCredentialValidationError(response)) {
        const body = await response.json();
        const errResp: ApiDataResponseError = {
          errors: [{ title: response.statusText, detail: body.message }],
        };
        return errResp;
      }

      if (isError(response)) {
        const body = await response.json();
        const errResp: ApiDataResponseError = {
          errors: [{ title: response.statusText, detail: body }],
        };
        return errResp;
      }

      return await response.json();
    } catch (e) {
      getObservabilityService().addError(e);
      this.logger.error(e);
      throw e;
    }
  }
}

// the temporal API responds with a 401 if third party credentials are invalid.
// in this case, we would never want to try to refresh and should return a generic error response instead.
function isCredentialValidationError(response): boolean {
  return response.status == 401 && response.url.includes(createCredentialEndpoint);
}

function isError(response): boolean {
  return 200 < response.status && response.status >= 400;
}
